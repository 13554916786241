<template>
<div class="user">
    <div class="header">
        <Labels nome="Credenciais" cor="#72848c" size="25px" />
        <div class="search">
            <div class="input-group">
                <input type="text" class="form-control input-search" placeholder="pesquisa" v-model="search">
                <span class="input-group-btn">
                    <button type="button" class="btn btn-default btn-search" v-on:click="rules"><i class="fas fa-search icon-search"></i></button>
                </span>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="cards">
            <Card title="Funções" color="#72848c" colorbackground="#ffffff" fontSize="18px" text_tooltip="Adicionar Usuário" view_select="true" view_add="false">
                <template v-slot:content class="tabs">
                    <div class="menu">
                        <select class="form-select form-select-sm" v-model="credential" @change="rules">
                            <option value="0" disabled>Selecione uma credencial</option>
                            <option v-for="(credential, index) in credentials" :value="credential.code" :key="index">{{credential.name}}</option>
                        </select>
                    </div>
                    <div v-if="roles == 'vazio'" class="dados_empty">
                        <p>Não há dados a serem exibidos!</p>
                    </div>
                    <div v-if="roles != 'vazio'" class="tables">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th>Função</th>
                                    <th>Nível</th>
                                    <th v-if="credential_edit">Controle</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(iten, index) in roles" :key="index">
                                    <td v-for="(i, index) in order(iten)" :key="index">{{i}}</td>
                                    <td v-if="credential_edit">
                                        <div class="controls">
                                            <button @click="loadrolecredential(iten, 'update')" type="button" class="btn btn-info btn-sm" data-bs-toggle="modal" data-bs-target="#modal_add">Editar</button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </template>
            </Card>
        </div>
        <div class="col-md-12">
            <div v-if="load" class="loading">
                <button class="btn btn-primary" type="button" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    <span class="visually-hidden">Loading...</span>
                </button>
            </div>
        </div>
        <div class="modal fade" id="modal_add" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Atualizar funcionalidade</h5>
                    </div>
                    <div class="modal-body">
                        <form>
                            <div class="row">
                                <input id="sector-id" type="hidden" v-model="roles_credentials.id" />
                                <div class="col-md-8">
                                    <label for="role" class="form-label">Funcionalidade</label>
                                    <input autocomplete="off" type="text" class="form-control" id="role" v-model="roles_credentials.name">
                                </div>
                                <div class="col-md-4">
                                    <label for="credential" class="form-label">Credencial</label>
                                    <select class="form-select" id="credential" v-model="roles_credentials.name_credential_code">
                                        <option v-for="(credential, index) in credentials" :value="credential.code" :key="index">{{credential.name}}</option>
                                    </select>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button @click="reset('cancelar')" type="button" class="btn btn-danger" data-bs-dismiss="modal">Cancelar</button>
                        <button @click="save" type="button" class="btn btn-primary" data-bs-dismiss="modal">Salvar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    baseApiUrl,
    userKey
} from '@/global'
import axios from 'axios'
import Labels from "@/components/Labels/Label"
import Card from "@/components/Cards/Card"

export default {
    name: "user",
    data: function () {
        return {
            roles: '',
            credentials: '',
            credential: 0,
            roles_credentials: '',
            search: '',
            load: false,
            credential_edit: false,
        }
    },
    components: {
        Labels,
        Card,
    },
    mounted() {

        const access_credential = this.$cookie.getCookie(userKey)

        var permissoes = access_credential.permissions

        var permissoes_array = permissoes.split(',')

        this.credential_edit = permissoes_array.includes('749993') == true ? true : false

        this.loadcredentials();
    },
    methods: {
        loadcredentials() {

            this.load = true

            const access_token = this.$cookie.getCookie(userKey)

            var method = 'post'
            var rotas = 'name-credential-list'

            var params = {
                credential_code: access_token.credential_code
            }

            const config = {
                headers: {
                    'Accept': "application/json",
                    Authorization: `Bearer ${access_token.token}`
                }
            }

            axios[method](`${baseApiUrl}/${rotas}`, params, config, {
                timeout: 5000
            }).then(res => {

                if (res.data.list_name_credential == 'vazio') {

                    this.credentials = 'vazio'

                    this.load = false
                } else {

                    this.credentials = res.data.list_name_credential

                    this.load = false

                }
            }).catch((e) => {

                this.load = false

                if (e.code === 'ECONNABORTED') {
                    this.load = false
                }
            });
        },
        rules() {

            var method = 'post'
            var rotas = 'roles-credential-list'

            var params = {
                code: this.credential,
                pesquisa: this.search
            }

            const access_token = this.$cookie.getCookie(userKey)

            const config = {
                headers: {
                    Authorization: `Bearer ${access_token.token}`
                }
            };

            axios[method](`${baseApiUrl}/${rotas}`, params, config, {
                timeout: 5000
            }).then(res => {

                if (res.data.list_roles_credential == 'vazio') {

                    this.roles = 'vazio'

                    this.load = false
                } else {

                    this.roles = res.data.list_roles_credential

                    this.load = false

                }
            }).catch((e) => {

                if (e.code === 'ECONNABORTED') {
                    //this.load = false
                }
            });
        },
        order(iten) {

            var list = Object.values(iten)

            list = list.slice(2, 4)

            return list;
        },
        reset(mode = 'add') {
            this.mode = 'add'
            this.roles_credentials = {}

            if (mode != 'info' && mode != 'cancelar') {
                this.rules()
            }
        },
        save() {

            this.load = true

            const method = 'post'

            var rotas = 'roles-credential-update'

            const access_token = this.$cookie.getCookie(userKey)

            const config = {
                headers: {
                    'Accept': "application/json",
                    Authorization: `Bearer ${access_token.token}`
                }
            }

            axios[method](`${baseApiUrl}/${rotas}`, this.roles_credentials, config, {
                timeout: 5000
            }).then(res => {

                this.load = false

                if (res.data.code == '2000') {

                    this.$toast.success(res.data.message_role)
                } else if (res.data.code == '3000') {
                    this.$toast.success(res.data.message_role)
                } else {
                    this.$toast.success(res.data.message_role)
                }

                this.reset()
            }).catch((e) => {

                if (e.code === 'ECONNABORTED') {
                    this.load = false
                }
            });

        },
        loadrolecredential(role, mode = 'add') {

            if (mode === 'delete') {
                this.larger = ''
            } else {
                this.larger = 'modal-lg'
            }

            this.mode = mode

            this.roles_credentials = {
                ...role
            }
        },
    }
}
</script>

<style scoped>
/*-------------------------------------- Cabeçalho Companies --------------------------------------*/
.menu {
    position: absolute;
    right: 0;
    top: 15px;
    margin-right: 15px;
}

.user {
    margin: 25px;
}

.user .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

/* -------------- Cabeçalho ------------------*/
.search .input-search {
    border-radius: 3px;
    border-color: #744cbc;
}

.search .input-search:focus,
.btn-search {
    box-shadow: 0 0 0 0 !important;
    outline: none !important;
}

.search .icon-search {
    color: #ffffff;
    transform: rotate(90deg);
}

.search .btn-search {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-color: #744cbc;
}

.search .btn-search:hover {
    background-color: #886aea !important;
}

/*--------------------------------------------- Table ---------------------------------------------*/
.user .cards .tables thead tr th {
    border-style: solid;
    border-bottom-width: 2px;
    color: rgb(114, 132, 140);
    border-bottom-color: rgb(222, 226, 230) !important;
}

.user .cards .tables thead th {
    font-size: 15px;
    font-weight: 600;
    padding-top: 10px;
    padding-bottom: 10px;
}

.user .cards .tables tbody td {
    font-size: 14px;
    padding-top: 11px;
    padding-bottom: 11px;
    color: #72848c;
}

.user .cards .tables tbody button {
    color: #ffffff;
    margin: 0 2px;
    box-shadow: 0 0 0 0 !important;
    outline: none !important;
}

.user .cards .pagination {
    display: flex;
    justify-content: center;
}

/*------------------------------------------- Paginação ------------------------------------------ */
.pagination-table {
    margin-top: 30px;
}

.pagination-table .page-item .page-link {
    color: #72848c;
    text-decoration: none;
    border: 1px solid #dee2e6;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    cursor: pointer;
}

.pagination-table .page-item.active .page-link {
    color: #ffffff;
    background-color: #744cbc;
    border-color: #744cbc;
}

/*--------------------------------------------- Modal ---------------------------------------------*/
.user .modal-content {
    border: none;
}

/*------------------------------------------- Modal Header ----------------------------------------*/
.user .modal-header {
    padding: 10px 20px;
    background-color: #744cbc;
}

.user .modal-header .modal-title {
    color: #ffffff;
    font-size: 18px;
}

/*-------------------------------------------- Modal Body -----------------------------------------*/
.user .modal-body .msg {
    text-align: center;
    color: #72848c;
}

.user .modal-body .msg h6 {
    padding: 20px 0px 12px 0px;
    font-size: 17px;
}

.user .modal-body label {
    font-size: 15px;
    color: #72848c;
    margin-top: 10px;
    margin-left: 1px;
    margin-bottom: 5px;
}

/*------------------------------------------- Modal Footer ----------------------------------------*/
.user .modal-footer {
    padding: 8px;
}

.user .modal-footer button {
    border: none;
}

/*----------------------------------------- Mensagem De Erro --------------------------------------*/
.msg_error {
    color: #ff0000;
    font-size: 14px;
}

input,
input:focus,
.is-invalid {
    box-shadow: 0 0 0 0;
    outline: 0;
}

.is-invalid {
    box-shadow: 0 0 0 0 !important;
    outline: 0 !important;
}

.dados_empty {
    color: #72848c;
    font-weight: 600;

}

.loading button {
    position: absolute;
    top: calc(50% - 35px);
    left: calc(50%);
    margin-left: 90px;
    width: 70px;
    height: 70px;
    background-color: #3b0253;
    border: none;
}

.loading span {
    width: 30px;
    height: 30px;
}
</style>
