<template>
<div class="people">
    <div class="header">
        <Labels nome="Colaboradores" cor="#72848c" size="25px" />
        <div class="search">
            <div class="input-group">
                <input type="text" class="form-control input-search" placeholder="pesquisa" v-model="search">
                <span class="input-group-btn">
                    <button type="button" class="btn btn-default btn-search" v-on:click="loadsearch"><i class="fas fa-search icon-search"></i></button>
                </span>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="cards">
            <Card title="Colaboradores Cadastrados" color="#72848c" colorbackground="#ffffff" fontSize="18px" text_tooltip="Adicionar Colaborador" :view_add="people_add" :view_active="view_add_button">
                <template v-slot:content class="tabs">
                    <div class="row">
                        <div v-if="false" class="col-md-3">
                            <select class="form-select form-select-sm select_filter" id="status" v-model="company_selected" @change="loadpeoples">
                                <option value="0" selected disabled>Selecione uma empresa</option>
                                <option v-for="(company_list, index) in companies_list" :value="company_list.id" :key="index">{{company_list.fantasy_name}}</option>
                            </select>
                        </div>
                        <div v-if="company_units" class="col-md-3">
                            <select class="form-select form-select-sm select_filter" id="status" v-model="company_unit_selected" @change="loadpeople_unit">
                                <option value="0" selected disabled>Selecione uma unidade</option>
                                <option v-for="(company_unit, index) in company_units" :value="company_unit.id" :key="index">{{company_unit.fantasy_name}}</option>
                            </select>
                        </div>
                        <div v-if="zones" class="col-md-3">
                            <select class="form-select form-select-sm select_filter" id="status" v-model="zone_selected" @change="loadpeople_zone">
                                <option value="0" selected disabled>Selecione uma área</option>
                                <option v-for="(zone, index) in zones" :value="zone.id" :key="index">{{zone.name}}</option>
                            </select>
                        </div>
                        <div v-if="sectors" class="col-md-3">
                            <select class="form-select form-select-sm select_filter" id="status" v-model="sector_selected" @change="loadpeople_sector">
                                <option value="0" selected disabled>Selecione um setor</option>
                                <option v-for="(sector, index) in sectors" :value="sector.id" :key="index">{{sector.name}}</option>
                            </select>
                        </div>
                    </div>
                    <Tab name_tab1="Ativo" name_tab2="Inativo">
                        <template v-slot:Ativo>
                            <div v-if="peoples_active == 'vazio'" class="dados_empty">
                                <p>Não há dados a serem exibidos!</p>
                            </div>
                            <div v-if="peoples_active != 'vazio' && peoples_active != 'nulo'" class="tables">
                                <table class="table table-hover">
                                    <thead>
                                        <tr>
                                            <th>Código</th>
                                            <th>Colaborador</th>
                                            <th>Status</th>
                                            <th v-if="people_info || people_edit || people_delete">Controle</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(iten, index) in peoples_active" :key="index">
                                            <td v-for="(i, index) in order(iten)" :key="index">{{i}}</td>
                                            <td v-if="people_info || people_edit || people_delete">
                                                <div class="controls">
                                                    <button v-if="people_info" @click="loadpeople(iten, 'info')" type="button" class="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#modal_delete">Detalhes</button>
                                                    <button v-if="people_edit" @click="loadpeople(iten, 'update')" type="button" class="btn btn-info btn-sm" data-bs-toggle="modal" data-bs-target="#modal_add">Editar</button>
                                                    <button v-if="people_delete" @click="loadpeople(iten, 'delete')" type="button" class="btn btn-danger btn-sm" data-bs-toggle="modal" data-bs-target="#modal_delete">Excluir</button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div v-if="paginate_view_a" class="paginate">
                                <v-pagination v-model="current_page_a" :pages="total_page_a" :range-size="1" active-color="#744CBC" @update:modelValue="updateHandler" />
                            </div>
                        </template>
                        <template v-slot:Inativo>
                            <div v-if="peoples_inactive == 'vazio'" class="dados_empty">
                                <p>Não há dados a serem exibidos!</p>
                            </div>
                            <div v-if="peoples_inactive != 'vazio' && peoples_inactive != 'nulo'" class="tables">
                                <table class="table table-hover">
                                    <thead>
                                        <tr>
                                            <th>Código</th>
                                            <th>Colaborador</th>
                                            <th>Status</th>
                                            <th v-if="people_info || people_edit || people_delete">Controle</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(iten, index) in peoples_inactive" :key="index">
                                            <td v-for="(i, index) in order(iten)" :key="index">{{i}}</td>
                                            <td v-if="people_info || people_edit || people_delete">
                                                <div class="controls">
                                                    <button v-if="people_info" @click="loadpeople(iten, 'info')" type="button" class="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#modal_delete">Detalhes</button>
                                                    <button v-if="people_edit" @click="loadpeople(iten, 'update')" type="button" class="btn btn-info btn-sm" data-bs-toggle="modal" data-bs-target="#modal_add">Editar</button>
                                                    <button v-if="people_delete" @click="loadpeople(iten, 'delete')" type="button" class="btn btn-danger btn-sm" data-bs-toggle="modal" data-bs-target="#modal_delete">Excluir</button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div v-if="paginate_view_i" class="paginate">
                                <v-pagination v-model="current_page_i" :pages="total_page_i" :range-size="1" active-color="#744CBC" @update:modelValue="updateHandler" />
                            </div>
                        </template>
                    </Tab>
                </template>
            </Card>
        </div>
        <div class="col-md-12">
            <div v-if="load" class="loading">
                <button class="btn btn-primary" type="button" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    <span class="visually-hidden">Loading...</span>
                </button>
            </div>
        </div>
    </div>
    <div class="modal fade" id="modal_add" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 v-if="mode === 'add'" class="modal-title" id="exampleModalLabel">Cadastrar Colaborador</h5>
                    <h5 v-if="mode === 'update'" class="modal-title" id="exampleModalLabel">Atualizar Colaborador</h5>
                </div>
                <div class="modal-body">
                    <form>
                        <div class="row">
                            <input id="people-id" type="hidden" v-model="people.id" />
                            <div class="col-md-4">
                                <label for="code" class="form-label">Código</label>
                                <input autocomplete="off" type="text" class="form-control" :class="{'is-invalid': v$.people.code.$error}" id="code" v-model="people.code" maxlength="31">
                                <div v-if="v$.people.code.$error" class="msg_error">{{codeErrors}}</div>
                            </div>
                            <div class="col-md-8">
                                <label for="people" class="form-label">Colaborador</label>
                                <input autocomplete="off" type="text" class="form-control" :class="{'is-invalid': v$.people.name.$error}" id="people" v-model="people.name" maxlength="101">
                                <div v-if="v$.people.name.$error" class="msg_error">{{nameErrors}}</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <label for="matriculation" class="form-label">Matrícula</label>
                                <input autocomplete="off" type="text" class="form-control" :class="{'is-invalid': v$.people.matriculation.$error}" id="matriculation" v-model="people.matriculation" maxlength="31">
                                <div v-if="v$.people.matriculation.$error" class="msg_error">{{matriculationErrors}}</div>
                            </div>
                            <div class="col-md-4">
                                <label for="cpf" class="form-label">Cpf</label>
                                <input autocomplete="off" type="text" class="form-control" id="cpf" v-model="people.cpf" v-maska="'###.###.###-##'">
                            </div>
                            <div class="col-md-4">
                                <label for="cost_center" class="form-label">Centro de custo</label>
                                <input autocomplete="off" type="text" class="form-control" :class="{'is-invalid': v$.people.cost_center.$error}" id="cost_center" v-model="people.cost_center" maxlength="31">
                                <div v-if="v$.people.cost_center.$error" class="msg_error">{{cost_centerErrors}}</div>
                            </div>
                        </div>
                        <!--<div class="row">
                            <div class="col-md-6">
                                <label for="sector" class="form-label">Área</label>
                                <select class="form-select" :class="{'is-invalid': v$.people.sector_id.$error}" id="sector" v-model="people.sector_id">
                                    <option v-for="(sector, index) in sectors" :value="sector.id" :key="index">{{sector.name}}</option>
                                </select>
                                <div v-if="v$.people.sector_id.$error" class="msg_error">{{sector_idErrors}}</div>
                            </div>
                            <div class="col-md-6">
                                <label for="sector" class="form-label">Setor</label>
                                <select class="form-select" :class="{'is-invalid': v$.people.sector_id.$error}" id="sector" v-model="people.sector_id">
                                    <option v-for="(sector, index) in sectors" :value="sector.id" :key="index">{{sector.name}}</option>
                                </select>
                                <div v-if="v$.people.sector_id.$error" class="msg_error">{{sector_idErrors}}</div>
                            </div>
                        </div>-->
                        <div class="row">
                            <div class="col-md-6">
                                <label for="group_id" class="form-label">Grupo</label>
                                <select class="form-select" :class="{'is-invalid': v$.people.group_id.$error}" id="group" v-model="people.group_id">
                                    <option v-for="(group, index) in groups" :value="group.id" :key="index">{{group.name}}</option>
                                </select>
                                <div v-if="v$.people.group_id.$error" class="msg_error">{{group_idErrors}}</div>
                            </div>
                            <div class="col-md-6">
                                <label for="role" class="form-label">Função</label>
                                <select class="form-select" :class="{'is-invalid': v$.people.role_id.$error}" id="role" v-model="people.role_id">
                                    <option v-for="(role, index) in roles" :value="role.id" :key="index">{{role.name}}</option>
                                </select>
                                <div v-if="v$.people.role_id.$error" class="msg_error">{{role_idErrors}}</div>
                            </div>
                        </div>
                        <div class="row">
                            <div v-if="false" class="col-md-4">
                                <label for="login" class="form-label">Login</label>
                                <input autocomplete="off" type="text" class="form-control" :class="{'is-invalid': v$.people.login.$error}" id="login" v-model="people.login">
                                <div v-if="v$.people.login.$error" class="msg_error">{{loginErrors}}</div>
                            </div>
                            <div class="col-md-6">
                                <label for="tag_name" class="form-label">Crachá</label>
                                <input autocomplete="off" type="text" class="form-control" :class="{'is-invalid': v$.people.tag_name.$error}" id="tag_name" v-model="people.tag_name">
                                <div v-if="v$.people.tag_name.$error" class="msg_error">{{tag_nameErrors}}</div>
                            </div>
                            <div class="col-md-6">
                                <label for="status" class="form-label">Status</label>
                                <select class="form-select" id="status" v-model="people.status">
                                    <option v-for="(statu, index) in status" :value="statu" :key="index">{{statu}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <label for="obs" class="form-label">Observação</label>
                                <textarea rows="5" autocomplete="off" type="text" class="form-control" :class="{'is-invalid': v$.people.obs.$error}" id="obs" v-model="people.obs" maxlength="501"></textarea>
                                <div v-if="v$.people.obs.$error" class="msg_error">{{obsErrors}}</div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button @click="reset" type="button" class="btn btn-danger" data-bs-dismiss="modal">Cancelar</button>
                    <button :disabled="v$.$invalid" @click="save" type="button" class="btn btn-primary" data-bs-dismiss="modal">Salvar</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="modal_delete" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modal_info" aria-hidden="true">
        <div class="modal-dialog" :class="larger">
            <div class="modal-content">
                <input id="people-id" type="hidden" v-model="people.id" />
                <div class="modal-header">
                    <h5 v-if="mode === 'delete'" class="modal-title" id="exampleModalLabel">Excluir Colaborador</h5>
                    <h5 v-if="mode === 'info'" class="modal-title" id="exampleModalLabel">Informações Do Colaborador</h5>
                </div>
                <div class="modal-body">
                    <div v-if="mode === 'delete'">
                        <div class="msg">
                            <h6>Você tem certeza que deseja excluir o registro?</h6>
                        </div>
                    </div>
                    <div v-if="mode === 'info'">
                        <div class="row">
                            <div class="col-md-12">
                                <label class="label_info"><b>Código: </b><span>{{people.code}}</span></label>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-md-12">
                                <label class="label_info"><b>Nome: </b><span>{{people.name}}</span></label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <label class="label_info"><b>Matricula: </b><span>{{people.matriculation}}</span></label>
                            </div>
                            <div class="col-md-4">
                                <label class="label_info"><b>Cpf: </b><span>{{people.cpf}}</span></label>
                            </div>
                            <div class="col-md-4">
                                <label class="label_info"><b>Centro De Custo: </b><span>{{people.cost_center}}</span></label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <label class="label_info"><b>Grupo: </b><span>{{people.group_permissions_name}}</span></label>
                            </div>
                            <div class="col-md-4">
                                <label class="label_info"><b>Função: </b><span>{{people.roles_name}}</span></label>
                            </div>
                            <div class="col-md-4">
                                <label class="label_info"><b>Setor: </b><span>{{people.sectors_name}}</span></label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <label class="label_info"><b>Crachá: </b><span>{{people.tag_name}}</span></label>
                            </div>
                            <div v-if="false" class="col-md-4">
                                <label class="label_info"><b>Login: </b><span>{{people.login}}</span></label>
                            </div>
                            <div class="col-md-4">
                                <label class="label_info"><b>Status: </b><span>{{people.status}}</span></label>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-md-12">
                                <label class="label_info"><b>Obs: </b><span>{{people.obs}}</span></label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button v-if="mode == 'info'" @click="reset" type="button" class="btn btn-danger btn_left" data-bs-dismiss="modal">Fechar</button>
                    <button v-if="mode != 'info'" @click="reset" type="button" class="btn btn-danger btn_left" data-bs-dismiss="modal">Cancelar</button>
                    <button v-if="mode != 'info'" @click="remove" type="button" class="btn btn-primary btn_right" data-bs-dismiss="modal">Confirmar</button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import {
    required,
    maxLength
} from '@vuelidate/validators'
import {
    baseApiUrl,
    userKey
} from '@/global'

import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

import axios from 'axios'
import Labels from "@/components/Labels/Label"
import Card from "@/components/Cards/Card"
import Tab from "@/components/Tabs/Tab"

export default {
    name: "people",
    data: function () {
        return {
            v$: useVuelidate(),
            mode: 'add',
            people: {},
            peoples: [],
            peoples_active: [],
            peoples_inactive: [],
            itens: '',
            larger: 'modal-lg',
            status: ['Ativo', 'Inativo'],
            search: '',
            dados_per_page_a: 0,
            dados_per_page_i: 0,
            current_page_a: 1,
            current_page_i: 1,
            total_page_a: 1,
            total_page_i: 1,
            page: 1,
            paginate_view_a: '',
            paginate_view_i: '',
            msg_error: '',
            load: false,
            groups: '',
            sector_selected: '0',
            sectors: '',
            roles: '',
            zone_selected: '0',
            zones: '',
            people_add: true,
            people_info: true,
            people_edit: true,
            people_delete: true,
            companies_list: '',
            company_selected: '0',
            company_units: '',
            company_unit_selected: '0',
            view_add_button: false,

        }
    },
    validations() {
        return {
            people: {
                code: {
                    maxLength: maxLength(30),
                    $autoDirty: true
                },
                name: {
                    required,
                    maxLength: maxLength(100),
                    $autoDirty: true
                },
                matriculation: {
                    required,
                    maxLength: maxLength(30),
                    $autoDirty: true
                },
                cost_center: {
                    maxLength: maxLength(30),
                    $autoDirty: true
                },
                tag_name: {
                    maxLength: maxLength(60),
                    $autoDirty: true
                },
                login: {
                    $autoDirty: true
                },
                group_id: {
                    required,
                    $autoDirty: true
                },
                role_id: {
                    required,
                    $autoDirty: true
                },
                obs: {
                    maxLength: maxLength(500),
                    $autoDirty: true
                },
            }
        }
    },
    components: {
        Labels,
        Card,
        Tab,
        VPagination
    },
    mounted() {

        const access_credential = this.$cookie.getCookie(userKey)

        var permissoes = access_credential.permissions

        var permissoes_array = permissoes.split(',')

        this.people_add     = permissoes_array.includes('949983') == true ? true : false
        this.people_info    = permissoes_array.includes('949982') == true ? true : false
        this.people_edit    = permissoes_array.includes('949981') == true ? true : false
        this.people_delete  = permissoes_array.includes('949980') == true ? true : false


        this.people.status = this.status['0']

        this.loadcompanies()

        //this.loadpeoples();
    },
    computed: {
        codeErrors() {
            const code = this.v$.people.code
            if (!code.$dirty) {
                return ""
            }
            if (!code.maxLength.$response) {
                return "Limite máximo de 30 caracteres!"
            } else {
                return ""
            }
        },
        nameErrors() {
            const name = this.v$.people.name
            if (!name.$dirty) {
                return ""
            }
            if (!name.required.$response) {
                return "O campo é obrigatório!"
            } else if (!name.maxLength.$response) {
                return "Limite máximo de 100 caracteres!"
            } else {
                return ""
            }
        },
        matriculationErrors() {
            const matriculation = this.v$.people.matriculation
            if (!matriculation.$dirty) {
                return ""
            }
            if (!matriculation.required.$response) {
                return "O campo é obrigatório!"
            } else if (!matriculation.maxLength.$response) {
                return "Limite máximo de 30 caracteres!"
            } else {
                return ""
            }
        },
        cost_centerErrors() {
            const cost_center = this.v$.people.cost_center
            if (!cost_center.$dirty) {
                return ""
            }
            if (!cost_center.maxLength.$response) {
                return "Limite máximo de 30 caracteres!"
            } else {
                return ""
            }
        },
        tag_nameErrors() {
            const tag_name = this.v$.people.tag_name
            if (!tag_name.$dirty) {
                return ""
            }
            if (!tag_name.maxLength.$response) {
                return "Limite máximo de 30 caracteres!"
            } else {
                return ""
            }
        },
        loginErrors() {
            const login = this.v$.people.login
            if (!login.$dirty) {
                return ""
            }
            if (!login.required.$response) {
                return "O campo é obrigatório!"
            } else {
                return ""
            }
        },
        group_idErrors() {
            const group_id = this.v$.people.group_id
            if (!group_id.$dirty) {
                return ""
            }
            if (!group_id.required.$response) {
                return "O campo é obrigatório!"
            } else {
                return ""
            }
        },
        role_idErrors() {
            const role_id = this.v$.people.role_id
            if (!role_id.$dirty) {
                return ""
            }
            if (!role_id.required.$response) {
                return "O campo é obrigatório!"
            } else {
                return ""
            }
        },
        obsErrors() {
            const obs = this.v$.people.obs
            if (!obs.$dirty) {
                return ""
            }
            if (!obs.maxLength.$response) {
                return "Limite máximo de 500 caracteres!"
            } else {
                return ""
            }
        }
    },
    methods: {
        loadsearch() {

            this.page = 1

            if (this.sector_selected > 0) {
                this.loadpeople_sector();
            } else if (this.zone_selected > 0) {
                this.loadpeople_zone();
            } else if (this.company_unit_selected > 0) {
                this.loadpeople_unit();
            } else if (this.company_selected > 0) {
                this.loadpeoples()
            }
        },
        updateHandler(valor = 0) {

            this.page = valor

            if (this.sector_selected > 0) {
                this.loadpeople_sector();
            } else if (this.zone_selected > 0) {
                this.loadpeople_zone();
            } else if (this.company_unit_selected > 0) {
                this.loadpeople_unit();
            } else if (this.company_selected > 0) {
                this.loadpeoples(1)
            }
        },
        loadcompanies() {

            this.load = true

            const access_credential = this.$cookie.getCookie(userKey)

            var params = {
                page: this.page,
                search: this.search,
                company_id: access_credential.company_id,
                code: access_credential.credential_code,
                menu: 'geral'
            }

            var method = 'post'
            var rotas = 'company-list-all-active'

            const access_token = this.$cookie.getCookie(userKey)

            const config = {
                headers: {
                    Authorization: `Bearer ${access_token.token}`
                }
            };

            axios[method](`${baseApiUrl}/${rotas}`, params, config, {
                timeout: 5000
            }).then(res => {

                this.company_selected = res.data.companies_list_active[0].id

                this.load = false

                this.loadpeoples()

            }).catch((e) => {

                if (e.code === 'ECONNABORTED') {
                    this.load = false
                }
            });
        },
        loadpeoples(valor = 0) {

            this.load = true

            const access_token = this.$cookie.getCookie(userKey)

            if (valor != 1 || valor == 0) {
                this.page = 1
            }

            this.company_unit_selected = 0,
                this.zones = ''
            this.sectors = ''

            var method = 'post'
            var rotas = 'collaborator-dependency-company'

            var params = {
                page: this.page,
                search: this.search,
                company_id: access_token.company_id,
                credential_code: access_token.credential_code
            }

            const config = {
                headers: {
                    Authorization: `Bearer ${access_token.token}`
                }
            };

            axios[method](`${baseApiUrl}/${rotas}`, params, config, {
                timeout: 5000
            }).then(res => {

                this.company_units = res.data.company_units

                this.groups = res.data.list_groups_active
                this.roles = res.data.list_roles_active
                this.sectors = res.data.list_sectors_active

                this.load = false

                if (res.data.collaborator_list_active == 'vazio') {

                    this.peoples_active = 'vazio'
                    this.paginate_view_a = false

                    this.load = false
                } else {

                    this.total_page_a = res.data.collaborator_list_active.last_page
                    this.current_page_a = res.data.collaborator_list_active.current_page
                    this.dados_per_page_a = res.data.collaborator_list_active.data.length

                    this.paginate_view_a = true

                    var peoples_active = res.data.collaborator_list_active.data

                    var result_active = peoples_active.map(getList)

                    this.peoples_active = result_active

                    this.load = false

                }

                if (res.data.collaborator_list_inactive == 'vazio') {
                    this.peoples_inactive = 'vazio'
                    this.paginate_view_i = false

                    this.load = false
                } else {

                    this.total_page_i = res.data.collaborator_list_inactive.last_page
                    this.current_page_i = res.data.collaborator_list_inactive.current_page
                    this.dados_per_page_i = res.data.collaborator_list_inactive.data.length

                    this.paginate_view_i = true

                    var peoples_inactive = res.data.collaborator_list_inactive.data

                    var result_inactive = peoples_inactive.map(getList)

                    this.peoples_inactive = result_inactive

                    this.load = false

                }
            }).catch((e) => {

                this.load = false

                if (e.code === 'ECONNABORTED') {
                    this.load = false
                }
            });

            function getList(item) {
                return {
                    id: item.collaborator_id,
                    code: item.collaborator_code,
                    name: item.collaborator_name,
                    status: item.collaborator_status,
                    matriculation: item.collaborator_matriculation,
                    cpf: item.collaborator_cpf,
                    cost_center: item.collaborator_cost_center,
                    login: item.collaborator_login,
                    tag_name: item.collaborator_tag_name,
                    obs: item.collaborator_obs,
                    group_id: item.group_permission_id,
                    group_permissions_name: item.group_permission_name,
                    sector_id: item.sector_id,
                    sectors_name: item.sector_name,
                    role_id: item.role_id,
                    roles_name: item.role_name

                }
            }
        },
        loadpeople_unit() {

            this.load = true

            const access_token = this.$cookie.getCookie(userKey)


            this.zone_selected = 0
            this.sectors = ''

            var method = 'post'
            var rotas = 'collaborator-dependency-unit'

            var params = {
                page: this.page,
                search: this.search,
                company_id: access_token.company_id,
                company_unit_id: this.company_unit_selected,
                credential_code: access_token.credential_code
            }

            
            const config = {
                headers: {
                    Authorization: `Bearer ${access_token.token}`
                }
            };

            axios[method](`${baseApiUrl}/${rotas}`, params, config, {
                timeout: 5000
            }).then(res => {

                this.zones = res.data.zones_list_active

                this.sectors = res.data.sectors_list_active
                this.roles = res.data.roles_list_active
                this.groups = res.data.groups_list_active

                if (res.data.collaborators_list_active == 'vazio') {

                    this.peoples_active = 'vazio'
                    this.paginate_view_a = false

                    this.load = false
                } else {

                    this.total_page_a = res.data.collaborators_list_active.last_page
                    this.current_page_a = res.data.collaborators_list_active.current_page
                    this.dados_per_page_a = res.data.collaborators_list_active.data.length

                    this.paginate_view_a = true

                    var peoples_active = res.data.collaborators_list_active.data

                    var result_active = peoples_active.map(getList)

                    this.peoples_active = result_active

                    this.load = false

                }

                if (res.data.collaborators_list_inactive == 'vazio') {
                    this.peoples_inactive = 'vazio'
                    this.paginate_view_i = false

                    this.load = false
                } else {

                    this.total_page_i = res.data.collaborators_list_inactive.last_page
                    this.current_page_i = res.data.collaborators_list_inactive.current_page
                    this.dados_per_page_i = res.data.collaborators_list_inactive.data.length

                    this.paginate_view_i = true

                    var peoples_inactive = res.data.collaborators_list_inactive.data

                    var result_inactive = peoples_inactive.map(getList)

                    this.peoples_inactive = result_inactive

                    this.load = false

                }
            }).catch((e) => {

                this.load = false

                if (e.code === 'ECONNABORTED') {
                    this.load = false
                }
            });

            function getList(item) {
                return {
                    id: item.collaborator_id,
                    code: item.collaborator_code,
                    name: item.collaborator_name,
                    status: item.collaborator_status,
                    matriculation: item.collaborator_matriculation,
                    cpf: item.collaborator_cpf,
                    cost_center: item.collaborator_cost_center,
                    login: item.collaborator_login,
                    tag_name: item.collaborator_tag_name,
                    obs: item.collaborator_obs,
                    group_id: item.group_permission_id,
                    group_permissions_name: item.group_permission_name,
                    sector_id: item.sector_id,
                    sectors_name: item.sector_name,
                    role_id: item.role_id,
                    roles_name: item.role_name

                }
            }
        },
        loadpeople_zone() {

            this.load = true

            const access_token = this.$cookie.getCookie(userKey)

            this.sector_selected = 0

            var method = 'post'
            var rotas = 'collaborator-dependency-zone'

            var params = {
                page: this.page,
                search: this.search,
                company_id: access_token.company_id,
                company_unit_id: this.company_unit_selected,
                zone_id: this.zone_selected,
                credential_code: access_token.credential_code
            }

            const config = {
                headers: {
                    Authorization: `Bearer ${access_token.token}`
                }
            };

            axios[method](`${baseApiUrl}/${rotas}`, params, config, {
                timeout: 5000
            }).then(res => {

                console.log(res.data)

                this.sectors = res.data.sectors_list_active
                this.roles = res.data.roles_list_active
                this.groups = res.data.groups_list_active

                if (res.data.collaborators_list_active == 'vazio') {

                    this.peoples_active = 'vazio'
                    this.paginate_view_a = false

                    this.load = false
                } else {

                    this.total_page_a = res.data.collaborators_list_active.last_page
                    this.current_page_a = res.data.collaborators_list_active.current_page
                    this.dados_per_page_a = res.data.collaborators_list_active.data.length

                    this.paginate_view_a = true

                    var peoples_active = res.data.collaborators_list_active.data

                    var result_active = peoples_active.map(getList)

                    this.peoples_active = result_active

                    this.load = false

                }

                if (res.data.collaborators_list_inactive == 'vazio') {
                    this.peoples_inactive = 'vazio'
                    this.paginate_view_i = false

                    this.load = false
                } else {

                    this.total_page_i = res.data.collaborators_list_inactive.last_page
                    this.current_page_i = res.data.collaborators_list_inactive.current_page
                    this.dados_per_page_i = res.data.collaborators_list_inactive.data.length

                    this.paginate_view_i = true

                    var peoples_inactive = res.data.collaborators_list_inactive.data

                    var result_inactive = peoples_inactive.map(getList)

                    this.peoples_inactive = result_inactive

                    this.load = false

                }
            }).catch((e) => {

                this.load = false

                if (e.code === 'ECONNABORTED') {
                    this.load = false
                }
            });

            function getList(item) {
                return {
                    id: item.collaborator_id,
                    code: item.collaborator_code,
                    name: item.collaborator_name,
                    status: item.collaborator_status,
                    matriculation: item.collaborator_matriculation,
                    cpf: item.collaborator_cpf,
                    cost_center: item.collaborator_cost_center,
                    login: item.collaborator_login,
                    tag_name: item.collaborator_tag_name,
                    obs: item.collaborator_obs,
                    group_id: item.group_permission_id,
                    group_permissions_name: item.group_permission_name,
                    sector_id: item.sector_id,
                    sectors_name: item.sector_name,
                    role_id: item.role_id,
                    roles_name: item.role_name

                }
            }
        },
        loadpeople_sector() {

            this.load = true

            const access_token = this.$cookie.getCookie(userKey)

            this.view_add_button = true

            var method = 'post'
            var rotas = 'collaborator-dependency-sector'

            var params = {
                page: this.page,
                search: this.search,
                company_id: this.company_selected,
                company_unit_id: this.company_unit_selected,
                zone_id: this.zone_selected,
                sector_id: this.sector_selected,
                credential_code: access_token.credential_code
            }

            const config = {
                headers: {
                    Authorization: `Bearer ${access_token.token}`
                }
            };

            axios[method](`${baseApiUrl}/${rotas}`, params, config, {
                timeout: 5000
            }).then(res => {


                this.roles = res.data.roles_list_active
                this.people.role_id =  res.data.roles_list_active[0].id

                console.log(this.role_id)

                this.groups = res.data.groups_list_active
                this.people.group_id = res.data.groups_list_active[0].id

                if (res.data.collaborators_list_active == 'vazio') {

                    this.peoples_active = 'vazio'
                    this.paginate_view_a = false

                    this.load = false
                } else {

                    this.total_page_a = res.data.collaborators_list_active.last_page
                    this.current_page_a = res.data.collaborators_list_active.current_page
                    this.dados_per_page_a = res.data.collaborators_list_active.data.length

                    this.paginate_view_a = true

                    var peoples_active = res.data.collaborators_list_active.data

                    var result_active = peoples_active.map(getList)

                    this.peoples_active = result_active

                    this.load = false

                }

                if (res.data.collaborators_list_inactive == 'vazio') {
                    this.peoples_inactive = 'vazio'
                    this.paginate_view_i = false

                    this.load = false
                } else {

                    this.total_page_i = res.data.collaborators_list_inactive.last_page
                    this.current_page_i = res.data.collaborators_list_inactive.current_page
                    this.dados_per_page_i = res.data.collaborators_list_inactive.data.length

                    this.paginate_view_i = true

                    var peoples_inactive = res.data.collaborators_list_inactive.data

                    var result_inactive = peoples_inactive.map(getList)

                    this.peoples_inactive = result_inactive

                    this.load = false

                }

            }).catch((e) => {

                this.load = false

                if (e.code === 'ECONNABORTED') {
                    this.load = false
                }
            });

            function getList(item) {
                return {
                    id: item.collaborator_id,
                    code: item.collaborator_code,
                    name: item.collaborator_name,
                    status: item.collaborator_status,
                    matriculation: item.collaborator_matriculation,
                    cpf: item.collaborator_cpf,
                    cost_center: item.collaborator_cost_center,
                    login: item.collaborator_login,
                    tag_name: item.collaborator_tag_name,
                    obs: item.collaborator_obs,
                    group_id: item.group_permission_id,
                    group_permissions_name: item.group_permission_name,
                    sector_id: item.sector_id,
                    sectors_name: item.sector_name,
                    role_id: item.role_id,
                    roles_name: item.role_name

                }
            }
        },
        order(iten) {

            var list = Object.values(iten)

            list = list.slice(1, 4)

            return list;
        },
        reset(mode = 'add') {
            this.mode = 'add'
            this.people = {}
            this.people.status = this.status['0']
            this.v$.$reset();

            if (mode != 'info' && mode != 'cancelar') {
                if (this.sector_selected > 0) {
                    this.loadpeople_sector();
                } else if (this.zone_selected > 0) {
                    this.loadpeople_zone();
                } else if (this.company_unit_selected > 0) {
                    this.loadpeople_unit();
                } else if (this.company_selected > 0) {
                    this.loadpeoples()
                }
            }
        },
        save() {

            this.load = true

            if (this.v$.$invalid) {

                console.log("Formulário alterado!")

            } else {
                const method = 'post'

                var rotas = this.people.id ? 'collaborator_update' : 'collaborator_create'

                const access_token = this.$cookie.getCookie(userKey)

                const config = {
                    headers: {
                        'Accept': "application/json",
                        Authorization: `Bearer ${access_token.token}`
                    }
                }

                this.people.company_id = this.company_selected
                this.people.company_unit_id = this.company_unit_selected
                this.people.zone_id = this.zone_selected,
                this.people.sector_id = this.sector_selected

                axios[method](`${baseApiUrl}/${rotas}`, this.people, config, {
                    timeout: 5000
                }).then(res => {

                    console.log(res.data)

                    this.load = false

                    if (res.data.code == '2000') {
                        this.$toast.success(res.data.message)
                    } else if (res.data.code == '3000') {
                        this.$toast.success(res.data.message)
                    } else {
                        this.$toast.success(res.data.message)
                    }

                    this.reset()
                }).catch((e) => {

                    if (e.code === 'ECONNABORTED') {
                        this.load = false
                    }
                });
            }
        },
        loadpeople(people, mode = 'add') {

            if (mode === 'delete') {
                this.larger = ''
            } else {
                this.larger = 'modal-lg'
            }

            this.mode = mode

            this.people = {
                ...people
            }
        },
        remove() {

            this.load = true

            if (this.dados_per_page_a == 1 || this.dados_per_page_i == 1) {
                this.page -= 1
            }

            var method = 'post'
            var rotas = 'collaborator_delete'

            const access_token = this.$cookie.getCookie(userKey)

            const config = {
                headers: {
                    'Accept': "application/json",
                    Authorization: `Bearer ${access_token.token}`
                }
            }

            axios[method](`${baseApiUrl}/${rotas}`, this.people, config, {
                timeout: 5000
            }).then(res => {

                if (res.data == 'Colaborador não encontrado!') {
                    this.$toast.error('Colaborador não encontrado!')
                } else {
                    this.$toast.success('Colaborador excluído com sucesso!')
                }

                this.reset()
            }).catch((e) => {

                if (e.code === 'ECONNABORTED') {
                    this.load = false
                }
            });
        }
    }
}
</script>

<style scoped>
.paginate {
    display: flex;
    justify-content: center;
}

/* ------------------------------------ Filtros de pesquisa ---------------------------------------*/
.cards .select_filter {
    margin-bottom: 15px;
}

/*-------------------------------------- Cabeçalho Companies --------------------------------------*/
.people {
    margin: 25px;
}

.people .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

/* -------------- Cabeçalho ------------------*/
.search .input-search {
    border-radius: 3px;
    border-color: #744cbc;
}

.search .input-search:focus,
.btn-search {
    box-shadow: 0 0 0 0 !important;
    outline: none !important;
}

.search .icon-search {
    color: #ffffff;
    transform: rotate(90deg);
}

.search .btn-search {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-color: #744cbc;
}

.search .btn-search:hover {
    background-color: #886aea !important;
}

/*--------------------------------------------- Table ---------------------------------------------*/
.people .cards .tables thead tr th {
    border-style: solid;
    border-bottom-width: 2px;
    color: rgb(114, 132, 140);
    border-bottom-color: rgb(222, 226, 230) !important;
}

.people .cards .tables thead th {
    font-size: 15px;
    font-weight: 600;
    padding-top: 10px;
    padding-bottom: 10px;
}

.people .cards .tables tbody td {
    font-size: 14px;
    padding-top: 4px;
    padding-bottom: 4px;
    line-height: 30px;
    color: #72848c;
}

.people .cards .tables tbody button {
    color: #ffffff;
    margin: 0 2px;
    padding: 2px 5px;
    box-shadow: 0 0 0 0 !important;
    outline: none !important;
}

.people .cards .pagination {
    display: flex;
    justify-content: center;
}

/*------------------------------------------- Paginação ------------------------------------------ */
.pagination-table {
    margin-top: 30px;
}

.pagination-table .page-item .page-link {
    color: #72848c;
    text-decoration: none;
    border: 1px solid #dee2e6;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    cursor: pointer;
}

.pagination-table .page-item.active .page-link {
    color: #ffffff;
    background-color: #744cbc;
    border-color: #744cbc;
}

/*--------------------------------------------- Modal ---------------------------------------------*/
.people .modal-content {
    border: none;
}

/*------------------------------------------- Modal Header ----------------------------------------*/
.people .modal-header {
    padding: 10px 20px;
    background-color: #744cbc;
}

.people .modal-header .modal-title {
    color: #ffffff;
    font-size: 18px;
}

/*-------------------------------------------- Modal Body -----------------------------------------*/
.people .modal-body .msg {
    text-align: center;
    color: #72848c;
}

.people .modal-body .msg h6 {
    padding: 20px 0px 12px 0px;
    font-size: 17px;
}

.people .modal-body label {
    font-size: 15px;
    color: #72848c;
    margin-top: 10px;
    margin-left: 1px;
    margin-bottom: 5px;
}

/*------------------------------------------- Modal Footer ----------------------------------------*/
.people .modal-footer {
    padding: 8px;
}

.people .modal-footer button {
    border: none;
}

/*----------------------------------------- Mensagem De Erro --------------------------------------*/
.msg_error {
    color: #ff0000;
    font-size: 14px;
}

input,
input:focus,
.is-invalid {
    box-shadow: 0 0 0 0;
    outline: 0;
}

.is-invalid {
    box-shadow: 0 0 0 0 !important;
    outline: 0 !important;
}

.dados_empty {
    color: #72848c;
    font-weight: 600;

}

.loading button {
    position: absolute;
    top: calc(50% - 35px);
    left: calc(50%);
    margin-left: 90px;
    width: 70px;
    height: 70px;
    background-color: #3b0253;
    border: none;
}

.loading span {
    width: 30px;
    height: 30px;
}
</style>
