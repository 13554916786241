<template>
    <div class="labels size">
        <p :style="{color:cor, fontSize: size}">{{nome}}</p>
    </div>
</template>

<script>
export default{
    name:"labels",
    props:['nome', 'cor', 'size'],
}
</script>

<style scoped>
    .labels p{
        margin:0;
        line-height: 34px;
        font-weight: 400;
    }
</style>
