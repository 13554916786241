<template>
<div class="menu">
    <div class="title-menu">
        <div class="title">
            <h5>Controls Admin</h5>
        </div>
    </div>
    <nav class="nav-menu" @click='teste'>
        <div class="accordion menu-accordion" id="accordionMenu">
            <div class="accordion-item">
                <li v-if="menu_dashboard == true" class="accordion-header" id="headingOne" @click="link_active('main', 'dashboard')">
                    <a data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" :class="{'active_main': active_main == 'dashboard'}">
                        <i class="fas fa-tachometer-alt"></i>
                        <span>Dashboard</span>
                        <i class="fas fa-angle-right icon-angle"></i>
                    </a>
                </li>
                <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionMenu">
                    <div class="accordion-body">
                        <ul class="menu-child">
                            <li v-if="sistema == true" @click="link_active('child', 'system')">
                                <router-link to="/system" :class="{'active': active_link == 'system'}">
                                    <i class="fas fa-circle"></i>
                                    <span>Sistema</span>
                                </router-link>
                            </li>
                            <!--<li @click="link_active('child', 'machine')">
                                <router-link to="/machine" :class="{'active': active_link == 'machine'}">
                                    <i class="fas fa-circle"></i>
                                    <span>Máquinas</span>
                                </router-link>
                            </li>-->
                            <li v-if="produto == true" @click="link_active('child', 'products')">
                                <router-link to="/products" :class="{'active': active_link == 'products'}">
                                    <i class="fas fa-circle"></i>
                                    <span>Produtos</span>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="accordion-item">
                <li v-if="menu_client" class="accordion-header" id="headingTwo" @click="link_active('main', 'client')">
                    <a data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo" :class="{'active_main': active_main == 'client'}">
                        <i class="fas fa-users"></i>
                        <span>Clientes</span>
                        <i class="fas fa-angle-right icon-angle"></i>
                    </a>
                </li>
                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionMenu">
                    <div class="accordion-body">
                        <ul class="menu-child">
                            <li v-if="list_company == true" @click="link_active('child', 'company')">
                                <router-link to="/company" :class="{'active': active_link == 'company'}">
                                    <i class="fas fa-circle"></i>
                                    <span>Empresas</span>
                                </router-link>
                            </li>
                            <li v-if="list_company_unit == true" @click="link_active('child', 'unit')">
                                <router-link to="/unit" :class="{'active': active_link == 'unit'}">
                                    <i class="fas fa-circle"></i>
                                    <span>Unidades</span>
                                </router-link>
                            </li>
                            <li @click="link_active('child', 'zone')">
                                <router-link to="/zone" :class="{'active': active_link == 'zone'}">
                                    <i class="fas fa-circle"></i>
                                    <span>Áreas</span>
                                </router-link>
                            </li>
                            <li @click="link_active('child', 'sector')">
                                <router-link to="/sector" :class="{'active': active_link == 'sector'}">
                                    <i class="fas fa-circle"></i>
                                    <span>Setores</span>
                                </router-link>
                            </li>
                            <li @click="link_active('child', 'characteristics')">
                                <router-link to="/characteristics" :class="{'active': active_link == 'characteristics'}">
                                    <i class="fas fa-circle"></i>
                                    <span>Características</span>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="accordion-item">
                <li v-if="menu_produto" class="accordion-header" id="headingThree" @click="link_active('main', 'product')">
                    <a data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree" :class="{'active_main': active_main == 'product'}">
                        <i class="fas fa-box"></i>
                        <span>Gestão de Produtos</span>
                        <i class="fas fa-angle-right icon-angle"></i>
                    </a>
                </li>
                <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionMenu">
                    <div class="accordion-body">
                        <ul class="menu-child">
                            <li v-if="list_produto" @click="link_active('child', 'product')">
                                <router-link to="/product" :class="{'active': active_link == 'product'}">
                                    <i class="fas fa-circle"></i>
                                    <span>Produtos</span>
                                </router-link>
                            </li>
                            <!--<li @click="link_active('child', 'config')">
                                <router-link to="/settingsproducts" :class="{'active': active_link == 'config'}">
                                    <i class="fas fa-circle"></i>
                                    <span>Configuração</span>
                                </router-link>
                            </li>-->
                            <li v-if="list_categoria" @click="link_active('child', 'category')">
                                <router-link to="/category" :class="{'active': active_link == 'category'}">
                                    <i class="fas fa-circle"></i>
                                    <span>Categorias</span>
                                </router-link>
                            </li>
                            <li v-if="list_unit_measurement" @click="link_active('child', 'unit_measurement')">
                                <router-link to="/unit_measurement" :class="{'active': active_link == 'unit_measurement'}">
                                    <i class="fas fa-circle"></i>
                                    <span>Unidade de Medida</span>
                                </router-link>
                            </li>
                            <li v-if="list_size" @click="link_active('child', 'size')">
                                <router-link to="/size" :class="{'active': active_link == 'size'}">
                                    <i class="fas fa-circle"></i>
                                    <span>Tamanho</span>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="accordion-item">
                <li v-if="menu_identification" class="accordion-header" id="headingFour" @click="link_active('main', 'identification')">
                    <a data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour" :class="{'active_main': active_main == 'identification'}">
                        <i class="fas fa-id-badge"></i>
                        <span>Central de identificação</span>
                        <i class="fas fa-angle-right icon-angle"></i>
                    </a>
                </li>
                <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionMenu">
                    <div class="accordion-body">
                        <ul class="menu-child">
                            <li v-if="list_collaborator" @click="link_active('child', 'people')">
                                <router-link to="/people" :class="{'active': active_link == 'people'}">
                                    <i class="fas fa-circle"></i>
                                    <span>Colaboradores</span>
                                </router-link>
                            </li>
                            <li v-if="list_role" @click="link_active('child', 'role')">
                                <router-link to="/role" :class="{'active': active_link == 'role'}">
                                    <i class="fas fa-circle"></i>
                                    <span>Funções</span>
                                </router-link>
                            </li>
                            <li v-if="list_group"  @click="link_active('child', 'group')">
                                <router-link to="/group" :class="{'active': active_link == 'group'}">
                                    <i class="fas fa-circle"></i>
                                    <span>Grupos</span>
                                </router-link>
                            </li>
                            <li v-if="list_permission" @click="link_active('child', 'permission')">
                                <router-link to="/permission" :class="{'active': active_link == 'permission'}">
                                    <i class="fas fa-circle"></i>
                                    <span>Permissões</span>
                                </router-link>
                            </li>
                            <li v-if="list_justification" @click="link_active('child', 'justification')">
                                <router-link to="/justification" :class="{'active': active_link == 'justification'}">
                                    <i class="fas fa-circle"></i>
                                    <span>Justificativa</span>
                                </router-link>
                            </li>
                            <li v-if="list_request" @click="link_active('child', 'requisition')">
                                <router-link to="/requisition" :class="{'active': active_link == 'requisition'}">
                                    <i class="fas fa-circle"></i>
                                    <span>Requisição</span>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="accordion-item">
                <li v-if="menu_report" class="accordion-header" id="headingFive" @click="link_active('main', 'report')">
                    <a data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="true" aria-controls="collapseFive" :class="{'active_main': active_main == 'report'}">
                        <i class="fas fa-chart-pie"></i>
                        <span>Relatórios</span>
                        <i class="fas fa-angle-right icon-angle"></i>
                    </a>
                </li>
                <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionMenu">
                    <div class="accordion-body">
                        <ul class="menu-child">
                            <li v-if="list_retirada" @click="link_active('child', 'retirada')">
                                <router-link to="/epi_sheet" class="link" :class="{'active': active_link == 'retirada'}">
                                    <i class="fas fa-circle"></i>
                                    <span>Retirada</span>
                                </router-link>
                            </li>
                            <li v-if="list_entrega" @click="link_active('child', 'delivery')">
                                <router-link to="/menu_report" class="link" :class="{'active': active_link == 'delivery'}">
                                    <i class="fas fa-circle"></i>
                                    <span>Entregas</span>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="accordion-item">
                <li v-if="list_menu_control_access" class="accordion-header" id="headingSix" @click="link_active('main', 'access_control')">
                    <a data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="true" aria-controls="collapseSix" :class="{'active_main': active_main == 'access_control'}">
                        <i class="fas fa-door-open"></i>
                        <span>Controle de acesso</span>
                        <i class="fas fa-angle-right icon-angle"></i>
                    </a>
                </li>
                <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionMenu">
                    <div class="accordion-body">
                        <ul class="menu-child">
                            <li v-if="list_user" @click="link_active('child', 'user')">
                                <router-link to="/user" class="link" :class="{'active': active_link == 'user'}">
                                    <i class="fas fa-circle"></i>
                                    <span>Usuários</span>
                                </router-link>
                            </li>
                            <li v-if="list_credenciais" @click="link_active('child', 'credential')">
                                <router-link to="/credential" :class="{'active': active_link == 'credential'}">
                                    <i class="fas fa-circle"></i>
                                    <span>Credenciais</span>
                                </router-link>
                            </li>
                            <li v-if="list_access_permission" @click="link_active('child', 'accessPermission')">
                                <router-link to="/access_permission" :class="{'active': active_link == 'accessPermission'}">
                                    <i class="fas fa-circle"></i>
                                    <span>Permissões de Acesso</span>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="accordion-item">
                <li v-if="menu_upload" class="accordion-header" id="headingSeven" @click="link_active('main', 'uploads')">
                    <a data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="true" aria-controls="collapseSeven" :class="{'active_main': active_main == 'uploads'}">
                        <i class="fas fa-upload"></i>
                        <span>Uploads</span>
                        <i class="fas fa-angle-right icon-angle"></i>
                    </a>
                </li>
                <div id="collapseSeven" class="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionMenu">
                    <div class="accordion-body">
                        <ul class="menu-child">
                            <li @click="link_active('child', 'collaborators')">
                                <router-link to="/uploadCollaborators" :class="{'active': active_link == 'collaborators'}">
                                    <i class="fas fa-circle"></i>
                                    <span>Colaboradores</span>
                                </router-link>
                            </li>
                            <li @click="link_active('child', 'products')">
                                <router-link to="/uploadProducts" :class="{'active': active_link == 'products'}">
                                    <i class="fas fa-circle"></i>
                                    <span>Produtos</span>
                                </router-link>
                            </li>
                            <li @click="link_active('child', 'upload_zone')">
                                <router-link to="/uploadzone" class="link" :class="{'active': active_link == 'upload-zone'}">
                                    <i class="fas fa-circle"></i>
                                    <span>Área</span>
                                </router-link>
                            </li>
                            <li @click="link_active('child', 'upload_categories')">
                                <router-link to="/uploadCategory" class="link" :class="{'active': active_link == 'upload_categories'}">
                                    <i class="fas fa-circle"></i>
                                    <span>Categorias</span>
                                </router-link>
                            </li>
                            <li @click="link_active('child', 'upload_functions')">
                                <router-link to="/uploadFunction" class="link" :class="{'active': active_link == 'upload_functions'}">
                                    <i class="fas fa-circle"></i>
                                    <span>Funções</span>
                                </router-link>
                            </li>
                            <li @click="link_active('child', 'upload_groups')">
                                <router-link to="/uploadGroup" class="link" :class="{'active': active_link == 'upload_groups'}">
                                    <i class="fas fa-circle"></i>
                                    <span>Grupos</span>
                                </router-link>
                            </li>
                            <li @click="link_active('child', 'upload_justifications')">
                                <router-link to="/uploadJustification" class="link" :class="{'active': active_link == 'upload_justifications'}">
                                    <i class="fas fa-circle"></i>
                                    <span>Justificativas</span>
                                </router-link>
                            </li>
                            <li @click="link_active('child', 'upload_sector')">
                                <router-link to="/uploadsector" class="link" :class="{'active': active_link == 'upload_sector'}">
                                    <i class="fas fa-circle"></i>
                                    <span>Setor</span>
                                </router-link>
                            </li>
                            <li @click="link_active('child', 'upload_permission_product_group')">
                                <router-link to="/uploadgroupxproduct" class="link" :class="{'active': active_link == 'upload_permission_product_group'}">
                                    <i class="fas fa-circle"></i>
                                    <span>Grupos x Produtos</span>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="accordion-item">
                <li v-if="list_menu_cofiguracao" class="accordion-header" id="headingEight" @click="link_active('main', 'config')">
                    <a data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="true" aria-controls="collapseEight" :class="{'active_main': active_main == 'config'}">
                        <i class="fas fa-cog"></i>
                        <span>Configurações</span>
                        <i class="fas fa-angle-right icon-angle"></i>
                    </a>
                </li>
                <div id="collapseEight" class="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionMenu">
                    <div class="accordion-body">
                        <ul class="menu-child">
                            <li @click="link_active('child', 'equipament')">
                                <router-link to="/equipament" class="link" :class="{'active': active_link == 'equipament'}">
                                    <i class="fas fa-circle"></i>
                                    <span>Equipamentos</span>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</div>
</template>

<script>
import {
    userKey
} from '@/global'

export default {
    name: "Menu",
    data: function () {
        return {
            active_main: '',
            active_link: '',

            menu_dashboard: false,
            sistema: false,
            produto: false,

            menu_client: false,
            list_company: false,
            list_company_unit: false,
            list_zone: false,
            list_sector: false,
            list_caracteristica: false,

            menu_produto: false,
            list_produto: false,
            list_categoria: false,
            list_unit_measurement: false,
            list_size: false,

            menu_identification: false,
            list_collaborator: false,
            list_role: false,
            list_group: false,
            list_permission: false,
            list_justification: false,
            list_request: false,

            menu_report: false,
            list_retirada: false,
            list_entrega: false,

            list_menu_control_access:false,
            list_user: false,
            list_credenciais:false,
            list_access_permission:false,

            menu_upload: false,
            list_upload_colaborador: false,
            list_upload_produto: false,
            list_upload_area: false,
            list_upload_categoria: false,
            list_upload_role: false,
            list_upload_group: false,
            list_upload_justificativa: false,
            list_upload_setor: false,
            list_upload_groupxproduct: false,

            list_menu_cofiguracao:false,
            list_equipamento: false
        }
    },
    created() {

        const access_credential = this.$cookie.getCookie(userKey)

        var permissoes = access_credential.permissions

        var permissoes_array = permissoes.split(',')

        this.menu_dashboard            = permissoes_array.includes('1000000') == true ? true : false
        this.sistema                   = permissoes_array.includes('999999') == true ? true : false
        this.produto                   = permissoes_array.includes('999998') == true ? true : false

        this.menu_client               = permissoes_array.includes('950000') == true ? true : false
        this.list_company              = permissoes_array.includes('999998') == true ? true : false
        this.list_company_unit         = permissoes_array.includes('949994') == true ? true : false
        this.list_zone                 = permissoes_array.includes('949989') == true ? true : false
        this.list_sector               = permissoes_array.includes('949984') == true ? true : false
        this.list_caracteristica       = permissoes_array.includes('940000') == true ? true : false

        this.menu_produto              = permissoes_array.includes('900000') == true ? true : false
        this.list_produto              = permissoes_array.includes('899999') == true ? true : false
        this.list_categoria            = permissoes_array.includes('899994') == true ? true : false
        this.list_unit_measurement     = permissoes_array.includes('899989') == true ? true : false
        this.list_size                 = permissoes_array.includes('899984') == true ? true : false

        this.menu_identification       = permissoes_array.includes('850000') == true ? true : false
        this.list_collaborator         = permissoes_array.includes('849999') == true ? true : false
        this.list_role                 = permissoes_array.includes('849994') == true ? true : false
        this.list_group                = permissoes_array.includes('849989') == true ? true : false
        this.list_permission           = permissoes_array.includes('849984') == true ? true : false
        this.list_justification        = permissoes_array.includes('849983') == true ? true : false
        this.list_request              = permissoes_array.includes('849978') == true ? true : false

        this.menu_report               = permissoes_array.includes('800000') == true ? true : false
        this.list_retirada             = permissoes_array.includes('799999') == true ? true : false
        this.list_entrega              = permissoes_array.includes('799998') == true ? true : false

        this.menu_upload               = permissoes_array.includes('899984') == true ? true : false
        this.list_upload_colaborador   = permissoes_array.includes('899984') == true ? true : false
        this.list_upload_produto       = permissoes_array.includes('899984') == true ? true : false
        this.list_upload_area          = permissoes_array.includes('899984') == true ? true : false
        this.list_upload_categoria     = permissoes_array.includes('899984') == true ? true : false
        this.list_upload_role          = permissoes_array.includes('899984') == true ? true : false
        this.list_upload_group         = permissoes_array.includes('899984') == true ? true : false
        this.list_upload_justificativa = permissoes_array.includes('899984') == true ? true : false
        this.list_upload_setor         = permissoes_array.includes('899984') == true ? true : false
        this.list_upload_groupxproduct = permissoes_array.includes('899984') == true ? true : false

        this.list_menu_control_access  = permissoes_array.includes('750000') == true ? true : false
        this.list_user                 = permissoes_array.includes('749999') == true ? true : false
        this.list_credenciais          = permissoes_array.includes('749994') == true ? true : false
        this.list_access_permission    = permissoes_array.includes('749992') == true ? true : false

        this.menu_upload               = permissoes_array.includes('700000') == true ? true : false

        this.list_menu_cofiguracao     = permissoes_array.includes('650000') == true ? true : false

        //console.log(permissoes_array.includes('1000000'))

        /*var descendentes = document.querySelectorAll("li a span");
            for (var i = 0; i < descendentes.length; i++) {
                descendentes[i].addEventListener("click", function (e) {
                    console.log('O elemento clicado foi o ' + this.innerHTML);
                })
            }*/
    },
    methods: {
        /*teste(event){

            //console.log(event.target)

        },*/
        link_active(menu, rota) {

            if (menu == 'main') {

                this.active_main = rota
            }
            if (menu == 'child') {
                this.active_link = rota
            }
        },
    },
    computed: {
        menu_visible() {
            return this.$store.state.isMenuVisible;
        },
        /*classesCSS: function(){
          return{
            toggle: !this.menu_visible,
          };
        }*/
    }
};
</script>

<style scoped>
.content-left {
    width: 250px;
    min-height: 100vh;
    background-color: darkorchid;
    border: none;
    transition: all 0.5s;
}

.content-left .sidebar-title {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 57px;
}

.content-left .sidebar-title a {
    text-decoration: none;
    color: #fff;
    font-size: 18px;
}

.content-left .sidebar-menu-vertical {
    background-color: gold;
    min-height: 100vh;
}

/*---------------------------------------- Título do menu ---------------------------------------*/
.menu {
    height: 100vh;
    background-image: linear-gradient(45deg, #65379b, #886aea);
    overflow-y: auto;
}

.menu .title-menu {
    height: 60px;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.menu .title-menu h5 {
    margin: 0;
    font-size: 16px;
    color: #ffffff;
    font-family: Arial;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 0.5px;
}

/*---------------------------------------- Menu Principal----------------------------------------*/
.nav-menu {
    background-color: transparent;
}

.nav-menu .menu-accordion {
    background-color: transparent;
}

.nav-menu .menu-accordion .accordion-item {
    border: none !important;
    background-color: transparent !important;
}

.nav-menu .accordion-header {
    list-style-type: none;
    padding: 0;
}

.nav-menu .accordion-header a {
    color: rgba(255, 255, 255, 0.65);
}

.nav-menu .accordion-header a {
    padding: 11px 5px 11px 15px;
    line-height: 23px;
    font-size: 15px;
    display: block;
    font-family: Arial;
    font-weight: 400;
    text-decoration: none;
    border-left: 3px solid transparent;
    cursor: pointer;
}

.nav-menu .accordion-header a i {
    margin-right: 10px;
    font-size: 18px;
}

.nav-menu .accordion-header a .icon-angle {
    margin-top: 5px;
    font-size: 15px;
    float: right;
    margin-right: 20px;
}

.nav-menu .accordion-header:hover>a {
    color: #ffffff;
    background-color: rgba(255, 255, 255, 0.15);
    border-left-color: #ffffff;
}

.nav-menu .accordion-header:hover span,
.nav-menu .accordion-header:hover i {
    color: #ffffff;
}

.nav-menu .accordion-header .active_main {
    color: #fff !important;
    background-color: rgba(255, 255, 255, 0.15);
    border-left-color: #ffffff;
}

.nav-menu .accordion-body .active {
    color: #fff !important;
}

/*------------------------------------------ Menu filho -----------------------------------------*/
.nav-menu .accordion-body {
    margin: 0;
    padding: 0;
}

.nav-menu .accordion-body .menu-child {
    margin: 0;
    padding: 0px 0px 0px 15px;
    list-style-type: none;
}

.nav-menu .accordion-body li {
    margin: 0;
}

.nav-menu .accordion-body li {
    padding: 6px 5px 6px 15px;
}

.nav-menu .accordion-body a {
    line-height: 20px;
    text-decoration: none;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.65);
    font-family: Arial;
}

.nav-menu .accordion-body i {
    font-size: 10px;
}

.nav-menu .accordion-body li span {
    position: relative;
    top: 2px;
    left: 10px;
}

.nav-menu .accordion-body li:hover>a {
    color: #ffffff;
    background-color: transparent;
    border-left-color: transparent;
}

/*-------------------------------- Conteúdo do lado direito do sistema --------------------------*/
.content-right {
    background-color: rgb(196, 195, 194);
    width: 100%;
    border: none;
}

.content-right .sidebar-menu-horizontal {
    display: flex;
    align-items: center;
    padding-left: 20px;
    height: 57px;
    background-color: #fff;
}

/*------------------------------------------- toggle menu ---------------------------------------*/
.toggle {
    margin-left: -250px;
    transition: all 0.5s;
}

/* Media queries*/
@media screen and (max-width: 768px) {
    .content-left {
        margin-left: 0px;
        transition: all 0.5s;
    }

    /* toggle menu*/
    .toggle {
        margin-left: 0px;
        transition: all 0.5s;
    }
}
</style>
