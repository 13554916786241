import {toaste} from '@/main'

export const userKey = 'user_session'

export const baseApiUrl = 'https://technipb.sempher.com.br/api'

export function showError(e){

    if(e.code === 'ECONNABORTED'){

        toaste.$toast.error('Falha, tempo limite excedido!')

    }else if(e.response.data.errors){

        var msg = [];

        Object.values(e.response.data.errors).forEach(function(key) {

            msg.push(key)

        });

          toaste.$toast.error(msg[0][0])

    }

    /*if(e && e.response && e.response.data){
        toaste.$toast.error(e.response.data)
    }else if(typeof e === 'string'){
        toaste.$toast.error(e)
    }else{
        toaste.$toast.error('Falha na operação!')
    }*/
}

export default {baseApiUrl, showError}
