<template>
<div class="content">
    <router-view />
</div>
</template>

<script>
export default {
    name: "Content",
}
</script>

<style scoped>
.content {
    overflow: auto;
    grid-area: content;
}
</style>
