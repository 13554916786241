<template>
    <div class="machine">
        <div class="row">
            <div class="col-md-12">
                <CardInfoBanner title="Total usuários" quantity="2800" color="linear-gradient(45deg, #4facfe, #00f2fe)"></CardInfoBanner>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <CardInfoTableList title="Dispenser Machine" quantity="2800" color="linear-gradient(45deg, #4facfe, #00f2fe)"></CardInfoTableList>
            </div>
            <div class="col-md-6">
                <CardInfoTableList title="Almoxarifados" quantity="2800" color="linear-gradient(45deg, #4facfe, #00f2fe)"></CardInfoTableList>
            </div>
        </div>
    </div>
</template>

<script>
import CardInfoBanner from '@/components/Cards/Card-info-banner'
import CardInfoTableList from '@/components/Cards/Card-info-table-list'

export default {
    name: "system",
    components: { CardInfoBanner, CardInfoTableList },
}
</script>

<style scoped>
/*-------------------------------------- Cabeçalho System --------------------------------------*/
.machine {
    margin: 25px;
}
</style>