<template>
    <div class="tabs">
        <nav>
            <div class="nav nav-tabs" id="nav-tab" role="tablist">
                <button class="nav-link active title-tab" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">{{name_tab1}}</button>
                <button class="nav-link" id="nav-profile-tab title-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">{{name_tab2}}</button>
            </div>
        </nav>
        <div class="tab-content" id="nav-tabContent">
            <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                <div class="tab pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                    <slot name="Ativo"/>
                </div>
            </div>
            <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                <div class="tab pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                    <slot name="Inativo"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:"tabs",
    props:["name_tab1", "name_tab2"]
}
</script>

<style scoped>
    .tabs .nav-tabs{
        border:1px solid #dee2e6;
        border-top-color:transparent;
        border-left-color: transparent;
        border-right-color: transparent;
    }

    .tabs .nav-link{
        border:transparent;
        font-weight: 600;
        color:#72848c;
    }

    .tabs .nav-link.active{
        font-weight: 600;
        color:#ffffff;
        background-color: #744cbc;
    }

    .tabs .tab-content .tab-pane{
        padding-top: 15px;
    }
</style>