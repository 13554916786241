<template>
<div class="group">
    <div class="header">
        <Labels nome="Permissões" cor="#72848c" size="25px" />
        <div v-if="false" class="search">
            <div class="input-group">
                <input type="text" class="form-control input-search" placeholder="pesquisa" v-model="search">
                <span class="input-group-btn">
                    <button type="button" class="btn btn-default btn-search" v-on:click="loadgroups"><i class="fas fa-search icon-search"></i></button>
                </span>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="cards">
            <Card title="Permissões" color="#72848c" colorbackground="#ffffff" fontSize="18px" text_tooltip="Adicionar Grupo de permissão" view_add="false">
                <template v-slot:content class="tabs">
                    <div class="permission">
                        <br>
                        <div class="row">
                            <div class="col-md-10 offset-md-1">
                                <select class="form-select" id="credential" @change="loadproducts" v-model="group_selected">
                                    <option value="0">Selecione um grupo</option>
                                    <option v-for="(iten, index) in groups_active" :value="iten.id" :key="index">{{iten.name}}</option>
                                </select>
                            </div>
                        </div>
                        <br>
                        <hr>
                        <div class="row info_geral">
                            <div class="col-md-12" v-for="(iten, index) in products_active" :key="index">
                                <div class="product_left">
                                    <div class="row1">
                                        <div class="left">
                                            <div class="info_product">
                                                <div class="image_product">
                                                    <img src="@/assets/img/perfil.jpg" alt="foto-perfil">
                                                </div>
                                                <div class="description_product">
                                                    <p><span>COL1234: </span>{{iten.name}}</p>
                                                    <p><span>UNIDADE: </span> {{iten.unit_measurement}} Unidade</p>
                                                    <p><span>DATA DE EDIÇÃO: </span>{{iten.date}}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="right">
                                            <div class="switch_on_off">
                                                <div class="form-check form-switch">
                                                    <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" v-model="checked['active_'+iten.id]" @change="addproductsgroup(iten)">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row2">
                                        <div class="row info_input">
                                            <div class="col-md-3">
                                                <label for="code" class="form-label">Período(Dias)</label>
                                                <input autocomplete="off" type="number" name="period" class="form-control form-control-sm config-values" :id="'period'+iten.id" min="0" v-model="info_product['period_'+iten.id]">
                                            </div>
                                            <div class="col-md-3">
                                                <label for="code" class="form-label">Máximo(Qtde)</label>
                                                <input autocomplete="off" type="number" class="form-control form-control-sm config-values" :id="'quantity'+iten.id" min="0" v-model="info_product['quantity_'+iten.id]">
                                            </div>
                                            <div class="col-md-3">
                                                <label for="code" class="form-label">Vida Útil(Dias)</label>
                                                <input autocomplete="off" type="number" class="form-control form-control-sm config-values" :id="'lifecycle'+iten.id" min="0" v-model="info_product['lifecycle_'+iten.id]">
                                            </div>
                                            <div class="col-md-3 btn-save">
                                                <button @click="updateproductsgroup(iten.id)" :id="'btn'+iten.id" class="btn btn-primary btn-sm config-btn">Salvar</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr>
                            </div>
                        </div>
                    </div>
                </template>
            </Card>
        </div>
        <div class="col-md-12">
            <div v-if="load" class="loading">
                <button class="btn btn-primary" type="button" disabled>
                    <span class="spinner-border spinner-border-sm" group="status" aria-hidden="true"></span>
                    <span class="visually-hidden">Loading...</span>
                </button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import {
    required,
    maxLength
} from '@vuelidate/validators'
import {
    baseApiUrl,
    userKey
} from '@/global'
import axios from 'axios'
import Labels from "@/components/Labels/Label"
import Card from "@/components/Cards/Card"

export default {
    name: "group",
    data: function () {
        return {
            v$: useVuelidate(),
            products_active: '',
            groups_active: '',
            group_selected: 0,
            search: '',
            info_product: [],
            checked: [],
            mode: 'add',
            group: {},
            groups: [],
            itens: '',
            larger: 'modal-lg',
            status: ['ATIVO', 'INATIVO'],
            msg_error: '',
            load: false
        }
    },
    validations() {
        return {
            group: {
                code: {
                    maxLength: maxLength(30),
                    $autoDirty: true
                },
                name: {
                    required,
                    maxLength: maxLength(30),
                    $autoDirty: true
                },
                description: {
                    maxLength: maxLength(100),
                    $autoDirty: true
                },
                obs: {
                    maxLength: maxLength(500),
                    $autoDirty: true
                },
            }
        }
    },
    components: {
        Labels,
        Card,
    },
    mounted() {
        const access_credential = this.$cookie.getCookie(userKey)

        var permissoes = access_credential.permissions

        var permissoes_array = permissoes.split(',')

        this.permission_add     = permissoes_array.includes('949983') == true ? true : false
        this.permission_info    = permissoes_array.includes('949982') == true ? true : false
        this.permission_edit    = permissoes_array.includes('949981') == true ? true : false
        this.permission_delete  = permissoes_array.includes('949980') == true ? true : false


        this.loadgroups();
    },
    computed: {
        codeErrors() {
            const code = this.v$.group.code
            if (!code.$dirty) {
                return ""
            }
            if (!code.maxLength.$response) {
                return "Limite máximo de 30 caracteres!"
            } else {
                return ""
            }
        },
        nameErrors() {
            const name = this.v$.group.name
            if (!name.$dirty) {
                return ""
            }
            if (!name.required.$response) {
                return "O campo é obrigatório!"
            } else if (!name.maxLength.$response) {
                return "Limite máximo de 30 caracteres!"
            } else {
                return ""
            }
        },
        descriptionErrors() {
            const email = this.v$.group.email
            if (!email.$dirty) {
                return ""
            }
            if (!email.maxLength.$response) {
                return "Limite máximo de 100 caracteres!"
            } else {
                return ""
            }
        },
        obsErrors() {
            const obs = this.v$.group.obs
            if (!obs.$dirty) {
                return ""
            }
            if (!obs.maxLength.$response) {
                return "Limite máximo de 500 caracteres!"
            } else {
                return ""
            }
        }
    },
    methods: {
        /* ==============================  Carrega todos os grupos ativos no sistema web ==============================*/
        loadgroups() {

            this.load = true

            const access_token = this.$cookie.getCookie(userKey)

            var method = 'get'

            var rotas = 'grouppermission-list-active'

            const config = {
                headers: {
                    Authorization: `Bearer ${access_token.token}`
                }
            };

            axios[method](`${baseApiUrl}/${rotas}`, config,{
                timeout: 5000
            }).then(res => {

                if (res.data.grouppermission_list_active == 'vazio') {

                    this.groups_active = 'vazio'
                    this.load = false
                } else {

                    var groups_active = res.data.grouppermission_list_active

                    var result_active = groups_active.map(getList)

                    this.groups_active = result_active

                    this.load = false
                }
            }).catch((e) => {

                if (e.code === 'ECONNABORTED') {
                    this.load = false
                }
            });

            function getList(item) {
                return {
                    id: item.id,
                    name: item.name,
                }
            }
        },
        /* =========================  Carrega todos os produtos referente ao grupo selecionado ========================*/
        loadproducts() {

            this.load = true

            const access_token = this.$cookie.getCookie(userKey)

            var method = 'post'
            var rotas = 'product-list-active'

            const config = {
                headers: {
                    Authorization: `Bearer ${access_token.token}`
                }
            };

            var params = {
                search: this.search
            }

            axios[method](`${baseApiUrl}/${rotas}`, params, config,{
                timeout: 5000
            }).then(res => {

                var products_active = res.data.list_product_active

                var result_active = products_active.map(getList)

                this.products_active = result_active

                this.load = false

                this.productGroup()

            }).catch((e) => {

                if (e.code === 'ECONNABORTED') {
                    this.load = false
                }
            });

            function getList(item) {
                return {
                    id: item.id,
                    name: item.name,
                    unit_measurement: item.unit_measure_id,
                    date: (item.updated_at.substring(0, 10)).split('-').reverse().join('-')
                }
            }
        },
        /* =========================  Adiciona ou remove um produto a um determinado grupo de permissão ========================*/
        addproductsgroup(iten) {

            var condicao = 'active_' + iten.id // Forma a condição ex: active_1

            var result_key = Object.keys(this.checked) // Captura as chaves (keys) do array checked

            var indice = result_key.findIndex(checkIndiceKey) // Compara se existe o item condicao no array checked

            function checkIndiceKey(value) {
                return value == condicao
            }

            var valores = Object.values(this.checked) // Captura os valores do array checked

            var on_off = valores[indice] // Captura o valor no indice passado e coloca em on_off

            var method = 'post'
            var rotas = ''

            var params = {
                group_id: this.group_selected,
                product_id: iten.id,
            }

            const access_token = this.$cookie.getCookie(userKey)

            const config = {
                headers: {
                    Authorization: `Bearer ${access_token.token}`
                }
            };

            this.info_product['period_' + iten.id] = ''
            this.info_product['quantity_' + iten.id] = ''
            this.info_product['lifecycle_' + iten.id] = ''

            if (on_off) {
                if (this.group_selected > 0 && iten.id > 0) {

                    rotas = 'productsgroupc'

                    axios[method](`${baseApiUrl}/${rotas}`, params, config, {
                        timeout: 5000
                    }).then(() => {

                        document.getElementById('period' + iten.id).disabled = false
                        document.getElementById('quantity' + iten.id).disabled = false
                        document.getElementById('lifecycle' + iten.id).disabled = false

                        document.getElementById('btn' + iten.id).disabled = false

                    }).catch((e) => {

                        if (e.code === 'ECONNABORTED') {
                            this.load = false
                        }
                    });
                }
            } else if (!on_off) {

                if (this.group_selected > 0 && iten.id > 0) {

                    rotas = 'productsgroupd'

                    axios[method](`${baseApiUrl}/${rotas}`, params, config, {
                        timeout: 5000
                    }).then(res => {

                        console.log(res.data)

                        document.getElementById('period' + iten.id).disabled = true
                        document.getElementById('quantity' + iten.id).disabled = true
                        document.getElementById('lifecycle' + iten.id).disabled = true

                        document.getElementById('btn' + iten.id).disabled = true


                        document.getElementById('period' + iten.id).value = ''
                        document.getElementById('quantity' + iten.id).value = ''
                        document.getElementById('lifecycle' + iten.id).value = ''

                    }).catch((e) => {

                        if (e.code === 'ECONNABORTED') {
                            this.load = false
                        }
                    });
                }
            }
        },
        /*========================= Atualiza as informações de um produto relacionado a um determinado grupo ======*/
        updateproductsgroup(id) {

            var method = 'post'
            var rotas = 'productsgroupu'

            var condicao = 'period_' + id

            var result_key = Object.keys(this.info_product)

            var indice = result_key.findIndex(checkIndiceKey)

            function checkIndiceKey(value) {
                return value == condicao
            }

            var valores = Object.values(this.info_product)

            var params = {
                group_id: this.group_selected,
                product_id: id,
                period: valores[indice],
                quantity: valores[indice + 1],
                lifecycle: valores[indice + 2]
            }

            const access_token = this.$cookie.getCookie(userKey)

            const config = {
                headers: {
                    Authorization: `Bearer ${access_token.token}`
                }
            };

            axios[method](`${baseApiUrl}/${rotas}`, params, config, {
                timeout: 5000
            }).then(() => {

            }).catch((e) => {

                if (e.code === 'ECONNABORTED') {
                    this.load = false
                }
            });
        },
        /*================================================================================================== */
        productGroup() {

            var elementos
            var i

            if (this.group_selected > 0) {

                var method = 'post'
                var rotas = 'productsgroupl'

                var params = {
                    id: this.group_selected
                }

                const access_token = this.$cookie.getCookie(userKey)

                const config = {
                    headers: {
                        Authorization: `Bearer ${access_token.token}`
                    }
                };

                axios[method](`${baseApiUrl}/${rotas}`, params, config, {
                    timeout: 5000
                }).then(res => {

                    if (res.data.products_group.length > 0) {

                        this.checked = []

                        this.info_product = []

                        elementos = document.getElementsByClassName('config-values');

                        for (i = 0; i < elementos.length; i++) {
                            elementos[i].disabled = true;
                        }

                        elementos = document.getElementsByClassName('config-btn');

                        for (i = 0; i < elementos.length; i++) {
                            elementos[i].disabled = true;
                        }

                        res.data.products_group.forEach(item => {

                            var product_id = item.product_id

                            this.checked['active_' + product_id] = true

                            document.getElementById('period' + product_id).disabled = false
                            document.getElementById('quantity' + product_id).disabled = false
                            document.getElementById('lifecycle' + product_id).disabled = false

                            document.getElementById('btn' + product_id).disabled = false

                            this.info_product['period_' + product_id] = item.period ? item.period : ''
                            this.info_product['quantity_' + product_id] = item.quantity ? item.quantity : ''
                            this.info_product['lifecycle_' + product_id] = item.lifecycle ? item.lifecycle : ''

                        });
                    } else {

                        this.checked = []
                        this.info_product = []

                        elementos = document.getElementsByClassName('config-values');

                        for (i = 0; i < elementos.length; i++) {
                            elementos[i].disabled = true;
                        }

                    }

                }).catch((e) => {

                    if (e.code === 'ECONNABORTED') {
                        this.load = false
                    }
                });
            }
        },
        order(iten) {

            var list = Object.values(iten)

            list = list.slice(1, 4)

            return list;
        },
        reset() {
            this.mode = 'add'
            this.group = {}
            this.group.status = this.status['0']
            this.v$.$reset();
            this.loadgroups()
        },
        save() {

            this.load = true

            if (this.v$.$invalid) {

                console.log("Formulário alterado!")

            } else {
                const method = this.group.id ? 'put' : 'post'

                const id = this.group.id ? `/${this.group.id}` : ''

                var rotas = 'permissiongroups'

                const options = {
                    headers: {
                        "Accept": "application/json"
                    }
                }

                axios[method](`${baseApiUrl}/${rotas}${id}`, this.group, {
                    timeout: 15000
                }, options).then(() => {

                    this.load = false

                    if (method === 'post') {
                        this.$toast.success('Grupo cadastrado com sucesso!')
                    } else if (method === 'put') {
                        this.$toast.success('Grupo atualizado com sucesso!')
                    }

                    this.reset()
                }).catch((e) => {

                    if (e.code === 'ECONNABORTED') {
                        this.load = false
                    }
                });
            }
        },
        loadgroup(group, mode = 'add') {

            if (mode === 'delete') {
                this.larger = ''
            } else {
                this.larger = 'modal-lg'
            }

            this.mode = mode

            this.group = {
                ...group
            }
        },
        remove() {

            const id = this.group.id

            axios.delete(`${baseApiUrl}/permissiongroups/${id}`, {
                timeout: 15000
            }).then(res => {

                if (res.data == 'Grupo não encontrado!') {
                    this.$toast.error('Grupo não encontrado!')
                } else {
                    this.$toast.success('Grupo excluído com sucesso!')
                }

                this.reset()
            }).catch()
        }
    }
}
</script>

<style scoped>
/*-------------------------------------- Cabeçalho Companies --------------------------------------*/
.group {
    margin: 25px;
}

.group .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

/* -------------- Cabeçalho ------------------*/
.search .input-search {
    border-radius: 3px;
    border-color: #744cbc;
}

.search .input-search:focus,
.btn-search {
    box-shadow: 0 0 0 0 !important;
    outline: none !important;
}

.search .icon-search {
    color: #ffffff;
    transform: rotate(90deg);
}

.search .btn-search {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-color: #744cbc;
}

.search .btn-search:hover {
    background-color: #886aea !important;
}

/*--------------------------------------------- Table ---------------------------------------------*/
.group .cards .tables thead tr th {
    border-style: solid;
    border-bottom-width: 2px;
    color: rgb(114, 132, 140);
    border-bottom-color: rgb(222, 226, 230) !important;
}

.group .cards .tables thead th {
    font-size: 15px;
    font-weight: 600;
    padding-top: 10px;
    padding-bottom: 10px;
}

.group .cards .tables tbody td {
    font-size: 14px;
    padding-top: 4px;
    padding-bottom: 4px;
    line-height: 30px;
    color: #72848c;
}

.group .cards .tables tbody button {
    color: #ffffff;
    margin: 0 2px;
    padding: 2px 5px;
    box-shadow: 0 0 0 0 !important;
    outline: none !important;
}

.info_input .btn-save {
    display: flex;
    align-items: flex-end;
    justify-content: end;
}

.info_input .btn-save button {
    margin-right: 27px;
}

.group .cards .pagination {
    display: flex;
    justify-content: center;
}

/*------------------------------------------- Paginação ------------------------------------------ */
.pagination-table {
    margin-top: 30px;
}

.pagination-table .page-item .page-link {
    color: #72848c;
    text-decoration: none;
    border: 1px solid #dee2e6;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    cursor: pointer;
}

.pagination-table .page-item.active .page-link {
    color: #ffffff;
    background-color: #744cbc;
    border-color: #744cbc;
}

/*--------------------------------------------- Modal ---------------------------------------------*/
.group .modal-content {
    border: none;
}

/*------------------------------------------- Modal Header ----------------------------------------*/
.group .modal-header {
    padding: 10px 20px;
    background-color: #744cbc;
}

.group .modal-header .modal-title {
    color: #ffffff;
    font-size: 18px;
}

/*-------------------------------------------- Modal Body -----------------------------------------*/
.group .modal-body .msg {
    text-align: center;
    color: #72848c;
}

.group .modal-body .msg h6 {
    padding: 20px 0px 12px 0px;
    font-size: 17px;
}

.group .modal-body label {
    font-size: 15px;
    color: #72848c;
    margin-top: 10px;
    margin-left: 1px;
    margin-bottom: 5px;
}

/*------------------------------------------- Modal Footer ----------------------------------------*/
.group .modal-footer {
    padding: 8px;
}

.group .modal-footer button {
    border: none;
}

/*----------------------------------------- Mensagem De Erro --------------------------------------*/
.msg_error {
    color: #ff0000;
    font-size: 14px;
}

input,
input:focus,
.is-invalid {
    box-shadow: 0 0 0 0;
    outline: 0;
}

.is-invalid {
    box-shadow: 0 0 0 0 !important;
    outline: 0 !important;
}

.dados_empty {
    color: #72848c;
    font-weight: 600;

}

.loading button {
    position: absolute;
    top: calc(50% - 35px);
    left: calc(50%);
    margin-left: 90px;
    width: 70px;
    height: 70px;
    background-color: #3b0253;
    border: none;
}

.loading span {
    width: 30px;
    height: 30px;
}

/*================================================ Permission products =========================================== */
.info_geral {
    max-height: 45vh;
    overflow-y: scroll;
}

input:focus,
select:focus {
    box-shadow: 0 0 0 0;
    outline: 0;
}

.product_left {
    display: flex;
    flex-direction: column;
}

.product_right {
    display: flex;
    flex-direction: column;
}

.row1 {
    display: flex;
    justify-content: space-between;
}

.row1 .info_product {
    display: flex;
    align-items: center;
}

.row1 .image_product img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
}

.row1 .description_product {
    display: flex;
    flex-direction: column;
}

.row1 .description_product p {
    margin: 0;
    font-size: 12px
}

.row1 .description_product p span {
    font-weight: 700;
    color: #4e5c63;
}

.row1 .switch_on_off {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    margin-right: 30px;
}

/* Row 2 */

.row2 .info_input {
    margin-top: 10px;
}

.row2 .info_input label {
    font-size: 14px;
    margin: 0
}
</style>
