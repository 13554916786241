<template>
<div class="requisition">
    <div class="header">
        <Labels nome="Requisições" cor="#72848c" size="25px" />
        <div class="search">
            <div class="input-group">
                <input type="text" class="form-control input-search" placeholder="pesquisa" v-model="search">
                <span class="input-group-btn">
                    <button type="button" class="btn btn-default btn-search" v-on:click="loadrequisitions"><i class="fas fa-search icon-search"></i></button>
                </span>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="cards">
            <Card title="Requisições Realizadas" color="#72848c" colorbackground="#ffffff" fontSize="18px" text_tooltip="Adicionar requisição" :view_add="request_add" :view_active="view_add_button">
                <template v-slot:content class="tabs">
                    <Tab name_tab1="Ativa">
                        <template v-slot:Ativo>
                            <div v-if="requisitions_active == 'vazio'" class="dados_empty">
                                <p>Não há dados a serem exibidos!</p>
                            </div>
                            <div v-if="requisitions_active != 'vazio' && requisitions_active != 'nulo'" class="tables">
                                <table class="table table-hover">
                                    <thead>
                                        <tr>
                                            <th>Código</th>
                                            <th>Código Produto</th>
                                            <th>M. Usuário</th>
                                            <th>M. Operador</th>
                                            <th>Status</th>
                                            <th>Controle</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(iten, index) in requisitions_active" :key="index">
                                            <td v-for="(i, index) in order(iten)" :key="index">{{i}}</td>
                                            <td>
                                                <div class="controls">
                                                    <button @click="loadrequisition(iten, 'info')" type="button" class="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#modal_delete">Detalhes</button>
                                                    <button @click="loadrequisition(iten, 'delete')" type="button" class="btn btn-danger btn-sm" data-bs-toggle="modal" data-bs-target="#modal_delete">Excluir</button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="pagination">
                                <nav class="pagination-table">
                                    <ul class="pagination">
                                        <li v-if="page_1" class="page-item">
                                            <a class="page-link" v-on:click="loadpages(1)"><i class="fas fa-angle-left"></i></a>
                                        </li>
                                        <li v-if="page_1" class="page-item" :class="{active: active_1}"><a class="page-link" v-on:click="loadpages(page_1)">{{page_1}}</a></li>
                                        <li v-if="page_2" class="page-item" :class="{active: active_2}" aria-current="page">
                                            <a class="page-link" v-on:click="loadpages(page_2)">{{page_2}}</a>
                                        </li>
                                        <li v-if="page_3" class="page-item" :class="{active: active_3}"><a class="page-link" v-on:click="loadpages(page_3)">{{page_3}}</a></li>
                                        <li v-if="last_page" class="page-item">
                                            <a class="page-link" v-on:click="loadpages(last_page)"><i class="fas fa-angle-right"></i></a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </template>
                        <template v-slot:Inativo>
                            <div class="tables">
                                <table class="table table-hover">
                                    <thead>
                                        <tr>
                                            <th>Código</th>
                                            <th>Requsição</th>
                                            <th>Status</th>
                                            <th>Controle</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(iten, index) in requisitions_inactive" :key="index">
                                            <td v-for="(i, index) in order(iten)" :key="index">{{i}}</td>
                                            <td>
                                                <div class="controls">
                                                    <button @click="loadrequisition(iten, 'info')" type="button" class="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#modal_delete">Detalhes</button>
                                                    <button @click="loadrequisition(iten, 'update')" type="button" class="btn btn-info btn-sm" data-bs-toggle="modal" data-bs-target="#modal_add">Editar</button>
                                                    <button @click="loadrequisition(iten, 'delete')" type="button" class="btn btn-danger btn-sm" data-bs-toggle="modal" data-bs-target="#modal_delete">Excluir</button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="pagination">
                                <nav class="pagination-table">
                                    <ul class="pagination">
                                        <li v-if="page_1" class="page-item">
                                            <a class="page-link" v-on:click="loadpages(1)"><i class="fas fa-angle-left"></i></a>
                                        </li>
                                        <li v-if="page_1" class="page-item" :class="{active: active_1}"><a class="page-link" v-on:click="loadpages(page_1)">{{page_1}}</a></li>
                                        <li v-if="page_2" class="page-item" :class="{active: active_2}" aria-current="page">
                                            <a class="page-link" v-on:click="loadpages(page_2)">{{page_2}}</a>
                                        </li>
                                        <li v-if="page_3" class="page-item" :class="{active: active_3}"><a class="page-link" v-on:click="loadpages(page_3)">{{page_3}}</a></li>
                                        <li v-if="last_page" class="page-item">
                                            <a class="page-link" v-on:click="loadpages(last_page)"><i class="fas fa-angle-right"></i></a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </template>
                    </Tab>
                </template>
            </Card>
        </div>
        <div class="col-md-12">
            <div v-if="load" class="loading">
                <button class="btn btn-primary" type="button" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    <span class="visually-hidden">Loading...</span>
                </button>
            </div>
        </div>
    </div>
    <div class="modal fade" id="modal_add" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 v-if="mode === 'add'" class="modal-title" id="exampleModalLabel">Requisição</h5>
                    <h5 v-if="mode === 'update'" class="modal-title" id="exampleModalLabel">Atualizar Setor</h5>
                </div>
                <div class="modal-body">
                    <form>
                        <div class="row">
                            <div class="col-md-12">
                                <label for="people_id" class="form-label">Colaboradores</label>
                                <div class="select_collaborators">
                                    <table class="table table-sm" id="tableColaboradores">
                                        <tbody>
                                            <tr v-for="(iten, index) in list_collaborators" :key="index" @click="link_active(iten.id)">
                                                <td :class="{'active': active_link == iten.id}">
                                                    <a @click="select_colaborator(iten)">
                                                        <div class="info-people-list">
                                                            <div class="info-product">
                                                                <p><span>Matrícula:</span> {{iten.matriculation}}</p>
                                                                <p><span>Nome:</span> {{iten.name}}</p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <label for="people_id" class="form-label">Produtos</label>
                                <div class="select_product">
                                    <table class="table table-sm">
                                        <tbody>
                                            <tr v-for="(iten, index) in list_products" :key="index" @click="link_active2(iten.id)">
                                                <td :class="{'active': active_link2 == iten.id}">
                                                    <a @click="select_products(iten)">
                                                        <div class="info-people-list">
                                                            <div class="image">
                                                                <img src="@/assets/img/perfil.jpg" alt="">
                                                            </div>
                                                            <div class="info-product">
                                                                <p><span>Código:</span> {{iten.code}}</p>
                                                                <p><span>Nome:</span> {{iten.name}}</p>
                                                                <p><span>Unidade:</span> {{iten.quantity}} Par</p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <label for="justification" class="form-label">Justificativa</label>
                                <select class="form-select" id="justification" v-model="requisition.justification">
                                    <option v-for="(justification, index) in justifications" :value="justification.id" :key="index">{{justification.name}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <label for="quantity" class="form-label">Quantidade</label>
                                <input autocomplete="off" type="text" class="form-control" id="quantity" v-model="requisition.quantity">
                            </div>
                            <div class="col-md-4">
                                <label for="justification" class="form-label">Local de retirada</label>
                                <select class="form-select" id="justification" v-model="requisition.equipament">
                                    <option v-for="(equipament, index) in equipaments" :value="equipament.id" :key="index">{{equipament.name}}</option>
                                </select>
                            </div>
                            <div class="col-md-4">
                                <label for="delivery_location" class="form-label">Efeito no saldo</label>
                                <select class="form-select" id="delivery_location" v-model="requisition.balance">
                                    <option selected value="1">Contabilizar</option>
                                    <option value="2">Desconsiderar</option>
                                </select>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button @click="reset" type="button" class="btn btn-danger" data-bs-dismiss="modal">Cancelar</button>
                    <button @click="save" type="button" class="btn btn-primary" data-bs-dismiss="modal">Salvar</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="modal_delete" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modal_info" aria-hidden="true">
        <div class="modal-dialog" :class="larger">
            <div class="modal-content">
                <input id="requisition-id" type="hidden" v-model="requisition.id" />
                <div class="modal-header">
                    <h5 v-if="mode === 'delete'" class="modal-title" id="exampleModalLabel">Excluir Setor</h5>
                    <h5 v-if="mode === 'info'" class="modal-title" id="exampleModalLabel">Informações Do Setor</h5>
                </div>
                <div class="modal-body">
                    <div v-if="mode === 'delete'">
                        <div class="msg">
                            <h6>Você tem certeza que deseja excluir o registro?</h6>
                        </div>
                    </div>
                    <div v-if="mode === 'info'">
                        <div class="row">
                            <div class="col-md-12">
                                <label class="label_info"><b>Código: </b><span>{{requisition.code}}</span></label>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-md-12">
                                <label class="label_info"><b>Nome: </b><span>{{requisition.name}}</span></label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <label class="label_info"><b>Email: </b><span>{{requisition.email}}</span></label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <label class="label_info"><b>Telefone: </b><span>{{requisition.phone}}</span></label>
                            </div>
                            <div class="col-md-4">
                                <label class="label_info"><b>Centro de custo: </b><span>{{requisition.cost_center}}</span></label>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-md-12">
                                <label class="label_info"><b>Obs: </b><span>{{requisition.obs}}</span></label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button v-if="mode == 'info'" @click="reset" type="button" class="btn btn-danger btn_left" data-bs-dismiss="modal">Fechar</button>
                    <button v-if="mode != 'info'" @click="reset" type="button" class="btn btn-danger btn_left" data-bs-dismiss="modal">Cancelar</button>
                    <button v-if="mode != 'info'" @click="remove" type="button" class="btn btn-primary btn_right" data-bs-dismiss="modal">Confirmar</button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
/*import {required, maxLength, email} from '@vuelidate/validators'*/
import {
    baseApiUrl,
    userKey,
    showError
} from '@/global'
import axios from 'axios'
import Labels from "@/components/Labels/Label"
import Card from "@/components/Cards/Card"
import Tab from "@/components/Tabs/Tab"

export default {
    name: "requisition",
    data: function () {
        return {
            v$: useVuelidate(),
            mode: 'add',
            requisition: {},
            requisitions: [],
            requisitions_active: [],
            requisitions_inactive: [],
            itens: '',
            larger: 'modal-lg',
            search: '',
            page_1: '',
            page_2: '',
            page_3: '',
            current_page: 1,
            active_1: '',
            active_2: '',
            active_3: '',
            last_page: '',
            msg_error: '',
            load: false,
            view_add_button: true,
            request_add: true,
            active_link: false,
            active_link2: false,
            list_collaborators: '',
            list_products: '',
            justifications: '',
            equipaments: '',
            add_collaborators:'',
            add_products:''
        }
    },
    components: {
        Labels,
        Card,
        Tab,
    },
    mounted() {

        const access_credential = this.$cookie.getCookie(userKey)

        var permissoes = access_credential.permissions

        var permissoes_array = permissoes.split(',')

        this.request_add     = permissoes_array.includes('949983') == true ? true : false
        this.request_info    = permissoes_array.includes('949982') == true ? true : false
        this.request_edit    = permissoes_array.includes('949981') == true ? true : false
        this.request_delete  = permissoes_array.includes('949980') == true ? true : false


        this.requisition.balance = 1
        this.loadrequisitions();
    },
    methods: {
        loadpages(page_select) {

            console.log(page_select)

            this.current_page = page_select

            this.loadrequisitions()
        },
        select_colaborator(iten_collaborators){
            this.add_collaborators = iten_collaborators
        },
        select_products(iten_products){
            this.add_products = iten_products
        },
        loadrequisitions() {

            this.load = false

            var method;
            var rotas;

            method = 'get'

            rotas = 'list_info_request'

            axios[method](`${baseApiUrl}/${rotas}`).then(res => {

                this.list_collaborators = res.data.list_collaborators
                this.list_products = res.data.list_products

                this.justifications = res.data.list_Justifications
                this.requisition.justification = res.data.list_Justifications[0].id

                this.equipaments = res.data.list_equipaments
                this.requisition.equipament = res.data.list_equipaments[0].id

                console.log(res.data)

            });

            /*function getList(item) {
                return {
                    code: item.id,
                    code_product: item.code_product,
                    matricula: item.matriculation,
                    matricula_opeartor: item.operator_matriculation,
                    status: 'Pendente'
                }
            }*/
        },
        order(iten) {

            var list = Object.values(iten)

            list = list.slice(0, 5)

            return list;
        },
        reset() {
            this.mode = 'add'
            this.requisition = {}
            this.v$.$reset();
            this.loadrequisitions()
        },
        save() {

            const access_credential = this.$cookie.getCookie(userKey)

            const method = 'post'

            var rotas = 'request_create'

            const options = {
                headers: {
                    "Accept": "application/json"
                }
            }
            

            this.requisition.collaborators_add = this.add_collaborators
            this.requisition.products_add = this.add_products

            this.requisition.operator = access_credential.name

            console.log(this.add_products)

            axios[method](`${baseApiUrl}/${rotas}`, this.requisition, options).then(res => {

                console.log(res.data)

                //this.$toast.success('Usuário cadastrado com sucesso!')

                /*if (method === 'post') {
                    this.$toast.success('Setor cadastrada com sucesso!')
                } else if (method === 'put') {
                    this.$toast.success('Setor atualizada com sucesso!')
                }

                this.reset()*/
            }).catch(showError)
        },
        loadrequisition(requisition, mode = 'add') {

            if (mode === 'delete') {
                this.larger = ''
            } else {
                this.larger = 'modal-lg'
            }

            this.mode = mode

            this.requisition = {
                ...requisition
            }
        },
        remove() {

            const id = this.requisition.id

            axios.delete(`${baseApiUrl}/requests/${id}`).then(res => {

                if (res.data == 'Setor não encontrado!') {
                    this.$toast.error('Setor não encontrado!')
                } else {
                    this.$toast.success('Setor excluída com sucesso!')
                }

                this.reset()
            }).catch(showError)
        },
        link_active(index) {

            this.active_link = index
        },
        link_active2(index) {

            this.active_link2 = index
        },
    }
}
</script>

<style scoped>
/*-------------------------------------- Cabeçalho Companies --------------------------------------*/
.requisition {
    margin: 25px;
}

.requisition .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

/* -------------- Cabeçalho ------------------*/
.search .input-search {
    border-radius: 3px;
    border-color: #744cbc;
}

.search .input-search:focus,
.btn-search {
    box-shadow: 0 0 0 0 !important;
    outline: none !important;
}

.search .icon-search {
    color: #ffffff;
    transform: rotate(90deg);
}

.search .btn-search {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-color: #744cbc;
}

.search .btn-search:hover {
    background-color: #886aea !important;
}

/*--------------------------------------------- Table ---------------------------------------------*/
.requisition .cards .tables thead tr th {
    border-style: solid;
    border-bottom-width: 2px;
    color: rgb(114, 132, 140);
    border-bottom-color: rgb(222, 226, 230) !important;
}

.requisition .cards .tables thead th {
    font-size: 15px;
    font-weight: 600;
    padding-top: 10px;
    padding-bottom: 10px;
}

.requisition .cards .tables tbody td {
    font-size: 14px;
    padding-top: 4px;
    padding-bottom: 4px;
    line-height: 30px;
    color: #72848c;
}

.requisition .cards .tables tbody button {
    color: #ffffff;
    margin: 0 2px;
    padding: 2px 5px;
    box-shadow: 0 0 0 0 !important;
    outline: none !important;
}

.requisition .cards .pagination {
    display: flex;
    justify-content: center;
}

/*------------------------------------------- Paginação ------------------------------------------ */
.pagination-table {
    margin-top: 30px;
}

.pagination-table .page-item .page-link {
    color: #72848c;
    text-decoration: none;
    border: 1px solid #dee2e6;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    cursor: pointer;
}

.pagination-table .page-item.active .page-link {
    color: #ffffff;
    background-color: #744cbc;
    border-color: #744cbc;
}

/*--------------------------------------------- Modal ---------------------------------------------*/
.requisition .modal-content {
    border: none;
}

/*------------------------------------------- Modal Header ----------------------------------------*/
.requisition .modal-header {
    padding: 10px 20px;
    background-color: #744cbc;
}

.requisition .modal-header .modal-title {
    color: #ffffff;
    font-size: 18px;
}

/*-------------------------------------------- Modal Body -----------------------------------------*/
.requisition .modal-body .msg {
    text-align: center;
    color: #72848c;
}

.requisition .modal-body .msg h6 {
    padding: 20px 0px 12px 0px;
    font-size: 17px;
}

.requisition .modal-body label {
    font-size: 15px;
    color: #72848c;
    margin-top: 10px;
    margin-left: 1px;
    margin-bottom: 5px;
}

/*------------------------------------------- Modal Footer ----------------------------------------*/
.requisition .modal-footer {
    padding: 8px;
}

.requisition .modal-footer button {
    border: none;
}

/*----------------------------------------- Mensagem De Erro --------------------------------------*/
.msg_error {
    color: #ff0000;
    font-size: 14px;
}

input,
input:focus,
.is-invalid {
    box-shadow: 0 0 0 0;
    outline: 0;
}

.is-invalid {
    box-shadow: 0 0 0 0 !important;
    outline: 0 !important;
}

.dados_empty {
    color: #72848c;
    font-weight: 600;

}

.loading button {
    position: absolute;
    top: calc(50% - 35px);
    left: calc(50%);
    margin-left: 90px;
    width: 70px;
    height: 70px;
    background-color: #3b0253;
    border: none;
}

.loading span {
    width: 30px;
    height: 30px;
}

/*=================================================================================================*/
/*                                        Select Colaboradores                                     */
/*=================================================================================================*/
a {
    cursor: pointer;
}

.select_collaborators {
    height: 109px;
    border: 1px solid #ced4da;
    padding: 5px;
    border-radius: 5px;
    overflow-y: scroll;
}

.select_collaborators .info-people-list {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    ;
}

.select_collaborators .info-people-list .image img {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin-right: 7px;
}

.select_collaborators .info-people-list .info-product p {
    font-size: 15px;
    line-height: 16px;
    margin: 0;
}

.select_collaborators .info-people-list .info-product p span {
    font-weight: 600;
}

/**/
.select_product {
    height: 133px;
    border: 1px solid #ced4da;
    padding: 5px;
    border-radius: 5px;
    overflow-y: scroll;
}

.select_product .info-people-list {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    ;
}

.select_product .info-people-list .image img {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin-right: 7px;
}

.select_product .info-people-list .info-product p {
    font-size: 15px;
    line-height: 16px;
    margin: 0;
}

.select_product .info-people-list .info-product p span {
    font-weight: 600;
}

.select_collaborators .active, .select_product .active {
    color: #fff;
    background-color: #886aea;
    border-radius: 4px;
    ;
}
</style>
