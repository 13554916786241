<template>
<div class="zone">
    <div class="header">
        <Labels nome="Relatório" cor="#72848c" size="25px" />
    </div>
    <div class="row">
        <div class="cards">
            <Card-report title="Retirada Por Requisição" :periodo_inicial="date_period_inicial" :periodo_final="date_period_final" systemname="Sistema de Controle de Entrega de EPIs" color="#72848c" colorbackground="#ffffff" fontSize="18px" text_tooltip="Selecionar campos" :view_add="true" :view_excel="true" :view_active="view_add_button">
                <template v-slot:content class="tabs">
                    <div v-if="false" class="dados_empty">
                        <p>Não há dados a serem exibidos!</p>
                    </div>
                    <div v-if="true" class="tables">
                        <div class="info_company">
                            <div class="side_info">
                                <div class="company_title">
                                    Empresa:
                                </div>
                                <div class="company_name">
                                    Technip
                                </div>
                            </div>
                            <div class="side_back">
                                <button type="button" v-on:click="back_to_menu" class="btn btn-primary btn-sm back_btn">Voltar</button>
                            </div>
                        </div>
                        <div class="tabela_reponsiva">
                            <table class="table table-bordered table-hover table-sm">
                                <thead>
                                    <tr>
                                        <th v-if="area">Área</th>
                                        <th v-if="setor">Setor</th>
                                        <th v-if="ponto_entrega">Ponto de Entrega</th>
                                        <th v-if="equipamento">Equipamento</th>
                                        <th v-if="colaborador">Colaborador</th>
                                        <th v-if="matricula_colaborador">Matrícula Colaborador</th>
                                        <th v-if="funcao">Função</th>
                                        <th v-if="code_produto">Código do Produto</th>
                                        <th v-if="produto">Produto</th>
                                        <th v-if="quantidade_entregue">Quantidade</th>
                                        <th v-if="ca">Ca</th>
                                        <th v-if="justification">Justificativa</th>
                                        <th v-if="effect_balance">Efeito no saldo</th>
                                        <th v-if="operador">Operador</th>
                                        <!--<th v-if="matricula_operador">Matrícula Operador</th>-->
                                        <th v-if="data_emissao">Data de emissao</th>
                                        <th v-if="data_emissao">Hora de emissao</th>
                                        <th v-if="data_entrega">Data de entrega</th>
                                        <th v-if="data_entrega">Hora de entrega</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(iten, index) in list_delivery_request" :key="index">
                                        <td v-for="(i, index) in iten" :key="index">{{i}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </template>
            </Card-report>
        </div>
        <div class="col-md-12">
            <div v-if="load" class="loading">
                <button class="btn btn-primary" type="button" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    <span class="visually-hidden">Loading...</span>
                </button>
            </div>
        </div>
    </div>
    <div class="modal fade" id="modal_add" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Campos do relatório</h5>
                </div>
                <div class="modal-body">
                    <form>
                        <br>
                        <div class="row">
                            <div class="col-md-3">
                                <div class="form-check">
                                    <input class="form-check-input check_label" type="checkbox" value="" id="areaCheckChecked" v-model="area">
                                    <label class="form-check-label field-select check_label" for="areaCheckChecked">
                                        Área
                                    </label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-check">
                                    <input class="form-check-input check_label" type="checkbox" value="" id="setorCheckChecked" v-model="setor">
                                    <label class="form-check-label field-select check_label" for="setorCheckChecked">
                                        Setor
                                    </label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-check">
                                    <input class="form-check-input check_label" type="checkbox" value="" id="ponto_entregaCheckChecked" v-model="ponto_entrega">
                                    <label class="form-check-label field-select check_label" for="ponto_entregaCheckChecked">
                                        Ponto de Entrega
                                    </label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-check">
                                    <input class="form-check-input check_label" type="checkbox" value="" id="equipamentoCheckChecked" v-model="equipamento">
                                    <label class="form-check-label field-select check_label" for="equipamentoCheckChecked">
                                        Equipamento
                                    </label>
                                </div>
                            </div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col-md-3">
                                <div class="form-check">
                                    <input class="form-check-input check_label" type="checkbox" value="" id="colaboradorCheckChecked" v-model="colaborador">
                                    <label class="form-check-label field-select check_label" for="colaboradorCheckChecked">
                                        Colaborador
                                    </label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-check">
                                    <input class="form-check-input check_label" type="checkbox" value="" id="matricula_colaboradorCheckChecked" v-model="matricula_colaborador">
                                    <label class="form-check-label field-select check_label" for="matricula_colaboradorCheckChecked">
                                        Matrícula Colaborador
                                    </label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-check">
                                    <input class="form-check-input check_label" type="checkbox" value="" id="funcaoCheckChecked" v-model="funcao">
                                    <label class="form-check-label field-select check_label" for="funcaoCheckChecked">
                                        Função
                                    </label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-check">
                                    <input class="form-check-input check_label" type="checkbox" value="" id="codigo_produtoCheckChecked" v-model="code_produto">
                                    <label class="form-check-label field-select check_label" for="codigo_produtoCheckChecked">
                                        Código do Produto
                                    </label>
                                </div>
                            </div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col-md-3">
                                <div class="form-check">
                                    <input class="form-check-input check_label" type="checkbox" value="" id="description_produtoCheckChecked" v-model="produto">
                                    <label class="form-check-label field-select check_label" for="description_produtoCheckChecked">
                                        Produto
                                    </label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-check">
                                    <input class="form-check-input check_label" type="checkbox" value="" id="quantidade_entregueCheckChecked" v-model="quantidade_entregue">
                                    <label class="form-check-label field-select check_label" for="quantidade_entregueCheckChecked">
                                        Quantidade
                                    </label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-check">
                                    <input class="form-check-input check_label" type="checkbox" value="" id="caCheckChecked" v-model="ca">
                                    <label class="form-check-label field-select check_label" for="caCheckChecked">
                                        Ca
                                    </label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-check">
                                    <input class="form-check-input check_label" type="checkbox" value="" id="justificationCheckChecked" v-model="justification">
                                    <label class="form-check-label field-select check_label" for="justificationCheckChecked">
                                        Justificativa
                                    </label>
                                </div>
                            </div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col-md-3">
                                <div class="form-check">
                                    <input class="form-check-input check_label" type="checkbox" value="" id="effect_balanceCheckChecked" v-model="effect_balance">
                                    <label class="form-check-label field-select check_label" for="effect_balanceCheckChecked">
                                        Efeito no saldo
                                    </label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-check">
                                    <input class="form-check-input check_label" type="checkbox" value="" id="operadorCheckChecked" v-model="operador">
                                    <label class="form-check-label field-select check_label" for="operadorCheckChecked">
                                        Operador
                                    </label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-check">
                                    <input class="form-check-input check_label" type="checkbox" value="" id="data_entregaCheckChecked" v-model="data_entrega">
                                    <label class="form-check-label field-select check_label" for="data_entregaCheckChecked">
                                        Data de entrega
                                    </label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-check">
                                    <input class="form-check-input check_label" type="checkbox" value="" id="data_emissaoCheckChecked" v-model="data_emissao">
                                    <label class="form-check-label field-select check_label" for="data_emissaoCheckChecked">
                                        Data de emissão
                                    </label>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="offset-md-2 col-md-5">
                                <label for="datainicial" class="form-label">Data Inicial</label>
                                <v-date-picker v-model="date_inicial" :model-config="modelConfig">
                                    <template #default="{ inputValue, inputEvents }">
                                        <input class="px-3 py-1 border rounded" id="datainicial" :value="inputValue" v-on="inputEvents" />
                                    </template>
                                </v-date-picker>
                            </div>
                            <div class="col-md-5">
                                <label for="datafinal" class="form-label">Data Final</label>
                                <v-date-picker v-model="date_final" :model-config="modelConfig">
                                    <template #default="{ inputValue, inputEvents }">
                                        <input class="px-3 py-1 border rounded" id="datafinal" :value="inputValue" v-on="inputEvents" />
                                    </template>
                                </v-date-picker>
                            </div>
                        </div>
                    </form>
                    <hr>
                    <br/>
                    <div>
                        <div class="row">
                            <div class="offset-md-3 col-md-6">
                                <div class="form-check form-check-inline first-option">
                                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="1" v-model="selectReport" checked>
                                    <label class="form-check-label radio-label" for="inlineRadio1">Relatório em tela</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="2" v-model="selectReport">
                                    <label class="form-check-label radio-label" for="inlineRadio2">Relatório em excel</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Cancelar</button>
                    <button @click="selectReportShow" type="button" class="btn btn-primary" data-bs-dismiss="modal">Salvar</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="modal_excel" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Exportar relatório excel</h5>
                </div>
                <div class="modal-body">
                    <p class="msg_excel">Clique no botão gerar para emitir o relatório</p>
                    <form v-if="false">
                        <br>
                        <div class="row">
                            <div class="col-md-3">
                                <div class="form-check">
                                    <input class="form-check-input check_label" type="checkbox" value="" id="areaCheckChecked" v-model="area">
                                    <label class="form-check-label field-select check_label" for="areaCheckChecked">
                                        Área
                                    </label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-check">
                                    <input class="form-check-input check_label" type="checkbox" value="" id="setorCheckChecked" v-model="setor">
                                    <label class="form-check-label field-select check_label" for="setorCheckChecked">
                                        Setor
                                    </label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-check">
                                    <input class="form-check-input check_label" type="checkbox" value="" id="ponto_entregaCheckChecked" v-model="ponto_entrega">
                                    <label class="form-check-label field-select check_label" for="ponto_entregaCheckChecked">
                                        Ponto de Entrega
                                    </label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-check">
                                    <input class="form-check-input check_label" type="checkbox" value="" id="equipamentoCheckChecked" v-model="equipamento">
                                    <label class="form-check-label field-select check_label" for="equipamentoCheckChecked">
                                        Equipamento
                                    </label>
                                </div>
                            </div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col-md-3">
                                <div class="form-check">
                                    <input class="form-check-input check_label" type="checkbox" value="" id="colaboradorCheckChecked" v-model="colaborador">
                                    <label class="form-check-label field-select check_label" for="colaboradorCheckChecked">
                                        Colaborador
                                    </label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-check">
                                    <input class="form-check-input check_label" type="checkbox" value="" id="matricula_colaboradorCheckChecked" v-model="matricula_colaborador">
                                    <label class="form-check-label field-select check_label" for="matricula_colaboradorCheckChecked">
                                        Matrícula Colaborador
                                    </label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-check">
                                    <input class="form-check-input check_label" type="checkbox" value="" id="centro_custoCheckChecked" v-model="centro_custo">
                                    <label class="form-check-label field-select check_label" for="centro_custoCheckChecked">
                                        Centro de Custo
                                    </label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-check">
                                    <input class="form-check-input check_label" type="checkbox" value="" id="produtoCheckChecked" v-model="produto">
                                    <label class="form-check-label field-select check_label" for="produtoCheckChecked">
                                        Produto
                                    </label>
                                </div>
                            </div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col-md-3">
                                <div class="form-check">
                                    <input class="form-check-input check_label" type="checkbox" value="" id="caCheckChecked" v-model="ca">
                                    <label class="form-check-label field-select check_label" for="caCheckChecked">
                                        Ca
                                    </label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-check">
                                    <input class="form-check-input check_label" type="checkbox" value="" id="quantidadeCheckChecked" v-model="quantidade_entregue">
                                    <label class="form-check-label field-select check_label" for="quantidadeCheckChecked">
                                        Quantidade
                                    </label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-check">
                                    <input class="form-check-input check_label" type="checkbox" value="" id="identificacaoCheckChecked" v-model="identificacao">
                                    <label class="form-check-label field-select check_label" for="identificacaoCheckChecked">
                                        Identificação
                                    </label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-check">
                                    <input class="form-check-input check_label" type="checkbox" value="" id="autenticacaoCheckChecked" v-model="autenticacao">
                                    <label class="form-check-label field-select check_label" for="autenticacaoCheckChecked">
                                        Autenticação
                                    </label>
                                </div>
                            </div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col-md-3">
                                <div class="form-check">
                                    <input class="form-check-input check_label" type="checkbox" value="" id="regraCheckChecked" v-model="regra">
                                    <label class="form-check-label field-select check_label" for="regraCheckChecked">
                                        Regra
                                    </label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-check">
                                    <input class="form-check-input check_label" type="checkbox" value="" id="data_entregaCheckChecked" v-model="data_entrega">
                                    <label class="form-check-label field-select check_label" for="data_entregaCheckChecked">
                                        Data de Entrega
                                    </label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-check">
                                    <input class="form-check-input check_label" type="checkbox" value="" id="horaCheckChecked" v-model="hora">
                                    <label class="form-check-label field-select check_label" for="horaCheckChecked">
                                        Hora
                                    </label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-check">
                                    <input class="form-check-input check_label" type="checkbox" value="" id="operadorCheckChecked" v-model="operador">
                                    <label class="form-check-label field-select check_label" for="operadorCheckChecked">
                                        Operador
                                    </label>
                                </div>
                            </div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col-md-3">
                                <div class="form-check">
                                    <input class="form-check-input check_label" type="checkbox" value="" id="matricula_operadorCheckChecked" v-model="matricula_operador">
                                    <label class="form-check-label field-select check_label" for="matricula_operadorCheckChecked">
                                        Matrícula Operador
                                    </label>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="offset-md-2 col-md-5">
                                <label for="datainicial" class="form-label">Data Inicial</label>
                                <v-date-picker v-model="date_inicial" :model-config="modelConfig">
                                    <template #default="{ inputValue, inputEvents }">
                                        <input class="px-3 py-1 border rounded" id="datainicial" :value="inputValue" v-on="inputEvents" />
                                    </template>
                                </v-date-picker>
                            </div>
                            <div class="col-md-5">
                                <label for="datafinal" class="form-label">Data Final</label>
                                <v-date-picker v-model="date_final" :model-config="modelConfig">
                                    <template #default="{ inputValue, inputEvents }">
                                        <input class="px-3 py-1 border rounded" id="datafinal" :value="inputValue" v-on="inputEvents" />
                                    </template>
                                </v-date-picker>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button @click="reset('cancelar')" type="button" class="btn btn-danger" data-bs-dismiss="modal">Cancelar</button>
                    <button @click="selectReportShow" type="button" class="btn btn-primary" data-bs-dismiss="modal">Gerar</button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    baseApiUrl,
    userKey
} from '@/global'

import axios from 'axios'
import Labels from "@/components/Labels/Label"
import CardReport from "@/components/Cards/Card-report"

export default {
    name: "zone",
    data: function () {
        return {
            date_inicial: new Date().toISOString(),
            date_final: new Date().toISOString(),
            date_period_inicial: '---',
            date_period_final: '---',
            mode: 'report_delivery_request',
            load: false,
            list_delivery_request:'',
            area:false,
            setor:false,
            ponto_entrega:false,
            equipamento:false,
            colaborador: false,
            matricula_colaborador: false,
            funcao:false,
            code_produto:false,
            produto:false,
            quantidade_entregue: false,
            ca:false,
            justification: false,
            effect_balance:false,
            operador: false,
            matricula_operador:false,
            data_emissao: false,
            data_entrega: false,
            itens: '',
            larger: 'modal-lg',
            companies_list: '',
            company_selected: '0',
            company_units: '',
            company_unit_selected: '0',
            view_add_button: true,
            modelConfig: {
                type: 'string',
                mask: 'YYYY-MM-DD', 
            },
            selectReport:1
        }
    },
    components: {
        Labels,
        CardReport
    },
    mounted() {

        //this.loadcompanies();
    },
    methods: {
        selectReportShow(){

            if(this.area == true || this.setor == true || this.ponto_entrega == true || this.equipamento == true || this.colaborador == true || this.matricula_colaborador == true || this.funcao || this.code_produto == true || this.produto == true || this.ca == true || this.quantidade_entregue == true || this.identificacao == true || this.autenticacao == true || this.regra == true || this.data_entrega == true || this.hora == true || this.operador == true){
                
                if(this.selectReport == 1){
                    console.log("tela")
                    this.report_request()
                }else if(this.selectReport == 2){
                    console.log("excel")
                    this.report_delivery_excel()
                }
            }else{
                this.$toast.error('Selecione um campo !')
                
                return;
            }
        },
        report_request() {

            this.load = true

            let result_date_inicial = this.date_inicial.substring(0, 10);
            let result_date_final = this.date_final.substring(0,10);

            this.date_period_inicial = result_date_inicial.split("-").reverse().join('-')
            this.date_period_final = result_date_final.split("-").reverse().join('-')

            var params = {
                area: this.area,
                setor: this.setor,
                ponto_entrega: this.ponto_entrega,
                equipamento: this.equipamento,
                colaborador: this.colaborador,
                matricula_colaborador: this.matricula_colaborador,
                funcao: this.funcao,
                code_produto: this.code_produto,
                produto: this.produto,
                quantidade_entregue: this.quantidade_entregue,
                justification: this.justification,
                effect_balance: this.effect_balance,
                ca: this.ca,
                operador: this.operador,
                matricula_operador: this.matricula_operador,
                data_emissao: this.data_emissao,
                data_entrega: this.data_entrega,
                date_inicial: result_date_inicial,
                date_final: result_date_final
            }

            var method = 'post'
            var rotas = 'list-report-delivery-request'

            const access_token = this.$cookie.getCookie(userKey)

            const config = {
                headers: {
                    Authorization: `Bearer ${access_token.token}`
                }
            };

            axios[method](`${baseApiUrl}/${rotas}`, params, config, {
                timeout: 5000
            }).then(res => {

                if(res.data.list_delivery_request != 'vazio'){
                    res.data.list_delivery_request.forEach(element => {

                        delete element.data_emissao

                        delete element.data_entrega

                    });

                    this.list_delivery_request = res.data.list_delivery_request
                }

                this.load = false

            }).catch((e) => {

                this.load = false

                console.log(e)

                if (e.code === 'ECONNABORTED') {
                    this.load = false
                }
            });
        },
        back_to_menu() {

            this.$router.push({
                path: '/menu_report'
            })

        },
        report_delivery_excel(){

            this.load = true

            var method = 'post'
            var rotas = 'report_request_excel'

            const access_token = this.$cookie.getCookie(userKey)

            const config = {
                headers: {
                    Authorization: `Bearer ${access_token.token}`,
                    "Accept": "application/octet-stream"
                },
                //responseType: 'blob'
            };

            let result_date_inicial = this.date_inicial.substring(0, 10);
            let result_date_final = this.date_final.substring(0,10);

            console.log(result_date_inicial);
            

            this.date_period_inicial = result_date_inicial.split("-").reverse().join('-')
            this.date_period_final = result_date_final.split("-").reverse().join('-')

            var params = {
                area: this.area,
                setor: this.setor,
                ponto_entrega: this.area,                                  //this.ponto_entrega,
                equipamento: this.equipamento,
                colaborador: this.colaborador,
                matricula_colaborador: this.matricula_colaborador,
                funcao: this.funcao,
                code_produto: this.code_produto,
                produto: this.produto,
                ca: this.ca,
                quantidade_entregue: this.quantidade_entregue,
                justification: this.justification,
                effect_balance: this.effect_balance,
                operador: this.operador,
                data_emissao: this.data_emissao,
                data_entrega: this.data_entrega,
                date_inicial: result_date_inicial,
                date_final: result_date_final
                /*identificacao: this.identificacao,
                autenticacao: this.autenticacao,*/
                
                /*hora: this.hora,*/
            }

            axios[method](`${baseApiUrl}/${rotas}`, params, {responseType: "blob"},config,{
                timeout: 15000
            }).then(response => {

                const blob = new Blob([response.data], { type: 'application/vnd.ms-excel' });

                const url = URL.createObjectURL(blob)
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'delivery_requisição.xlsx')
                document.body.appendChild(link)
                link.click()

                this.load = false

            }).catch((e) => {

                this.load = false

                if (e.code === 'ECONNABORTED') {
                    this.load = false
                }
            });
        },
        loadcompanies() {

            this.load = false

            var params = {
                page: this.page,
                search: this.search
            }

            var method = 'post'
            var rotas = 'company-list-all-active'

            const access_token = this.$cookie.getCookie(userKey)

            const config = {
                headers: {
                    Authorization: `Bearer ${access_token.token}`
                }
            };

            axios[method](`${baseApiUrl}/${rotas}`, params, config, {
                timeout: 5000
            }).then(res => {

                this.companies_list = res.data.companies_list_active.data

                this.load = false

            }).catch((e) => {

                if (e.code === 'ECONNABORTED') {
                    this.load = false
                }
            });
        },
        order(iten) {

            var list = Object.values(iten)

            list = list.slice(1, 4)

            return list;
        },
        reset(mode = 'add') {

            this.mode = 'add'
            this.zone = {}
            this.zone.status = this.status['0']
            this.v$.$reset();

            if (mode != 'info' && mode != 'cancelar') {
                this.loadzones_unit()
            }
        },
        loadzone(zone, mode = 'add') {

            if (mode === 'delete') {
                this.larger = ''
            } else {
                this.larger = 'modal-lg'
            }

            this.mode = mode

            this.zone = {
                ...zone
            }
        }
    }
}
</script>

<style scoped>
/*----------------------------------------- Informações da empresa --------------------------------*/
.check_label{
    cursor:pointer;
}
.info_company {
    display: flex;
    margin-bottom: 12px;
    justify-content: space-between;
}

.side_info {
    display: flex;
}

.company_title {
    margin-right: 5px;
    font-size: 18px;
    color: #728690;
    font-weight: 500;
}

.company_name {
    display: flex;
    align-items: center;
    font-size: 17px;
    color: #728690;
}

.back_btn {
    padding: 2px 10px;
    background: blueviolet;
    border: none;
}

.back_btn:hover {
    padding: 2px 10px;
    background: rgba(120, 47, 189, 0.664);
    ;
    border: none;
}

/*------------------------------------------------ tootip -----------------------------------------*/
.v-popper--theme-info-tooltip .v-popper__inner {
    background: #2a2828 !important;
    color: #e51f1f !important;
}

.paginate {
    display: flex;
    justify-content: center;
}

/* ------------------------------------ Filtros de pesquisa ---------------------------------------*/
.cards .select_filter {
    margin-bottom: 15px;
}

/*-------------------------------------- Cabeçalho Companies --------------------------------------*/
.zone {
    margin: 25px;
}

.zone .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

/* -------------- Cabeçalho ------------------*/
.search .input-search {
    border-radius: 3px;
    border-color: #744cbc;
}

.search .input-search:focus,
.btn-search {
    box-shadow: 0 0 0 0 !important;
    outline: none !important;
}

.search .icon-search {
    color: #ffffff;
    transform: rotate(90deg);
}

.search .btn-search {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-color: #744cbc;
}

.search .btn-search:hover {
    background-color: #886aea !important;
}

/*--------------------------------------------- Table ---------------------------------------------*/
.zone .cards .tabela_reponsiva{
    margin:0 0 0 0;
    padding:0;
    overflow-x: scroll;
}

.zone .cards .tables thead tr th {
    border-style: solid;
    border-bottom-width: 2px;
    color: rgb(114, 132, 140);
    border-bottom-color: rgb(222, 226, 230) !important;
    min-width: 200px;
}

.zone .cards .tables thead th {
    font-size: 15px;
    font-weight: 600;
    padding-top: 10px;
    padding-bottom: 10px;
}

.zone .cards .tables tbody td {
    font-size: 14px;
    padding-top: 0px;
    padding-bottom: 0px;
    line-height: 30px;
    color: #72848c;
}

.zone .cards .tables tbody button {
    color: #ffffff;
    margin: 0 2px;
    padding: 2px 5px;
    box-shadow: 0 0 0 0 !important;
    outline: none !important;
}

.zone .cards .pagination {
    display: flex;
    justify-content: center;
}

/*------------------------------------------- Paginação ------------------------------------------ */
.pagination-table {
    margin-top: 30px;
}

.pagination-table .page-item .page-link {
    color: #72848c;
    text-decoration: none;
    border: 1px solid #dee2e6;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    cursor: pointer;
}

.pagination-table .page-item.active .page-link {
    color: #ffffff;
    background-color: #744cbc;
    border-color: #744cbc;
}

/*--------------------------------------------- Modal ---------------------------------------------*/
.zone .modal-content {
    border: none;
}

/*------------------------------------------- Modal Header ----------------------------------------*/
.zone .modal-header {
    padding: 10px 20px;
    background-color: #744cbc;
}

.zone .modal-header .modal-title {
    color: #ffffff;
    font-size: 18px;
}

/*-------------------------------------------- Modal Body -----------------------------------------*/
.zone .modal-body .msg {
    text-align: center;
    color: #72848c;
}

.zone .modal-body .msg h6 {
    padding: 20px 0px 12px 0px;
    font-size: 17px;
}

.zone .modal-body label {
    font-size: 15px;
    color: #72848c;
    margin-top: 10px;
    margin-left: 1px;
    margin-bottom: 5px;
}


.zone .first-option{
    margin-right:40px;
    margin-left:30px;
}

.zone .radio-label{
    margin:0 !important;
    cursor:pointer;
}

/*------------------------------------------- Modal Footer ----------------------------------------*/
.zone .modal-footer {
    padding: 8px;
}

.zone .modal-footer button {
    border: none;
}

/*----------------------------------------- Mensagem De Erro --------------------------------------*/
.msg_error {
    color: #ff0000;
    font-size: 14px;
}

input,
input:focus,
.is-invalid {
    box-shadow: 0 0 0 0;
    outline: 0;
}

.is-invalid {
    box-shadow: 0 0 0 0 !important;
    outline: 0 !important;
}

.dados_empty {
    color: #72848c;
    font-weight: 600;

}

.loading button {
    position: absolute;
    top: calc(50% - 35px);
    left: calc(50%);
    margin-left: 90px;
    width: 70px;
    height: 70px;
    background-color: #3b0253;
    border: none;
}

.loading span {
    width: 30px;
    height: 30px;
}

/*--------------------------------------- Modal campos de pesquisa ------------------------------------------- */
.zone .modal-body .field-select {
    margin: 0px 0px 0px 0px;
}
</style>
