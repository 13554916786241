<template>
<div class="card card-style" :style="{ background: color }">
    <div class="card-body card_body">
        <div>
            <p class="title-label">{{title}}</p>
            <h5 class="result">{{quantity}}</h5>
        </div>
        <div class="card_icon">
            <i class="fas fa-user-friends"></i>
        </div>
    </div>
    <div class="circle"></div>
</div>
</template>

<script>
export default {
    name: "cardP",
    props: ["title", "quantity" ,"color"],
}
</script>

<style scoped>
/*.azulgradient {
    background: #4facfe;
    background: linear-gradient(45deg, #4facfe, #00f2fe) !important;
}*/

.card-style {
    position: relative;
    border: none;
    /*background: #11998e;*/
    /*background: linear-gradient(45deg, #11998e, #38ef7d) !important;
    margin-bottom: 25px;
    box-shadow: 0 5px 15px rgb(57 98 254 / 2%), 0 3px 6px rgb(117 108 254 / 12%);*/
}

.card_body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
}

.title-label {
    color: #fff;
    text-align: left;
    margin-top: 0;
    margin-bottom: 10px;
}

.result {
    color: #fff;
    font-size: 18px;
    line-height: 22px;
    font-weight: 500;
    text-align: left;
    margin-top: 0;
    margin-bottom: 10px;
}

.card_icon {
    color: #fff;
    font-size: 35px;
}

.circle {
    width: 100px;
    height: 100px;
    position: absolute;
    margin-top: 10px;
    right: -20px;
    bottom: -40px;
    background-color: rgba(255, 255, 255, 15%);
    border-radius: 100%;
    border: none;
}
</style>
