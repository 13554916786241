<template>
<div class="menu_report">
    <div class="row">
        <div class="col-md-3">
            <router-link to="/delivery_regra">
                <div class="card">
                    <div class="card-body">
                        <div class="icon">
                            <i class="fas fa-list-alt"></i>
                        </div>
                        <div class="title">
                            <p>Entrega por Regra</p>
                        </div>
                    </div>
                </div>
            </router-link>
        </div>
        <div class="col-md-3">
            <router-link to="/delivery_requisicao">
                <div class="card">
                    <div class="card-body">
                        <div class="icon">
                            <i class="far fa-list-alt"></i>
                        </div>
                        <div class="title">
                            <p>Entrega por Requisição</p>
                        </div>
                    </div>
                </div>
            </router-link>
        </div>
    </div>
</div>
</template>

<script>

/*import Labels from "@/components/Labels/Label"
import Card from "@/components/Cards/Card"
import Tab from "@/components/Tabs/Tab"*/

export default {
    name: "zone",
    data: function () {
        return {
            
        }
    },
    /*components: {
        Labels,
        Card,
        Tab,
        VPagination
    },*/
    methods: {
        loadsearch() {
            if (this.company_unit_selected > 0) {
                this.loadzones_unit();
            } else if (this.company_selected > 0) {
                this.loadszone()
            }
        }
    }
}
</script>

<style scoped>

.menu_report{
    padding: 40px;
}

a{
    text-decoration: none;;
}

.card{
    border:none;
    box-shadow: 0 5px 15px rgb(57 98 254 / 20%), 0 3px 6px rgb(117 108 254 / 12%);
}

.card:hover .card-body i, .card:hover .card-body .title{
    color: rgba(120, 47, 189, 0.664);
}

.card .card-body{
    padding: 40px 0px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.card .card-body i{
    font-size:50px;
    color:blueviolet;
}

.card .card-body .title{
    margin-top:20px;
    font-size:18px;
    font-weight: 600;
    color:blueviolet;
}

.card .card-body .title p{
    margin-bottom: 0;
}
</style>
