<template>
  <div class="container-fluid">
    <div v-if="user_logado" class="side-left" id="side-left" :class="classesCSS">
      <Menu/>
    </div>
    <div class="side-right">
      <div v-if="user_logado" class="bar-top">
        <Header/>
      </div>
      <div class="page">
        <Content/>
      </div>
    </div>
  </div>
</template>

<script>

import {userKey} from '@/global'

import Menu from "@/views/template/common/Menu";
import Header from "@/views/template/common/Header";
import Content from "@/views/template/common/Content";

export default{
  name:"App",
  components: {Header, Menu, Content},
  data: function (){
    return{
      logado: true,
      mobile: 1
    }
  },
  created(){
    
    window.addEventListener("resize", this.myEventHandler);

    this.$store.commit('setUser', this.$cookie.getCookie(userKey))
  },
  methods: {
    myEventHandler(e){

      var result_class;

      var sizewidth = e.currentTarget.innerWidth

      if(sizewidth > 768){

          if(this.mobile == 0){

                result_class = document.getElementById("side-left");

                if(result_class.classList.contains("toggle1")){

                    document.getElementById('side-left').classList.remove('toggle1');
                }

                this.mobile = 1;
          }else{

            result_class = document.getElementById("side-left");

            if(!result_class.classList.contains("toggle1")){
                this.$store.commit('toggleMenu', true)
            }
          }
      }else{
          
          if(this.mobile == 1){

            result_class = document.getElementById("side-left");
                    
            if(result_class.classList.contains("toggle1")){
                        
                document.getElementById('side-left').classList.remove('toggle1');

            }

            this.mobile = 0;
            
          }else{

            result_class = document.getElementById("side-left");

            if(!result_class.classList.contains("toggle1")){
                this.$store.commit('toggleMenu', true)
            }
          }
      }

      console.log(this.mobile)

      //console.log(e.currentTarget.innerWidth)
    }
  },
  computed:{
    user_logado(){
      return this.$store.state.user;
    },
    menu_visible(){
      return this.$store.state.isMenuVisible;
    },
    classesCSS: function(){
      return{
        toggle1: !this.menu_visible,
      };
    }
  }
}
</script>

<style lang="scss">
  body{
    margin:0px;
    overflow: hidden;
  }

  .container-fluid{
    display: flex;
    padding: 0 !important;
    background-color: #f4f6f9;
  }

  .side-left{
    min-width: 250px;
    transition: all 0.5s;
  }

  .side-right{
    overflow: hidden;
  }

  .toggle1{
    margin-left: -250px;
    overflow: hidden;
    transition: all 0.5s;
  }

@media screen and (max-width: 768px) {
  .side-left{
        width: 250px;
        margin-left: -250px;
        overflow: hidden;
        transition: all 0.5s;
  }

  .toggle1{
        margin-left:0px;
        transition: all 0.5s;
    }
}


  .side-right{
    width: 100%;
  }

</style>
