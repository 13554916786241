<template>
<div class="companie">
    <div class="header">
        <Labels nome="Empresas" cor="#72848c" size="25px" />
        <div class="search">
            <div class="input-group">
                <input type="text" class="form-control input-search" placeholder="pesquisa" v-model="search">
                <span class="input-group-btn">
                    <button type="button" class="btn btn-default btn-search" v-on:click="loadcompanies"><i class="fas fa-search icon-search"></i></button>
                </span>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="cards">
            <Card title="Empresas Cadastradas" color="#72848c" colorbackground="#ffffff" fontSize="18px" text_tooltip="Adicionar empresa" :view_add="company_add" :view_active="true">
                <template v-slot:content class="tabs">
                    <Tab name_tab1="Ativa" name_tab2="Inativa">
                        <template v-slot:Ativo>
                            <div v-if="companies_active == 'vazio'" class="dados_empty">
                                <p>Não há dados a serem exibidos!</p>
                            </div>
                            <div v-if="companies_active != 'vazio' && companies_active != 'nulo'" class="tables">
                                <table class="table table-hover">
                                    <thead>
                                        <tr>
                                            <th>Código</th>
                                            <th>Empresa</th>
                                            <th>Cnpj</th>
                                            <th>Status</th>
                                            <th v-if="company_info || company_edit || company_delete">Controle</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(iten, index) in companies_active" :key="index">
                                            <td v-for="(i, index) in order(iten)" :key="index">{{i}}</td>
                                            <td v-if="company_info || company_edit || company_delete">
                                                <div class="controls">
                                                    <button v-if="company_info == true" @click="loadcompanie(iten, 'info')" type="button" class="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#modal_delete">Detalhes</button>
                                                    <button v-if="company_edit == true" @click="loadcompanie(iten, 'update')" type="button" class="btn btn-info btn-sm" data-bs-toggle="modal" data-bs-target="#modal_add">Editar</button>
                                                    <button v-if="company_delete == true" @click="loadcompanie(iten, 'delete')" type="button" class="btn btn-danger btn-sm" data-bs-toggle="modal" data-bs-target="#modal_delete">Excluir</button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div v-if="paginate_view_a" class="paginate">
                                <v-pagination v-model="current_page_a" :pages="total_page_a" :range-size="1" active-color="#744CBC" @update:modelValue="updateHandler" />
                            </div>
                        </template>
                        <template v-slot:Inativo>
                            <div v-if="companies_inactive == 'vazio'" class="dados_empty">
                                <p>Não há dados a serem exibidos!</p>
                            </div>
                            <div v-if="companies_inactive != 'vazio' && companies_inactive != 'nulo'" class="tables">
                                <table class="table table-hover">
                                    <thead>
                                        <tr>
                                            <th>Código</th>
                                            <th>Empresa</th>
                                            <th>Cnpj</th>
                                            <th>Status</th>
                                            <th v-if="company_info || company_edit || company_delete">Controle</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(iten, index) in companies_inactive" :key="index">
                                            <td v-for="(i, index) in order(iten)" :key="index">{{i}}</td>
                                            <td v-if="company_info || company_edit || company_delete">
                                                <div class="controls">
                                                    <button v-if="company_info == true" @click="loadcompanie(iten, 'info')" type="button" class="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#modal_delete">Detalhes</button>
                                                    <button v-if="company_edit == true" @click="loadcompanie(iten, 'update')" type="button" class="btn btn-info btn-sm" data-bs-toggle="modal" data-bs-target="#modal_add">Editar</button>
                                                    <button v-if="company_delete == true" @click="loadcompanie(iten, 'delete')" type="button" class="btn btn-danger btn-sm" data-bs-toggle="modal" data-bs-target="#modal_delete">Excluir</button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div v-if="paginate_view_i" class="paginate">
                                <v-pagination v-model="current_page_i" :pages="total_page_i" :range-size="1" active-color="#744CBC" @update:modelValue="updateHandler" />
                            </div>
                        </template>
                    </Tab>
                </template>
            </Card>
        </div>
        <div class="col-md-12">
            <div v-if="load" class="loading">
                <button class="btn btn-primary" type="button" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    <span class="visually-hidden">Loading...</span>
                </button>
            </div>
        </div>
    </div>
    <div class="modal fade" id="modal_add" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 v-if="mode === 'add'" class="modal-title" id="exampleModalLabel">Cadastrar Empresa</h5>
                    <h5 v-if="mode === 'update'" class="modal-title" id="exampleModalLabel">Atualizar Empresa</h5>
                </div>
                <div class="modal-body">
                    <form>
                        <div class="row">
                            <input id="companie-id" type="hidden" v-model="companie.id" />
                            <div class="col-md-4">
                                <label for="code" class="form-label">Código</label>
                                <input autocomplete="off" type="text" class="form-control" :class="{'is-invalid': v$.companie.code.$error}" id="code" v-model="companie.code" maxlength="31">
                                <div v-if="v$.companie.code.$error" class="msg_error">{{codeErrors}}</div>
                            </div>
                            <div class="col-md-8">
                                <label for="fantasy_name" class="form-label">Nome Fantasia</label>
                                <input autocomplete="off" type="text" class="form-control" :class="{'is-invalid': v$.companie.fantasy_name.$error}" id="fantasy_name" v-model="companie.fantasy_name" maxlength="51">
                                <div v-if="v$.companie.fantasy_name.$error" class="msg_error">{{fantasy_nameErrors}}</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <label for="ip_access" class="form-label">Ip de acesso</label>
                                <input autocomplete="off" type="text" class="form-control" :class="{'is-invalid': v$.companie.ip_access.$error}" id="ip_access" v-model="companie.ip_access" v-maska="'###.###.###.###'">
                                <div v-if="v$.companie.ip_access.$error" class="msg_error">{{ip_accessErrors}}</div>
                            </div>
                            <div class="col-md-8">
                                <label for="social_reason" class="form-label">Razão Social</label>
                                <input autocomplete="off" type="text" class="form-control" :class="{'is-invalid': v$.companie.social_reason.$error}" id="social_reason" v-model="companie.social_reason" maxlength="101">
                                <div v-if="v$.companie.social_reason.$error" class="msg_error">{{social_reasonErrors}}</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <label for="cnpj" class="form-label">CNPJ</label>
                                <input autocomplete="off" type="text" class="form-control" :class="{'is-invalid': v$.companie.cnpj.$error}" id="cnpj" v-model="companie.cnpj" v-maska="'##.###.###/####-##'">
                                <div v-if="v$.companie.cnpj.$error" class="msg_error">{{cnpjErrors}}</div>
                            </div>
                            <div class="col-md-4">
                                <label for="ie" class="form-label">IE</label>
                                <input autocomplete="off" type="text" class="form-control" :class="{'is-invalid': v$.companie.ie.$error}" id="ie" v-model="companie.ie" maxlength="21">
                                <div v-if="v$.companie.ie.$error" class="msg_error">{{ieErrors}}</div>
                            </div>
                            <div class="col-md-4">
                                <label for="cost_center" class="form-label">Centro de custo</label>
                                <input autocomplete="off" type="text" class="form-control" :class="{'is-invalid': v$.companie.cost_center.$error}" id="cost_center" v-model="companie.cost_center" maxlength="31">
                                <div v-if="v$.companie.cost_center.$error" class="msg_error">{{cost_centerErrors}}</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-8">
                                <label for="email" class="form-label">Email</label>
                                <input autocomplete="off" type="text" class="form-control" :class="{'is-invalid': v$.companie.email.$error}" id="email" v-model="companie.email" maxlength="101">
                                <div v-if="v$.companie.email.$error" class="msg_error">{{emailErrors}}</div>
                            </div>
                            <div class="col-md-4">
                                <label for="phone" class="form-label">Telefone</label>
                                <input autocomplete="off" type="text" class="form-control" id="phone" v-model="companie.phone" v-maska="'(##)#####-####'">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <label for="street" class="form-label">Rua</label>
                                <input autocomplete="off" type="text" class="form-control" :class="{'is-invalid': v$.companie.street.$error}" id="street" v-model="companie.street" maxlength="101">
                                <div v-if="v$.companie.street.$error" class="msg_error">{{streetErrors}}</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <label for="district" class="form-label">Bairro</label>
                                <input autocomplete="off" type="text" class="form-control" :class="{'is-invalid': v$.companie.district.$error}" id="district" v-model="companie.district" maxlength="41">
                                <div v-if="v$.companie.district.$error" class="msg_error">{{districtErrors}}</div>
                            </div>
                            <div class="col-md-4">
                                <label for="number" class="form-label">Número</label>
                                <input autocomplete="off" type="text" class="form-control" :class="{'is-invalid': v$.companie.number.$error}" id="number" v-model="companie.number" maxlength="21">
                                <div v-if="v$.companie.number.$error" class="msg_error">{{numberErrors}}</div>
                            </div>
                            <div class="col-md-4">
                                <label for="cep" class="form-label">Cep</label>
                                <input autocomplete="off" type="text" class="form-control" id="cep" v-model="companie.cep" v-maska="'#####-###'">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <label for="city" class="form-label">Cidade</label>
                                <input autocomplete="off" type="text" class="form-control" :class="{'is-invalid': v$.companie.city.$error}" id="city" v-model="companie.city" maxlength="101">
                                <div v-if="v$.companie.city.$error" class="msg_error">{{cityErrors}}</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <label for="country" class="form-label">País</label>
                                <input autocomplete="off" type="text" class="form-control" :class="{'is-invalid': v$.companie.country.$error}" id="country" v-model="companie.country" maxlength="41">
                                <div v-if="v$.companie.country.$error" class="msg_error">{{countryErrors}}</div>
                            </div>
                            <div class="col-md-6">
                                <label for="status" class="form-label">Status</label>
                                <select class="form-select" id="status" v-model="companie.status">
                                    <option v-for="(statu, index) in status" :value="statu" :key="index">{{statu}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <label for="obs" class="form-label">Observação</label>
                                <textarea rows="5" autocomplete="off" type="text" class="form-control" :class="{'is-invalid': v$.companie.obs.$error}" id="obs" v-model="companie.obs" maxlength="501"></textarea>
                                <div v-if="v$.companie.obs.$error" class="msg_error">{{obsErrors}}</div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button @click="reset" type="button" class="btn btn-danger" data-bs-dismiss="modal">Cancelar</button>
                    <button :disabled="v$.$invalid" @click="save" type="button" class="btn btn-primary" data-bs-dismiss="modal">Salvar</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="modal_delete" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modal_info" aria-hidden="true">
        <div class="modal-dialog" :class="larger">
            <div class="modal-content">
                <input id="companie-id" type="hidden" v-model="companie.id" />
                <div class="modal-header">
                    <h5 v-if="mode === 'delete'" class="modal-title" id="exampleModalLabel">Excluir Empresa</h5>
                    <h5 v-if="mode === 'info'" class="modal-title" id="exampleModalLabel">Informações Da Empresa</h5>
                </div>
                <div class="modal-body">
                    <div v-if="mode === 'delete'">
                        <div class="msg">
                            <h6>Você tem certeza que deseja excluir o registro?</h6>
                        </div>
                    </div>
                    <div v-if="mode === 'info'">
                        <div class="row">
                            <div class="col-md-12">
                                <label class="label_info"><b>Código: </b><span>{{companie.code}}</span></label>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-md-6">
                                <label class="label_info"><b>Nome Fantasia: </b><span>{{companie.fantasy_name}}</span></label>
                            </div>
                            <div class="col-md-6">
                                <label class="label_info"><b>Ip De Acesso: </b><span>{{companie.ip_access}}</span></label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <label class="label_info"><b>Razão Social: </b><span>{{companie.social_reason}}</span></label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <label class="label_info"><b>CNPJ: </b><span>{{companie.cnpj}}</span></label>
                            </div>
                            <div class="col-md-6">
                                <label class="label_info"><b>IE: </b><span>{{companie.ie}}</span></label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <label class="label_info"><b>Centro De Custo: </b><span>{{companie.cost_center}}</span></label>
                            </div>
                            <div class="col-md-6">
                                <label class="label_info"><b>Telefone: </b><span>{{companie.phone}}</span></label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <label class="label_info"><b>Email: </b><span>{{companie.email}}</span></label>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-md-12">
                                <label class="label_info"><b>Rua: </b><span>{{companie.street}}</span></label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <label class="label_info"><b>Bairro: </b><span>{{companie.district}}</span></label>
                            </div>
                            <div class="col-md-4">
                                <label class="label_info"><b>N°: </b><span>{{companie.number}}</span></label>
                            </div>
                            <div class="col-md-4">
                                <label class="label_info"><b>Cep: </b><span>{{companie.cep}}</span></label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-8">
                                <label class="label_info"><b>Cidade: </b><span>{{companie.city}}</span></label>
                            </div>
                            <div class="col-md-4">
                                <label class="label_info"><b>País: </b><span>{{companie.country}}</span></label>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-md-8">
                                <label class="label_info"><b>Status: </b><span>{{companie.status}}</span></label>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-md-12 obs-text">
                                <label class="label_info"><b>Obs: </b><span>{{companie.obs}}</span></label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button v-if="mode == 'info'" @click="reset" type="button" class="btn btn-danger btn_left" data-bs-dismiss="modal">Fechar</button>
                    <button v-if="mode != 'info'" @click="reset" type="button" class="btn btn-danger btn_left" data-bs-dismiss="modal">Cancelar</button>
                    <button v-if="mode != 'info'" @click="remove" type="button" class="btn btn-primary btn_right" data-bs-dismiss="modal">Confirmar</button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import {
    required,
    maxLength,
    email
} from '@vuelidate/validators'
import {
    baseApiUrl,
    userKey
} from '@/global'

import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

import axios from 'axios'
import Labels from "@/components/Labels/Label"
import Card from "@/components/Cards/Card"
import Tab from "@/components/Tabs/Tab"

export default {
    name: "companie",
    data: function () {
        return {
            v$: useVuelidate(),
            mode: 'add',
            companie: {},
            companies: [],
            companies_active: [],
            companies_inactive: [],
            itens: '',
            larger: 'modal-lg',
            status: ['Ativa', 'Inativa'],
            search: '',
            dados_per_page_a: 0,
            dados_per_page_i: 0,
            current_page_a: 1,
            current_page_i: 1,
            total_page_a: 1,
            total_page_i: 1,
            page: 1,
            paginate_view_a: '',
            paginate_view_i: '',
            msg_error: '',
            load: false,
            company_add: false,
            company_info: false,
            company_edit: false,
            company_delete: false,
        }
    },
    validations() {
        return {
            companie: {
                code: {
                    maxLength: maxLength(30),
                    $autoDirty: true
                },
                fantasy_name: {
                    required,
                    maxLength: maxLength(50),
                    $autoDirty: true
                },
                ip_access: {
                    required,
                    $autoDirty: true
                },
                social_reason: {
                    required,
                    maxLength: maxLength(100),
                    $autoDirty: true
                },
                cnpj: {
                    required,
                    $autoDirty: true
                },
                ie: {
                    maxLength: maxLength(20),
                    $autoDirty: true
                },
                email: {
                    maxLength: maxLength(100),
                    email,
                    $autoDirty: true
                },
                cost_center: {
                    maxLength: maxLength(30),
                    $autoDirty: true
                },
                street: {
                    maxLength: maxLength(100),
                    $autoDirty: true
                },
                district: {
                    maxLength: maxLength(40),
                    $autoDirty: true
                },
                number: {
                    maxLength: maxLength(20),
                    $autoDirty: true
                },
                city: {
                    required,
                    maxLength: maxLength(100),
                    $autoDirty: true
                },
                country: {
                    required,
                    maxLength: maxLength(40),
                    $autoDirty: true
                },
                obs: {
                    maxLength: maxLength(500),
                    $autoDirty: true
                },
            }
        }
    },
    components: {
        Labels,
        Card,
        Tab,
        VPagination
    },
    created(){

        const access_credential = this.$cookie.getCookie(userKey)

        var permissoes = access_credential.permissions

        var permissoes_array = permissoes.split(',')

        this.company_add          = permissoes_array.includes('949998') == true ? true : false
        this.company_info         = permissoes_array.includes('949997') == true ? true : false
        this.company_edit       = permissoes_array.includes('949996') == true ? true : false
        this.company_delete       = permissoes_array.includes('949995') == true ? true : false
    },
    mounted() {

        this.companie.status = this.status['0']

        this.loadcompanies();
    },
    computed: {
        codeErrors() {
            const code = this.v$.companie.code
            if (!code.$dirty) {
                return ""
            }
            if (!code.maxLength.$response) {
                return "Limite máximo de 30 caracteres!"
            } else {
                return ""
            }
        },
        fantasy_nameErrors() {
            const fantasy_name = this.v$.companie.fantasy_name
            if (!fantasy_name.$dirty) {
                return ""
            }
            if (!fantasy_name.required.$response) {
                return "O campo é obrigatório!"
            } else if (!fantasy_name.maxLength.$response) {
                return "Limite máximo de 50 caracteres!"
            } else {
                return ""
            }
        },
        ip_accessErrors() {
            const ip_access = this.v$.companie.ip_access
            if (!ip_access.$dirty) {
                return ""
            }
            if (!ip_access.required.$response) {
                return "O campo é obrigatório!"
            } else {
                return ""
            }
        },
        social_reasonErrors() {
            const social_reason = this.v$.companie.social_reason
            if (!social_reason.$dirty) {
                return ""
            }
            if (!social_reason.required.$response) {
                return "O campo é obrigatório!"
            } else if (!social_reason.maxLength.$response) {
                return "Limite máximo de 100 caracteres!"
            } else {
                return ""
            }
        },
        cnpjErrors() {
            const cnpj = this.v$.companie.cnpj
            if (!cnpj.$dirty) {
                return ""
            }
            if (!cnpj.required.$response) {
                return "O campo é obrigatório!"
            } else {
                return ""
            }
        },
        ieErrors() {
            const ie = this.v$.companie.ie
            if (!ie.$dirty) {
                return ""
            }
            if (!ie.maxLength.$response) {
                return "Limite máximo de 20 caracteres!"
            } else {
                return ""
            }
        },
        emailErrors() {
            const email = this.v$.companie.email
            if (!email.$dirty) {
                return ""
            }
            if (!email.maxLength.$response) {
                return "Limite máximo de 100 caracteres!"
            } else if (!email.email.$response) {
                return "Formato incorreto da informação!"
            } else {
                return ""
            }
        },
        streetErrors() {
            const street = this.v$.companie.street
            if (!street.$dirty) {
                return ""
            }
            if (!street.maxLength.$response) {
                return "Limite máximo de 100 caracteres!"
            } else {
                return ""
            }
        },
        districtErrors() {
            const district = this.v$.companie.district
            if (!district.$dirty) {
                return ""
            }
            if (!district.maxLength.$response) {
                return "Limite máximo de 40 caracteres!"
            } else {
                return ""
            }
        },
        numberErrors() {
            const number = this.v$.companie.number
            if (!number.$dirty) {
                return ""
            }
            if (!number.maxLength.$response) {
                return "Limite máximo de 20 caracteres!"
            } else {
                return ""
            }
        },
        cost_centerErrors() {
            const cost_center = this.v$.companie.cost_center
            if (!cost_center.$dirty) {
                return ""
            }
            if (!cost_center.maxLength.$response) {
                return "Limite máximo de 30 caracteres!"
            } else {
                return ""
            }
        },
        cityErrors() {
            const city = this.v$.companie.city
            if (!city.$dirty) {
                return ""
            }
            if (!city.required.$response) {
                return "O campo é obrigatório!"
            } else if (!city.maxLength.$response) {
                return "Limite máximo de 100 caracteres!"
            } else {
                return ""
            }
        },
        countryErrors() {
            const country = this.v$.companie.country
            if (!country.$dirty) {
                return ""
            }
            if (!country.required.$response) {
                return "O campo é obrigatório!"
            } else if (!country.maxLength.$response) {
                return "Limite máximo de 40 caracteres!"
            } else {
                return ""
            }
        },
        obsErrors() {
            const obs = this.v$.companie.obs
            if (!obs.$dirty) {
                return ""
            }
            if (!obs.maxLength.$response) {
                return "Limite máximo de 500 caracteres!"
            } else {
                return ""
            }
        }
    },
    methods: {
        updateHandler(valor) {

            this.page = valor

            this.loadcompanies();
        },
        loadcompanies() {

            this.load = true

            const access_token = this.$cookie.getCookie(userKey)

            var params = {
                page: this.page,
                search: this.search,
                company_id: access_token.company_id,
                credential_code: access_token.credential_code
            }

            var method = 'post'
            var rotas = 'company-list-company'

            const config = {
                headers: {
                    Authorization: `Bearer ${access_token.token}`
                }
            };

            axios[method](`${baseApiUrl}/${rotas}`, params, config, {
                timeout: 5000
            }).then(res => {

                if (res.data.companies_list_active == 'vazio') {

                    this.companies_active = 'vazio'
                    this.paginate_view_a = false
                    this.load = false
                } else {

                    this.total_page_a = res.data.companies_list_active.last_page
                    this.current_page_a = res.data.companies_list_active.current_page
                    this.dados_per_page_a = res.data.companies_list_active.data.length

                    this.paginate_view_a = true

                    var companies_active = res.data.companies_list_active.data

                    var result_active = companies_active.map(getList)

                    this.companies_active = result_active

                    this.load = false
                }

                if (res.data.companies_list_inactive == 'vazio') {
                    this.companies_inactive = 'vazio'
                    this.paginate_view_i = false
                    this.load = false
                } else {

                    this.total_page_i = res.data.companies_list_inactive.last_page
                    this.current_page_i = res.data.companies_list_inactive.current_page
                    this.dados_per_page_i = res.data.companies_list_inactive.data.length

                    this.paginate_view_i = true

                    var companies_inactive = res.data.companies_list_inactive.data

                    var result_inactive = companies_inactive.map(getList)

                    this.companies_inactive = result_inactive

                    this.load = false

                }
            }).catch((e) => {

                if (e.code === 'ECONNABORTED') {
                    this.load = false
                }
            });

            function getList(item) {
                return {
                    id: item.id,
                    code: item.code,
                    fantasy_name: item.fantasy_name,
                    cnpj: item.cnpj,
                    status: item.status,
                    ip_access: item.ip_access,
                    social_reason: item.social_reason,
                    ie: item.ie,
                    cost_center: item.cost_center,
                    email: item.email,
                    phone: item.phone,
                    street: item.street,
                    district: item.district,
                    number: item.number,
                    cep: item.cep,
                    city: item.city,
                    country: item.country,
                    obs: item.obs
                }
            }
        },
        order(iten) {

            var list = Object.values(iten)

            list = list.slice(1, 5)

            return list;
        },
        reset(mode = 'add') {
            this.mode = 'add'
            this.companie = {}
            this.companie.status = this.status['0']
            this.v$.$reset();

            if (mode != 'info' && mode != 'cancelar') {
                this.loadcompanies()
            }
        },
        save() {

            this.load = true

            if (this.v$.$invalid) {

                console.log("Formulário alterado!")

                this.load = false

            } else {
                const method = 'post'

                var rotas = this.companie.id ? 'company-update' : 'company-create'

                const access_token = this.$cookie.getCookie(userKey)

                const config = {
                    headers: {
                        'Accept': "application/json",
                        Authorization: `Bearer ${access_token.token}`
                    }
                }

                axios[method](`${baseApiUrl}/${rotas}`, this.companie, config, {
                    timeout: 5000
                }).then(res => {

                    this.load = false

                    if (res.data.code == '2000') {
                        this.$toast.success(res.data.message)
                    } else if (res.data.code == '3000') {
                        this.$toast.success(res.data.message)
                    } else {
                        this.$toast.success(res.data.message)
                    }

                    this.reset()
                }).catch((e) => {

                    this.load = false

                    if (e.code === 'ECONNABORTED') {
                        this.load = false
                    }
                });
            }
        },
        loadcompanie(companie, mode = 'add') {

            if (mode === 'delete') {
                this.larger = ''
            } else {
                this.larger = 'modal-lg'
            }

            this.mode = mode

            this.companie = {
                ...companie
            }
        },
        remove() {

            this.load = true

            if (this.dados_per_page_a == 1 || this.dados_per_page_i == 1) {
                this.page -= 1
            }

            var method = 'post'
            var rotas = 'company-delete'

            const access_token = this.$cookie.getCookie(userKey)

            const config = {
                headers: {
                    'Accept': "application/json",
                    Authorization: `Bearer ${access_token.token}`
                }
            }

            axios[method](`${baseApiUrl}/${rotas}`, this.companie, config, {
                timeout: 5000
            }).then(res => {

                this.load = false

                if (res.data.code == '2000') {
                    this.$toast.success(res.data.message)
                } else if (res.data.code == '3000') {
                    this.$toast.success(res.data.message)
                } else {
                    this.$toast.success(res.data.message)
                }

                this.reset()
            }).catch((e) => {

                if (e.code === 'ECONNABORTED') {
                    this.load = false
                }
            });
        }
    }
}
</script>

<style scoped>
.paginate {
    display: flex;
    justify-content: center;
}

/* ------------------------------------ Filtros de pesquisa ---------------------------------------*/
.cards .select_filter {
    margin-bottom: 15px;
}

/*-------------------------------------- Cabeçalho Companies --------------------------------------*/
.companie {
    margin: 25px;
}

.companie .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

/* -------------- Cabeçalho ------------------*/
.search .input-search {
    border-radius: 3px;
    border-color: #744cbc;
}

.search .input-search:focus,
.btn-search {
    box-shadow: 0 0 0 0 !important;
    outline: none !important;
}

.search .icon-search {
    color: #ffffff;
    transform: rotate(90deg);
}

.search .btn-search {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-color: #744cbc;
}

.search .btn-search:hover {
    background-color: #886aea !important;
}

/*--------------------------------------------- Table ---------------------------------------------*/
.companie .cards .tables thead tr th {
    border-style: solid;
    border-bottom-width: 2px;
    color: rgb(114, 132, 140);
    border-bottom-color: rgb(222, 226, 230) !important;
}

.companie .cards .tables thead th {
    font-size: 15px;
    font-weight: 600;
    padding-top: 10px;
    padding-bottom: 10px;
}

.companie .cards .tables tbody td {
    font-size: 14px;
    padding-top: 4px;
    padding-bottom: 4px;
    line-height: 30px;
    color: #72848c;
}

.companie .cards .tables tbody button {
    color: #ffffff;
    margin: 0 2px;
    padding: 2px 5px;
    box-shadow: 0 0 0 0 !important;
    outline: none !important;
}

.companie .cards .pagination {
    display: flex;
    justify-content: center;
}

/*------------------------------------------- Paginação ------------------------------------------ */
.pagination-table {
    margin-top: 30px;
}

.pagination-table .page-item .page-link {
    color: #72848c;
    text-decoration: none;
    border: 1px solid #dee2e6;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    cursor: pointer;
}

.pagination-table .page-item.active .page-link {
    color: #ffffff;
    background-color: #744cbc;
    border-color: #744cbc;
}

/*--------------------------------------------- Modal ---------------------------------------------*/
.companie .modal-content {
    border: none;
}

/*------------------------------------------- Modal Header ----------------------------------------*/
.companie .modal-header {
    padding: 10px 20px;
    background-color: #744cbc;
}

.companie .modal-header .modal-title {
    color: #ffffff;
    font-size: 18px;
}

/*-------------------------------------------- Modal Body -----------------------------------------*/
.companie .modal-body .msg {
    text-align: center;
    color: #72848c;
}

.companie .modal-body .msg h6 {
    padding: 20px 0px 12px 0px;
    font-size: 17px;
}

.companie .modal-body .obs-text {
    word-wrap: break-word;
}

.companie .modal-body label {
    width: 100%;
    font-size: 15px;
    color: #72848c;
    margin-top: 10px;
    margin-left: 1px;
    margin-bottom: 5px;
}

/*------------------------------------------- Modal Footer ----------------------------------------*/
.companie .modal-footer {
    padding: 8px;
}

.companie .modal-footer button {
    border: none;
}

/*----------------------------------------- Mensagem De Erro --------------------------------------*/
.msg_error {
    color: #ff0000;
    font-size: 14px;
}

input,
input:focus,
.is-invalid {
    box-shadow: 0 0 0 0;
    outline: 0;
}

.is-invalid {
    box-shadow: 0 0 0 0 !important;
    outline: 0 !important;
}

.dados_empty {
    color: #72848c;
    font-weight: 600;

}

.loading button {
    position: absolute;
    top: calc(50% - 35px);
    left: calc(50%);
    margin-left: 90px;
    width: 70px;
    height: 70px;
    background-color: #3b0253;
    border: none;
}

.loading span {
    width: 30px;
    height: 30px;
}
</style>
