<template>
<div class="card">
    <div class="card-header" :style="{backgroundColor:colorbackground}">
        <div class="header">
            <div class="title-card" :style="{color:color, fontSize}">{{title}}</div>
            <div class="menu">
                <a v-if="view_add == true && view_active == true" class="btn btn-link" id="button" data-bs-toggle="modal" data-bs-target="#modal_add" v-tooltip="{content: text_tooltip,theme: 'info-tooltip'}"><i class="fas fa-plus"></i></a>
            </div>
        </div>
    </div>
    <div class="card-body">
        <slot name="content" />
    </div>
</div>
</template>

<script>
export default {
    name: "card",
    props: ["title", "color", "colorbackground", "fontSize", "text_tooltip", "view_select", "view_add", "view_active"],
}
</script>

<style scoped>

.card {
    /*margin-bottom: 25px;*/
    border-radius: 8px;
    border: none;
    box-shadow: 0 9px 23px rgba(0, 0, 0, .09), 0 5px 5px rgba(0, 0, 0, .06) !important;
    transition: box-shadow 0.8s cubic-bezier(.25, .8, .25, 1);
    max-height: 78vh;
}

.card .card-header {
    background-color: #fff;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.card .card-header .title-card {
    line-height: 22px;
    font-weight: 500;
    padding: 10px 10px 10px 0px;
}

/*-------------------------------------------- header ---------------------------------------------*/
.card .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/*--------------------------------------------- Menu ----------------------------------------------*/
.card .header .menu i {
    color: #ffffff;
    background-color: green;
    font-size: 12px;
    border-radius: 10%;
    margin: 0;
    padding: 4px 5px;
    margin-left: 3px;
}

.card .card-body{
    overflow: auto;
}

/*=====================================================*/
.menu {
    display: flex;
    flex-direction: row;
}

.btn-link {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 10px;
}
</style>
