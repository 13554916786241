<template>
<div class="group">
    <div class="header">
        <Labels nome="Grupos De Permissões" cor="#72848c" size="25px" />
        <div class="search">
            <div class="input-group">
                <input type="text" class="form-control input-search" placeholder="pesquisa" v-model="search">
                <span class="input-group-btn">
                    <button type="button" class="btn btn-default btn-search" v-on:click="loadgroups_unit"><i class="fas fa-search icon-search"></i></button>
                </span>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="cards">
            <Card title="Grupos De Permissões Cadastrados" color="#72848c" colorbackground="#ffffff" fontSize="18px" text_tooltip="Adicionar Grupo de permissão" :view_add="grouppermission_add" :view_active="view_add_button">
                <template v-slot:content class="tabs">
                     <div class="row">
                        <div v-if="false" class="col-md-3">
                            <select class="form-select form-select-sm select_filter" id="status" v-model="company_selected" @change="loadgroups">
                                <option value="0" selected disabled>Selecione uma empresa</option>
                                <option v-for="(company_list, index) in companies_list" :value="company_list.id" :key="index">{{company_list.fantasy_name}}</option>
                            </select>
                        </div>
                        <div v-if="company_units" class="col-md-3">
                            <select class="form-select form-select-sm select_filter" id="status" v-model="company_unit_selected" @change="loadgroups_unit">
                                <option value="0" selected disabled>Selecione uma unidade</option>
                                <option v-for="(company_unit, index) in company_units" :value="company_unit.id" :key="index">{{company_unit.fantasy_name}}</option>
                            </select>
                        </div>
                    </div>
                    <Tab name_tab1="Ativo" name_tab2="Inativo">
                        <template v-slot:Ativo>
                            <div v-if="groups_active == 'vazio'" class="dados_empty">
                                <p>Não há dados a serem exibidos!</p>
                            </div>
                            <div v-if="groups_active != 'vazio' && groups_active != 'nulo'" class="tables">
                                <table class="table table-hover">
                                    <thead>
                                        <tr>
                                            <th>Código</th>
                                            <th>Grupo</th>
                                            <th>Status</th>
                                            <th v-if="grouppermission_info || grouppermission_edit || grouppermission_delete">Controle</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(iten, index) in groups_active" :key="index">
                                            <td v-for="(i, index) in order(iten)" :key="index">{{i}}</td>
                                            <td v-if="grouppermission_info || grouppermission_edit || grouppermission_delete">
                                                <div class="controls">
                                                    <button v-if="grouppermission_info" @click="loadgroup(iten, 'info')" type="button" class="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#modal_delete">Detalhes</button>
                                                    <button v-if="grouppermission_edit" @click="loadgroup(iten, 'update')" type="button" class="btn btn-info btn-sm" data-bs-toggle="modal" data-bs-target="#modal_add">Editar</button>
                                                    <button v-if="grouppermission_delete" @click="loadgroup(iten, 'delete')" type="button" class="btn btn-danger btn-sm" data-bs-toggle="modal" data-bs-target="#modal_delete">Excluir</button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div v-if="paginate_view_a" class="paginate">
                                <v-pagination v-model="current_page_a" :pages="total_page_a" :range-size="1" active-color="#744CBC" @update:modelValue="updateHandler" />
                            </div>
                        </template>
                        <template v-slot:Inativo>
                            <div v-if="groups_inactive == 'vazio'" class="dados_empty">
                                <p>Não há dados a serem exibidos!</p>
                            </div>
                            <div v-if="groups_inactive != 'vazio' && groups_inactive != 'nulo'" class="tables">
                                <table class="table table-hover">
                                    <thead>
                                        <tr>
                                            <th>Código</th>
                                            <th>Grupo</th>
                                            <th>Status</th>
                                            <th v-if="grouppermission_info || grouppermission_edit || grouppermission_delete">Contgroup</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(iten, index) in groups_inactive" :key="index">
                                            <td v-for="(i, index) in order(iten)" :key="index">{{i}}</td>
                                            <td v-if="grouppermission_info || grouppermission_edit || grouppermission_delete">
                                                <div class="controls">
                                                    <button v-if="grouppermission_info" @click="loadgroup(iten, 'info')" type="button" class="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#modal_delete">Detalhes</button>
                                                    <button v-if="grouppermission_edit" @click="loadgroup(iten, 'update')" type="button" class="btn btn-info btn-sm" data-bs-toggle="modal" data-bs-target="#modal_add">Editar</button>
                                                    <button v-if="grouppermission_delete" @click="loadgroup(iten, 'delete')" type="button" class="btn btn-danger btn-sm" data-bs-toggle="modal" data-bs-target="#modal_delete">Excluir</button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div v-if="paginate_view_i" class="paginate">
                                <v-pagination v-model="current_page_i" :pages="total_page_i" :range-size="1" active-color="#744CBC" @update:modelValue="updateHandler" />
                            </div>
                        </template>
                    </Tab>
                </template>
            </Card>
        </div>
        <div class="col-md-12">
            <div v-if="load" class="loading">
                <button class="btn btn-primary" type="button" disabled>
                    <span class="spinner-border spinner-border-sm" group="status" aria-hidden="true"></span>
                    <span class="visually-hidden">Loading...</span>
                </button>
            </div>
        </div>
    </div>
    <div class="modal fade" id="modal_add" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 v-if="mode === 'add'" class="modal-title" id="exampleModalLabel">Cadastrar grupos de permissão</h5>
                    <h5 v-if="mode === 'update'" class="modal-title" id="exampleModalLabel">Atualizar grupo de permissão</h5>
                </div>
                <div class="modal-body">
                    <form>
                        <div class="row">
                            <input id="group-id" type="hidden" v-model="group.id" />
                            <div class="col-md-4">
                                <label for="code" class="form-label">Código</label>
                                <input autocomplete="off" type="text" class="form-control" :class="{'is-invalid': v$.group.code.$error}" id="code" v-model="group.code" maxlength="31">
                                <div v-if="v$.group.code.$error" class="msg_error">{{codeErrors}}</div>
                            </div>
                            <div class="col-md-8">
                                <label for="group" class="form-label">Nome</label>
                                <input autocomplete="off" type="text" class="form-control" :class="{'is-invalid': v$.group.name.$error}" id="group" v-model="group.name" maxlength="31">
                                <div v-if="v$.group.name.$error" class="msg_error">{{nameErrors}}</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <label for="description" class="form-label">Descrição</label>
                                <input autocomplete="off" type="text" class="form-control" :class="{'is-invalid': v$.group.description.$error}" id="email" v-model="group.description" maxlength="101">
                                <div v-if="v$.group.description.$error" class="msg_error">{{descriptionErrors}}</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <label for="status" class="form-label">Status</label>
                                <select class="form-select" id="status" v-model="group.status">
                                    <option v-for="(statu, index) in status" :value="statu" :key="index">{{statu}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <label for="obs" class="form-label">Observação</label>
                                <textarea rows="5" autocomplete="off" type="text" class="form-control" :class="{'is-invalid': v$.group.obs.$error}" id="obs" v-model="group.obs" maxlength="501"></textarea>
                                <div v-if="v$.group.obs.$error" class="msg_error">{{obsErrors}}</div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button @click="reset" type="button" class="btn btn-danger" data-bs-dismiss="modal">Cancelar</button>
                    <button @click="save" type="button" class="btn btn-primary" data-bs-dismiss="modal">Salvar</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="modal_delete" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modal_info" aria-hidden="true">
        <div class="modal-dialog" :class="larger">
            <div class="modal-content">
                <input id="group-id" type="hidden" v-model="group.id" />
                <div class="modal-header">
                    <h5 v-if="mode === 'delete'" class="modal-title" id="exampleModalLabel">Excluir grupo de permissão</h5>
                    <h5 v-if="mode === 'info'" class="modal-title" id="exampleModalLabel">Informações do grupo de permissão</h5>
                </div>
                <div class="modal-body">
                    <div v-if="mode === 'delete'">
                        <div class="msg">
                            <h6>Você tem certeza que deseja excluir o registro?</h6>
                        </div>
                    </div>
                    <div v-if="mode === 'info'">
                        <div class="row">
                            <div class="col-md-12">
                                <label class="label_info"><b>Código: </b><span>{{group.code}}</span></label>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-md-12">
                                <label class="label_info"><b>Nome: </b><span>{{group.name}}</span></label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <label class="label_info"><b>Descrição: </b><span>{{group.description}}</span></label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <label class="label_info"><b>Status: </b><span>{{group.status}}</span></label>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-md-12">
                                <label class="label_info"><b>Obs: </b><span>{{group.obs}}</span></label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button v-if="mode == 'info'" @click="reset" type="button" class="btn btn-danger btn_left" data-bs-dismiss="modal">Fechar</button>
                    <button v-if="mode != 'info'" @click="reset" type="button" class="btn btn-danger btn_left" data-bs-dismiss="modal">Cancelar</button>
                    <button v-if="mode != 'info'" @click="remove" type="button" class="btn btn-primary btn_right" data-bs-dismiss="modal">Confirmar</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="modal_product" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modal_info" aria-hidden="true">
        <div class="modal-dialog" :class="larger">
            <div class="modal-content">
                <input id="group-id" type="hidden" v-model="group.id" />
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Produtos por grupo</h5>
                </div>
                <div class="modal-body">
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                <th>Código</th>
                                <th>Produto</th>
                                <th>Controle</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(iten, index) in groups_active" :key="index">
                                <td v-for="(i, index) in order(iten)" :key="index">{{i}}</td>
                                <td>
                                    <div class="controls">
                                        <div class="form-check form-switch">
                                            <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger btn_left" data-bs-dismiss="modal">Fechar</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="modal_config" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modal_info" aria-hidden="true">
        <div class="modal-dialog" :class="larger">
            <div class="modal-content">
                <input id="group-id" type="hidden" v-model="group.id" />
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Permissões do grupo</h5>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-9">
                            <div class="info_product">
                                <div class="image_product">
                                    <img src="@/assets/img/perfil.jpg" alt="foto-perfil">
                                </div>
                                <div class="description_product">
                                    <p><span>Codigo: </span>000001</p>
                                    <p><span>Produto: </span>Óculos de proteção</p>
                                    <p><span>UNIDADE: </span> 2 Unidade</p>
                                    <p><span>DATA DE EDIÇÃO: </span>25-10-2022</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row info_input">
                        <div class="col-md-3">
                            <label for="code" class="form-label">Período(Dias)</label>
                            <input autocomplete="off" type="number" class="form-control form-control-sm" min="0">
                        </div>
                        <div class="col-md-3">
                            <label for="code" class="form-label">Máximo(Qtde)</label>
                            <input autocomplete="off" type="number" class="form-control form-control-sm" min="0">
                        </div>
                        <div class="col-md-3">
                            <label for="code" class="form-label">Vida Útil(Dias)</label>
                            <input autocomplete="off" type="number" class="form-control form-control-sm" min="0" disabled>
                        </div>
                        <div class="col-md-3 btn-save">
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked>
                                <!--<label class="form-check-label" for="flexSwitchCheckChecked">Checked switch checkbox input</label>-->
                            </div>
                            <button @click="updateproductsgroup(iten.id)" class="btn btn-primary btn-sm">Salvar</button>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-md-9">
                            <div class="info_product">
                                <div class="image_product">
                                    <img src="@/assets/img/perfil.jpg" alt="foto-perfil">
                                </div>
                                <div class="description_product">
                                    <p><span>Codigo: </span>000001</p>
                                    <p><span>Produto: </span>Óculos de proteção</p>
                                    <p><span>UNIDADE: </span> 2 Unidade</p>
                                    <p><span>DATA DE EDIÇÃO: </span>25-10-2022</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row info_input">
                        <div class="col-md-3">
                            <label for="code" class="form-label">Período(Dias)</label>
                            <input autocomplete="off" type="number" class="form-control form-control-sm" min="0" disabled>
                        </div>
                        <div class="col-md-3">
                            <label for="code" class="form-label">Máximo(Qtde)</label>
                            <input autocomplete="off" type="number" class="form-control form-control-sm" min="0" disabled>
                        </div>
                        <div class="col-md-3">
                            <label for="code" class="form-label">Vida Útil(Dias)</label>
                            <input autocomplete="off" type="number" class="form-control form-control-sm" min="0" disabled>
                        </div>
                        <div class="col-md-3 btn-save">
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked>
                                <!--<label class="form-check-label" for="flexSwitchCheckChecked">Checked switch checkbox input</label>-->
                            </div>
                            <button @click="updateproductsgroup(iten.id)" class="btn btn-primary btn-sm">Salvar</button>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-md-9">
                            <div class="info_product">
                                <div class="image_product">
                                    <img src="@/assets/img/perfil.jpg" alt="foto-perfil">
                                </div>
                                <div class="description_product">
                                    <p><span>Codigo: </span>000001</p>
                                    <p><span>Produto: </span>Óculos de proteção</p>
                                    <p><span>UNIDADE: </span> 2 Unidade</p>
                                    <p><span>DATA DE EDIÇÃO: </span>25-10-2022</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row info_input">
                        <div class="col-md-3">
                            <label for="code" class="form-label">Período(Dias)</label>
                            <input autocomplete="off" type="number" class="form-control form-control-sm" min="0" disabled>
                        </div>
                        <div class="col-md-3">
                            <label for="code" class="form-label">Máximo(Qtde)</label>
                            <input autocomplete="off" type="number" class="form-control form-control-sm" min="0" disabled>
                        </div>
                        <div class="col-md-3">
                            <label for="code" class="form-label">Vida Útil(Dias)</label>
                            <input autocomplete="off" type="number" class="form-control form-control-sm" min="0" disabled>
                        </div>
                        <div class="col-md-3 btn-save">
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked>
                                <!--<label class="form-check-label" for="flexSwitchCheckChecked">Checked switch checkbox input</label>-->
                            </div>
                            <button @click="updateproductsgroup(iten.id)" class="btn btn-primary btn-sm">Salvar</button>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-md-9">
                            <div class="info_product">
                                <div class="image_product">
                                    <img src="@/assets/img/perfil.jpg" alt="foto-perfil">
                                </div>
                                <div class="description_product">
                                    <p><span>Codigo: </span>000001</p>
                                    <p><span>Produto: </span>Óculos de proteção</p>
                                    <p><span>UNIDADE: </span> 2 Unidade</p>
                                    <p><span>DATA DE EDIÇÃO: </span>25-10-2022</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row info_input">
                        <div class="col-md-3">
                            <label for="code" class="form-label">Período(Dias)</label>
                            <input autocomplete="off" type="number" class="form-control form-control-sm" min="0" disabled>
                        </div>
                        <div class="col-md-3">
                            <label for="code" class="form-label">Máximo(Qtde)</label>
                            <input autocomplete="off" type="number" class="form-control form-control-sm" min="0" disabled>
                        </div>
                        <div class="col-md-3">
                            <label for="code" class="form-label">Vida Útil(Dias)</label>
                            <input autocomplete="off" type="number" class="form-control form-control-sm" min="0" disabled>
                        </div>
                        <div class="col-md-3 btn-save">
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked>
                                <!--<label class="form-check-label" for="flexSwitchCheckChecked">Checked switch checkbox input</label>-->
                            </div>
                            <button @click="updateproductsgroup(iten.id)" class="btn btn-primary btn-sm">Salvar</button>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-md-9">
                            <div class="info_product">
                                <div class="image_product">
                                    <img src="@/assets/img/perfil.jpg" alt="foto-perfil">
                                </div>
                                <div class="description_product">
                                    <p><span>Codigo: </span>000001</p>
                                    <p><span>Produto: </span>Óculos de proteção</p>
                                    <p><span>UNIDADE: </span> 2 Unidade</p>
                                    <p><span>DATA DE EDIÇÃO: </span>25-10-2022</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row info_input">
                        <div class="col-md-3">
                            <label for="code" class="form-label">Período(Dias)</label>
                            <input autocomplete="off" type="number" class="form-control form-control-sm" min="0" disabled>
                        </div>
                        <div class="col-md-3">
                            <label for="code" class="form-label">Máximo(Qtde)</label>
                            <input autocomplete="off" type="number" class="form-control form-control-sm" min="0" disabled>
                        </div>
                        <div class="col-md-3">
                            <label for="code" class="form-label">Vida Útil(Dias)</label>
                            <input autocomplete="off" type="number" class="form-control form-control-sm" min="0" disabled>
                        </div>
                        <div class="col-md-3 btn-save">
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked>
                                <!--<label class="form-check-label" for="flexSwitchCheckChecked">Checked switch checkbox input</label>-->
                            </div>
                            <button @click="updateproductsgroup(iten.id)" class="btn btn-primary btn-sm">Salvar</button>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger btn_left" data-bs-dismiss="modal">Fechar</button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import {
    required,
    maxLength
} from '@vuelidate/validators'
import {
    baseApiUrl,
    userKey
} from '@/global'

import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

import axios from 'axios'
import Labels from "@/components/Labels/Label"
import Card from "@/components/Cards/Card"
import Tab from "@/components/Tabs/Tab"

export default {
    name: "group",
    data: function () {
        return {
            v$: useVuelidate(),
            mode: 'add',
            group: {},
            groups: [],
            groups_active: [],
            groups_inactive: [],
            itens: '',
            larger: 'modal-lg',
            status: ['Ativo', 'Inativo'],
            search: '',
            dados_per_page_a: 0,
            dados_per_page_i: 0,
            current_page_a: 1,
            current_page_i: 1,
            total_page_a: 1,
            total_page_i: 1,
            page: 1,
            paginate_view_a: '',
            paginate_view_i: '',
            msg_error: '',
            load: false,
            grouppermission_add:true,
            grouppermission_info:true,
            grouppermission_edit:true,
            grouppermission_delete:true,
            companies_list:'',
            company_units:'',
            company_unit_selected: '0',
            company_selected:'0',
            view_add_button: false
        }
    },
    validations() {
        return {
            group: {
                code: {
                    maxLength: maxLength(30),
                    $autoDirty: true
                },
                name: {
                    required,
                    maxLength: maxLength(30),
                    $autoDirty: true
                },
                description: {
                    maxLength: maxLength(100),
                    $autoDirty: true
                },
                obs: {
                    maxLength: maxLength(500),
                    $autoDirty: true
                },
            }
        }
    },
    components: {
        Labels,
        Card,
        Tab,
        VPagination
    },
    mounted() {

        const access_credential = this.$cookie.getCookie(userKey)

        var permissoes = access_credential.permissions

        var permissoes_array = permissoes.split(',')

        this.grouppermission_add     = permissoes_array.includes('849988') == true ? true : false
        this.grouppermission_info    = permissoes_array.includes('849987') == true ? true : false
        this.grouppermission_edit    = permissoes_array.includes('849986') == true ? true : false
        this.grouppermission_delete  = permissoes_array.includes('849985') == true ? true : false

        this.group.status = this.status['0']

        this.loadcompanies();
    },
    computed: {
        codeErrors() {
            const code = this.v$.group.code
            if (!code.$dirty) {
                return ""
            }
            if (!code.maxLength.$response) {
                return "Limite máximo de 30 caracteres!"
            } else {
                return ""
            }
        },
        nameErrors() {
            const name = this.v$.group.name
            if (!name.$dirty) {
                return ""
            }
            if (!name.required.$response) {
                return "O campo é obrigatório!"
            } else if (!name.maxLength.$response) {
                return "Limite máximo de 30 caracteres!"
            } else {
                return ""
            }
        },
        descriptionErrors() {
            const email = this.v$.group.email
            if (!email.$dirty) {
                return ""
            }
            if (!email.maxLength.$response) {
                return "Limite máximo de 100 caracteres!"
            } else {
                return ""
            }
        },
        obsErrors() {
            const obs = this.v$.group.obs
            if (!obs.$dirty) {
                return ""
            }
            if (!obs.maxLength.$response) {
                return "Limite máximo de 500 caracteres!"
            } else {
                return ""
            }
        }
    },
    methods: {
        updateHandler(valor) {

            this.page = valor

            if (this.company_unit_selected > 0) {
                this.loadgroups_unit();
            } else if (this.company_selected > 0) {
                this.loadgroups(1)
            }
        },
        loadcompanies() {

            this.load = true

            const access_credential = this.$cookie.getCookie(userKey)

            var params = {
                page: this.page,
                search: this.search,
                company_id: access_credential.company_id,
                code: access_credential.credential_code,
                menu:'geral'
            }

            var method = 'post'
            var rotas = 'company-list-all-active'

            const access_token = this.$cookie.getCookie(userKey)

            const config = {
                headers: {
                    Authorization: `Bearer ${access_token.token}`
                }
            };

            axios[method](`${baseApiUrl}/${rotas}`, params, config, {
                timeout: 5000
            }).then(res => {

                this.company_selected = res.data.companies_list_active[0].id

                this.load = false

                this.loadgroups()

            }).catch((e) => {

                if (e.code === 'ECONNABORTED') {
                    this.load = false
                }
            });
        },
        loadgroups(valor = 0) {

            this.load = true

            const access_token = this.$cookie.getCookie(userKey)

            if(valor != 1 || valor == 0){
                this.page = 1
            }

            var params = {
                page: this.page,
                search: this.search,
                company_id: access_token.company_id,
                credential_code: access_token.credential_code
            }

            var method = 'post'
            var rotas = 'grouppermission-dependency-company'

            const config = {
                headers: {
                    Authorization: `Bearer ${access_token.token}`
                }
            };

            axios[method](`${baseApiUrl}/${rotas}`, params, config, {
                timeout: 5000
            }).then(res => {

                this.company_units = res.data.company_units

                this.company_unit_selected = '0'

                this.load = false

                if (res.data.grouppermission_list_active == 'vazio') {

                    this.groups_active = 'vazio'
                    this.paginate_view_a = false

                    this.load = false
                } else {

                    this.total_page_a = res.data.grouppermission_list_active.last_page
                    this.current_page_a = res.data.grouppermission_list_active.current_page
                    this.dados_per_page_a = res.data.grouppermission_list_active.data.length

                    this.paginate_view_a = true

                    var grouppermission_active = res.data.grouppermission_list_active.data

                    var result_active = grouppermission_active.map(getList)

                    this.groups_active = result_active

                    this.load = false

                }

                if (res.data.grouppermission_list_inactive == 'vazio') {
                    this.groups_inactive = 'vazio'
                    this.paginate_view_i = false

                    this.load = false
                } else {

                    this.total_page_i = res.data.grouppermission_list_inactive.last_page
                    this.current_page_i = res.data.grouppermission_list_inactive.current_page
                    this.dados_per_page_i = res.data.grouppermission_list_inactive.data.length

                    this.paginate_view_i = true

                    var grouppermission_inactive = res.data.grouppermission_list_inactive.data

                    var result_inactive = grouppermission_inactive.map(getList)

                    this.groups_inactive = result_inactive

                    this.load = false

                }
            }).catch((e) => {

                this.load = false

                if (e.code === 'ECONNABORTED') {
                    this.load = false
                }
            });

            function getList(item) {
                return {
                    id: item.id,
                    code: item.code,
                    name: item.name,
                    status: item.status,
                    description: item.description,
                    obs: item.obs
                }
            }
        },
        loadgroups_unit() {

            console.log('entrou')

            this.load = true

            this.view_add_button = true

            var method = 'post'
            var rotas = 'grouppermission-dependency-unit'

            var params = {
                page: this.page,
                search: this.search,
                company_unit_id: this.company_unit_selected
            }

            const access_token = this.$cookie.getCookie(userKey)

            const config = {
                headers: {
                    Authorization: `Bearer ${access_token.token}`
                }
            };

            axios[method](`${baseApiUrl}/${rotas}`, params, config, {
                timeout: 5000
            }).then(res => {

                console.log(res.data)
                
                if (res.data.grouppermission_list_active == 'vazio') {

                    this.groups_active = 'vazio'
                    this.paginate_view_a = false

                    this.load = false
                } else {

                    this.total_page_a = res.data.grouppermission_list_active.last_page
                    this.current_page_a = res.data.grouppermission_list_active.current_page
                    this.dados_per_page_a = res.data.grouppermission_list_active.data.length

                    this.paginate_view_a = true

                    var grouppermission_active = res.data.grouppermission_list_active.data

                    var result_active = grouppermission_active.map(getList)

                    this.groups_active = result_active

                    this.load = false

                }

                if (res.data.grouppermission_list_inactive == 'vazio') {
                    this.groups_inactive = 'vazio'
                    this.paginate_view_i = false

                    this.load = false
                } else {

                    this.total_page_i = res.data.grouppermission_list_inactive.last_page
                    this.current_page_i = res.data.grouppermission_list_inactive.current_page
                    this.dados_per_page_i = res.data.grouppermission_list_inactive.data.length

                    this.paginate_view_i = true

                    var grouppermission_inactive = res.data.grouppermission_list_inactive.data

                    var result_inactive = grouppermission_inactive.map(getList)

                    this.groups_inactive = result_inactive

                    this.load = false

                }
            }).catch((e) => {

                this.load = false

                if (e.code === 'ECONNABORTED') {
                    this.load = false
                }
            });

            function getList(item) {
                return {
                    id: item.id,
                    code: item.code,
                    name: item.name,
                    status: item.status,
                    description: item.description,
                    obs: item.obs
                }
            }
        },
        order(iten) {

            var list = Object.values(iten)

            list = list.slice(1, 4)

            return list;
        },
        reset(mode = 'add') {
            this.mode = 'add'
            this.group = {}
            this.group.status = this.status['0']
            this.v$.$reset();

            if (mode != 'info' && mode != 'cancelar') {
                this.loadgroups_unit()
            }
        },
        save() {

            this.load = true

            if (this.v$.$invalid) {

                this.$toast.error('Formulário alterado!')

                this.load = false

            } else {

                const method = 'post'

                var rotas = this.group.id ? 'grouppermission-update' : 'grouppermission-create'

                const access_token = this.$cookie.getCookie(userKey)

                const config = {
                    headers: {
                        'Accept': "application/json",
                        Authorization: `Bearer ${access_token.token}`
                    }
                }

                this.group.company_id = this.company_selected
                this.group.company_unit_id = this.company_unit_selected
                
                axios[method](`${baseApiUrl}/${rotas}`, this.group, config, {
                    timeout: 5000
                }).then(res => {

                    console.log(res.data)

                    this.load = false

                    if (res.data.code == '2000') {
                        this.$toast.success(res.data.message)
                    } else if (res.data.code == '3000') {
                        this.$toast.success(res.data.message)
                    } else {
                        this.$toast.success(res.data.message)
                    }

                    this.reset()
                }).catch((e) => {

                    if (e.code === 'ECONNABORTED') {
                        this.load = false
                    }
                });
            }
        },
        loadgroup(group, mode = 'add') {

            if (mode === 'delete') {
                this.larger = ''
            } else {
                this.larger = 'modal-lg'
            }

            this.mode = mode

            this.group = {
                ...group
            }
        },
        remove() {

            this.load = true

            if (this.dados_per_page_a == 1 || this.dados_per_page_i == 1) {
                this.page -= 1
            }

            var method = 'post'
            var rotas = 'grouppermission-delete'

            const access_token = this.$cookie.getCookie(userKey)

            const config = {
                headers: {
                    'Accept': "application/json",
                    Authorization: `Bearer ${access_token.token}`
                }
            }

            axios[method](`${baseApiUrl}/${rotas}`, this.group, config, {
                timeout: 5000
            }).then(res => {

                if (res.data == 'Grupo de permissão não encontrado!') {
                    this.$toast.error('Grupo de permissão não encontrado!')
                } else {
                    this.$toast.success('Grupo de permissão excluído com sucesso!')
                }

                this.reset()
            }).catch((e) => {

                if (e.code === 'ECONNABORTED') {
                    this.load = false
                }
            });
        }
    }
}
</script>

<style scoped>
.paginate {
    display: flex;
    justify-content: center;
}

/* ------------------------------------ Filtros de pesquisa ---------------------------------------*/
.cards .select_filter {
    margin-bottom: 15px;
}

/*-------------------------------------- Cabeçalho Companies --------------------------------------*/
.group {
    margin: 25px;
}

.group .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

/* -------------- Cabeçalho ------------------*/
.search .input-search {
    border-radius: 3px;
    border-color: #744cbc;
}

.search .input-search:focus,
.btn-search {
    box-shadow: 0 0 0 0 !important;
    outline: none !important;
}

.search .icon-search {
    color: #ffffff;
    transform: rotate(90deg);
}

.search .btn-search {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-color: #744cbc;
}

.search .btn-search:hover {
    background-color: #886aea !important;
}

/*--------------------------------------------- Table ---------------------------------------------*/
.group .cards .tables thead tr th {
    border-style: solid;
    border-bottom-width: 2px;
    color: rgb(114, 132, 140);
    border-bottom-color: rgb(222, 226, 230) !important;
}

.group .cards .tables thead th {
    font-size: 15px;
    font-weight: 600;
    padding-top: 10px;
    padding-bottom: 10px;
}

.group .cards .tables tbody td {
    font-size: 14px;
    padding-top: 4px;
    padding-bottom: 4px;
    line-height: 30px;
    color: #72848c;
}

.group .cards .tables tbody button {
    color: #ffffff;
    margin: 0 2px;
    padding: 2px 5px;
    box-shadow: 0 0 0 0 !important;
    outline: none !important;
}

.group .cards .pagination {
    display: flex;
    justify-content: center;
}

/*------------------------------------------- Paginação ------------------------------------------ */
.pagination-table {
    margin-top: 30px;
}

.pagination-table .page-item .page-link {
    color: #72848c;
    text-decoration: none;
    border: 1px solid #dee2e6;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    cursor: pointer;
}

.pagination-table .page-item.active .page-link {
    color: #ffffff;
    background-color: #744cbc;
    border-color: #744cbc;
}

/*--------------------------------------------- Modal ---------------------------------------------*/
.group .modal-content {
    border: none;
}

/*------------------------------------------- Modal Header ----------------------------------------*/
.group .modal-header {
    padding: 10px 20px;
    background-color: #744cbc;
}

.group .modal-header .modal-title {
    color: #ffffff;
    font-size: 18px;
}

/*-------------------------------------------- Modal Body -----------------------------------------*/
.group .modal-body .msg {
    text-align: center;
    color: #72848c;
}

.group .modal-body .msg h6 {
    padding: 20px 0px 12px 0px;
    font-size: 17px;
}

.group .modal-body label {
    font-size: 15px;
    color: #72848c;
    margin-top: 10px;
    margin-left: 1px;
    margin-bottom: 5px;
}

/*------------------------------------------- Modal Footer ----------------------------------------*/
.group .modal-footer {
    padding: 8px;
}

.group .modal-footer button {
    border: none;
}

/*----------------------------------------- Mensagem De Erro --------------------------------------*/
.msg_error {
    color: #ff0000;
    font-size: 14px;
}

input,
input:focus,
.is-invalid {
    box-shadow: 0 0 0 0;
    outline: 0;
}

.is-invalid {
    box-shadow: 0 0 0 0 !important;
    outline: 0 !important;
}

.dados_empty {
    color: #72848c;
    font-weight: 600;

}

.loading button {
    position: absolute;
    top: calc(50% - 35px);
    left: calc(50%);
    margin-left: 90px;
    width: 70px;
    height: 70px;
    background-color: #3b0253;
    border: none;
}

.loading span {
    width: 30px;
    height: 30px;
}

/*============================================ Modal Permissões =========================================== */
/*------------- Informações geral do produto ---------- */
#modal_config .info_product {
    display: flex;
    align-items: center;
}
/*------------------- Imagem do produto --------------- */
#modal_config .info_product .image_product img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin-right: 10px;
}
/*---------- Descrição da informações do produto ------ */
#modal_config .info_product .description_product {
    display: flex;
    flex-direction: column;
}

#modal_config .info_product .description_product p {
    margin: 0;
    font-size: 14px
}

#modal_config .info_product .description_product p span {
    font-weight: 700;
    color: #4e5c63;
    font-size: 13px
}

#modal_config .info_input {
    margin-top: 10px;
}

#modal_config .info_input label {
    font-size: 14px;
    margin: 0
}

#modal_config .info_input .btn-save{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items:flex-end;
}
</style>
