<template>
<div>
    <div class="card card-grafic" :style="{ background: background }">
        <div class="card-header">
            <h6>Contribuidores de Maior Impacto</h6>
        </div>
        <div class="card-body">
            <div id="chart2"></div>
        </div>
    </div>
</div>
</template>

<script>
import ApexCharts from 'apexcharts';

import {
    baseApiUrl,
    userKey
} from '@/global'
import axios from 'axios'

export default {
    name: "cardP",
    props: ["title", "quantity", "color", "background"],
    data: function () {
        return {
            list_products_regra: {
                list_product_regra_code: [],
                list_product_regra_value: []
            }
        }
    },
    mounted() {
        this.list_products_regra_consumo()
    },
    methods: {
        list_products_regra_consumo() {

            this.load = true

            var method = 'get'
            var rotas = 'ca-count'

            const access_token = this.$cookie.getCookie(userKey)

            const config = {
                headers: {
                    Authorization: `Bearer ${access_token.token}`
                }
            };

            axios[method](`${baseApiUrl}/${rotas}`, config, {
                timeout: 5000
            }).then(res => {

                res.data.list_product_regra.forEach(element => {

                    this.list_products_regra.list_product_regra_code.push(element.code)
                    this.list_products_regra.list_product_regra_value.push(element.quantity)

                });

                this.grafic_regra()

                this.load = false

            }).catch((e) => {

                if (e.code === 'ECONNABORTED') {
                    this.load = false
                }
            });
        },
        grafic_regra() {
            var options2 = {
                series: [{
                    name: 'Quantidade:',
                    data: this.list_products_regra.list_product_regra_value
                }],
                chart: {
                    toolbar: {
                        show: false,
                        tools: {
                            selection: true,
                            zoom: true,
                            zoomin: true,
                            zoomout: true,
                        },
                        export: {
                            csv: {
                                filename: undefined,
                                columnDelimiter: ',',
                                headerCategory: 'category',
                                headerValue: 'value',
                                dateFormatter(timestamp) {
                                    return new Date(timestamp).toDateString()
                                }
                            },
                            svg: {
                                filename: undefined,
                            },
                            png: {
                                filename: undefined,
                            }
                        },
                        autoSelected: 'zoom'
                    },
                    zoom: {
                        enabled: true,
                    },
                    height: 300,
                    type: 'bar',
                    events: {
                        click: function (chart, w, e) {
                            console.log(chart, w, e)
                        }
                    }
                },
                colors: ["#26A0FC", "#26E7A6", "#FEBC3B", "#FF6178", "#8B75D7", "#6D848E"],
                plotOptions: {
                    bar: {
                        columnWidth: '50%',
                        distributed: true,
                    }
                },
                dataLabels: {
                    enabled: false
                },
                legend: {
                    show: false
                },
                xaxis: {
                    categories: this.list_products_regra.list_product_regra_code,
                    /*categories: [
                        ['Luva', 'Plastica'],
                        ['Protetor', 'Silicone'],
                        ['Luva', 'PVC'],
                        'Óculos',
                        ['Calça', 'Branca'],
                        ['Avental', 'Plastico'],
                        ['Sapato', 'Segurança'],
                        ['Luva', 'Latex'],
                        ['Bota', 'PVC'],
                        ['Creme']
                    ],*/
                    offsetX: 0,
                    offsetY: 0,
                    labels: {
                        formatter: function (val) {
                            return "Código: " + val
                        },
                        minHeight: 50,
                        maxHeight: 800,
                        style: {
                            colors: 'grey',
                            fontSize: '12px'
                        }
                    },
                    tickPlacement: 'on'
                }
            };

            var chart2 = new ApexCharts(document.querySelector("#chart2"), options2);
            chart2.render();
        }
    }
}
</script>

<style scoped>
/*.azulgradient {
    background: #4facfe;
    background: linear-gradient(45deg, #4facfe, #00f2fe) !important;
}*/

.card-style {
    position: relative;
    border: none;
    box-shadow: 0 5px 15px rgb(57 98 254 / 2%), 0 3px 6px rgb(117 108 254 / 12%);
    background: linear-gradient(45deg, #00b09b, #96c93d) !important;
    margin-bottom: 10px;
    /*background: #11998e;*/
    /*background: linear-gradient(45deg, #11998e, #38ef7d) !important;
    margin-bottom: 25px;
    box-shadow: 0 5px 15px rgb(57 98 254 / 2%), 0 3px 6px rgb(117 108 254 / 12%);*/
}

.card-grafic {
    position: relative;
    border: none;
    box-shadow: 0 5px 15px rgb(57 98 254 / 2%), 0 3px 6px rgb(117 108 254 / 12%);
    margin-bottom: 10px;
}

.card-header{
    color:#fff;
    background: #744CBC;
}

.card_body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding: 0px;
}

.type_delivery {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.performance_grafic,
.performance {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.performance {
    margin-top: 30px;
    margin-bottom: 30px;
    border-left: 1px solid rgba(255, 255, 255, 1);
    border-right: 1px solid rgba(255, 255, 255, 1);
}

.performance_title {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    line-height: 28px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.9);
}

.performance_number {
    display: flex;
    justify-content: center;
    font-size: 24px;
    line-height: 28px;
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
}

.info {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: rgba(255, 255, 255, 1);
}

.info p {
    margin-bottom: 3px;
    font-size: 14px;
    padding-left: 15px;
}

.info p span {
    font-weight: 700;
}

p {
    display: flex;
    align-items: center;
    margin-bottom: 0;
}

i {
    margin-top: 1px;
    font-size: 6px;
    color: #fff;
    padding-right: 10px;
}
</style>
