import { createApp } from 'vue'
import { VueCookieNext } from 'vue-cookie-next'
import VueObserveVisibility from 'vue3-observe-visibility'
import VTooltipPlugin from 'v-tooltip'
import App from './App.vue'
import router from './router'
import store from './store'
import VCalendar from 'v-calendar';
import 'v-calendar/dist/style.css';
//import 'v-calendar/dist/style.css';

import '@fortawesome/fontawesome-free/css/all.css'

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

import "@/assets/css/global.css"

import Toaster from "@meforma/vue-toaster";

import Maska from 'maska'

import 'v-tooltip/dist/v-tooltip.css'

const app = createApp(App)

export const toaste = app.use(Toaster, {
    position: 'top',
    useDefaultCss: true
})

app.use(VCalendar, {})
app.use(VueCookieNext)
app.use(VueObserveVisibility)
app.use(VTooltipPlugin, {
    themes: {
        'info-tooltip': {
        '$extend': 'tooltip',
        '$resetCss': false,
          triggers: ['hover'],
          autoHide: true,
          placement: 'left',
          html: false,
        },
      },
  })
app.use(Maska)
app.use(store)
app.use(router,{
  mode:"history"
})
app.mount('#app')

/*setTimeout(this.$toast.clear, 3000)*/

export default {toaste}












