<template>
<div class="product">
    <div class="header">
        <Labels nome="Produtos" cor="#72848c" size="25px" />
        <div class="search">
            <div class="input-group">
                <input type="text" class="form-control input-search" placeholder="pesquisa" v-model="search">
                <span class="input-group-btn">
                    <button type="button" class="btn btn-default btn-search" v-on:click="loadsearch"><i class="fas fa-search icon-search"></i></button>
                </span>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="cards">
            <Card title="Produtos Cadastrados" color="#72848c" colorbackground="#ffffff" fontSize="18px" text_tooltip="Adicionar produto" :view_add="product_add" :view_active="view_add_button">
                <template v-slot:content class="tabs">
                    <div class="row">
                        <div v-if="false" class="col-md-3">
                            <select class="form-select form-select-sm select_filter" id="status" v-model="company_selected" @change="loadproducts">
                                <option value="0" selected disabled>Selecione uma empresa</option>
                                <option v-for="(company_list, index) in companies_list" :value="company_list.id" :key="index">{{company_list.fantasy_name}}</option>
                            </select>
                        </div>
                        <div v-if="company_units" class="col-md-3">
                            <select class="form-select form-select-sm select_filter" id="status" v-model="company_unit_selected" @change="loadproducts_unit">
                                <option value="0" selected disabled>Selecione uma unidade</option>
                                <option v-for="(company_unit, index) in company_units" :value="company_unit.id" :key="index">{{company_unit.fantasy_name}}</option>
                            </select>
                        </div>
                    </div>
                    <Tab name_tab1="Ativo" name_tab2="Inativo">
                        <template v-slot:Ativo>
                            <div v-if="products_active == 'vazio'" class="dados_empty">
                                <p>Não há dados a serem exibidos!</p>
                            </div>
                            <div v-if="products_active != 'vazio' && products_active != 'nulo'" class="tables">
                                <table class="table table-hover">
                                    <thead>
                                        <tr>
                                            <th>Código</th>
                                            <th>Produto</th>
                                            <th>Ca</th>
                                            <th>Vencimento</th>
                                            <th>Status</th>
                                            <th v-if="product_info || product_edit || product_delete">Controle</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(iten, index) in products_active" :key="index">
                                            <td v-for="(i, index) in order(iten)" :key="index">{{i}}</td>
                                            <td v-if="product_info || product_edit || product_delete">
                                                <div class="controls">
                                                    <button v-if="product_info" @click="loadproduct(iten, 'info')" type="button" class="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#modal_delete">Detalhes</button>
                                                    <button v-if="product_edit" @click="loadproduct(iten, 'update')" type="button" class="btn btn-info btn-sm" data-bs-toggle="modal" data-bs-target="#modal_add">Editar</button>
                                                    <button v-if="product_delete" @click="loadproduct(iten, 'delete')" type="button" class="btn btn-danger btn-sm" data-bs-toggle="modal" data-bs-target="#modal_delete">Excluir</button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div v-if="paginate_view_a" class="paginate">
                                <v-pagination v-model="current_page_a" :pages="total_page_a" :range-size="1" active-color="#744CBC" @update:modelValue="updateHandler" />
                            </div>
                        </template>
                        <template v-slot:Inativo>
                            <div class="tables">
                                <table class="table table-hover">
                                    <thead>
                                        <tr>
                                            <th>Código</th>
                                            <th>Produto</th>
                                            <th>Ca</th>
                                            <th>Vencimento</th>
                                            <th>Status</th>
                                            <th v-if="product_info || product_edit || product_delete">Controle</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(iten, index) in products_inactive" :key="index">
                                            <td v-for="(i, index) in order(iten)" :key="index">{{i}}</td>
                                            <td v-if="product_info || product_edit || product_delete">
                                                <div class="controls">
                                                    <button v-if="product_info" @click="loadproduct(iten, 'info')" type="button" class="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#modal_delete">Detalhes</button>
                                                    <button v-if="product_edit" @click="loadproduct(iten, 'update')" type="button" class="btn btn-info btn-sm" data-bs-toggle="modal" data-bs-target="#modal_add">Editar</button>
                                                    <button v-if="product_delete" @click="loadproduct(iten, 'delete')" type="button" class="btn btn-danger btn-sm" data-bs-toggle="modal" data-bs-target="#modal_delete">Excluir</button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div v-if="paginate_view_i" class="paginate">
                                <v-pagination v-model="current_page_i" :pages="total_page_i" :range-size="1" active-color="#744CBC" @update:modelValue="updateHandler" />
                            </div>
                        </template>
                    </Tab>
                </template>
            </Card>
        </div>
        <div class="col-md-12">
            <div v-if="load" class="loading">
                <button class="btn btn-primary" type="button" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    <span class="visually-hidden">Loading...</span>
                </button>
            </div>
        </div>
    </div>
    <div class="modal fade" id="modal_add" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 v-if="mode === 'add'" class="modal-title" id="exampleModalLabel">Cadastrar Produto</h5>
                    <h5 v-if="mode === 'update'" class="modal-title" id="exampleModalLabel">Atualizar Produto</h5>
                </div>
                <div class="modal-body">
                    <form>
                        <div class="row">
                            <input id="product-id" type="hidden" v-model="product.id" />
                            <div class="col-md-4">
                                <label for="code" class="form-label">Código</label>
                                <input autocomplete="off" type="text" class="form-control" :class="{'is-invalid': v$.product.code.$error}" id="code" v-model="product.code" maxlength="31">
                                <div v-if="v$.product.code.$error" class="msg_error">{{codeErrors}}</div>
                            </div>
                            <div class="col-md-8">
                                <label for="product" class="form-label">Produto</label>
                                <input autocomplete="off" type="text" class="form-control" :class="{'is-invalid': v$.product.name.$error}" id="product" v-model="product.name" maxlength="301">
                                <div v-if="v$.product.name.$error" class="msg_error">{{nameErrors}}</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <label for="unitmeasure_id" class="form-label">Unidade De Medida</label>
                                <select class="form-select" :class="{'is-invalid': v$.product.unitmeasure_id.$error}" id="unitmeasure_id" v-model="product.unitmeasure_id">
                                    <option v-for="(unit_measurement, index) in unit_measurements" :value="unit_measurement.id" :key="index">{{unit_measurement.name}}</option>
                                </select>
                                <div v-if="v$.product.unitmeasure_id.$error" class="msg_error">{{unitmeasure_idErrors}}</div>
                            </div>
                            <div class="col-md-4">
                                <label for="size_id" class="form-label">Tamanho</label>
                                <select class="form-select" :class="{'is-invalid': v$.product.size_id.$error}" id="size_id" v-model="product.size_id">
                                    <option v-for="(size, index) in sizes" :value="size.id" :key="index">{{size.name}}</option>
                                </select>
                                <div v-if="v$.product.size_id.$error" class="msg_error">{{size_idErrors}}</div>
                            </div>
                            <div class="col-md-4">
                                <label for="quantity" class="form-label">Quantidade</label>
                                <input autocomplete="off" type="text" class="form-control" :class="{'is-invalid': v$.product.quantity.$error}" id="quantity" v-model="product.quantity" maxlength="16">
                                <div v-if="v$.product.quantity.$error" class="msg_error">{{quantityErrors}}</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <label for="ca" class="form-label">CA</label>
                                <input autocomplete="off" type="text" class="form-control" :class="{'is-invalid': v$.product.ca.$error}" id="ca" v-model="product.ca" maxlength="51">
                                <div v-if="v$.product.ca.$error" class="msg_error">{{caErrors}}</div>
                            </div>
                            <div class="col-md-6">
                                <label for="priority" class="form-label">Prioridade</label>
                                <input autocomplete="off" type="text" class="form-control"  id="priority" v-model="product.priority" maxlength="16">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <label for="status" class="form-label">Categorias</label>
                                <select class="form-select" :class="{'is-invalid': v$.product.category_id.$error}" id="status" v-model="product.category_id">
                                    <option v-for="(category, index) in categories" :value="category.id" :key="index">{{category.name}}</option>
                                </select>
                                <div v-if="v$.product.category_id.$error" class="msg_error">{{category_idErrors}}</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <label for="status" class="form-label">Status</label>
                                <select class="form-select" id="status" v-model="product.status">
                                    <option v-for="(statu, index) in status" :value="statu" :key="index">{{statu}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <label for="description" class="form-label">Descrição</label>
                                <textarea rows="5" autocomplete="off" type="text" class="form-control" :class="{'is-invalid': v$.product.description.$error}" id="description" v-model="product.description" maxlength="501"></textarea>
                                <div v-if="v$.product.description.$error" class="msg_error">{{descriptionErrors}}</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <label for="indication" class="form-label">Indicação</label>
                                <textarea rows="5" autocomplete="off" type="text" class="form-control" :class="{'is-invalid': v$.product.indication.$error}" id="indication" v-model="product.indication" maxlength="501"></textarea>
                                <div v-if="v$.product.indication.$error" class="msg_error">{{indicationErrors}}</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <label for="precaution" class="form-label">Precaução</label>
                                <textarea rows="5" autocomplete="off" type="text" class="form-control" :class="{'is-invalid': v$.product.precaution.$error}" id="precaution" v-model="product.precaution" maxlength="501"></textarea>
                                <div v-if="v$.product.precaution.$error" class="msg_error">{{precautionErrors}}</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <label for="obs" class="form-label">Observação</label>
                                <textarea rows="5" autocomplete="off" type="text" class="form-control" :class="{'is-invalid': v$.product.obs.$error}" id="obs" v-model="product.obs" maxlength="501"></textarea>
                                <div v-if="v$.product.obs.$error" class="msg_error">{{obsErrors}}</div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button @click="reset" type="button" class="btn btn-danger" data-bs-dismiss="modal">Cancelar</button>
                    <button :disabled="v$.$invalid" @click="save" type="button" class="btn btn-primary" data-bs-dismiss="modal">Salvar</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="modal_delete" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modal_info" aria-hidden="true">
        <div class="modal-dialog" :class="larger">
            <div class="modal-content">
                <input id="product-id" type="hidden" v-model="product.id" />
                <div class="modal-header">
                    <h5 v-if="mode === 'delete'" class="modal-title" id="exampleModalLabel">Excluir Produto</h5>
                    <h5 v-if="mode === 'info'" class="modal-title" id="exampleModalLabel">Informações Do Produto</h5>
                </div>
                <div class="modal-body">
                    <div v-if="mode === 'delete'">
                        <div class="msg">
                            <h6>Você tem certeza que deseja excluir o registro?</h6>
                        </div>
                    </div>
                    <div v-if="mode === 'info'">
                        <div class="row">
                            <div class="col-md-12">
                                <label class="label_info"><b>Código: </b><span>{{product.code}}</span></label>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-md-12">
                                <label class="label_info"><b>Produto: </b><span>{{product.name}}</span></label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <label class="label_info"><b>Categoria: </b><span>{{product.category_name}}</span></label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <label class="label_info"><b>Unidade Medida: </b><span>{{product.unitmeasure_name}}</span></label>
                            </div>
                            <div class="col-md-4">
                                <label class="label_info"><b>Tamanho: </b><span>{{product.size_name}}</span></label>
                            </div>
                            <div class="col-md-4">
                                <label class="label_info"><b>Quantidade: </b><span>{{product.quantity}}</span></label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <label class="label_info"><b>Prioridade: </b><span>{{product.priority}}</span></label>
                            </div>
                            <div class="col-md-4">
                                <label class="label_info"><b>CA: </b><span>{{product.ca}}</span></label>
                            </div>
                            <div class="col-md-4">
                                <label class="label_info"><b>Status: </b><span>{{product.status}}</span></label>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-md-12">
                                <label class="label_info"><b>Descrição: </b><span>{{product.description}}</span></label>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-md-12">
                                <label class="label_info"><b>Indicação: </b><span>{{product.indication}}</span></label>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-md-12">
                                <label class="label_info"><b>Precaução: </b><span>{{product.precaution}}</span></label>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-md-12">
                                <label class="label_info"><b>Obs: </b><span>{{product.obs}}</span></label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button v-if="mode == 'info'" @click="reset" type="button" class="btn btn-danger btn_left" data-bs-dismiss="modal">Fechar</button>
                    <button v-if="mode != 'info'" @click="reset" type="button" class="btn btn-danger btn_left" data-bs-dismiss="modal">Cancelar</button>
                    <button v-if="mode != 'info'" @click="remove" type="button" class="btn btn-primary btn_right" data-bs-dismiss="modal">Confirmar</button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import {
    required,
    maxLength
} from '@vuelidate/validators'
import {
    baseApiUrl,
    userKey
} from '@/global'

import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

import axios from 'axios'
import Labels from "@/components/Labels/Label"
import Card from "@/components/Cards/Card"
import Tab from "@/components/Tabs/Tab"

export default {
    name: "product",
    data: function () {
        return {
            v$: useVuelidate(),
            mode: 'add',
            product: {},
            products: [],
            products_active: [],
            products_inactive: [],
            itens: '',
            larger: 'modal-lg',
            status: ['Ativo', 'Inativo'],
            search: '',
            dados_per_page_a: 0,
            dados_per_page_i: 0,
            current_page_a: 1,
            current_page_i: 1,
            total_page_a: 1,
            total_page_i: 1,
            page: 1,
            paginate_view_a: '',
            paginate_view_i: '',
            msg_error: '',
            load: false,
            unit_measurements: '',
            sizes: '',
            categories:'',
            product_add:false,                   
            product_info: false,
            product_edit: false,
            product_delete: false,
            companies_list:'',
            company_units:'',
            company_unit_selected: '0',
            company_selected:'0',
            view_add_button: false
        }
    },
    validations() {
        return {
            product: {
                code: {
                    maxLength: maxLength(100),
                    $autoDirty: true
                },
                name: {
                    required,
                    maxLength: maxLength(300),
                    $autoDirty: true
                },
                unitmeasure_id: {
                    required,
                    $autoDirty: true
                },
                size_id: {
                    required,
                    $autoDirty: true
                },
                quantity: {
                    required,
                    $autoDirty: true
                },
                ca: {
                    maxLength: maxLength(50),
                    $autoDirty: true
                },
                category_id: {
                    required,
                    $autoDirty: true
                },
                description: {
                    required,
                    maxLength: maxLength(500),
                    $autoDirty: true
                },
                indication: {
                    required,
                    maxLength: maxLength(100),
                    $autoDirty: true
                },
                precaution: {
                    required,
                    maxLength: maxLength(100),
                    $autoDirty: true
                },
                obs: {
                    maxLength: maxLength(500),
                    $autoDirty: true
                },
            }
        }
    },
    components: {
        Labels,
        Card,
        Tab,
        VPagination
    },
    mounted() {

        const access_credential = this.$cookie.getCookie(userKey)

        var permissoes = access_credential.permissions

        var permissoes_array = permissoes.split(',')

        this.product_add     = permissoes_array.includes('899998') == true ? true : false
        this.product_info    = permissoes_array.includes('899997') == true ? true : false
        this.product_edit    = permissoes_array.includes('899996') == true ? true : false
        this.product_delete  = permissoes_array.includes('899995') == true ? true : false

        this.product.status = this.status['0']

        this.loadcompanies();
    },
    computed: {
        codeErrors() {
            const code = this.v$.product.code
            if (!code.$dirty) {
                return ""
            }
            if (!code.maxLength.$response) {
                return "Limite máximo de 30 caracteres!"
            } else {
                return ""
            }
        },
        nameErrors() {
            const name = this.v$.product.name
            if (!name.$dirty) {
                return ""
            }
            if (!name.required.$response) {
                return "O campo é obrigatório!"
            } else if (!name.maxLength.$response) {
                return "Limite máximo de 300 caracteres!"
            } else {
                return ""
            }
        },
        unitmeasure_idErrors() {
            const unit_measurement = this.v$.product.unit_measurement
            if (!unit_measurement.$dirty) {
                return ""
            }
            if (!unit_measurement.required.$response) {
                return "O campo é obrigatório!"
            } else {
                return ""
            }
        },
        size_idErrors() {
            const size = this.v$.product.size
            if (!size.$dirty) {
                return ""
            }
            if (!size.required.$response) {
                return "O campo é obrigatório!"
            } else {
                return ""
            }
        },
        quantityErrors() {
            const quantity = this.v$.product.quantity
            if (!quantity.$dirty) {
                return ""
            }
            if (!quantity.required.$response) {
                return "O campo é obrigatório!"
            } else {
                return ""
            }
        },
        caErrors() {
            const ca = this.v$.product.ca
            if (!ca.$dirty) {
                return ""
            }

            if (!ca.maxLength.$response) {
                return "Limite máximo de 50 caracteres!"
            } else {
                return ""
            }
        },
        category_idErrors() {
            const quantity = this.v$.product.quantity
            if (!quantity.$dirty) {
                return ""
            }
            if (!quantity.required.$response) {
                return "O campo é obrigatório!"
            } else {
                return ""
            }
        },
        descriptionErrors() {
            const description = this.v$.product.description
            if (!description.$dirty) {
                return ""
            }
            if (!description.required.$response) {
                return "O campo é obrigatório!"
            } else if (!description.maxLength.$response) {
                return "Limite máximo de 500 caracteres!"
            } else if (!description.$response) {
                return "Formato incorreto da informação!"
            } else {
                return ""
            }
        },
        indicationErrors() {
            const indication = this.v$.product.indication
            if (!indication.$dirty) {
                return ""
            }

            if (!indication.required.$response) {
                return "O campo é obrigatório!"
            } else if (!indication.maxLength.$response) {
                return "Limite máximo de 100 caracteres!"
            } else if (!indication.$response) {
                return "Formato incorreto da informação!"
            } else {
                return ""
            }
        },
        precautionErrors() {
            const precaution = this.v$.product.precaution
            if (!precaution.$dirty) {
                return ""
            }

            if (!precaution.required.$response) {
                return "O campo é obrigatório!"
            } else if (!precaution.maxLength.$response) {
                return "Limite máximo de 100 caracteres!"
            } else if (!precaution.$response) {
                return "Formato incorreto da informação!"
            } else {
                return ""
            }
        },
        obsErrors() {
            const obs = this.v$.product.obs
            if (!obs.$dirty) {
                return ""
            }
            if (!obs.maxLength.$response) {
                return "Limite máximo de 500 caracteres!"
            } else {
                return ""
            }
        }
    },
    methods: {
        loadsearch() {
            if (this.company_unit_selected > 0) {
                this.loadproducts_unit();
            } else if (this.company_selected > 0) {
                this.loadproducts()
            }
        },
        updateHandler(valor = 0) {

            this.page = valor

            if (this.company_unit_selected > 0) {
                this.loadproducts_unit();
            } else if (this.company_selected > 0) {
                this.loadproducts(1)
            }
        },
        loadcompanies() {

            this.load = true

            const access_credential = this.$cookie.getCookie(userKey)

            var params = {
                page: this.page,
                search: this.search,
                company_id: access_credential.company_id,
                code: access_credential.credential_code
            }

            var method = 'post'
            var rotas = 'company-list-all-active'

            const access_token = this.$cookie.getCookie(userKey)

            const config = {
                headers: {
                    Authorization: `Bearer ${access_token.token}`
                }
            };

            axios[method](`${baseApiUrl}/${rotas}`, params, config, {
                timeout: 5000
            }).then(res => {

                this.company_selected = res.data.companies_list_active[0].id

                this.load = false

                this.loadproducts();

            }).catch((e) => {

                if (e.code === 'ECONNABORTED') {
                    this.load = false
                }
            });
        },
        loadproducts(valor = 0) {

            this.load = true

            const access_token = this.$cookie.getCookie(userKey)

            if(valor != 1 || valor == 0){
                this.page = 1
            }

            this.company_unit_selected = '0'

            var params = {
                page: this.page,
                search: this.search,
                company_id: access_token.company_id,
                credential_code: access_token.credential_code
            }

            var method = 'post'
            var rotas = 'product-list'

            const config = {
                headers: {
                    Authorization: `Bearer ${access_token.token}`
                }
            };

            axios[method](`${baseApiUrl}/${rotas}`, params, config, {
                timeout: 5000
            }).then(res => {

                console.log(res.data)

                this.company_units = res.data.company_units

                this.load = false


                this.unit_measurements = res.data.list_unitmeasurements_active
                this.sizes = res.data.list_sizes_active
                this.categories = res.data.list_categories_active

                if (res.data.products_list_active == 'vazio') {

                    this.products_active = 'vazio'
                    this.paginate_view_a = false
                    this.load = false
                } else {

                    this.total_page_a = res.data.list_product_active.last_page
                    this.current_page_a = res.data.list_product_active.current_page
                    this.dados_per_page_a = res.data.list_product_active.data.length

                    this.paginate_view_a = true

                    var products_active = res.data.list_product_active.data

                    var result_active = products_active.map(getList)

                    this.products_active = result_active

                    this.load = false

                }

                if (res.data.products_list_inactive == 'vazio') {
                    this.products_inactive = 'vazio'
                    this.paginate_view_i = false
                    this.load = false
                } else {

                    this.total_page_i = res.data.list_product_inactive.last_page
                    this.current_page_i = res.data.list_product_inactive.current_page
                    this.dados_per_page_i = res.data.list_product_inactive.data.length

                    this.paginate_view_i = true

                    var products_inactive = res.data.list_product_inactive.data

                    var result_inactive = products_inactive.map(getList)

                    this.products_inactive = result_inactive

                    this.load = false

                }
            }).catch((e) => {

                if (e.code === 'ECONNABORTED') {
                    this.load = false
                }
            });

            function getList(item) {
                return {
                    id: item.products_id,
                    code: item.products_code,
                    name: item.products_name,
                    ca: item.products_ca,
                    priority: item.products_priority,
                    status: item.products_status,
                    category_id: item.products_category_id,
                    vencimento: '26/10/2023',
                    unitmeasure_id: item.products_unitmeasure_id,
                    size_id: item.products_size_id,
                    quantity: item.products_quantity,
                    description: item.products_description,
                    indication: item.products_indication,
                    precaution: item.products_precaution,
                    obs: item.products_obs,
                    unitmeasure_name: item.unit_measurements_name,
                    size_name: item.sizes_name,
                    category_name: item.categories_name
                }
            }
        },
        loadproducts_unit() {

            this.load = true

            this.view_add_button = true

            var method = 'post'
            var rotas = 'product-list-unit'

            var params = {
                page: this.page,
                search: this.search,
                company_unit_id: this.company_unit_selected
            }

            const access_token = this.$cookie.getCookie(userKey)

            const config = {
                headers: {
                    Authorization: `Bearer ${access_token.token}`
                }
            };

            axios[method](`${baseApiUrl}/${rotas}`, params, config, {
                timeout: 5000
            }).then(res => {

                console.log(res.data)

                this.unit_measurements = res.data.list_unitmeasurements_active
                this.product.unitmeasure_id =  res.data.list_unitmeasurements_active[0].id
                this.sizes = res.data.list_sizes_active
                this.product.size_id =  res.data.list_sizes_active[0].id
                this.categories = res.data.list_categories_active
                this.product.category_id =  res.data.list_categories_active[0].id
                
                if (res.data.list_product_active == 'vazio') {

                    this.products_active = 'vazio'
                    this.paginate_view_a = false

                    this.load = false
                } else {

                    this.total_page_a = res.data.list_product_active.last_page
                    this.current_page_a = res.data.list_product_active.current_page
                    this.dados_per_page_a = res.data.list_product_active.data.length

                    this.paginate_view_a = true

                    var products_active = res.data.list_product_active.data

                    var result_active = products_active.map(getList)

                    this.products_active = result_active

                    this.load = false

                }

                if (res.data.list_product_inactive == 'vazio') {
                    this.products_inactive = 'vazio'
                    this.paginate_view_i = false

                    this.load = false
                } else {

                    this.total_page_i = res.data.list_product_inactive.last_page
                    this.current_page_i = res.data.list_product_inactive.current_page
                    this.dados_per_page_i = res.data.list_product_inactive.data.length

                    this.paginate_view_i = true

                    var products_inactive = res.data.list_product_inactive.data

                    var result_inactive = products_inactive.map(getList)

                    this.products_inactive = result_inactive

                    this.load = false

                }
            }).catch((e) => {

                this.load = false

                if (e.code === 'ECONNABORTED') {
                    this.load = false
                }
            });

            function getList(item) {
                return {
                    id: item.products_id,
                    code: item.products_code,
                    name: item.products_name,
                    ca: item.products_ca,
                    priority: item.products_priority,
                    status: item.products_status,
                    category_id: item.products_category_id,
                    vencimento: '26/10/2023',
                    unitmeasure_id: item.products_unitmeasure_id,
                    size_id: item.products_size_id,
                    quantity: item.products_quantity,
                    description: item.products_description,
                    indication: item.products_indication,
                    precaution: item.products_precaution,
                    obs: item.products_obs,
                    unitmeasure_name: item.unit_measurements_name,
                    size_name: item.sizes_name,
                    category_name: item.categories_name
                }
            }
        },
        order(iten) {

            var list = Object.values(iten)

            list = list.slice(1, 6)

            return list;
        },
        reset(mode = 'add') {
            this.mode = 'add'
            this.product = {}
            this.product.status = this.status['0']
            this.v$.$reset();

            if (mode != 'info' && mode != 'cancelar') {
                this.loadproducts_unit()
            }
        },
        save() {

            console.log(this.v$.$invalid)

            if (this.v$.$invalid) {

                console.log("Formulário alterado!")

            } else {
                const method = 'post'

                var rotas = this.product.id ? 'product-update' : 'product-create'

                const access_token = this.$cookie.getCookie(userKey)

                const config = {
                    headers: {
                        'Accept': "application/json",
                        Authorization: `Bearer ${access_token.token}`
                    }
                }

                this.product.company_id = this.company_selected
                this.product.company_unit_id = this.company_unit_selected

                axios[method](`${baseApiUrl}/${rotas}`, this.product, config).then(res => {

                    console.log(res.data)

                    this.load = false

                    if (res.data.code == '2000') {
                        this.$toast.success(res.data.message)
                    } else if (res.data.code == '3000') {
                        this.$toast.success(res.data.message)
                    } else {
                        this.$toast.success(res.data.message)
                    }

                    this.reset()
                }).catch((e) => {

                    if (e.code === 'ECONNABORTED') {
                        this.load = false
                    }
                });
            }
        },
        loadproduct(product, mode = 'add') {

            if (mode === 'delete') {
                this.larger = ''
            } else {
                this.larger = 'modal-lg'
            }

            this.mode = mode

            this.product = {
                ...product
            }
        },
        remove() {

            this.load = true

            if (this.dados_per_page_a == 1 || this.dados_per_page_i == 1) {
                this.page -= 1
            }

            var method = 'post'
            var rotas = 'product-delete'

            const access_token = this.$cookie.getCookie(userKey)

            const config = {
                headers: {
                    'Accept': "application/json",
                    Authorization: `Bearer ${access_token.token}`
                }
            }

            axios[method](`${baseApiUrl}/${rotas}`, this.product, config).then(res => {

                this.load = false

                if (res.data.code == '4000') {
                    this.$toast.success(res.data.message)
                } else {
                    this.$toast.error(res.data.message)
                }

                this.reset()
            }).catch((e) => {
                
                if (e.code === 'ECONNABORTED') {
                    this.load = false
                }
            });
        }
    }
}
</script>

<style scoped>
.paginate {
    display: flex;
    justify-content: center;
}

/* ------------------------------------ Filtros de pesquisa ---------------------------------------*/
.cards .select_filter {
    margin-bottom: 15px;
}

/*-------------------------------------- Cabeçalho Companies --------------------------------------*/
.product {
    margin: 25px;
}

.product .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

/* -------------- Cabeçalho ------------------*/
.search .input-search {
    border-radius: 3px;
    border-color: #744cbc;
}

.search .input-search:focus,
.btn-search {
    box-shadow: 0 0 0 0 !important;
    outline: none !important;
}

.search .icon-search {
    color: #ffffff;
    transform: rotate(90deg);
}

.search .btn-search {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-color: #744cbc;
}

.search .btn-search:hover {
    background-color: #886aea !important;
}

/*--------------------------------------------- Table ---------------------------------------------*/
.product .cards .tables thead tr th {
    border-style: solid;
    border-bottom-width: 2px;
    color: rgb(114, 132, 140);
    border-bottom-color: rgb(222, 226, 230) !important;
}

.product .cards .tables thead th {
    font-size: 15px;
    font-weight: 600;
    padding-top: 10px;
    padding-bottom: 10px;
}

.product .cards .tables tbody td {
    font-size: 14px;
    padding-top: 4px;
    padding-bottom: 4px;
    line-height: 30px;
    color: #72848c;
}

.product .cards .tables tbody button {
    color: #ffffff;
    margin: 0 2px;
    padding: 2px 5px;
    box-shadow: 0 0 0 0 !important;
    outline: none !important;
}

/*.product .cards .pagination {
    display: flex;
    justify-content: center;
}*/

/*------------------------------------------- Paginação ------------------------------------------ */

/*.pagination-table {
    margin-top: 30px;
}

.pagination-table .page-item .page-link {
    color: #72848c;
    text-decoration: none;
    border: 1px solid #dee2e6;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    cursor: pointer;
}

.page-item.active .page-link {
    color: #ffffff;
    background-color: #744cbc;
    border-color: #744cbc;
}*/

/*--------------------------------------------- Modal ---------------------------------------------*/
.product .modal-content {
    border: none;
}

/*------------------------------------------- Modal Header ----------------------------------------*/
.product .modal-header {
    padding: 10px 20px;
    background-color: #744cbc;
}

.product .modal-header .modal-title {
    color: #ffffff;
    font-size: 18px;
}

/*-------------------------------------------- Modal Body -----------------------------------------*/
.product .modal-body .msg {
    text-align: center;
    color: #72848c;
}

.product .modal-body .msg h6 {
    padding: 20px 0px 12px 0px;
    font-size: 17px;
}

.product .modal-body label {
    font-size: 15px;
    color: #72848c;
    margin-top: 10px;
    margin-left: 1px;
    margin-bottom: 5px;
}

/*------------------------------------------- Modal Footer ----------------------------------------*/
.product .modal-footer {
    padding: 8px;
}

.product .modal-footer button {
    border: none;
}

/*----------------------------------------- Mensagem De Erro --------------------------------------*/
.msg_error {
    color: #ff0000;
    font-size: 14px;
}

input,
input:focus,
.is-invalid {
    box-shadow: 0 0 0 0;
    outline: 0;
}

.is-invalid {
    box-shadow: 0 0 0 0 !important;
    outline: 0 !important;
}

.dados_empty {
    color: #72848c;
    font-weight: 600;

}

.loading button {
    position: absolute;
    top: calc(50% - 35px);
    left: calc(50%);
    margin-left: 90px;
    width: 70px;
    height: 70px;
    background-color: #3b0253;
    border: none;
}

.loading span {
    width: 30px;
    height: 30px;
}
</style>
