<template>
<div>
    <div class="card card-style" :style="{ background: background }">
        <div class="card-body card_body">
            <div class="type_delivery">
                <div class="performance_grafic">
                    <div id="chart1">
                    </div>
                </div>
                <div class="performance">
                    <p class="performance_number">65%</p>
                    <p class="performance_title">Permissões</p>
                </div>
                <div class="info">
                    <p><i class="fa fa-circle"></i><span> Pontos de entrega:</span> 4 equipamentos</p>
                    <p><i class="fa fa-circle"></i><span>Conectividade:</span> 1 de 4 equipamentos</p>
                    <p><i class="fa fa-circle"></i><span>Devolução de itens:</span> 11 itens</p>
                    <p><i class="fa fa-circle"></i><span>Falta de produto na máquina:</span> 0 itens</p>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import ApexCharts from 'apexcharts';

export default {
    name: "cardP",
    props: ["title", "quantity", "color", "background"],
    mounted() {
        var options1 = {
            chart: {
                height: 180,
                type: "radialBar",
            },

            series: [67],
            colors: ["#fff"],
            plotOptions: {
                radialBar: {
                    hollow: {
                        margin: 0,
                        size: "60%",
                        background: "transparent"
                    },
                    track: {
                        dropShadow: {
                            enabled: true,
                            top: 2,
                            left: 0,
                            blur: 4,
                            opacity: 0.15
                        }
                    },
                    dataLabels: {
                        name: {
                            offsetY: -10,
                            color: "#fff",
                            fontSize: "13px",
                            show: false
                        },
                        value: {
                            offsetY: 10,
                            color: "#fff",
                            fontSize: "30px",
                            show: true
                        }
                    }
                }
            },
            fill: {
                type: "gradient",
                gradient: {
                    shade: "dark",
                    type: "vertical",
                    gradientToColors: ["#fff"],
                    stops: [0, 100]
                }
            },
            stroke: {
                lineCap: "round"
            },
            labels: ["Progress"]
        };

        var chart1 = new ApexCharts(document.querySelector("#chart1"), options1);
        chart1.render();
    }
}
</script>

<style scoped>
/*.azulgradient {
    background: #4facfe;
    background: linear-gradient(45deg, #4facfe, #00f2fe) !important;
}*/

.card-style {
    position: relative;
    border: none;
    box-shadow: 0 5px 15px rgb(57 98 254 / 2%), 0 3px 6px rgb(117 108 254 / 12%);
    background: linear-gradient(45deg, #00b09b, #96c93d) !important;
    margin-bottom: 10px;
    /*background: #11998e;*/
    /*background: linear-gradient(45deg, #11998e, #38ef7d) !important;
    margin-bottom: 25px;
    box-shadow: 0 5px 15px rgb(57 98 254 / 2%), 0 3px 6px rgb(117 108 254 / 12%);*/
}

.card-grafic {
    position: relative;
    border: none;
    box-shadow: 0 5px 15px rgb(57 98 254 / 2%), 0 3px 6px rgb(117 108 254 / 12%);
    margin-bottom: 10px;
}

.card_body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding: 0px;
}

.type_delivery {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.performance_grafic,
.performance {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.performance {
    margin-top: 30px;
    margin-bottom: 30px;
    border-left: 1px solid rgba(255, 255, 255, 1);
    border-right: 1px solid rgba(255, 255, 255, 1);
}

.performance_title {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    line-height: 28px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.9);
}

.performance_number {
    display: flex;
    justify-content: center;
    font-size: 24px;
    line-height: 28px;
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
}

.info {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: rgba(255, 255, 255, 1);
}

.info p {
    margin-bottom: 3px;
    font-size: 14px;
    padding-left: 15px;
}

.info p span {
    font-weight: 700;
}

p {
    display: flex;
    align-items: center;
    margin-bottom: 0;
}

i {
    margin-top: 1px;
    font-size: 6px;
    color: #fff;
    padding-right: 10px;
}
</style>
