<template>
    <div class="machine">
        <div class="row">
            <div class="col-md-3">
                <CardInfoFlat title="Total CA's" :quantity="total_cas" color="radial-gradient(circle 248px at center,#f14793,#5204ce)" icon="far fa-copy"></CardInfoFlat>
            </div>
            <div class="col-md-3">
                <CardInfoFlat title="CA's OK" :quantity="total_cas_ok" color="radial-gradient(circle 248px at center,#00f260,#0575e6)" icon="fas fa-check"></CardInfoFlat>
            </div>
            <div class="col-md-3">
                <CardInfoFlat title="CA's a vencer" :quantity="total_cas_a_vencer" color="radial-gradient(circle 248px at center,#f1ea07,#f10707)" icon="fas fa-hourglass-half"></CardInfoFlat>
            </div>
            <div class="col-md-3">
                <CardInfoFlat title="CA's vencidos" :quantity="total_cas_vencidos" color="radial-gradient(circle 248px at center,#ff5447,#f1076f)" icon="fas fa-hourglass-end"></CardInfoFlat>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col-md-12">
                <CardInfoBar title="Total usuários" quantity="2800" color="linear-gradient(45deg, #4facfe, #00f2fe)"></CardInfoBar>
            </div>
        </div>
    </div>
</template>

<script>

import {
    baseApiUrl,
    userKey
} from '@/global'

import axios from 'axios'
import CardInfoBar from '@/components/Cards/Card-info-bar'
import CardInfoFlat from '@/components/Cards/Card-info-flat'

export default {
    name: "system",
    data: function () {
        return {
            total_cas: '0',
            total_cas_ok:'0',
            total_cas_vencidos:'0',
            total_cas_a_vencer:'0'
        }
    },
    components: { CardInfoBar, CardInfoFlat },
    mounted() {

        this.load_cas();
    },
    methods: {
        load_cas(){

            this.load = true

            var method = 'get'
            var rotas = 'ca-count'

            const access_token = this.$cookie.getCookie(userKey)

            const config = {
                headers: {
                    Authorization: `Bearer ${access_token.token}`
                }
            };

            axios[method](`${baseApiUrl}/${rotas}`, config, {
                timeout: 5000
            }).then(res => {

                console.log(res.data)

                this.total_cas = res.data.list_cas_count_total

                this.total_cas_ok = res.data.list_cas_ok

                this.total_cas_vencidos = res.data.list_cas_vencidos

                this.total_cas_a_vencer = res.data.list_cas_a_vencer

                this.load = false

            }).catch((e) => {

                if (e.code === 'ECONNABORTED') {
                    this.load = false
                }
            });
        }
    }
}
</script>

<style scoped>
/*-------------------------------------- Cabeçalho System --------------------------------------*/
.machine {
    margin: 25px;
}
</style>