<template>
<div class="characteristics">
    <div class="header">
        <Labels nome="Características" cor="#72848c" size="25px" />
    </div>
    <div class="row">
        <div class="cards">
            <Card title="Configurações de características" color="#72848c" colorbackground="#ffffff" fontSize="18px" text_tooltip="Adicionar área" :view_add="characteristics_add" :view_active="view_add_button">
                <template v-slot:content class="tabs">
                    <div class="card-config">
                        <div class="row">
                            <div v-if="false" class="col-md-3">
                                <select class="form-select form-select-sm select_filter" id="status" v-model="company_selected" @change="loadsectors">
                                    <option value="0" selected disabled>Selecione uma empresa</option>
                                    <option v-for="(company_list, index) in companies_list" :value="company_list.id" :key="index">{{company_list.fantasy_name}}</option>
                                </select>
                            </div>
                            <div v-if="company_units" class="col-md-3">
                                <select class="form-select form-select-sm select_filter" id="status" v-model="company_unit_selected" @change="list_characteristics">
                                    <option value="0" selected disabled>Selecione uma unidade</option>
                                    <option v-for="(company_unit, index) in company_units" :value="company_unit.id" :key="index">{{company_unit.fantasy_name}}</option>
                                </select>
                            </div>
                        </div>
                        <div v-if="false" class="card" style="border-color:#744CBC !important;">
                            <div class="card-header" style="background-color: #744CBC; color:#FFF; border-color:#744CBC">
                                <h6>Definições</h6>
                            </div>
                            <div class="card-body" style="border-color:none !important;">
                                <form>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <label for="">Sincronização</label>
                                            <div class="row range-ajuste">
                                                <div class="col-md-4">
                                                    <input type="number" class="form-control" v-model="value_sinc">
                                                </div>
                                                <div class="col-md-8">
                                                    <input type="range" class="form-range" id="customRange1" min="0" max="1440" v-model="value_sinc">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <label for="">Prazo devolução</label>
                                            <div class="row range-ajuste">
                                                <div class="col-md-4">
                                                    <input type="number" class="form-control" v-model="value_prazo">
                                                </div>
                                                <div class="col-md-8">
                                                    <input type="range" class="form-range" id="customRange1" min="0" max="1440" v-model="value_prazo">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="card-footer">
                                <button @click="save('definition')" class="btn btn-primary btn-sm">Salvar</button>
                            </div>
                        </div>
                        <br>
                        <div v-if="list_term_responsability" class="card" style="border-color:#744CBC !important;">
                            <div class="card-header" style="background-color: #744CBC; color:#FFF; border-color:#744CBC">
                                <h6>Termo de responsabilidade</h6>
                            </div>
                            <div class="card-body" style="border-color:none !important;">
                                <form>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <textarea rows="5" autocomplete="off" type="text" class="form-control" id="obs" maxlength="10001" v-model="termo_responsabilidade"></textarea>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div v-if="update_term_responsability" class="card-footer">
                                <button @click="save('term_responsability')" class="btn btn-primary btn-sm">Salvar</button>
                            </div>
                        </div>
                        <br>
                        <div class="card" style="border-color:#744CBC !important;">
                            <div class="card-header" style="background-color: #744CBC; color:#FFF; border-color:#744CBC">
                                <h6>Termo de aceite</h6>
                            </div>
                            <div class="card-body">
                                <form>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <textarea rows="5" autocomplete="off" type="text" class="form-control" id="obs" maxlength="501" v-model="termo_aceite"></textarea>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="card-footer">
                                <button @click="save('acceptance_term')" class="btn btn-primary btn-sm">Salvar</button>
                            </div>
                        </div>
                        <br>
                        <div class="card" style="border-color:#744CBC !important;">
                            <div class="card-header" style="background-color: #744CBC; color:#FFF; border-color:#744CBC">
                                <h6>Ficha padrão</h6>
                            </div>
                            <div class="card-body">
                                <form>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <textarea rows="5" autocomplete="off" type="text" class="form-control" id="obs" maxlength="501" v-model="ficha_padrao"></textarea>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="card-footer">
                                <button @click="save('standard_sheet')" class="btn btn-primary btn-sm">Salvar</button>
                            </div>
                        </div>
                    </div>
                </template>
            </Card>
        </div>
        <div class="col-md-12">
            <div v-if="load" class="loading">
                <button class="btn btn-primary" type="button" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    <span class="visually-hidden">Loading...</span>
                </button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import {
    required,
    maxLength,
    email
} from '@vuelidate/validators'
import {
    baseApiUrl,
    userKey,
    showError
} from '@/global'

import "@hennge/vue3-pagination/dist/vue3-pagination.css";

import axios from 'axios'
import Labels from "@/components/Labels/Label"
import Card from "@/components/Cards/Card"

export default {
    name: "characteristics",
    data: function () {
        return {
            v$: useVuelidate(),
            termo_responsabilidade: '',
            termo_aceite: '',
            ficha_padrao: '',
            value_sinc: 0,
            value_prazo: 0,
            mode: 'add',
            characteristics: {},
            characteristicss: [],
            characteristicss_active: [],
            characteristicss_inactive: [],
            itens: '',
            larger: 'modal-lg',
            search: '',
            dados_per_page_a: 0,
            dados_per_page_i: 0,
            current_page_a: 1,
            current_page_i: 1,
            total_page_a: 1,
            total_page_i: 1,
            page: 1,
            paginate_view_a: '',
            paginate_view_i: '',
            msg_error: '',
            load: false,
            companies_list: '',
            company_selected: '0',
            company_units: '',
            company_unit_selected: '0',
            view_add_button: false,
            characteristics_add: true,
            characteristics_info: true,
            characteristics_edit: true,
            characteristics_delete: true,
            list_term_responsability:false,
            update_term_responsability:false
        }
    },
    validations() {
        return {
            characteristics: {
                code: {
                    maxLength: maxLength(30),
                    $autoDirty: true
                },
                name: {
                    required,
                    maxLength: maxLength(30),
                    $autoDirty: true
                },
                email: {
                    maxLength: maxLength(100),
                    email,
                    $autoDirty: true
                },
                cost_center: {
                    maxLength: maxLength(30),
                    $autoDirty: true
                },
                obs: {
                    maxLength: maxLength(500),
                    $autoDirty: true
                },
            }
        }
    },
    components: {
        Labels,
        Card
    },
    mounted() {

        const access_credential = this.$cookie.getCookie(userKey)

        var permissoes = access_credential.permissions

        var permissoes_array = permissoes.split(',')

        this.list_term_responsability   = permissoes_array.includes('939999') == true ? true : false
        this.update_term_responsability = permissoes_array.includes('939998') == true ? true : false

        this.loadscharacteristics();
    },
    computed: {
        codeErrors() {
            const code = this.v$.characteristics.code
            if (!code.$dirty) {
                return ""
            }
            if (!code.maxLength.$response) {
                return "Limite máximo de 30 caracteres!"
            } else {
                return ""
            }
        },
        nameErrors() {
            const name = this.v$.characteristics.name
            if (!name.$dirty) {
                return ""
            }
            if (!name.required.$response) {
                return "O campo é obrigatório!"
            } else if (!name.maxLength.$response) {
                return "Limite máximo de 30 caracteres!"
            } else {
                return ""
            }
        },
        emailErrors() {
            const email = this.v$.characteristics.email
            if (!email.$dirty) {
                return ""
            }
            if (!email.maxLength.$response) {
                return "Limite máximo de 100 caracteres!"
            } else if (!email.email.$response) {
                return "Formato incorreto da informação!"
            } else {
                return ""
            }
        },
        cost_centerErrors() {
            const cost_center = this.v$.characteristics.cost_center
            if (!cost_center.$dirty) {
                return ""
            }
            if (!cost_center.maxLength.$response) {
                return "Limite máximo de 30 caracteres!"
            } else {
                return ""
            }
        },
        obsErrors() {
            const obs = this.v$.characteristics.obs
            if (!obs.$dirty) {
                return ""
            }
            if (!obs.maxLength.$response) {
                return "Limite máximo de 500 caracteres!"
            } else {
                return ""
            }
        }
    },
    methods: {
        list_characteristics() {

            this.load = true

            this.characteristics.company_id = 7
            this.characteristics.companyunit_id = 10

            const method = 'post'

            var rotas = 'list_characteristics'

            const access_token = this.$cookie.getCookie(userKey)

            var params = {
                companyunit_id: this.company_unit_selected
            }

            const config = {
                headers: {
                    "Accept": "application/json",
                    "Authorization": `Bearer ${access_token.token}`
                }
            }

            axios[method](`${baseApiUrl}/${rotas}`, params, config, {
                timeout: 5000
            }).then(res => {

                console.log(res.data)

                this.value_sinc = res.data.list_characteristics[0].sinc
                this.value_prazo = res.data.list_characteristics[0].return_period

                this.termo_responsabilidade = res.data.list_characteristics[0].statement_responsibility

                this.termo_aceite = res.data.list_characteristics[0].acceptance_term

                this.ficha_padrao = res.data.list_characteristics[0].standard_sheet

                this.load = false
                
            }).catch((e) => {

                this.load = false

                showError(e)

                if (e.code === 'ECONNABORTED') {
                    this.load = false
                }
            });
        },
        loadsearch() {
            if (this.company_unit_selected > 0) {
                this.loadcharacteristicss_unit();
            } else if (this.company_selected > 0) {
                this.loadscharacteristics()
            }
        },
        updateHandler(valor = 0) {

            this.page = valor

            if (this.company_unit_selected > 0) {
                this.loadcharacteristicss_unit();
            } else if (this.company_selected > 0) {
                this.loadscharacteristics(1)
            }
        },
        loadcompanies() {

            this.load = true

            const access_credential = this.$cookie.getCookie(userKey)

            var params = {
                page: this.page,
                search: this.search,
                company_id: access_credential.company_id,
                code: access_credential.credential_code,
                menu: 'geral'
            }

            var method = 'post'
            var rotas = 'company-list-all-active'

            const access_token = this.$cookie.getCookie(userKey)

            const config = {
                headers: {
                    Authorization: `Bearer ${access_token.token}`
                }
            };

            //var that = this

            axios[method](`${baseApiUrl}/${rotas}`, params, config, {
                timeout: 5000
            }).then(res => {

                console.log(res.data)

                //this.companies_list = res.data.companies_list_active
                //this.company_selected = res.data.companies_list_active[0].id

                this.load = false

                //that.loadscharacteristics()company_units

            }).catch((e) => {

                this.load = false

                if (e.code === 'ECONNABORTED') {
                    this.load = false
                }
            });
        },
        loadscharacteristics() {

            this.load = true

            const access_token = this.$cookie.getCookie(userKey)

            var method = 'post'
            var rotas = 'characteristics_dependency_company'

            var params = {
                company_id: access_token.company_id,
                credential_code: access_token.credential_code
            }

            const config = {
                headers: {
                    Authorization: `Bearer ${access_token.token}`
                }
            };

            axios[method](`${baseApiUrl}/${rotas}`, params, config, {
                timeout: 5000
            }).then(res => {

                console.log(res.data)

                this.view_add_button = false

                this.company_units = ''

                this.company_units = res.data.company_units

                this.company_unit_selected = '0'

                this.characteristicss = ''

                if (res.data.characteristicss_list_active == 'vazio') {

                    this.characteristicss_active = 'vazio'
                    this.paginate_view_a = false

                    this.load = false
                } else {

                    this.total_page_a = res.data.characteristicss_list_active.last_page
                    this.current_page_a = res.data.characteristicss_list_active.current_page
                    this.dados_per_page_a = res.data.characteristicss_list_active.data.length

                    this.paginate_view_a = true

                    var characteristicss_active = res.data.characteristicss_list_active.data

                    var result_active = characteristicss_active.map(getList)

                    this.characteristicss_active = result_active

                    this.load = false

                }

                if (res.data.characteristicss_list_inactive == 'vazio') {
                    this.characteristicss_inactive = 'vazio'
                    this.paginate_view_i = false

                    this.load = false
                } else {

                    this.total_page_i = res.data.characteristicss_list_inactive.last_page
                    this.current_page_i = res.data.characteristicss_list_inactive.current_page
                    this.dados_per_page_i = res.data.characteristicss_list_inactive.data.length

                    this.paginate_view_i = true

                    var characteristicss_inactive = res.data.characteristicss_list_inactive.data

                    var result_inactive = characteristicss_inactive.map(getList)

                    this.characteristicss_inactive = result_inactive

                    this.load = false

                }
            }).catch((e) => {

                this.load = false

                if (e.code === 'ECONNABORTED') {
                    this.load = false
                }
            });

            function getList(item) {
                return {
                    id: item.id,
                    code: item.code,
                    name: item.name,
                    cost_center: item.cost_center,
                    email: item.email,
                    phone: item.phone,
                    obs: item.obs
                }
            }
        },
        loadcharacteristicss_unit() {

            this.load = true

            const access_token = this.$cookie.getCookie(userKey)

            var method = 'post'
            var rotas = 'characteristics-dependency-unit'

            var params = {
                page: this.page,
                search: this.search,
                company_unit_id: this.company_unit_selected
            }

            const config = {
                headers: {
                    Authorization: `Bearer ${access_token.token}`
                }
            };

            axios[method](`${baseApiUrl}/${rotas}`, params, config, {
                timeout: 5000
            }).then(res => {

                this.view_add_button = true

                this.characteristics_selected = '0'
                this.characteristicss = res.data.characteristicss

                if (res.data.characteristicss_list_active == 'vazio') {

                    this.characteristicss_active = 'vazio'
                    this.paginate_view_a = false

                    this.load = false
                } else {

                    this.total_page_a = res.data.characteristicss_list_active.last_page
                    this.current_page_a = res.data.characteristicss_list_active.current_page
                    this.dados_per_page_a = res.data.characteristicss_list_active.data.length

                    this.paginate_view_a = true

                    var characteristicss_active = res.data.characteristicss_list_active.data

                    var result_active = characteristicss_active.map(getList)

                    this.characteristicss_active = result_active

                    this.load = false

                }

                if (res.data.characteristicss_list_inactive == 'vazio') {
                    this.characteristicss_inactive = 'vazio'
                    this.paginate_view_i = false

                    this.load = false
                } else {

                    this.total_page_i = res.data.characteristicss_list_inactive.last_page
                    this.current_page_i = res.data.characteristicss_list_inactive.current_page
                    this.dados_per_page_i = res.data.characteristicss_list_inactive.data.length

                    this.paginate_view_i = true

                    var characteristicss_inactive = res.data.characteristicss_list_inactive.data

                    var result_inactive = characteristicss_inactive.map(getList)

                    this.characteristicss_inactive = result_inactive

                    this.load = false

                }
            }).catch((e) => {

                this.load = false

                if (e.code === 'ECONNABORTED') {
                    this.load = false
                }
            });

            function getList(item) {
                return {
                    id: item.id,
                    code: item.code,
                    name: item.name,
                    cost_center: item.cost_center,
                    email: item.email,
                    phone: item.phone,
                    obs: item.obs
                }
            }
        },
        loadcharacteristicss() {

            this.load = true

            var params = {
                page: this.page,
                search: this.search
            }

            var method = 'post'
            var rotas = 'characteristics-list-all'

            const access_token = this.$cookie.getCookie(userKey)

            const config = {
                headers: {
                    Authorization: `Bearer ${access_token.token}`
                }
            };

            axios[method](`${baseApiUrl}/${rotas}`, params, config, {
                timeout: 5000
            }).then(res => {

                if (res.data.characteristicss_list_active == 'vazio') {

                    this.characteristicss_active = 'vazio'
                    this.paginate_view_a = false
                    this.load = false
                } else {

                    this.total_page_a = res.data.characteristicss_list_active.last_page
                    this.current_page_a = res.data.characteristicss_list_active.current_page
                    this.dados_per_page_a = res.data.characteristicss_list_active.data.length

                    this.paginate_view_a = true

                    var characteristicss_active = res.data.characteristicss_list_active.data

                    var result_active = characteristicss_active.map(getList)

                    this.characteristicss_active = result_active

                    this.load = false

                }

                if (res.data.characteristicss_list_inactive == 'vazio') {
                    this.characteristicss_inactive = 'vazio'
                    this.paginate_view_i = false
                    this.load = false
                } else {

                    this.total_page_i = res.data.characteristicss_list_inactive.last_page
                    this.current_page_i = res.data.characteristicss_list_inactive.current_page
                    this.dados_per_page_i = res.data.characteristicss_list_inactive.data.length

                    this.paginate_view_i = true

                    var characteristicss_inactive = res.data.characteristicss_list_inactive.data

                    var result_inactive = characteristicss_inactive.map(getList)

                    this.characteristicss_inactive = result_inactive

                    this.load = false

                }
            }).catch((e) => {

                if (e.code === 'ECONNABORTED') {
                    this.load = false
                }
            });

            function getList(item) {
                return {
                    id: item.id,
                    code: item.code,
                    name: item.name,
                    cost_center: item.cost_center,
                    email: item.email,
                    phone: item.phone,
                    obs: item.obs
                }
            }
        },
        order(iten) {

            var list = Object.values(iten)

            list = list.slice(1, 4)

            return list;
        },
        reset(mode = 'add') {

            this.mode = 'add'
            this.characteristics = {}
            this.v$.$reset();

            if (mode != 'info' && mode != 'cancelar') {
                this.loadcharacteristicss_unit()
            }
        },
        save(action) {

            this.load = true

            this.characteristics.company_id = 7
            this.characteristics.companyunit_id = 10

            if (action == 'definition') {

                this.characteristics.sinc = this.value_sinc,
                    this.characteristics.return_period = this.value_prazo

            } else if (action == 'term_responsability') {

                this.characteristics.statement_responsibility = this.termo_responsabilidade

            } else if (action == 'acceptance_term') {

                this.characteristics.acceptance_term = this.termo_aceite

            } else if (action == 'standard_sheet') {

                this.characteristics.standard_sheet = this.ficha_padrao

            }

            const method = 'post'

            var rotas = 'config_characteristics'

            const access_token = this.$cookie.getCookie(userKey)

            const config = {
                headers: {
                    "Accept": "application/json",
                    "Authorization": `Bearer ${access_token.token}`
                }
            }

            axios[method](`${baseApiUrl}/${rotas}`, this.characteristics, config, {
                timeout: 5000
            }).then(res => {

                console.log(res.data)

                this.load = false

                if (res.data.code == '2000') {
                    this.$toast.success(res.data.message)
                } else if (res.data.code == '3000') {
                    this.$toast.success(res.data.message)
                } else {
                    this.$toast.success(res.data.message)
                }

                this.reset()
            }).catch((e) => {

                this.load = false

                console.log(e)

                if (e.code === 'ECONNABORTED') {
                    this.load = false
                }
            });
        },
        loadcharacteristics(characteristics, mode = 'add') {

            if (mode === 'delete') {
                this.larger = ''
            } else {
                this.larger = 'modal-lg'
            }

            this.mode = mode

            this.characteristics = {
                ...characteristics
            }
        },
        remove() {

            this.load = true

            if (this.dados_per_page_a == 1 || this.dados_per_page_i == 1) {
                this.page -= 1
            }

            var method = 'post'
            var rotas = 'characteristics-delete'

            const access_token = this.$cookie.getCookie(userKey)

            const config = {
                headers: {
                    'Accept': "application/json",
                    Authorization: `Bearer ${access_token.token}`
                }
            }

            axios[method](`${baseApiUrl}/${rotas}`, this.characteristics, config, {
                timeout: 5000
            }).then(res => {

                this.load = false

                if (res.data.code == '4000') {
                    this.$toast.success(res.data.message)
                } else {
                    this.$toast.error(res.data.message)
                }

                this.reset()

            }).catch((e) => {

                showError(e)

                if (e.code === 'ECONNABORTED') {
                    this.load = false
                }
            });
        }
    }
}
</script>

<style scoped>
.card-footer {
    display: flex;
    justify-content: flex-end;
    background-color: transparent;
}

.range-ajuste {
    display: flex;
    align-items: center;
}

label {
    color: rgb(114, 132, 140);
    margin-bottom: 10px;
    font-weight: 500;
}

.card-config {
    height: calc(100vh - 230px);
    overflow-y: scroll;
    overflow-x: hidden;
}

.v-popper--theme-info-tooltip .v-popper__inner {
    background: #2a2828 !important;
    color: #e51f1f !important;
}

.paginate {
    display: flex;
    justify-content: center;
}

/* ------------------------------------ Filtros de pesquisa ---------------------------------------*/
.cards .select_filter {
    margin-bottom: 15px;
}

/*-------------------------------------- Cabeçalho Companies --------------------------------------*/
.characteristics {
    margin: 25px;
}

.characteristics .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

/* -------------- Cabeçalho ------------------*/
.search .input-search {
    border-radius: 3px;
    border-color: #744cbc;
}

.search .input-search:focus,
.btn-search {
    box-shadow: 0 0 0 0 !important;
    outline: none !important;
}

.search .icon-search {
    color: #ffffff;
    transform: rotate(90deg);
}

.search .btn-search {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-color: #744cbc;
}

.search .btn-search:hover {
    background-color: #886aea !important;
}

/*--------------------------------------------- Table ---------------------------------------------*/
.characteristics .cards .tables thead tr th {
    border-style: solid;
    border-bottom-width: 2px;
    color: rgb(114, 132, 140);
    border-bottom-color: rgb(222, 226, 230) !important;
}

.characteristics .cards .tables thead th {
    font-size: 15px;
    font-weight: 600;
    padding-top: 10px;
    padding-bottom: 10px;
}

.characteristics .cards .tables tbody td {
    font-size: 14px;
    padding-top: 4px;
    padding-bottom: 4px;
    line-height: 30px;
    color: #72848c;
}

.characteristics .cards .tables tbody button {
    color: #ffffff;
    margin: 0 2px;
    padding: 2px 5px;
    box-shadow: 0 0 0 0 !important;
    outline: none !important;
}

.characteristics .cards .pagination {
    display: flex;
    justify-content: center;
}

/*------------------------------------------- Paginação ------------------------------------------ */
.pagination-table {
    margin-top: 30px;
}

.pagination-table .page-item .page-link {
    color: #72848c;
    text-decoration: none;
    border: 1px solid #dee2e6;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    cursor: pointer;
}

.pagination-table .page-item.active .page-link {
    color: #ffffff;
    background-color: #744cbc;
    border-color: #744cbc;
}

/*--------------------------------------------- Modal ---------------------------------------------*/
.characteristics .modal-content {
    border: none;
}

/*------------------------------------------- Modal Header ----------------------------------------*/
.characteristics .modal-header {
    padding: 10px 20px;
    background-color: #744cbc;
}

.characteristics .modal-header .modal-title {
    color: #ffffff;
    font-size: 18px;
}

/*-------------------------------------------- Modal Body -----------------------------------------*/
.characteristics .modal-body .msg {
    text-align: center;
    color: #72848c;
}

.characteristics .modal-body .msg h6 {
    padding: 20px 0px 12px 0px;
    font-size: 17px;
}

.characteristics .modal-body label {
    font-size: 15px;
    color: #72848c;
    margin-top: 10px;
    margin-left: 1px;
    margin-bottom: 5px;
}

/*------------------------------------------- Modal Footer ----------------------------------------*/
.characteristics .modal-footer {
    padding: 8px;
}

.characteristics .modal-footer button {
    border: none;
}

/*----------------------------------------- Mensagem De Erro --------------------------------------*/
.msg_error {
    color: #ff0000;
    font-size: 14px;
}

input,
input:focus,
.is-invalid {
    box-shadow: 0 0 0 0;
    outline: 0;
}

.is-invalid {
    box-shadow: 0 0 0 0 !important;
    outline: 0 !important;
}

.dados_empty {
    color: #72848c;
    font-weight: 600;

}

.loading button {
    position: absolute;
    top: calc(50% - 35px);
    left: calc(50%);
    margin-left: 90px;
    width: 70px;
    height: 70px;
    background-color: #3b0253;
    border: none;
}

.loading span {
    width: 30px;
    height: 30px;
}
</style>
