<template>
<div class="body_sheet">
    <div class="card_report">
        <div class="card_header">
            <div class="template_header">
                <div class="logo">
                    <img src="@/assets/img/logo_technip.png" alt="foto-perfil">
                </div>
                <div class="name_report">
                    <div class="title-card-name">COMPROVANTE DE FORNECIMENTO DE EPI</div>
                    <hr class="division_1">
                    <div class="title-card-date">Período: {{date_period_inicial}} á {{date_period_final}}</div>
                    <div class="title-card-system-name">Sistema de Controle de Entrega de EPIs</div>
                </div>
            </div>
            <div class="menu">
                <a v-if="view_add == true && view_active == true" class="btn btn-link" id="button" data-bs-toggle="modal" data-bs-target="#modal_add"><i class="fas fa-filter"></i></a>
            </div>
        </div>
        <hr class="division_header">
        <div class="info_company">
            <div class="side_info">
                <div class="company_title">
                    Empresa:
                </div>
                <div class="company_name">
                    Technip
                </div>
            </div>
            <div class="side_back">
                <div class="side_info">
                    <div class="company_title">
                        CNPJ:
                    </div>
                    <div class="company_name">
                        68.915.891/0034-08
                    </div>
                </div>
                <!--<button type="button" v-on:click="back_to_menu" class="btn btn-primary btn-sm back_btn">Voltar</button>-->
            </div>
        </div>
        <div v-for="(item, index) in delivery" :key="index" class="epi_sheet">
            <p class="declaration">{{this.statement_responsibility}}</p>
            <br>
            <div class="col-md-12">
                <label><b>Dados básicos do funcionário Sempher</b></label>
            </div>
            <hr class="hr_info">
            <div class="row row-1">
                <div class="col-md-8">
                    <label class="label_info"><b>Nome: </b><span>{{item.collaborators_name}}</span></label>
                </div>
                <div class="col-md-4">
                    <label class="label_info"><b>Matrícula: </b><span>{{item.collaborators_matricula}}</span></label>
                </div>
            </div>
            <div class="row">
                <div class="col-md-8">
                    <label class="label_info"><b>Função: </b><span>{{item.collaborators_role}}</span></label>
                </div>
                <div class="col-md-4">
                    <label class="label_info"><b>Setor: </b><span>{{item.collaborators_setor}}</span></label>
                </div>
            </div>
            <hr>
            <table class="table table-bordered border-secondary">
                <thead>
                    <tr>
                        <th scope="col">Código</th>
                        <th scope="col">Descrição</th>
                        <th scope="col">Quantidade</th>
                        <th scope="col">CA</th>
                        <th scope="col">Data</th>
                        <th scope="col">Máquina</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(iten, index1) in item.info_delivery" :key="index1">
                        <td>{{iten.code_product}}</td>
                        <td>{{iten.description_product}}</td>
                        <td>{{iten.quantity_delivery_product}}</td>
                        <td>{{iten.ca_product}}</td>
                        <td>{{iten.data_entrega}}</td>
                        <td>{{iten.equipamento}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="modal fade" id="modal_add" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Filtros</h5>
                </div>
                <div class="modal-body">
                    <form>
                        <div class="row">
                            <div class="offset-md-2 col-md-5">
                                <label for="datainicial" class="form-label">Data Inicial</label>
                                <v-date-picker v-model="date_inicial" :model-config="modelConfig">
                                    <template #default="{ inputValue, inputEvents }">
                                        <input class="px-3 py-1 border rounded" id="datainicial" :value="inputValue" v-on="inputEvents" />
                                    </template>
                                </v-date-picker>
                            </div>
                            <div class="col-md-5">
                                <label for="datafinal" class="form-label">Data Final</label>
                                <v-date-picker v-model="date_final" :model-config="modelConfig">
                                    <template #default="{ inputValue, inputEvents }">
                                        <input class="px-3 py-1 border rounded" id="datafinal" :value="inputValue" v-on="inputEvents" />
                                    </template>
                                </v-date-picker>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-md-4">
                                <select class="form-select form-select-sm select_filter" id="status" v-model="companyUnit_selected">
                                    <option value="0" selected disabled>Selecione uma unidade</option>
                                    <option value="10">Technip Brasil</option>
                                </select>
                            </div>
                            <div class="col-md-4">
                                <select class="form-select form-select-sm select_filter" id="status" v-model="zone_selected" @change="load_collaborator">
                                    <option value="0" selected disabled>Selecione uma área</option>
                                    <option v-for="(list_zone, index) in list_zones" :value="list_zone.name" :key="index">{{list_zone.name}}</option>
                                </select>
                            </div>
                            <div class="col-md-4">
                                <select class="form-select form-select-sm select_filter" id="status" v-model="collaboratorSelected" @change="loadsectors">
                                    <option value="0" selected disabled>Selecione um colaborador</option>
                                    <option v-for="(list_collaborator, index) in list_collaborators" :value="list_collaborator.id" :key="index">{{list_collaborator.name}}</option>
                                </select>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button @click="reset('cancelar')" type="button" class="btn btn-danger" data-bs-dismiss="modal">Cancelar</button>
                    <button @click="list_entrega_final" type="button" class="btn btn-primary" data-bs-dismiss="modal">Listar</button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    baseApiUrl,
    userKey
} from '@/global'
import axios from 'axios'

export default {
    name: "report_delivery_epi",
    data: function () {
        return {
            date_inicial: new Date().toISOString(),
            date_final: new Date().toISOString(),
            date_period_inicial: '---',
            date_period_final: '---',
            user_add: false,
            user_info: '',
            user_edit: '',
            user_delete: '',
            statement_responsibility: '',
            delivery: '',
            view_add: true,
            view_active: true,
            companyUnit_selected: '0',
            zone_selected:'0',
            list_zones: '',
            list_collaborators:'',
            collaboratorSelected:"0",
            modelConfig: {
                type: 'string',
                mask: 'YYYY-MM-DD', 
            }
        }
    },
    created() {
        
        this.load_zone()
        this.list_characteristics_responsibility()
        //this.list_entrega()
    },
    methods: {
        list_entrega_final(){
            this.load = true

            const method = 'post'

            var rotas = 'list-report-delivery'

            const access_token = this.$cookie.getCookie(userKey)

            let result_date_inicial = this.date_inicial.substring(0, 10);
            let result_date_final = this.date_final.substring(0,10);

            this.date_period_inicial = result_date_inicial.split("-").reverse().join('-')
            this.date_period_final = result_date_final.split("-").reverse().join('-')

            var params = {
                companyunit_id: this.companyUnit_selected,
                date_inicial:result_date_inicial,
                date_final: result_date_final,
                zone_selected: this.zone_selected,
                collaboratorSelected: this.collaboratorSelected
            }

            const config = {
                headers: {
                    "Accept": "application/json",
                    "Authorization": `Bearer ${access_token.token}`
                }
            }

            axios[method](`${baseApiUrl}/${rotas}`, params, config, {
                timeout: 5000
            }).then(res => {

                console.log(res.data)

                if(res.data && res.data.list_delivery != "0"){
                    this.delivery = res.data.list_delivery
                }

                

                /*this.delivery = [
                    {
                        collaborators_name: 'Juca castelo',
                        collaborators_matriculation: '78545',
                        info_delivery:[
                            {
                                code_product: "1222",
                                description_product: "Almofada de encosto"
                            },
                            {
                                code_product: "1225",
                                description_product: "Avental de proteção"
                            }
                        ]
                    },
                    {
                        collaborators_name: 'Felipe castelo',
                        collaborators_matriculation: '78525',
                        info_delivery:[
                            {
                                code_product: "1222",
                                description_product: "Sapato de encosto"
                            },
                            {
                                code_product: "1225",
                                description_product: "Camisa de proteção"
                            }
                        ]
                    },
                ]*/

                this.load = false

            }).catch((e) => {

                this.load = false

                if (e.code === 'ECONNABORTED') {
                    this.load = false
                }
            });
        },
        load_zone(){

            this.load = true

            const method = 'post'

            var rotas = 'list-zone-unit-company-id'

            const access_token = this.$cookie.getCookie(userKey)

            var params = {
                company_id: 7,
                company_unit_id: 10
            }

            const config = {
                headers: {
                    "Accept": "application/json",
                    "Authorization": `Bearer ${access_token.token}`
                }
            }

            axios[method](`${baseApiUrl}/${rotas}`, params, config, {
                timeout: 5000
            }).then(res => {

                console.log(res.data)

                this.list_zones = res.data.zones_list_active

                this.load = false

            }).catch((e) => {

                this.load = false

                if (e.code === 'ECONNABORTED') {
                    this.load = false
                }
            });
        },
        load_collaborator(){

            this.load = true

            const method = 'post'

            var rotas = 'list_collaborator_all'

            const access_token = this.$cookie.getCookie(userKey)

            var params = {
                zone_name: this.zone_selected
            }

            const config = {
                headers: {
                    "Accept": "application/json",
                    "Authorization": `Bearer ${access_token.token}`
                }
            }

            axios[method](`${baseApiUrl}/${rotas}`, params, config, {
                timeout: 5000
            }).then(res => {

                console.log(res.data)

                this.list_collaborators = res.data.list_collaborator_active

                this.load = false

            }).catch((e) => {

                this.load = false

                if (e.code === 'ECONNABORTED') {
                    this.load = false
                }
            });
        },
        list_entrega() {

            this.load = true

            const method = 'post'

            var rotas = 'list-report-delivery'

            const access_token = this.$cookie.getCookie(userKey)

            var params = {
                companyunit_id: 10
            }

            const config = {
                headers: {
                    "Accept": "application/json",
                    "Authorization": `Bearer ${access_token.token}`
                }
            }

            axios[method](`${baseApiUrl}/${rotas}`, params, config, {
                timeout: 5000
            }).then(res => {

                console.log(res.data)

                this.delivery = res.data.list_delivery

                /*this.delivery = [
                    {
                        collaborators_name: 'Juca castelo',
                        collaborators_matriculation: '78545',
                        info_delivery:[
                            {
                                code_product: "1222",
                                description_product: "Almofada de encosto"
                            },
                            {
                                code_product: "1225",
                                description_product: "Avental de proteção"
                            }
                        ]
                    },
                    {
                        collaborators_name: 'Felipe castelo',
                        collaborators_matriculation: '78525',
                        info_delivery:[
                            {
                                code_product: "1222",
                                description_product: "Sapato de encosto"
                            },
                            {
                                code_product: "1225",
                                description_product: "Camisa de proteção"
                            }
                        ]
                    },
                ]*/

                this.load = false

            }).catch((e) => {

                this.load = false

                if (e.code === 'ECONNABORTED') {
                    this.load = false
                }
            });
        },
        list_characteristics_responsibility() {

            this.load = true

            //this.characteristics.company_id = 7
            //this.characteristics.companyunit_id = 10

            const method = 'post'

            var rotas = 'list_characteristics'

            const access_token = this.$cookie.getCookie(userKey)

            var params = {
                companyunit_id: 10
            }

            const config = {
                headers: {
                    "Accept": "application/json",
                    "Authorization": `Bearer ${access_token.token}`
                }
            }

            axios[method](`${baseApiUrl}/${rotas}`, params, config, {
                timeout: 5000
            }).then(res => {

                this.statement_responsibility = res.data.list_characteristics[0].statement_responsibility

                this.load = false

            }).catch((e) => {

                this.load = false

                if (e.code === 'ECONNABORTED') {
                    this.load = false
                }
            });
        },
        list_entrega_unit(){

            this.load = true

            const method = 'post'

            var rotas = 'list_collaborator_all'

            const access_token = this.$cookie.getCookie(userKey)

            var params = {
                companyUnitSelected: this.companyUnit_selected,
                zoneSelected: this.zone_selected,
                collaboratorSelected: this.collaboratorSelected,
                dataInicial: this.date_inicial,
                dateFinal: this.date_final
            }

            const config = {
                headers: {
                    "Accept": "application/json",
                    "Authorization": `Bearer ${access_token.token}`
                }
            }

            axios[method](`${baseApiUrl}/${rotas}`, params, config, {
                timeout: 5000
            }).then(res => {

                console.log(res.data)

                this.load = false

            }).catch((e) => {

                this.load = false

                if (e.code === 'ECONNABORTED') {
                    this.load = false
                }
            });
        },
        list_entrega_unit_zone(){

            this.load = true

            const method = 'post'

            var rotas = 'list_collaborator_all'

            const access_token = this.$cookie.getCookie(userKey)

            var params = {
                companyUnitSelected: this.companyUnit_selected,
                zoneSelected: this.zone_selected,
                collaboratorSelected: this.collaboratorSelected,
                dataInicial: this.date_inicial,
                dateFinal: this.date_final
            }

            const config = {
                headers: {
                    "Accept": "application/json",
                    "Authorization": `Bearer ${access_token.token}`
                }
            }

            axios[method](`${baseApiUrl}/${rotas}`, params, config, {
                timeout: 5000
            }).then(res => {

                console.log(res.data)

                this.load = false

            }).catch((e) => {

                this.load = false

                if (e.code === 'ECONNABORTED') {
                    this.load = false
                }
            });
        },
        list_entrega_unit_zone_collaborator(){

            this.load = true

            const method = 'post'

            var rotas = 'list_collaborator_all'

            const access_token = this.$cookie.getCookie(userKey)

            var params = {
                companyUnitSelected: this.companyUnit_selected,
                zoneSelected: this.zone_selected,
                collaboratorSelected: this.collaboratorSelected,
                dataInicial: this.date_inicial,
                dateFinal: this.date_final
            }

            const config = {
                headers: {
                    "Accept": "application/json",
                    "Authorization": `Bearer ${access_token.token}`
                }
            }

            axios[method](`${baseApiUrl}/${rotas}`, params, config, {
                timeout: 5000
            }).then(res => {

                console.log(res.data)

                this.load = false

            }).catch((e) => {

                this.load = false

                if (e.code === 'ECONNABORTED') {
                    this.load = false
                }
            });
        }
    }
}
</script>

<style scoped>
/*------------------------------------------- Modal Header ----------------------------------------*/
.body_sheet .modal-header {
    padding: 10px 20px;
    background-color: #744cbc;
}

.body_sheet .modal-header .modal-title {
    color: #ffffff;
    font-size: 18px;
}

.card_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.template_header {
    display: flex;
    padding: 8px 16px;
}

.template_header .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 20px;
}

.template_header .logo img {
    width: 150px;
    height: 50px;
}

/*================================================================================================*/
.name_report {
    display: flex;
    flex-direction: column;
}

.name_report .title-card-name {
    color: rgb(114, 132, 140);
    font-size: 18px;
    line-height: 22px;
    font-weight: 500;
    padding: 0px 10px 0px 0px;
}

.name_report .division_1 {
    margin: 5px 0px;
}

.name_report .title-card-date {
    color: rgb(114, 132, 140);
    font-size: 14px;
    line-height: 20px;
    padding: 0px 10px 0px 0px;
}

.name_report .title-card-system-name {
    color: rgb(114, 132, 140);
    font-size: 14px;
    line-height: 20px;
    padding: 0px 10px 0px 0px;
}

/*=============================================================================================== */

/*--------------------------------------------- Menu ----------------------------------------------*/
.card_report .menu i {
    color: #ffffff;
    background-color: #744CBC;
    font-size: 12px;
    border-radius: 10%;
    margin: 0;
    padding: 6px 5px 4px 5px;
    margin-left: 3px;
}

/*=====================================================*/
.card_report .menu {
    display: flex;
    flex-direction: row;
    margin-right: 15px;
}

.btn-link {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 10px;
}

/*=============================================================================================== */
.division_header {
    margin-bottom: 10px;
}

.info_company {
    display: flex;
    margin-bottom: 12px;
    justify-content: space-between;
    margin: 0px 20px 30px 30px;
}

.side_info {
    display: flex;
}

.company_title {
    margin-right: 5px;
    font-size: 18px;
    color: #728690;
    font-weight: 500;
}

.company_name {
    display: flex;
    align-items: center;
    font-size: 17px;
    color: #728690;
}

.back_btn {
    padding: 2px 10px;
    background: blueviolet;
    border: none;
}

.back_btn:hover {
    padding: 2px 10px;
    background: rgba(120, 47, 189, 0.664);
    ;
    border: none;
}

tbody {
    border-top: none !important;
}

.body_sheet {
    height: 100vh;
    overflow-y: scroll;
}

.card_report {
    margin: 25px;
    border: 1px solid #fff;
    border-radius: 7px;
    background-color: #fff;
    box-shadow: 0 5px 15px rgb(57 98 254 / 2%), 0 3px 6px rgb(117 108 254 / 12%);
}

.epi_sheet {
    margin: 30px;
}

p {
    text-align: justify;
    margin: 0;
    font-size: 12px;
}

.hr_info {
    margin-top: 3px;
    margin-bottom: 10px;
}

.row-1 {
    margin-bottom: 10px;
}

label {
    font-size: 12px;
    color: #72848c;
    margin-top: 0px;
    margin-left: 1px;
    margin-bottom: 0px;
}

th {
    font-size: 12px;
    font-weight: 600;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #fff;
    background: #7F5CD4 !important;
    border: 1px solid #7F5CD4 !important;
}

td {
    padding: 3px 8px !important;
    color: #72848c;
    font-size: 12px;
}

</style>
