<template>
    <div class="header">
        <a v-on:click="toggleMenu" class="toggle">
            <i class="fas fa-align-justify"></i>
        </a>
        <nav>
            <div class="btn-group">
                <a class="btn btn-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                    <img src="@/assets/img/perfil.jpg" alt="foto-perfil">
                    {{namePerfil}}
                </a>
                <ul class="dropdown-menu dropdown-menu-end submenu">
                    <li v-if="false">
                        <a class="dropdown-item menu-children">
                            Perfil
                            <span class="badge rounded-pill bg-danger">50%</span>
                        </a>
                    </li>
                    <li v-if="false"><a class="dropdown-item menu-children">Configuração</a></li>
                    <li v-if="false"><a class="dropdown-item menu-children">Ajuda</a></li>
                    <li>
                        <a v-on:click="logout" class="dropdown-item menu-children">
                            Sair
                            <i class="fas fa-sign-out-alt"></i>
                        </a>
                    </li>
                </ul>
            </div>
        </nav>
    </div>
</template>
<script>

    import {userKey} from '@/global'

    export default{
        name: "Header",
        computed:{
            namePerfil(){
                const access = this.$cookie.getCookie(userKey)

                return access.name
            }
        },
        methods:{
            toggleMenu(){
                this.$store.commit('toggleMenu')
            },
            logout(){
                this.$cookie.removeCookie(userKey)
                this.$store.commit('setUser', null)
                this.$router.push({path: '/login'})
            }
        }
    }
</script>

<style scoped>
/*--------------------------------------- Parte principal -----------------------------------------*/
    .header{
        grid-area:header;
        background-color: #ffffff;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

/*------------------------------------- Font link principal ---------------------------------------*/
    .header a{
        font-family: Arial;
    }
/*----------------------------------------- Icone toggle ------------------------------------------*/
    .header i{
        margin-left:15px;
        font-size:20px;
        color:#744cbc;
        cursor:pointer;
    }

/*-------------------------------------- Menu config button ---------------------------------------*/
    .header nav{
        margin-right:5px;
    }

    .header .btn-link{
        display: flex;
        align-items: center;
        text-decoration: none;
        font-size:14px;
        line-height: 23px;
        font-weight: 400;
        color:#744cbc;
    }

    .header .btn-link:hover{
        background-color: transparent;
        color:#3e009b;
    }

    .header .btn-link img{
        width: 29px;
        height: 29px;
        border-radius: 50%;
        margin-right:10px;
    }

/*------------------------------------- Menu config options ---------------------------------------*/
.header .submenu{
    margin-right: 10px !important;
    width: 250px;
    top:0px !important;
    right:0;
    border:none;
}

.header .menu-children{
    padding: 10px 20px 10px 20px;
    color:#505050;
    font-size:14px;
    line-height: 23px;
    font-weight:400;
    font-family: Arial;
}

.header .menu-children span, .header .menu-children i{
    float: right;
    position: relative;
    top:2px;
}

.header .menu-children:hover{
    color:#744cbc;
    background-color: transparent;
    font-weight: 500;
    cursor:pointer;
}
</style>
