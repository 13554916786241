<template>
<div class="user">
    <div class="header">
        <Labels nome="Usuários" cor="#72848c" size="25px" />
        <div class="search">
            <div class="input-group">
                <input type="text" class="form-control input-search" placeholder="pesquisa" v-model="search">
                <span class="input-group-btn">
                    <button type="button" class="btn btn-default btn-search" v-on:click="loadusers"><i class="fas fa-search icon-search"></i></button>
                </span>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="cards">
            <Card title="Usuários Cadastrados" color="#72848c" colorbackground="#ffffff" fontSize="18px" text_tooltip="Adicionar Usuário" view_select="false" :view_add="user_add" :view_active="view_add_button">
                <template v-slot:content class="tabs">
                    <div v-if="user_company_select" class="row">
                        <div class="col-md-3">
                            <select class="form-select form-select-sm select_filter" id="status" v-model="company_selected" @change="loadusers">
                                <option value="0" selected disabled>Empresa</option>
                                <option v-for="(company_list, index) in companies_list" :value="company_list.id" :key="index">{{company_list.fantasy_name}}</option>
                            </select>
                        </div>
                    </div>
                    <Tab name_tab1="Ativo" name_tab2="Inativo">
                        <template v-slot:Ativo>
                            <div v-if="users_active == 'vazio'" class="dados_empty">
                                <p>Não há dados a serem exibidos!</p>
                            </div>
                            <div v-if="users_active != 'vazio' && users_active != 'nulo'" class="tables">
                                <table class="table table-hover">
                                    <thead>
                                        <tr>
                                            <th>Código</th>
                                            <th>Nome</th>
                                            <th>Nível de acesso</th>
                                            <th>Status</th>
                                            <th v-if="user_info || user_edit || user_delete">Controle</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(iten, index) in users_active" :key="index">
                                            <td v-for="(i, index) in order(iten)" :key="index">{{i}}</td>
                                            <td v-if="user_info || user_edit || user_delete">
                                                <div class="controls">
                                                    <button v-if="user_info" @click="loaduser(iten, 'info')" type="button" class="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#modal_delete">Detalhes</button>
                                                    <button v-if="user_edit" @click="loaduser(iten, 'update')" type="button" class="btn btn-info btn-sm" data-bs-toggle="modal" data-bs-target="#modal_add">Editar</button>
                                                    <button v-if="user_delete" @click="loaduser(iten, 'delete')" type="button" class="btn btn-danger btn-sm" data-bs-toggle="modal" data-bs-target="#modal_delete">Excluir</button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div v-if="paginate_view_a" class="paginate">
                                <v-pagination v-model="current_page_a" :pages="total_page_a" :range-size="1" active-color="#744CBC" @update:modelValue="updateHandler" />
                            </div>
                        </template>
                        <template v-slot:Inativo>
                            <div v-if="users_inactive == 'vazio'" class="dados_empty">
                                <p>Não há dados a serem exibidos!</p>
                            </div>
                            <div v-if="users_inactive != 'vazio' && users_inactive != 'nulo'" class="tables">
                                <table class="table table-hover">
                                    <thead>
                                        <tr>
                                            <th>Código</th>
                                            <th>Nome</th>
                                            <th>Centro de Custo</th>
                                            <th>Status</th>
                                            <th>Controle</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(iten, index) in users_inactive" :key="index">
                                            <td v-for="(i, index) in order(iten)" :key="index">{{i}}</td>
                                            <td>
                                                <div class="controls">
                                                    <button @click="loaduser(iten, 'info')" type="button" class="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#modal_delete">Detalhes</button>
                                                    <button @click="loaduser(iten, 'update')" type="button" class="btn btn-info btn-sm" data-bs-toggle="modal" data-bs-target="#modal_add">Editar</button>
                                                    <button @click="loaduser(iten, 'delete')" type="button" class="btn btn-danger btn-sm" data-bs-toggle="modal" data-bs-target="#modal_delete">Excluir</button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div v-if="paginate_view_i" class="paginate">
                                <v-pagination v-model="current_page_i" :pages="total_page_i" :range-size="1" active-color="#744CBC" @update:modelValue="updateHandler" />
                            </div>
                        </template>
                    </Tab>
                </template>
            </Card>
        </div>
        <div class="col-md-12">
            <div v-if="load" class="loading">
                <button class="btn btn-primary" type="button" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    <span class="visually-hidden">Loading...</span>
                </button>
            </div>
        </div>
    </div>
    <div class="modal fade" id="modal_add" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 v-if="mode === 'add'" class="modal-title" id="exampleModalLabel">Cadastrar Usuário</h5>
                    <h5 v-if="mode === 'update'" class="modal-title" id="exampleModalLabel">Atualizar Usuário</h5>
                </div>
                <div class="modal-body">
                    <form>
                        <div class="row">
                            <input id="user-id" type="hidden" v-model="user.id" />
                            <div class="col-md-4">
                                <label for="code" class="form-label">Código</label>
                                <input autocomplete="off" type="text" class="form-control" :class="{'is-invalid': v$.user.code.$error}" id="code" v-model="user.code" maxlength="31">
                                <div v-if="v$.user.code.$error" class="msg_error">{{codeErrors}}</div>
                            </div>
                            <div class="col-md-8">
                                <label for="user" class="form-label">Nome</label>
                                <input autocomplete="off" type="text" class="form-control" :class="{'is-invalid': v$.user.name.$error}" id="user" v-model="user.name" maxlength="31">
                                <div v-if="v$.user.name.$error" class="msg_error">{{nameErrors}}</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <label for="email" class="form-label">Email</label>
                                <input autocomplete="off" type="text" class="form-control" :class="{'is-invalid': v$.user.email.$error}" id="email" v-model="user.email" maxlength="101">
                                <div v-if="v$.user.email.$error" class="msg_error">{{emailErrors}}</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <label for="credential" class="form-label">Credenciais</label>
                                <select class="form-select" id="credential" v-model="user.credential_code">
                                    <option v-for="(credentials_name, index) in credentials_names" :value="credentials_name.code" :key="index">{{credentials_name.name}}</option>
                                </select>
                            </div>
                            <div class="col-md-6">
                                <label for="phone" class="form-label">Telefone</label>
                                <input autocomplete="off" type="text" class="form-control" :class="{'is-invalid': v$.user.phone.$error}" id="phone" v-model="user.phone" v-maska="'(##)#####-####'">
                                <div v-if="v$.user.phone.$error" class="msg_error">{{phoneErrors}}</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <label for="status" class="form-label">Status</label>
                                <select class="form-select" id="status" v-model="user.status">
                                    <option v-for="(statu, index) in status" :value="statu" :key="index">{{statu}}</option>
                                </select>
                            </div>
                            <div class="col-md-6">
                                <label for="status" class="form-label">Acesso</label>
                                <select class="form-select" id="status" v-model="user.access_adm">
                                    <option v-for="(access, index) in access_adm" :value="access" :key="index">{{access}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <label for="companies" class="form-label">Empresa</label>
                                <select class="form-select" :class="{'is-invalid': v$.user.company_id.$invalid}" id="companies" v-model="user.company_id">
                                    <option v-for="(company, index) in companies" :value="company.id" :key="index">{{company.fantasy_name}}</option>
                                </select>
                                <div v-if="v$.user.company_id.$invalid" class="msg_error">{{company_idErrors}}</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <label for="unit" class="form-label">Unidade</label>
                                <select class="form-select" :class="{'is-invalid': v$.user.units_id.$invalid}" id="unit" v-model="user.units_id">
                                    <option v-for="(unit, index) in units" :value="unit.id" :key="index">{{unit.fantasy_name}}</option>
                                </select>
                                <div v-if="v$.user.units_id.$invalid" class="msg_error">{{units_idErrors}}</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <label for="zone" class="form-label">Área</label>
                                <select class="form-select" :class="{'is-invalid': v$.user.zone_id.$invalid}" id="zone" v-model="user.zone_id" @change="loadsectors">
                                    <option v-for="(zone, index) in zones" :value="zone.id" :key="index">{{zone.name}}</option>
                                </select>
                                <div v-if="v$.user.zone_id.$invalid" class="msg_error">{{zone_idErrors}}</div>
                            </div>
                            <div class="col-md-6">
                                <label for="sector" class="form-label">Setor</label>
                                <select class="form-select" :class="{'is-invalid': v$.user.sector_id.$invalid}" id="sector" v-model="user.sector_id">
                                    <option v-for="(sector, index) in sectors" :value="sector.id" :key="index">{{sector.name}}</option>
                                </select>
                                <div v-if="v$.user.sector_id.$error" class="msg_error">{{sector_idErrors}}</div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" @click="reset('cancel_add')" class="btn btn-danger" data-bs-dismiss="modal">Cancelar</button>
                    <button :disabled="v$.$invalid" @click="save" type="button" class="btn btn-primary" data-bs-dismiss="modal">Salvar</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="modal_delete" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modal_info" aria-hidden="true">
        <div class="modal-dialog" :class="larger">
            <div class="modal-content">
                <input id="user-id" type="hidden" v-model="user.id" />
                <div class="modal-header">
                    <h5 v-if="mode === 'delete'" class="modal-title" id="exampleModalLabel">Excluir Usuário</h5>
                    <h5 v-if="mode === 'info'" class="modal-title" id="exampleModalLabel">Informações Do Usuário</h5>
                </div>
                <div class="modal-body">
                    <div v-if="mode === 'delete'">
                        <div class="msg">
                            <h6>Você tem certeza que deseja excluir o registro?</h6>
                        </div>
                    </div>
                    <div v-if="mode === 'info'">
                        <div class="row">
                            <div class="col-md-12">
                                <label class="label_info"><b>Código: </b><span>{{user.code}}</span></label>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-md-12">
                                <label class="label_info"><b>Nome: </b><span>{{user.name}}</span></label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <label class="label_info"><b>Email: </b><span>{{user.email}}</span></label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <label class="label_info"><b>Credencial: </b><span>{{user.name_credential_name}}</span></label>
                            </div>
                            <div class="col-md-4">
                                <label class="label_info"><b>Telefone: </b><span>{{user.phone}}</span></label>
                            </div>
                            <div class="col-md-4">
                                <label class="label_info"><b>Acesso: </b><span>{{user.access_adm}}</span></label>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-md-12">
                                <label class="label_info"><b>Empresa: </b><span>{{user.company_fantasy_name}}</span></label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <label class="label_info"><b>Unidade: </b><span>{{user.company_units_fantasy_name}}</span></label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <label class="label_info"><b>Área: </b><span>{{user.zones_name}}</span></label>
                            </div>
                            <div class="col-md-6">
                                <label class="label_info"><b>Setor: </b><span>{{user.sectors_name}}</span></label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <label class="label_info"><b>Status: </b><span>{{user.status}}</span></label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button v-if="mode == 'info'" type="button" class="btn btn-danger btn_left" data-bs-dismiss="modal">Fechar</button>
                    <button v-if="mode != 'info'" type="button" class="btn btn-danger btn_left" data-bs-dismiss="modal">Cancelar</button>
                    <button v-if="mode != 'info'" @click="remove" type="button" class="btn btn-primary btn_right" data-bs-dismiss="modal">Confirmar</button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import {
    required,
    maxLength,
    minLength,
    email
} from '@vuelidate/validators'
import {
    baseApiUrl,
    userKey,
    showError
} from '@/global'

import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

import axios from 'axios'
import Labels from "@/components/Labels/Label"
import Card from "@/components/Cards/Card"
import Tab from "@/components/Tabs/Tab"

export default {
    name: "user",
    data: function () {
        return {
            v$: useVuelidate(),
            mode: 'add',
            user: {},
            users: [],
            users_active: [],
            users_inactive: [],
            itens: '',
            larger: 'modal-lg',
            status: ['Ativo', 'Inativo', 'Desligado'],
            access_adm: ['EMPRESA', 'UNIDADE', 'ÁREA', 'SETOR'],
            search: '',
            dados_per_page_a: 0,
            dados_per_page_i: 0,
            current_page_a: 1,
            current_page_i: 1,
            total_page_a: 1,
            total_page_i: 1,
            page: 1,
            paginate_view_a: '',
            paginate_view_i: '',
            msg_error: '',
            load: false,
            companies: '',
            units: '',
            zones: '',
            sectors: '',
            credentials_names: '',
            user_company_select: false,
            user_add: true,
            user_info: true,
            user_edit: true,
            user_delete: true,
            companies_list: '',
            company_selected: '0',
            view_add_button: false
        }
    },
    validations() {
        return {
            user: {
                code: {
                    maxLength: maxLength(30),
                    $autoDirty: true
                },
                name: {
                    required,
                    maxLength: maxLength(30),
                    $autoDirty: true
                },
                email: {
                    required,
                    maxLength: maxLength(100),
                    email,
                    $autoDirty: true
                },
                phone: {
                    minLength: minLength(14),
                    $autoDirty: true
                },
                company_id: {
                    required,
                },
                units_id: {
                    required,
                },
                sector_id: {
                    required,
                },
                zone_id: {
                    required,
                }
            }
        }
    },
    components: {
        Labels,
        Card,
        Tab,
        VPagination
    },
    mounted() {

        this.view_add = false

        const access_permission = this.$cookie.getCookie(userKey)

        var permissoes = access_permission.permissions

        var permissoes_array = permissoes.split(',')

        this.user_add     = permissoes_array.includes('749998') == true ? true : false
        this.user_info    = permissoes_array.includes('749997') == true ? true : false
        this.user_edit    = permissoes_array.includes('749996') == true ? true : false
        this.user_delete  = permissoes_array.includes('749995') == true ? true : false

        this.user.status = this.status['0']

        this.user.access_adm = this.access_adm['0']

        const access_credential = this.$cookie.getCookie(userKey)

        if (access_credential.credential_code == 1000000 || access_credential.credential_code == 900000) {
            this.loadcompanies();
            this.user_company_select = true
        } else {
            this.loadusers();
            this.user_company_select = false
        }
    },
    computed: {
        codeErrors() {
            const code = this.v$.user.code
            if (!code.$dirty) {
                return ""
            }
            if (!code.maxLength.$response) {
                return "Limite máximo de 30 caracteres!"
            } else {
                return ""
            }
        },
        nameErrors() {
            const name = this.v$.user.name
            if (!name.$dirty) {
                return ""
            }
            if (!name.required.$response) {
                return "O campo é obrigatório!"
            } else if (!name.maxLength.$response) {
                return "Limite máximo de 30 caracteres!"
            } else {
                return ""
            }
        },
        emailErrors() {
            const email = this.v$.user.email
            if (!email.$dirty) {
                return ""
            }
            if (!email.required.$response) {
                return "O campo é obrigatório!"
            } else if (!email.maxLength.$response) {
                return "Limite máximo de 100 caracteres!"
            } else if (!email.email.$response) {
                return "Formato incorreto da informação!"
            } else {
                return ""
            }
        },
        phoneErrors() {
            const phone = this.v$.user.phone
            if (!phone.$dirty) {
                return ""
            }
            if (!phone.minLength.$response) {
                return "Limite mínimo de 14 caracteres!"
            } else {
                return ""
            }
        },
        company_idErrors() {
            const company_id = this.v$.user.company_id
            if (!company_id.required.$response) {
                return "O campo é obrigatório!"
            } else {
                return ""
            }
        },
        units_idErrors() {
            const units_id = this.v$.user.units_id
            if (!units_id.required.$response) {
                return "O campo é obrigatório!"
            } else {
                return ""
            }
        },
        zone_idErrors() {
            const zone_id = this.v$.user.zone_id
            if (!zone_id.required.$response) {
                return "O campo é obrigatório!"
            } else {
                return ""
            }
        },
        sector_idErrors() {
            const sector_id = this.v$.user.sector_id
            if (!sector_id.required.$response) {
                return "O campo é obrigatório!"
            } else {
                return ""
            }
        }
    },
    methods: {
        updateHandler(valor) {

            this.page = valor

            this.loadusers();
        },
        loadcompanies() {

            this.load = true

            const access_credential = this.$cookie.getCookie(userKey)

            var params = {
                page: this.page,
                search: this.search,
                company_id: 2,
                code: access_credential.credential_code,
                menu: 'user'
            }

            var method = 'post'
            var rotas = 'company-list-all-active'

            const access_token = this.$cookie.getCookie(userKey)

            const config = {
                headers: {
                    Authorization: `Bearer ${access_token.token}`
                }
            };

            axios[method](`${baseApiUrl}/${rotas}`, params, config, {
                timeout: 5000
            }).then(res => {

                console.log(res.data)

                this.companies_list = res.data.companies_list_active

                this.load = false

            }).catch((e) => {

                if (e.code === 'ECONNABORTED') {
                    this.load = false
                }
            });
        },
        loadusers() {

            var company_id

            this.load = true

            const access_credential = this.$cookie.getCookie(userKey)

            if (access_credential.credential_code == 1000000 || access_credential.credential_code == 900000) {
                company_id = this.company_selected
            } else {
                company_id = access_credential.company_id
            }

            var params = {
                page: this.page,
                search: this.search,
                company_id: company_id
            }

            var method = 'post'
            var rotas = 'user-dependency-company'

            const access_token = this.$cookie.getCookie(userKey)

            const config = {
                headers: {
                    Authorization: `Bearer ${access_token.token}`
                }
            };

            axios[method](`${baseApiUrl}/${rotas}`, params, config, {
                timeout: 5000
            }).then(res => {

                console.log(res.data)

                this.view_add_button = true

                this.companies = res.data.companies_list_active

                this.user.company_id = res.data.companies_list_active[0].id

                this.units = res.data.companiesunit_list_active

                this.user.units_id = res.data.companiesunit_list_active[0].id

                this.zones = res.data.zones_list_active

                this.user.zone_id = res.data.zones_list_active[0].id

                this.credentials_names = res.data.credentials_name_list

                this.user.credential_code = res.data.credentials_name_list[0].code

                console.log(res.data.credentials_name_list[0].id)

                if (res.data.users_list_active == 'vazio') {

                    this.users_active = 'vazio'
                    this.paginate_view_a = false
                    this.load = false
                } else {

                    this.view_add = true

                    this.total_page_a = res.data.users_list_active.last_page
                    this.current_page_a = res.data.users_list_active.current_page
                    this.dados_per_page_a = res.data.users_list_active.data.length

                    this.paginate_view_a = true

                    var users_active = res.data.users_list_active.data

                    var result_active = users_active.map(getList)

                    console.log(result_active)

                    this.users_active = result_active

                    

                }

                if (res.data.users_list_inactive == 'vazio') {
                    this.users_inactive = 'vazio'
                    this.paginate_view_i = false
                    this.load = false
                } else {

                    this.view_add = true

                    this.total_page_i = res.data.users_list_inactive.last_page
                    this.current_page_i = res.data.users_list_inactive.current_page
                    this.dados_per_page_i = res.data.users_list_inactive.data.length

                    this.paginate_view_i = true

                    var users_inactive = res.data.users_list_inactive.data

                    var result_inactive = users_inactive.map(getList)

                    this.users_inactive = result_inactive

                    this.load = false

                }

                this.loadsectors()
            }).catch((e) => {

                if (e.code === 'ECONNABORTED') {
                    this.load = false
                }
            });

            function getList(item) {
                return {
                    id: item.user_id,
                    code: item.user_code,
                    name: item.user_name,
                    access_adm: item.user_access_adm,
                    status: item.user_status,
                    email: item.user_email,
                    phone: item.user_phone,
                    company_id: item.user_company,
                    units_id: item.user_unit,
                    zone_id: item.user_zone,
                    credential_code: item.user_credential_code,
                    sector_id: item.user_sector,
                    name_credential_name: item.name_credential_name,
                    company_fantasy_name: item.company_fantasy_name,
                    company_units_fantasy_name: item.company_units_fantasy_name,
                    zones_name: item.zones_name,
                    sectors_name: item.sectors_name,

                }
            }
        },
        loadsectors(){

            this.load = true

            const access_credential = this.$cookie.getCookie(userKey)

            var params = {
                zone_id: this.user.zone_id
            }

            var method = 'post'
            var rotas = 'user-dependency-zone-sector'


            const config = {
                headers: {
                    Authorization: `Bearer ${access_credential.token}`
                }
            };

            axios[method](`${baseApiUrl}/${rotas}`, params, config, {
                timeout: 5000
            }).then(res => {

                console.log(res.data)

                this.sectors = res.data.sector_zone

                this.user.sector_id = res.data.sector_zone[0].id

                this.load = false

            }).catch((e) => {

                if (e.code === 'ECONNABORTED') {
                    this.load = false
                }
            });
        },
        order(iten) {

            var list = Object.values(iten)

            list = list.slice(1, 5)

            return list;
        },
        reset(mode = 'add') {
            this.mode = 'add'

            if(mode == 'cancel_add'){
                this.user.code = '',
                this.user.name = '',
                this.user.email = '',
                this.user.phone = ''
            }else{
                this.user = {}
            }

            this.user.status = this.status['0']
            this.user.access_adm = this.access_adm['0']
            this.v$.$reset();

            if (mode != 'info' && mode != 'cancelar') {
                this.loadusers()
            }

        },
        save() {

            if (this.v$.$invalid) {

                console.log("Formulário alterado!")

            } else {

                const method = 'post'

                var rotas = this.user.id ? 'user-update' : 'user-create'

                const access_token = this.$cookie.getCookie(userKey)

                const config = {
                    headers: {
                        'Accept': "application/json",
                        Authorization: `Bearer ${access_token.token}`
                    }
                }

                var data = {
                    id: this.user.id,
                    company_id: this.user.company_id,
                    credential_code: this.user.credential_code,
                    code: this.user.code,
                    name: this.user.name,
                    email: this.user.email,
                    phone: this.user.phone,
                    access_adm: this.user.access_adm,
                    unit_id: this.user.units_id,
                    zone_id: this.user.zone_id,
                    sector_id: this.user.sector_id,
                    status: this.user.status
                }

                axios[method](`${baseApiUrl}/${rotas}`, data, config, {
                    timeout: 5000
                }).then(res => {

                    this.load = false

                    console.log(res.data)

                    if (res.data.code == '2000') {
                        this.$toast.success(res.data.message)
                    } else if (res.data.code == '3000') {
                        this.$toast.success(res.data.message)
                    } else {
                        this.$toast.success(res.data.message)
                    }

                    this.reset()

                }).catch((e) => {

                    if (e.code === 'ECONNABORTED') {
                        this.load = false
                    }
                });
            }
        },
        loaduser(user, mode = 'add') {

            if (mode === 'delete') {
                this.larger = ''
            } else {
                this.larger = 'modal-lg'
            }

            this.mode = mode

            console.log(user)

            if (mode === 'update') {

                this.user = {
                    ...user
                }
            }

            if (mode === 'add') {

                this.user = {
                    ...user
                }
            } else {
                this.user = {
                    ...user
                }
            }

        },
        remove() {

            this.load = true

            if (this.dados_per_page_a == 1 || this.dados_per_page_i == 1) {
                this.page -= 1
            }

            var method = 'post'
            var rotas = 'user-delete'

            const access_token = this.$cookie.getCookie(userKey)

            const config = {
                headers: {
                    'Accept': "application/json",
                    Authorization: `Bearer ${access_token.token}`
                }
            }

            axios[method](`${baseApiUrl}/${rotas}`, this.user, config, {
                timeout: 5000
            }).then(res => {

                console.log(res.data)

                this.load = false

                if(res.data.code == "5001"){

                    if (res.data.exception.indexOf('1451') > -1) {

                    this.$toast.error("Usuário não pode ser excluído - Cod: 1451")

                    }
                }else if(res.data.code != "5001"){

                    if (res.data.code == '4000') {

                        this.$toast.success(res.data.message)

                    } else {

                        this.$toast.error(res.data.message)
                    }
                }

                this.reset()
            }).catch(showError)
        }
    }
}
</script>

<style scoped>
.paginate {
    display: flex;
    justify-content: center;
}

/* ------------------------------------ Filtros de pesquisa ---------------------------------------*/
.cards .select_filter {
    margin-bottom: 15px;
}

/*-------------------------------------- Cabeçalho Companies --------------------------------------*/
.user {
    margin: 25px;
}

.user .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

/* -------------- Cabeçalho ------------------*/
.search .input-search {
    border-radius: 3px;
    border-color: #744cbc;
}

.search .input-search:focus,
.btn-search {
    box-shadow: 0 0 0 0 !important;
    outline: none !important;
}

.search .icon-search {
    color: #ffffff;
    transform: rotate(90deg);
}

.search .btn-search {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-color: #744cbc;
}

.search .btn-search:hover {
    background-color: #886aea !important;
}

/*--------------------------------------------- Table ---------------------------------------------*/
.user .cards .tables thead tr th {
    border-style: solid;
    border-bottom-width: 2px;
    color: rgb(114, 132, 140);
    border-bottom-color: rgb(222, 226, 230) !important;
}

.user .cards .tables thead th {
    font-size: 15px;
    font-weight: 600;
    padding-top: 10px;
    padding-bottom: 10px;
}

.user .cards .tables tbody td {
    font-size: 14px;
    padding-top: 11px;
    padding-bottom: 11px;
    color: #72848c;
}

.user .cards .tables tbody button {
    color: #ffffff;
    margin: 0 2px;
    box-shadow: 0 0 0 0 !important;
    outline: none !important;
}

.user .cards .pagination {
    display: flex;
    justify-content: center;
}

/*------------------------------------------- Paginação ------------------------------------------ */
.pagination-table {
    margin-top: 30px;
}

.pagination-table .page-item .page-link {
    color: #72848c;
    text-decoration: none;
    border: 1px solid #dee2e6;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    cursor: pointer;
}

.pagination-table .page-item.active .page-link {
    color: #ffffff;
    background-color: #744cbc;
    border-color: #744cbc;
}

/*--------------------------------------------- Modal ---------------------------------------------*/
.user .modal-content {
    border: none;
}

/*------------------------------------------- Modal Header ----------------------------------------*/
.user .modal-header {
    padding: 10px 20px;
    background-color: #744cbc;
}

.user .modal-header .modal-title {
    color: #ffffff;
    font-size: 18px;
}

/*-------------------------------------------- Modal Body -----------------------------------------*/
.user .modal-body .msg {
    text-align: center;
    color: #72848c;
}

.user .modal-body .msg h6 {
    padding: 20px 0px 12px 0px;
    font-size: 17px;
}

.user .modal-body label {
    font-size: 15px;
    color: #72848c;
    margin-top: 10px;
    margin-left: 1px;
    margin-bottom: 5px;
}

/*------------------------------------------- Modal Footer ----------------------------------------*/
.user .modal-footer {
    padding: 8px;
}

.user .modal-footer button {
    border: none;
}

/*----------------------------------------- Mensagem De Erro --------------------------------------*/
.msg_error {
    color: #ff0000;
    font-size: 14px;
}

input,
input:focus,
.is-invalid {
    box-shadow: 0 0 0 0;
    outline: 0;
}

.is-invalid {
    box-shadow: 0 0 0 0 !important;
    outline: 0 !important;
}

.dados_empty {
    color: #72848c;
    font-weight: 600;

}

.loading button {
    position: absolute;
    top: calc(50% - 35px);
    left: calc(50%);
    margin-left: 90px;
    width: 70px;
    height: 70px;
    background-color: #3b0253;
    border: none;
}

.loading span {
    width: 30px;
    height: 30px;
}
</style>
