<template>
<div class="equipament">
    <div class="header">
        <Labels nome="Equipamentos" cor="#72848c" size="25px" />
        <div v-if="false" class="search">
            <div class="input-group">
                <input type="text" class="form-control input-search" placeholder="pesquisa" v-model="search">
                <span class="input-group-btn">
                    <button type="button" class="btn btn-default btn-search" v-on:click="loadsearch"><i class="fas fa-search icon-search"></i></button>
                </span>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="cards">
            <Card title="Equipamentos Cadastrados" color="#72848c" colorbackground="#ffffff" fontSize="18px" text_tooltip="Adicionar equipamento" :view_add="equipament_add" :view_active="view_add_button">
                <template v-slot:content class="tabs">
                    <div class="row">
                        <div v-if="false" class="col-md-3">
                            <select class="form-select form-select-sm select_filter" id="status" v-model="company_selected" @change="loadsequipament">
                                <option value="0" selected disabled>Selecione uma empresa</option>
                                <option v-for="(company_list, index) in companies_list" :value="company_list.id" :key="index">{{company_list.fantasy_name}}</option>
                            </select>
                        </div>
                        <div v-if="company_units" class="col-md-3">
                            <select class="form-select form-select-sm select_filter" id="status" v-model="company_unit_selected" @change="loadequipaments_unit">
                                <option value="0" selected disabled>Selecione uma unidade</option>
                                <option v-for="(company_unit, index) in company_units" :value="company_unit.id" :key="index">{{company_unit.fantasy_name}}</option>
                            </select>
                        </div>
                    </div>
                    <Tab name_tab1="Ativo" name_tab2="Inativo">
                        <template v-slot:Ativo>
                            <div v-if="equipaments_active == 'vazio'" class="dados_empty">
                                <p>Não há dados a serem exibidos!</p>
                            </div>
                            <div v-if="equipaments_active != 'vazio' && equipaments_active != 'nulo'" class="tables">
                                <table class="table table-hover">
                                    <thead>
                                        <tr>
                                            <th>Código</th>
                                            <th>Área</th>
                                            <th>Status</th>
                                            <th v-if="equipament_info || equipament_edit || equipament_delete">Controle</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(iten, index) in equipaments_active" :key="index">
                                            <td v-for="(i, index) in order(iten)" :key="index">{{i}}</td>
                                            <td v-if="equipament_info || equipament_edit || equipament_delete">
                                                <div class="controls">
                                                    <button v-if="equipament_info" @click="loadequipament(iten, 'info')" type="button" class="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#modal_delete">Detalhes</button>
                                                    <button v-if="equipament_edit" @click="loadequipament(iten, 'update')" type="button" class="btn btn-info btn-sm" data-bs-toggle="modal" data-bs-target="#modal_add">Editar</button>
                                                    <button v-if="equipament_delete" @click="loadequipament(iten, 'delete')" type="button" class="btn btn-danger btn-sm" data-bs-toggle="modal" data-bs-target="#modal_delete">Excluir</button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div v-if="paginate_view_a" class="paginate">
                                <v-pagination v-model="current_page_a" :pages="total_page_a" :range-size="1" active-color="#744CBC" @update:modelValue="updateHandler" />
                            </div>
                        </template>
                        <template v-slot:Inativo>
                            <div v-if="equipaments_inactive == 'vazio'" class="dados_empty">
                                <p>Não há dados a serem exibidos!</p>
                            </div>
                            <div v-if="equipaments_inactive != 'vazio' && equipaments_inactive != 'nulo'" class="tables">
                                <table class="table table-hover">
                                    <thead>
                                        <tr>
                                            <th>Código</th>
                                            <th>Área</th>
                                            <th>Status</th>
                                            <th v-if="equipament_info || equipament_edit || equipament_delete">Controle</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(iten, index) in equipaments_inactive" :key="index">
                                            <td v-for="(i, index) in order(iten)" :key="index">{{i}}</td>
                                            <td v-if="equipament_info || equipament_edit || equipament_delete">
                                                <div class="controls">
                                                    <button v-if="equipament_info" @click="loadequipament(iten, 'info')" type="button" class="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#modal_delete">Detalhes</button>
                                                    <button v-if="equipament_edit" @click="loadequipament(iten, 'update')" type="button" class="btn btn-info btn-sm" data-bs-toggle="modal" data-bs-target="#modal_add">Editar</button>
                                                    <button v-if="equipament_delete" @click="loadequipament(iten, 'delete')" type="button" class="btn btn-danger btn-sm" data-bs-toggle="modal" data-bs-target="#modal_delete">Excluir</button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div v-if="paginate_view_i" class="paginate">
                                <v-pagination v-model="current_page_i" :pages="total_page_i" :range-size="1" active-color="#744CBC" @update:modelValue="updateHandler" />
                            </div>
                        </template>
                    </Tab>
                </template>
            </Card>
        </div>
        <div class="col-md-12">
            <div v-if="load" class="loading">
                <button class="btn btn-primary" type="button" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    <span class="visually-hidden">Loading...</span>
                </button>
            </div>
        </div>
    </div>
    <div class="modal fade" id="modal_add" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 v-if="mode === 'add'" class="modal-title" id="exampleModalLabel">Cadastrar Equipamento</h5>
                    <h5 v-if="mode === 'update'" class="modal-title" id="exampleModalLabel">Atualizar Equipamento</h5>
                </div>
                <div class="modal-body">
                    <form>
                        <div class="row">
                            <input id="equipament-id" type="hidden" v-model="equipament.id" />
                            <div class="col-md-4">
                                <label for="code" class="form-label">Código</label>
                                <input autocomplete="off" type="text" class="form-control" :class="{'is-invalid': v$.equipament.code.$error}" id="code" v-model="equipament.code" maxlength="31">
                                <div v-if="v$.equipament.code.$error" class="msg_error">{{codeErrors}}</div>
                            </div>
                            <div class="col-md-8">
                                <label for="equipament" class="form-label">Nome</label>
                                <input autocomplete="off" type="text" class="form-control" :class="{'is-invalid': v$.equipament.name.$error}" id="equipament" v-model="equipament.name" maxlength="31">
                                <div v-if="v$.equipament.name.$error" class="msg_error">{{nameErrors}}</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <label for="code" class="form-label">Descrição</label>
                                <input autocomplete="off" type="text" class="form-control" :class="{'is-invalid': v$.equipament.description.$error}" id="code" v-model="equipament.description" maxlength="201">
                                <div v-if="v$.equipament.description.$error" class="msg_error">{{descriptionErrors}}</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <label for="code" class="form-label">Ip acesso remoto</label>
                                <input autocomplete="off" type="text" class="form-control" :class="{'is-invalid': v$.equipament.ip_acesso_remoto.$error}" id="code" v-model="equipament.ip_acesso_remoto" maxlength="16" v-maska="'###.###.###.###'">
                                <div v-if="v$.equipament.ip_acesso_remoto.$error" class="msg_error">{{ip_acesso_remotoErrors}}</div>
                            </div>
                            <div class="col-md-4">
                                <label for="equipament" class="form-label">Ip Saída</label>
                                <input autocomplete="off" type="text" class="form-control" :class="{'is-invalid': v$.equipament.ip_saida.$error}" id="equipament" v-model="equipament.ip_saida" maxlength="16" v-maska="'###.###.###.###'">
                                <div v-if="v$.equipament.ip_saida.$error" class="msg_error">{{ip_saidaErrors}}</div>
                            </div>
                            <div class="col-md-4">
                                <label for="equipament" class="form-label">Mac Address</label>
                                <input autocomplete="off" type="text" class="form-control" :class="{'is-invalid': v$.equipament.mac_address.$error}" id="equipament" v-model="equipament.mac_address" maxlength="18" v-maska="'XX:XX:XX:XX:XX:XX'">
                                <div v-if="v$.equipament.mac_address.$error" class="msg_error">{{mac_addressErrors}}</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <label for="status" class="form-label">Localização Área</label>
                                <select class="form-select" :class="{'is-invalid': v$.equipament.local_area.$error}" id="status" v-model="equipament.local_area" @change="loadsectors">
                                    <option v-for="(area, index) in list_area" :value="area.id" :key="index">{{area.name}}</option>
                                </select>
                                <div v-if="v$.equipament.local_area.$error" class="msg_error">{{local_areaErrors}}</div>
                            </div>
                            <div class="col-md-4">
                                <label for="status" class="form-label">Localização Setor</label>
                                <select class="form-select" :class="{'is-invalid': v$.equipament.local_sector.$error}" id="status" v-model="equipament.local_sector">
                                    <option v-for="(setor, index) in list_setor" :value="setor.id" :key="index">{{setor.name}}</option>
                                </select>
                                <div v-if="v$.equipament.local_sector.$error" class="msg_error">{{local_sectorErrors}}</div>
                            </div>
                            <div class="col-md-4">
                                <label for="equipament" class="form-label">Último Status</label>
                                <input autocomplete="off" type="text" class="form-control" id="equipament" v-model="equipament.last_status" maxlength="31">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <label for="status" class="form-label">Modelo</label>
                                <select class="form-select" id="status" v-model="equipament.modelo">
                                    <option v-for="(modelo, index) in modelos" :value="modelo" :key="index">{{modelo}}</option>
                                </select>
                            </div>
                            <div class="col-md-4">
                                <label for="status" class="form-label">Modo de Operação</label>
                                <select class="form-select" id="status" v-model="equipament.mode_operation">
                                    <option v-for="(operation, index) in mode_operation" :value="operation" :key="index">{{operation}}</option>
                                </select>
                            </div>
                            <div class="col-md-4">
                                <label for="status" class="form-label">Status</label>
                                <select class="form-select" id="status" v-model="equipament.status">
                                    <option v-for="(statu, index) in status" :value="statu" :key="index">{{statu}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <label for="status" class="form-label">Tipo</label>
                                <select class="form-select" id="status" v-model="equipament.type">
                                    <option v-for="(type, index) in type_equipaments" :value="type" :key="index">{{type}}</option>
                                </select>
                            </div>
                            <div class="col-md-6">
                                <label for="sequency" class="form-label">Sequência</label>
                                <input autocomplete="off" type="text" class="form-control" :class="{'is-invalid': v$.equipament.sequency.$error}" id="sequency" v-model="equipament.sequency" maxlength="16">
                                <div v-if="v$.equipament.sequency.$error" class="msg_error">{{sequencyErrors}}</div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button @click="reset('cancelar')" type="button" class="btn btn-danger" data-bs-dismiss="modal">Cancelar</button>
                    <button :disabled="v$.$invalid" @click="save" type="button" class="btn btn-primary" data-bs-dismiss="modal">Salvar</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="modal_delete" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modal_info" aria-hidden="true">
        <div class="modal-dialog" :class="larger">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 v-if="mode === 'delete'" class="modal-title" id="exampleModalLabel">Excluir Equipamento</h5>
                    <h5 v-if="mode === 'info'" class="modal-title" id="exampleModalLabel">Informações Do Equipamento</h5>
                </div>
                <div class="modal-body">
                    <div v-if="mode === 'delete'">
                        <div class="msg">
                            <h6>Você tem certeza que deseja excluir o registro?</h6>
                        </div>
                    </div>
                    <div v-if="mode === 'info'">
                        <div class="row">
                            <div class="col-md-12">
                                <label class="label_info"><b>Código: </b><span>{{equipament.code}}</span></label>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-md-12">
                                <label class="label_info"><b>Nome: </b><span>{{equipament.name}}</span></label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <label class="label_info"><b>Descrição: </b><span>{{equipament.description}}</span></label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <label class="label_info"><b>Ip Remoto: </b><span>{{equipament.ip_acesso_remoto}}</span></label>
                            </div>
                            <div class="col-md-4">
                                <label class="label_info"><b>Ip Saída: </b><span>{{equipament.ip_saida}}</span></label>
                            </div>
                            <div class="col-md-4">
                                <label class="label_info"><b>Mac Address: </b><span>{{equipament.mac_address}}</span></label>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-md-4">
                                <label class="label_info"><b>Local área: </b><span>{{equipament.zone_name}}</span></label>
                            </div>
                            <div class="col-md-4">
                                <label class="label_info"><b>Local setor: </b><span>{{equipament.sector_name}}</span></label>
                            </div>
                            <div class="col-md-4">
                                <label class="label_info"><b>Último status: </b><span>{{equipament.last_status}}</span></label>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-md-4">
                                <label class="label_info"><b>Modelo: </b><span>{{equipament.modelo}}</span></label>
                            </div>
                            <div class="col-md-4">
                                <label class="label_info"><b>Modo de operação: </b><span>{{equipament.mode_operation}}</span></label>
                            </div>
                            <div class="col-md-4">
                                <label class="label_info"><b>Status: </b><span>{{equipament.status}}</span></label>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-md-8">
                                <label class="label_info"><b>Tipo: </b><span>{{equipament.type}}</span></label>
                            </div>
                            <div class="col-md-4">
                                <label class="label_info"><b>Sequência: </b><span>{{equipament.sequency}}</span></label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button v-if="mode == 'info'" @click="reset('info')" type="button" class="btn btn-danger btn_left" data-bs-dismiss="modal">Fechar</button>
                    <button v-if="mode != 'info'" @click="reset('cancelar')" type="button" class="btn btn-danger btn_left" data-bs-dismiss="modal">Cancelar</button>
                    <button v-if="mode != 'info'" @click="remove" type="button" class="btn btn-primary btn_right" data-bs-dismiss="modal">Confirmar</button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import {
    required,
    maxLength
} from '@vuelidate/validators'
import {
    baseApiUrl,
    userKey,
    showError
} from '@/global'

import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

import axios from 'axios'
import Labels from "@/components/Labels/Label"
import Card from "@/components/Cards/Card"
import Tab from "@/components/Tabs/Tab"

export default {
    name: "equipament",
    data: function () {
        return {
            v$: useVuelidate(),
            mode: 'add',
            equipament: {},
            equipaments: [],
            equipaments_active: [],
            equipaments_inactive: [],
            itens: '',
            larger: 'modal-lg',
            status: ['Ativo', 'Inativo'],
            type_equipaments: ['Balcão - Linux'],
            search: '',
            dados_per_page_a: 0,
            dados_per_page_i: 0,
            current_page_a: 1,
            current_page_i: 1,
            total_page_a: 1,
            total_page_i: 1,
            page: 1,
            paginate_view_a: '',
            paginate_view_i: '',
            msg_error: '',
            load: false,
            companies_list: '',
            company_selected: '0',
            company_units: '',
            company_unit_selected: '0',
            view_add_button: false,
            equipament_add: true,
            equipament_info: true,
            equipament_edit: true,
            equipament_delete: true,
            list_area: '',
            list_setor:'',
            modelos:['BCA-EPI'],
            mode_operation:['Balcão Atendimento']
        }
    },
    validations() {
        return {
            equipament: {
                code: {
                    maxLength: maxLength(30),
                    $autoDirty: true
                },
                name: {
                    required,
                    maxLength: maxLength(30),
                    $autoDirty: true
                },
                description: {
                    maxLength: maxLength(200),
                    $autoDirty: true
                },
                ip_acesso_remoto: {
                    required,
                    maxLength: maxLength(15),
                    $autoDirty: true
                },
                ip_saida: {
                    required,
                    maxLength: maxLength(15),
                    $autoDirty: true
                },
                mac_address: {
                    required,
                    maxLength: maxLength(17),
                    $autoDirty: true
                },
                local_area: {
                    required,
                    $autoDirty: true
                },
                local_sector: {
                    required,
                    $autoDirty: true
                },
                mode_operation: {
                    required,
                    maxLength: maxLength(30),
                    $autoDirty: true
                },
                type_equipament: {
                    maxLength: maxLength(50),
                    $autoDirty: true
                },
                sequency: {
                    required,
                    maxLength: maxLength(15),
                    $autoDirty: true
                },
            }
        }
    },
    components: {
        Labels,
        Card,
        Tab,
        VPagination
    },
    mounted() {

        this.equipament.type = this.type_equipaments['0']
        this.equipament.status = this.status['0']
        this.equipament.modelo = this.modelos['0']
        this.equipament.mode_operation = this.mode_operation[0]

        this.loadcompanies();
    },
    computed: {
        codeErrors() {
            const code = this.v$.equipament.code
            if (!code.$dirty) {
                return ""
            }
            if (!code.maxLength.$response) {
                return "Limite máximo de 30 caracteres!"
            } else {
                return ""
            }
        },
        nameErrors() {
            const name = this.v$.equipament.name
            if (!name.$dirty) {
                return ""
            }
            if (!name.required.$response) {
                return "O campo é obrigatório!"
            } else if (!name.maxLength.$response) {
                return "Limite máximo de 30 caracteres!"
            } else {
                return ""
            }
        },
        descriptionErrors() {
            const description = this.v$.equipament.description
            if (!description.$dirty) {
                return ""
            }
            if (!description.maxLength.$response) {
                return "Limite máximo de 200 caracteres!"
            }else {
                return ""
            }
        },
        ip_acesso_remotoErrors() {
            const ip_acesso_remoto = this.v$.equipament.ip_acesso_remoto
            if (!ip_acesso_remoto.$dirty) {
                return ""
            }else if (!ip_acesso_remoto.required.$response) {
                return "O campo é obrigatório!"
            }else if (!ip_acesso_remoto.maxLength.$response) {
                return "Limite máximo de 15 caracteres!"
            } else {
                return ""
            }
        },
        ip_saidaErrors() {
            const ip_saida = this.v$.equipament.ip_saida
            if (!ip_saida.$dirty) {
                return ""
            }else if (!ip_saida.required.$response) {
                return "O campo é obrigatório!"
            }else if (!ip_saida.maxLength.$response) {
                return "Limite máximo de 15 caracteres!"
            } else {
                return ""
            }
        },
        mac_addressErrors() {
            const mac_address = this.v$.equipament.mac_address
            if (!mac_address.$dirty) {
                return ""
            }else if (!mac_address.required.$response) {
                return "O campo é obrigatório!"
            }else if (!mac_address.maxLength.$response) {
                return "Limite máximo de 17 caracteres!"
            } else {
                return ""
            }
        },
        local_areaErrors() {
            const local_area = this.v$.equipament.local_area
            if (!local_area.$dirty) {
                return ""
            }
            if (!local_area.required.$response) {
                return "O campo é obrigatório!"
            } else {
                return ""
            }
        },
        local_sectorErrors() {
            const local_sector = this.v$.equipament.local_sector
            if (!local_sector.$dirty) {
                return ""
            }
            if (!local_sector.required.$response) {
                return "O campo é obrigatório!"
            } else {
                return ""
            }
        },
        mode_operationErrors() {
            const mode_operation = this.v$.equipament.mode_operation
            if (!mode_operation.$dirty) {
                return ""
            }if (!mode_operation.required.$response) {
                return "O campo é obrigatório!"
            }else if (!mode_operation.maxLength.$response) {
                return "Limite máximo de 30 caracteres!"
            } else {
                return ""
            }
        },
        type_equipamentErrors() {
            const mode_operation = this.v$.equipament.mode_operation
            if (!mode_operation.$dirty) {
                return ""
            }
            if (!mode_operation.maxLength.$response) {
                return "Limite máximo de 500 caracteres!"
            } else {
                return ""
            }
        },
        sequencyErrors() {
            const sequency = this.v$.equipament.sequency
            if (!sequency.$dirty) {
                return ""
            }if (!sequency.required.$response) {
                return "O campo é obrigatório!"
            }else if (!sequency.maxLength.$response) {
                return "Limite máximo de 15 caracteres!"
            } else {
                return ""
            }
        },
    },
    methods: {
        loadsearch() {
            if (this.company_unit_selected > 0) {
                this.loadequipaments_unit();
            } else if (this.company_selected > 0) {
                this.loadsequipament()
            }
        },
        updateHandler(valor = 0) {

            this.page = valor

            if (this.company_unit_selected > 0) {
                this.loadequipaments_unit();
            } else if (this.company_selected > 0) {
                this.loadsequipament(1)
            }
        },
        loadcompanies() {

            this.load = true

            const access_credential = this.$cookie.getCookie(userKey)

            var params = {
                page: this.page,
                search: this.search,
                company_id: access_credential.company_id,
                code: access_credential.credential_code,
                menu: 'geral'
            }

            var method = 'post'
            var rotas = 'company-list-all-active'

            const access_token = this.$cookie.getCookie(userKey)

            const config = {
                headers: {
                    Authorization: `Bearer ${access_token.token}`
                }
            };

            var that = this

            axios[method](`${baseApiUrl}/${rotas}`, params, config, {
                timeout: 5000
            }).then(res => {

                this.company_selected = res.data.companies_list_active[0].id

                this.load = false

                that.loadsequipament()

            }).catch((e) => {

                this.load = false

                if (e.code === 'ECONNABORTED') {
                    this.load = false
                }
            });
        },
        loadsequipament(valor = 0) {

            this.load = true

            const access_token = this.$cookie.getCookie(userKey)

            if(valor != 1 || valor == 0){
                this.page = 1
            }

            var method = 'post'
            var rotas = 'equipament-dependency-company'

            var params = {
                page: this.page,
                search: this.search,
                company_id: access_token.company_id,
                credential_code: access_token.credential_code
            }

            const config = {
                headers: {
                    Authorization: `Bearer ${access_token.token}`
                }
            };

            axios[method](`${baseApiUrl}/${rotas}`, params, config, {
                timeout: 5000
            }).then(res => {

                this.view_add_button = false

                this.company_units = ''

                this.company_units = res.data.company_units

                this.company_unit_selected = '0'

                this.equipaments = ''

                if (res.data.equipaments_list_active == 'vazio') {

                    this.equipaments_active = 'vazio'
                    this.paginate_view_a = false

                    this.load = false
                } else {

                    this.total_page_a = res.data.equipaments_list_active.last_page
                    this.current_page_a = res.data.equipaments_list_active.current_page
                    this.dados_per_page_a = res.data.equipaments_list_active.data.length

                    this.paginate_view_a = true

                    var equipaments_active = res.data.equipaments_list_active.data

                    var result_active = equipaments_active.map(getList)

                    this.equipaments_active = result_active

                    this.load = false

                }

                if (res.data.equipaments_list_inactive == 'vazio') {
                    this.equipaments_inactive = 'vazio'
                    this.paginate_view_i = false

                    this.load = false
                } else {

                    this.total_page_i = res.data.equipaments_list_inactive.last_page
                    this.current_page_i = res.data.equipaments_list_inactive.current_page
                    this.dados_per_page_i = res.data.equipaments_list_inactive.data.length

                    this.paginate_view_i = true

                    var equipaments_inactive = res.data.equipaments_list_inactive.data

                    var result_inactive = equipaments_inactive.map(getList)

                    this.equipaments_inactive = result_inactive

                    this.load = false

                }
            }).catch((e) => {

                this.load = false

                if (e.code === 'ECONNABORTED') {
                    this.load = false
                }
            });

            function getList(item) {
                return {
                    id: item.id,
                    code: item.code,
                    name: item.name,
                    status: item.status,
                    cost_center: item.cost_center,
                    email: item.email,
                    phone: item.phone,
                    obs: item.obs
                }
            }
        },
        loadequipaments_unit() {

            this.load = true

            const access_token = this.$cookie.getCookie(userKey)

            var method = 'post'
            var rotas = 'equipament-list'

            var params = {
                page: this.page,
                search: this.search,
                company_id: this.company_selected,
                companyunit_id: this.company_unit_selected
            }

            const config = {
                headers: {
                    Authorization: `Bearer ${access_token.token}`
                }
            };

            axios[method](`${baseApiUrl}/${rotas}`, params, config, {
                timeout: 5000
            }).then(res => {

                this.view_add_button = true

                this.equipament_selected = '0'
                this.equipaments = res.data.equipaments

                this.list_area = res.data.list_zone
                this.equipament.local_area = res.data.list_zone[0].id

                if (res.data.list_equipament_active == 'vazio') {

                    this.equipaments_active = 'vazio'
                    this.paginate_view_a = false

                    this.load = false
                } else {

                    console.log(res.data.list_equipament_active)

                    /*this.total_page_a = res.data.list_equipament_active.last_page
                    this.current_page_a = res.data.list_equipament_active.current_page
                    this.dados_per_page_a = res.data.list_equipament_active.data.length

                    this.paginate_view_a = true*/

                    var equipaments_active = res.data.list_equipament_active

                    var result_active = equipaments_active.map(getList)

                    this.equipaments_active = result_active

                    console.log(this.equipaments_active)

                    this.load = false

                }

                if (res.data.list_equipament_inactive == 'vazio') {
                    this.equipaments_inactive = 'vazio'
                    this.paginate_view_i = false

                    this.load = false
                } else {

                   /* this.total_page_i = res.data.equipament_list_inactive.last_page
                    this.current_page_i = res.data.equipament_list_inactive.current_page
                    this.dados_per_page_i = res.data.equipament_list_inactive.data.length*/

                    this.paginate_view_i = true

                    var equipaments_inactive = res.data.list_equipament_inactive

                    var result_inactive = equipaments_inactive.map(getList)

                    this.equipaments_inactive = result_inactive

                    this.load = false

                }

                 this.loadsectors()
            }).catch((e) => {

                this.load = false

                if (e.code === 'ECONNABORTED') {
                    this.load = false
                }
            });

            function getList(item) {
                return {
                    id: item.equipament_id,
                    code: item.equipament_code,
                    name: item.equipament_name,
                    status: item.equipament_status,
                    description: item.equipament_description,
                    ip_acesso_remoto: item.equipament_ip_acesso_remoto,
                    ip_saida: item.equipament_ip_saida,
                    mac_address: item.equipament_mac_address,
                    local_area: item.equipament_local_area,
                    local_sector: item.equipament_local_sector,
                    last_status: item.equipament_last_status,
                    modelo: item.equipament_modelo,
                    mode_operation: item.equipament_mode_operation,
                    type: item.equipament_type,
                    sequency: item.equipament_sequency,
                    zone_name: item.zone_name,
                    sector_name: item.sector_name
                }
            }
        },
        loadequipaments() {

            this.load = true

            var params = {
                page: this.page,
                search: this.search
            }

            var method = 'post'
            var rotas = 'equipament-list-all'

            const access_token = this.$cookie.getCookie(userKey)

            const config = {
                headers: {
                    Authorization: `Bearer ${access_token.token}`
                }
            };

            axios[method](`${baseApiUrl}/${rotas}`, params, config, {
                timeout: 5000
            }).then(res => {

                if (res.data.equipaments_list_active == 'vazio') {

                    this.equipaments_active = 'vazio'
                    this.paginate_view_a = false
                    this.load = false
                } else {

                    this.total_page_a = res.data.equipaments_list_active.last_page
                    this.current_page_a = res.data.equipaments_list_active.current_page
                    this.dados_per_page_a = res.data.equipaments_list_active.data.length

                    this.paginate_view_a = true

                    var equipaments_active = res.data.equipaments_list_active.data

                    var result_active = equipaments_active.map(getList)

                    this.equipaments_active = result_active

                    this.load = false

                }

                if (res.data.equipaments_list_inactive == 'vazio') {
                    this.equipaments_inactive = 'vazio'
                    this.paginate_view_i = false
                    this.load = false
                } else {

                    this.total_page_i = res.data.equipaments_list_inactive.last_page
                    this.current_page_i = res.data.equipaments_list_inactive.current_page
                    this.dados_per_page_i = res.data.equipaments_list_inactive.data.length

                    this.paginate_view_i = true

                    var equipaments_inactive = res.data.equipaments_list_inactive.data

                    var result_inactive = equipaments_inactive.map(getList)

                    this.equipaments_inactive = result_inactive

                    this.load = false

                }
            }).catch((e) => {

                if (e.code === 'ECONNABORTED') {
                    this.load = false
                }
            });

            function getList(item) {
                return {
                    id: item.id,
                    code: item.code,
                    name: item.name,
                    description: item.description,
                    status: item.status,
                    cost_center: item.cost_center,
                    email: item.email,
                    phone: item.phone,
                    obs: item.obs
                }
            }
        },
        loadsectors(){

            this.load = true

            const access_credential = this.$cookie.getCookie(userKey)

            var params = {
                zone_id: this.equipament.local_area
            }

            console.log(params)

            var method = 'post'
            var rotas = 'user-dependency-zone-sector'


            const config = {
                headers: {
                    Authorization: `Bearer ${access_credential.token}`
                }
            };

            axios[method](`${baseApiUrl}/${rotas}`, params, config, {
                timeout: 5000
            }).then(res => {

                this.list_setor = res.data.sector_zone

                this.equipament.local_sector = res.data.sector_zone[0].id

                this.load = false

            }).catch((e) => {

                if (e.code === 'ECONNABORTED') {
                    this.load = false
                }
            });
        },
        order(iten) {

            var list = Object.values(iten)

            list = list.slice(1, 4)

            return list;
        },
        reset(mode = 'add') {

            this.mode = 'add'
            this.equipament = {}
            this.equipament.status = this.status['0']
            this.v$.$reset();

            if (mode != 'info' && mode != 'cancelar') {
                this.loadequipaments_unit()
            }
        },
        save() {

            this.load = true

            if (this.v$.$invalid) {

                this.$toast.error('Formulário alterado!')

                this.load = false

            } else {

                const method = 'post'

                var rotas = this.equipament.id ? 'equipament-update' : 'equipament-create'

                const access_token = this.$cookie.getCookie(userKey)

                const config = {
                    headers: {
                        "Accept": "application/json",
                        "Authorization": `Bearer ${access_token.token}`
                    }
                }

                this.equipament.company_id = this.company_selected
                this.equipament.companyunit_id = this.company_unit_selected

                axios[method](`${baseApiUrl}/${rotas}`, this.equipament, config, {
                    timeout: 5000
                }).then(res => {

                    console.log(res.data)

                    this.load = false

                    if (res.data.code == '2000') {
                        this.$toast.success(res.data.message)
                    } else if (res.data.code == '3000') {
                        this.$toast.success(res.data.message)
                    } else {
                        this.$toast.success(res.data.message)
                    }

                    this.reset()
                }).catch((e) => {

                    this.load = false

                    showError(e)

                    if (e.code === 'ECONNABORTED') {
                        this.load = false
                    }
                });
            }
        },
        loadequipament(equipament, mode = 'add') {

            if (mode === 'delete') {
                this.larger = ''
            } else {
                this.larger = 'modal-lg'
            }

            this.mode = mode

            this.equipament = {
                ...equipament
            }
        },
        remove() {

            this.load = true

            if (this.dados_per_page_a == 1 || this.dados_per_page_i == 1) {
                this.page -= 1
            }

            var method = 'post'
            var rotas = 'equipament-delete'

            const access_token = this.$cookie.getCookie(userKey)

            const config = {
                headers: {
                    'Accept': "application/json",
                    Authorization: `Bearer ${access_token.token}`
                }
            }

            axios[method](`${baseApiUrl}/${rotas}`, this.equipament, config, {
                timeout: 5000
            }).then(res => {

                this.load = false

                if (res.data.code == '4000') {
                    this.$toast.success(res.data.message)
                } else {
                    this.$toast.error(res.data.message)
                }

                this.reset()

            }).catch((e) => {

                showError(e)

                if (e.code === 'ECONNABORTED') {
                    this.load = false
                }
            });
        }
    }
}
</script>

<style scoped>
.v-popper--theme-info-tooltip .v-popper__inner {
    background: #2a2828 !important;
    color: #e51f1f !important;
}

.paginate {
    display: flex;
    justify-content: center;
}

/* ------------------------------------ Filtros de pesquisa ---------------------------------------*/
.cards .select_filter {
    margin-bottom: 15px;
}

/*-------------------------------------- Cabeçalho Companies --------------------------------------*/
.equipament {
    margin: 25px;
}

.equipament .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

/* -------------- Cabeçalho ------------------*/
.search .input-search {
    border-radius: 3px;
    border-color: #744cbc;
}

.search .input-search:focus,
.btn-search {
    box-shadow: 0 0 0 0 !important;
    outline: none !important;
}

.search .icon-search {
    color: #ffffff;
    transform: rotate(90deg);
}

.search .btn-search {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-color: #744cbc;
}

.search .btn-search:hover {
    background-color: #886aea !important;
}

/*--------------------------------------------- Table ---------------------------------------------*/
.equipament .cards .tables thead tr th {
    border-style: solid;
    border-bottom-width: 2px;
    color: rgb(114, 132, 140);
    border-bottom-color: rgb(222, 226, 230) !important;
}

.equipament .cards .tables thead th {
    font-size: 15px;
    font-weight: 600;
    padding-top: 10px;
    padding-bottom: 10px;
}

.equipament .cards .tables tbody td {
    font-size: 14px;
    padding-top: 4px;
    padding-bottom: 4px;
    line-height: 30px;
    color: #72848c;
}

.equipament .cards .tables tbody button {
    color: #ffffff;
    margin: 0 2px;
    padding: 2px 5px;
    box-shadow: 0 0 0 0 !important;
    outline: none !important;
}

.equipament .cards .pagination {
    display: flex;
    justify-content: center;
}

/*------------------------------------------- Paginação ------------------------------------------ */
.pagination-table {
    margin-top: 30px;
}

.pagination-table .page-item .page-link {
    color: #72848c;
    text-decoration: none;
    border: 1px solid #dee2e6;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    cursor: pointer;
}

.pagination-table .page-item.active .page-link {
    color: #ffffff;
    background-color: #744cbc;
    border-color: #744cbc;
}

/*--------------------------------------------- Modal ---------------------------------------------*/
.equipament .modal-content {
    border: none;
}

/*------------------------------------------- Modal Header ----------------------------------------*/
.equipament .modal-header {
    padding: 10px 20px;
    background-color: #744cbc;
}

.equipament .modal-header .modal-title {
    color: #ffffff;
    font-size: 18px;
}

/*-------------------------------------------- Modal Body -----------------------------------------*/
.equipament .modal-body .msg {
    text-align: center;
    color: #72848c;
}

.equipament .modal-body .msg h6 {
    padding: 20px 0px 12px 0px;
    font-size: 17px;
}

.equipament .modal-body label {
    font-size: 15px;
    color: #72848c;
    margin-top: 10px;
    margin-left: 1px;
    margin-bottom: 5px;
}

/*------------------------------------------- Modal Footer ----------------------------------------*/
.equipament .modal-footer {
    padding: 8px;
}

.equipament .modal-footer button {
    border: none;
}

/*----------------------------------------- Mensagem De Erro --------------------------------------*/
.msg_error {
    color: #ff0000;
    font-size: 14px;
}

input,
input:focus,
.is-invalid {
    box-shadow: 0 0 0 0;
    outline: 0;
}

.is-invalid {
    box-shadow: 0 0 0 0 !important;
    outline: 0 !important;
}

.dados_empty {
    color: #72848c;
    font-weight: 600;

}

.loading button {
    position: absolute;
    top: calc(50% - 35px);
    left: calc(50%);
    margin-left: 90px;
    width: 70px;
    height: 70px;
    background-color: #3b0253;
    border: none;
}

.loading span {
    width: 30px;
    height: 30px;
}
</style>
