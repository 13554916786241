<template>
<div class="card card-style" :style="{ background: background }">
    <div class="card-body card_body">
        <div>
            <p class="title-label" :style="{ color: color }">{{title}}</p>
        </div>
        <div class="table">
            <table class="table align-items-center">
                <tbody>
                    <tr class="first">
                        <td>
                            <i class="fa fa-circle mr-2" style="color: #14abef;"></i> Capacidade
                            <div class="progress">
                                <div class="bg-info progress-bar bg-success" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </td>
                        <td>{{quantity_total}}</td>
                    </tr>
                    <tr>
                        <td>
                            <i class="fa fa-circle mr-2" style="color: #02ba5a;"></i> Carga
                        </td>
                        <td>300</td>
                    </tr>
                    <tr>
                        <td>
                            <i class="fa fa-circle mr-2" style="color: #d13adf;"></i> Saldo
                        </td>
                        <td>200</td>
                    </tr>
                    <tr class="last">
                        <td>
                            <i class="fa fa-circle mr-2" style="color: #fba540;"></i> Entregues
                        </td>
                        <td>100</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="circle"></div>
</div>
</template>

<script>
export default {
    name: "cardP",
    props: ["title", "quantity_total", "color", "background"],
}
</script>

<style scoped>
/*.azulgradient {
    background: #4facfe;
    background: linear-gradient(45deg, #4facfe, #00f2fe) !important;
}*/
.card {
    height: 353px;
}

.card-style {
    position: relative;
    border: none;
    box-shadow: 0 5px 15px rgb(57 98 254 / 2%), 0 3px 6px rgb(117 108 254 / 12%);
    border: none;
    background-color: #fff;
    margin-bottom: 10px;
    /*background: #11998e;*/
    /*background: linear-gradient(45deg, #11998e, #38ef7d) !important;
    margin-bottom: 25px;
    box-shadow: 0 5px 15px rgb(57 98 254 / 2%), 0 3px 6px rgb(117 108 254 / 12%);*/
}

.card_body {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: center;
    overflow: hidden;
    padding-top: 5px !important;
}

.title-label {
    text-align: center;
    margin-top: 0;
    margin-top: 15px;
    margin-bottom: 45px;
    font-weight: 500;
    font-size: 18px
}

.result {
    color: #fff;
    font-size: 18px;
    line-height: 22px;
    font-weight: 500;
    text-align: left;
    margin-top: 0;
    margin-bottom: 10px;
}

.card_icon {
    color: #fff;
    font-size: 35px;
}

td {
    padding: 20px 0px !important;
}

.first {
    border-top: 1px solid #dee2e6;
}

.last {
    border-bottom: 1px solid transparent;
}
</style>
