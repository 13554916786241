<template>
<div class="user">
    <div class="header">
        <Labels nome="Uploads" cor="#72848c" size="25px" />
    </div>
    <div class="row">
        <div class="cards">
            <Card title="Upload de justificativas" color="#72848c" colorbackground="#ffffff" fontSize="18px" text_tooltip="Adicionar Usuário" view_select="false" :view_add="upload_justification_add" :view_active="justification_add">
                <template v-slot:content class="tabs">
                    <div class="row">
                        <div class="col-md-3">
                            <select class="form-select form-select-sm select_filter" v-model="company_selected" @change="loadunits">
                                <option value="0" selected disabled>Empresa</option>
                                <option v-for="(company_list, index) in companies_list" :value="company_list.id" :key="index">{{company_list.fantasy_name}}</option>
                            </select>
                        </div>
                        <div class="col-md-3">
                            <select class="form-select form-select-sm select_filter" v-model="unit_selected">
                                <option value="0" selected disabled>Unidade</option>
                                <option v-for="(unit_list, index) in units_list" :value="unit_list.id" :key="index">{{unit_list.fantasy_name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="input-group">
                        <input type="file" class="form-control" id="file" ref="file" aria-describedby="file" aria-label="Upload" v-on:change="handleFileUpload()">
                        <button class="btn btn-outline-secondary" v-on:click="submitFile()" type="button" id="file">Enviar</button>
                    </div>
                </template>
            </Card>
        </div>
    </div>
</div>
</template>

<script>
import {
    baseApiUrl,
    userKey
} from '@/global'

import axios from 'axios'
import Labels from "@/components/Labels/Label"
import Card from "@/components/Cards/Card"

export default {
    name: "user",
    data: function () {
        return {
            load: false,
            upload_justification_add: false,
            justification_add: false,
            companies_list: '',
            company_selected: '0',
            units_list: '',
            unit_selected: '0',
            file: ''
        }
    },
    components: {
        Labels,
        Card,
    },
    mounted() {

      this.loadcompanies();
    },
    methods: {
        loadcompanies() {

            this.load = true

            const access_credential = this.$cookie.getCookie(userKey)

            var params = {
                company_id: access_credential.company_id,
                code: access_credential.credential_code,
                menu: 'upload'
            }

            var method = 'post'
            var rotas = 'company-list-all-active'

            const access_token = this.$cookie.getCookie(userKey)

            const config = {
                headers: {
                    Authorization: `Bearer ${access_token.token}`
                }
            };

            axios[method](`${baseApiUrl}/${rotas}`, params, config, {
                timeout: 5000
            }).then(res => {

                console.log(res.data)

                this.companies_list = res.data.companies_list_active

                this.load = false

            }).catch((e) => {

                if (e.code === 'ECONNABORTED') {
                    this.load = false
                }
            });
        },
        loadunits() {

            this.load = true

            const access_token = this.$cookie.getCookie(userKey)

            this.unit_selected = '0'                                    // Coloca zero para que a unidade seja resetada ao escolher outra empresa

            var params = {
                company_id: access_token.company_id,
            }

            var method = 'post'
            var rotas = 'list-unit-company-id'

            
            const config = {
                headers: {
                    Authorization: `Bearer ${access_token.token}`
                }
            };

            axios[method](`${baseApiUrl}/${rotas}`, params, config, {
                timeout: 5000
            }).then(res => {

                console.log(res.data)

                this.units_list = res.data.units_list_active

                this.load = false

            }).catch((e) => {

                if (e.code === 'ECONNABORTED') {
                    this.load = false
                }
            });
        },
        submitFile() {
            let formData = new FormData();

            var method = 'post'
            var rotas = 'upload-justifications'

            if (this.company_selected == 0) {

                this.$toast.error('Selecione uma empresa!', {
                    duration: 2000
                })

                return;

            } else if(this.unit_selected == 0) {

                this.$toast.error('Selecione uma unidade!', {
                    duration: 2000
                })

                return;
            }else if(this.file == ''){
                this.$toast.error('Selecione um arquivo!', {
                    duration: 2000
                })

                return;
            }

            formData.append('company_id', this.company_selected);
            formData.append('company_unit_id', this.unit_selected);
            formData.append('file', this.file);

            const access_token = this.$cookie.getCookie(userKey)

            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${access_token.token}`
                }
            };

            axios[method](`${baseApiUrl}/${rotas}`,
                    formData, config, {
                        timeout: 5000
                    }).then(res => {

                    console.log(res.data)

                    document.getElementById('file').value='';
                    this.file = ''

                    this.$toast.success(res.data.message,{
                        duration: 2000
                    })

                })
                .catch(function () {
                    console.log('FAILURE!!');
                });
        },
        handleFileUpload() {
            this.file = this.$refs.file.files[0];
        },
        updateHandler(valor) {

            this.page = valor

            this.loadusers();
        }
    }
}
</script>

<style scoped>
.paginate {
    display: flex;
    justify-content: center;
}

/* ------------------------------------ Filtros de pesquisa ---------------------------------------*/
.cards .select_filter {
    margin-bottom: 15px;
}

/*-------------------------------------- Cabeçalho Companies --------------------------------------*/
.user {
    margin: 25px;
}

.user .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

/* -------------- Cabeçalho ------------------*/
.search .input-search {
    border-radius: 3px;
    border-color: #744cbc;
}

.search .input-search:focus,
.btn-search {
    box-shadow: 0 0 0 0 !important;
    outline: none !important;
}

.search .icon-search {
    color: #ffffff;
    transform: rotate(90deg);
}

.search .btn-search {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-color: #744cbc;
}

.search .btn-search:hover {
    background-color: #886aea !important;
}

/*--------------------------------------------- Table ---------------------------------------------*/
.user .cards .tables thead tr th {
    border-style: solid;
    border-bottom-width: 2px;
    color: rgb(114, 132, 140);
    border-bottom-color: rgb(222, 226, 230) !important;
}

.user .cards .tables thead th {
    font-size: 15px;
    font-weight: 600;
    padding-top: 10px;
    padding-bottom: 10px;
}

.user .cards .tables tbody td {
    font-size: 14px;
    padding-top: 11px;
    padding-bottom: 11px;
    color: #72848c;
}

.user .cards .tables tbody button {
    color: #ffffff;
    margin: 0 2px;
    box-shadow: 0 0 0 0 !important;
    outline: none !important;
}

.user .cards .pagination {
    display: flex;
    justify-content: center;
}

/*------------------------------------------- Paginação ------------------------------------------ */
.pagination-table {
    margin-top: 30px;
}

.pagination-table .page-item .page-link {
    color: #72848c;
    text-decoration: none;
    border: 1px solid #dee2e6;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    cursor: pointer;
}

.pagination-table .page-item.active .page-link {
    color: #ffffff;
    background-color: #744cbc;
    border-color: #744cbc;
}

/*--------------------------------------------- Modal ---------------------------------------------*/
.user .modal-content {
    border: none;
}

/*------------------------------------------- Modal Header ----------------------------------------*/
.user .modal-header {
    padding: 10px 20px;
    background-color: #744cbc;
}

.user .modal-header .modal-title {
    color: #ffffff;
    font-size: 18px;
}

/*-------------------------------------------- Modal Body -----------------------------------------*/
.user .modal-body .msg {
    text-align: center;
    color: #72848c;
}

.user .modal-body .msg h6 {
    padding: 20px 0px 12px 0px;
    font-size: 17px;
}

.user .modal-body label {
    font-size: 15px;
    color: #72848c;
    margin-top: 10px;
    margin-left: 1px;
    margin-bottom: 5px;
}

/*------------------------------------------- Modal Footer ----------------------------------------*/
.user .modal-footer {
    padding: 8px;
}

.user .modal-footer button {
    border: none;
}

/*----------------------------------------- Mensagem De Erro --------------------------------------*/
.msg_error {
    color: #ff0000;
    font-size: 14px;
}

input,
input:focus,
.is-invalid {
    box-shadow: 0 0 0 0;
    outline: 0;
}

.is-invalid {
    box-shadow: 0 0 0 0 !important;
    outline: 0 !important;
}

.dados_empty {
    color: #72848c;
    font-weight: 600;

}

.loading button {
    position: absolute;
    top: calc(50% - 35px);
    left: calc(50%);
    margin-left: 90px;
    width: 70px;
    height: 70px;
    background-color: #3b0253;
    border: none;
}

.loading span {
    width: 30px;
    height: 30px;
}
</style>
