<template>
<div class="limiter">
    <div class="container-fluid">
        <div class="wrap-login">
            <span class="login-title">
                Centric
            </span>
            <span class="login-icon" :class="{ spacebottom: !msg_status }">
                <i class="fab fa-cuttlefish"></i>
            </span>
            <p v-show="msg_status" class="msg">{{msg}}</p>
            <form class="login-form">
                <div class="wrap-input">
                    <input autocomplete="off" type="text" class="form-control input-login" placeholder=" " v-model="auth.email">
                    <label class="label-input-login">Usuário</label>
                    <div v-if="v$.auth.email.$error" class="msg_error">{{emailErrors}}</div>
                </div>
                <div class="wrap-input">
                    <span v-if="passview == true" v-on:click="viewpass" class="btn-show-pass" :class="{ center: v$.auth.password.$error }">
                        <i class="fas fa-eye-slash"></i>
                    </span>
                    <span v-if="passview == false" v-on:click="viewpass" class="btn-show-pass">
                        <i class="fas fa-eye"></i>
                    </span>
                    <input autocomplete="off" type="password" class="form-control input-login" id="input-password" placeholder=" " v-model="auth.password">
                    <label class="label-input-login">Senha</label>
                    <div v-if="v$.auth.password.$error" class="msg_error">{{passwordErrors}}</div>
                </div>
                <div class="container-form-btn">
                    <div class="wrap-form-btn">
                        <div class="login-form-bgbtn"></div>
                        <button :disabled="v$.$invalid" @click="authentication" type="button" class="login-form-btn">Entrar</button>
                    </div>
                </div>
                <div class="text-center">
                    <a @click="resetPassword" class="remember">
                        Recuperar dados de acesso
                    </a>
                </div>
            </form>
            <div class="col-md-12">
                <div v-if="load" class="loading">
                    <button class="btn btn-primary" type="button" disabled>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        <span class="visually-hidden">Loading...</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import {
    required,
    email
} from '@vuelidate/validators'
import {
    baseApiUrl,
    userKey
} from '@/global'
import axios from 'axios'

export default {
    name: "login",
    data: function () {
        return {
            v$: useVuelidate(),
            passview: true,
            auth: {},
            msg: '',
            msg_status: false,
            msg_error: '',
            load: false,
        }
    },
    validations() {
        return {
            auth: {
                email: {
                    required,
                    email,
                    $autoDirty: true
                },
                password: {
                    required,
                    $autoDirty: true
                }
            }
        }
    },
    computed: {
        emailErrors() {
            const email = this.v$.auth.email
            if (!email.$dirty) {
                return ""
            }
            if (!email.required.$response) {
                return "O campo é obrigatório!"
            } else if (!email.email.$response) {
                return "Formato incorreto da informação!"
            } else {
                return ""
            }
        },
        passwordErrors() {
            const password = this.v$.auth.password
            if (!password.$dirty) {
                return ""
            }
            if (!password.required.$response) {
                return "O campo é obrigatório!"
            } else {
                return ""
            }
        },
    },
    methods: {
        viewpass() {

            let input = document.querySelector('#input-password');

            if (input.getAttribute('type') == 'password') {
                input.setAttribute('type', 'text');
                this.passview = false
            } else {
                input.setAttribute('type', 'password');
                this.passview = true
            }
        },
        authentication() {

            if (this.v$.$invalid) {

                this.msg = 'Formulário Alterado!'
                this.msg_status = true

                this.load = false

            } else {
                const method = 'post'
                const rota = 'validate'

                const config = {
                    headers: {
                        "Accept": "application/json"
                    }
                }

                let that = this

                this.load = true

                axios[method](`${baseApiUrl}/${rota}`, this.auth, config, {
                        timeout: 5000
                    }).then(res => {

                        console.log(res)

                        var first_access = res.data.user.redefinir_password

                        if (first_access == 0) {

                            this.$cookie.setCookie('id_user', {user_id: res.data.user.id, email: this.auth.email})

                            this.$router.push({
                                path: '/RedefinirPassword'
                            })
                        } else if(first_access == 1){
                            console.log(res.data)

                            var name = res.data.user.name

                            var name_user = name.split(' ').slice(0, 1).join(' ');

                            this.msg_status = false
                            this.load = false

                            var user = {
                                token: res.data.user.token,
                                company_id: res.data.user.company_id,
                                credential_code: res.data.user.credential_code,
                                permissions: res.data.user.permissions[0].role_c_id,
                                name: name_user
                            }

                            this.$store.commit('setUser', user)

                            this.$cookie.setCookie(userKey, user, {
                                expire: 'Infinity'
                            })

                            //const access = this.$cookie.getCookie(userKey)

                            const userLogado = this.$store.getters.userLogado

                            if (userLogado) {
                                this.$router.push({
                                    path: '/System'
                                })
                            }
                        }
                    })
                    .catch(function (error) {

                        //console.log(error.response)
                        that.load = false

                        if (error.response) {

                            switch (error.response.status) {
                                case 401:
                                    that.msg = "Usuário ou senha incorretos!"
                                    that.msg_status = true
                                    break
                            }
                        } 

                        /*that.load = false

                        if (error.message == 'Network Error') {
                            that.msg = "Falha na rede!"
                            that.msg_status = true
                            //console.log("Servidor indisponível - Entre em contato com o administrador!")
                        }

                        if (error.response) {

                            switch (error.response.status) {
                                case 401:
                                    that.msg = "Usuário ou senha incorretos!"
                                    that.msg_status = true
                                    break
                            }
                        } else if (error.request) {

                            that.msg = "Falha na rede!"
                            that.msg_status = true
                            //console.clear()
                            //console.log(error.message);
                            console.log('erro de requisição!')
                        } else {

                            that.msg = "Entre em contato com o administrador!"
                            that.msg_status = true
                        }*/

                        /*if (error.response) {

                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);
                            console.log('erro de resposta!')
                        }else if (error.request) {

                            console.log(error.message);
                            console.log('erro de requisição!')
                        }else {

                            console.log('Error', error.message);
                            console.log('erro de geral!')
                        }*/
                    });
            }
        },
        resetPassword(){
            this.$router.push({
                path: '/RedefinirPasswordSendEmail'
            })
        }
    }
}
</script>

<style scoped>
/*--- Fonts -------------------------------------------------------------------------------------*/
@font-face {
    font-family: Poppins-Regular;
    src: url('../../../assets/Fonts/poppins/Poppins-Regular.ttf');
}

@font-face {
    font-family: Poppins-Medium;
    src: url('../../../assets/Fonts/poppins/Poppins-Medium.ttf');
}

@font-face {
    font-family: Poppins-Bold;
    src: url('../../../assets/Fonts/poppins/Poppins-Bold.ttf');
}

/*--- Page --------------------------------------------------------------------------------------*/
* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

body,
html {
    height: 100%;
    font-family: Poppins-Regular, sans-serif;
}

/*--- Padronização do browser -------------------------------------------------------------------*/
.limiter {
    width: 100%;
    margin: 0 auto;
}

/*--- Container main ----------------------------------------------------------------------------*/
.container-fluid {
    width: 100%;
    min-height: 100vh;
    padding: 0px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background: #f2f2f2;
}

/*--- Panel -------------------------------------------------------------------------------------*/
.wrap-login {
    position: relative;
    width: 390px;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    padding: 77px 45px 20px 45px;

    box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
    -o-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
    -ms-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
}

/*--- Title -------------------------------------------------------------------------------------*/
.wrap-login .login-title {
    display: block;
    font-family: Poppins-Bold;
    font-size: 30px;
    color: #823AF3;
    line-height: 1.2;
    text-align: center;
    padding-bottom: 26px;
}

/*--- Logo- -------------------------------------------------------------------------------------*/
.wrap-login .login-icon {
    display: block;
    font-family: Poppins-Bold;
    font-size: 30px;
    color: #333333;
    line-height: 1.2;
    text-align: center;

}

.wrap-login .spacebottom {
    padding-bottom: 48px;
}

.wrap-login .login-icon i {
    font-size: 40px;
    color: #f2f2f2;
    padding: 7px 8px;
    background: -o-linear-gradient(45deg, #00F2C4 5%, #3879DA 50%, #823AF3 100%);
    background: -moz-linear-gradient(45deg, #00F2C4 5%, #3879DA 50%, #823AF3 100%);
    background: -webkit-linear-gradient(315deg, #00F2C4 5%, #3879DA 50%, #823AF3 100%);
    border-radius: 5px;
}

.msg {
    text-align: center;
    color: red;
    font-weight: 600;
    font-size: 17px;
    padding: 8px 0px 15px 0px;
}

.msg_error{
    color:red;
    font-weight: 600;
}

/*--- Form --------------------------------------------------------------------------------------*/
.wrap-login .login-form {
    width: 100%;
}

/*--- Input -------------------------------------------------------------------------------------*/
.login-form .wrap-input {
    width: 100%;
    position: relative;
    margin-bottom: 37px;
}

.login-form .wrap-input .input-login {
    font-size: 15px;
    color: #555555;
    line-height: 6.2;
    display: block;
    width: 100%;
    height: 45px;
    background-color: transparent;
    padding: 2px 5px 0 5px;
}

.login-form .wrap-input .input-login:focus {
    outline: 0 !important;
    box-shadow: none !important;
}

.login-form .wrap-input .label-input-login {
    position: absolute;
    display: inline-block;
    height: 100%;
    top: 12px;
    left: 10px;
    pointer-events: none;
    color: #999999;

    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}

.login-form .wrap-input .input-login:focus+label {
    top: -12px;
    left: 10px;
    background-color: #ffffff;
    padding-left: 2px;
    padding-right: 2px;
    display: inline;
    width: auto;
    height: auto;

    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}

.login-form .wrap-input input:focus+label,
.login-form .wrap-input input:not(:placeholder-shown)+label {
    top: -12px;
    left: 10px;
    background-color: #ffffff;
    padding-left: 2px;
    padding-right: 2px;
    display: inline;
    width: auto;
    height: auto;

    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}

/*--- Show password -----------------------------------------------------------------------------*/
.btn-show-pass {
    font-size: 18px;
    color: #999999;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    position: absolute;
    height: 100%;
    right: 10px;
    padding-right: 5px;
    cursor: pointer;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}

.center {
    top: -12px;
}

.btn-show-pass:hover {
    color: #6a7dfe;
    color: -webkit-linear-gradient(left, #21d4fd, #b721ff);
    color: -o-linear-gradient(left, #21d4fd, #b721ff);
    color: -moz-linear-gradient(left, #21d4fd, #b721ff);
    color: linear-gradient(left, #21d4fd, #b721ff);
}

.btn-show-pass.active {
    color: #6a7dfe;
    color: -webkit-linear-gradient(left, #21d4fd, #b721ff);
    color: -o-linear-gradient(left, #21d4fd, #b721ff);
    color: -moz-linear-gradient(left, #21d4fd, #b721ff);
    color: linear-gradient(left, #21d4fd, #b721ff);
}

/*--- Button ------------------------------------------------------------------------------------*/
.login-form .container-form-btn {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 13px;
}

.login-form .container-form-btn .wrap-form-btn {
    width: 100%;
    display: block;
    position: relative;
    z-index: 1;
    border-radius: 4px;
    overflow: hidden;
    margin: 0 auto;
}

.login-form .container-form-btn .wrap-form-btn .login-form-bgbtn {
    position: absolute;
    z-index: -1;
    width: 300%;
    height: 100%;
    background: -o-linear-gradient(right, #21d4fd, #b721ff, #21d4fd, #b721ff);
    background: -moz-linear-gradient(right, #21d4fd, #b721ff, #21d4fd, #b721ff);
    background: -webkit-linear-gradient(right, #21d4fd, #b721ff, #21d4fd, #b721ff);
    top: 0;
    left: -100%;

    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}

.login-form .container-form-btn .wrap-form-btn button {
    outline: none !important;
    border: none;
    background: transparent;
}

.login-form .container-form-btn .wrap-form-btn .login-form-btn {
    font-size: 15px;
    color: #fff;
    line-height: 1.2;
    text-transform: uppercase;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    width: 100%;
    height: 45px;
}

.login-form .container-form-btn .wrap-form-btn button:hover {
    cursor: pointer;
}

.login-form .container-form-btn .wrap-form-btn:hover .login-form-bgbtn {
    left: 0;
}

/*--- Pré loader -------------------------------------------------------------------------------*/
.loading button {
    position: absolute;
    top: calc(50% - 35px);
    left: calc(50% - 36px);
    margin-left: 0px;
    width: 70px;
    height: 70px;
    background-color: #3b0253;
    border: none;
}

.loading span {
    width: 30px;
    height: 30px;
}

/*--- Recovery data access ----------------------------------------------------------------------*/
.login-form .text-center {
    padding-top: 90px;
}

.remember {
    font-family: Poppins-Regular;
    font-size: 14px;
    color: #999999;
    line-height: 1.5;
    text-decoration: none;
    cursor:pointer;
    transition: all 0.5s;
}

.remember:hover {
    color: #a64bf4;
    font-size: 15px;

    transition: all 0.5s;
}

/*--- Responsive --------------------------------------------------------------------------------*/
@media (max-width: 500px) {
    .wrap-login {
        width: 270px;
        padding: 50px 25px 25px 25px;
    }

    /*--- Title ---------------------------------------------------------------------------------*/
    .wrap-login .login-title {
        font-size: 27px;
        padding-bottom: 15px;
    }

    /*--- Logo- -------------------------------------------------------------------------------------*/
    .wrap-login .login-icon {
        padding-bottom: 48px;
    }

    .wrap-login .login-icon i {
        font-size: 45px;
    }

    /*--- Input -------------------------------------------------------------------------------------*/
    .login-form .wrap-input {
        margin-bottom: 30px;
    }

    .login-form .wrap-input .input-login {
        font-size: 13px;
        line-height: 1.2;
        height: 37px;
        padding: 3px 5px 0 5px;
    }

    .login-form .wrap-input .label-input-login {
        top: 10px;
    }

    /*--- Button ------------------------------------------------------------------------------------*/
    .login-form .container-form-btn .wrap-form-btn .login-form-btn {
        font-size: 14px;
        height: 37px;
    }

    /*--- Recovery data access ----------------------------------------------------------------------*/
    .remember {
        font-size: 12px;
    }

    .remember:hover {
        font-size: 12px;
        transition: all 0s;
    }
}
</style>
