<template>
<div class="card">
    <div class="card-header" :style="{backgroundColor:colorbackground}">
        <div class="header">
            <div class="template_header">
                <div class="logo">
                    <img src="@/assets/img/logo_technip.png" alt="foto-perfil">
                </div>
                <div class="name_report">
                    <div class="title-card-name" :style="{color:color, fontSize}">{{title}}</div>
                    <hr class="division_1">
                    <div class="title-card-date" :style="{color:color}">Período: {{periodo_inicial}} a {{periodo_final}}</div>
                    <div class="title-card-system-name" :style="{color:color}">{{systemname}}</div>
                </div>
            </div>
            <div class="menu">
                <a v-if="false" class="btn btn-link btn-menu-export" id="button" data-bs-toggle="modal" data-bs-target="#modal_excel" v-tooltip="{content: text_tooltip_excel,theme: 'info-tooltip'}">Exportar</a>
                <a v-if="view_add == true && view_active == true" class="btn btn-link" id="button" data-bs-toggle="modal" data-bs-target="#modal_add" v-tooltip="{content: text_tooltip,theme: 'info-tooltip'}"><i class="fas fa-filter"></i></a>
            </div>
        </div>
    </div>
    <div class="card-body">
        <slot name="content" />
    </div>
</div>
</template>

<script>
export default {
    name: "card",
    props: ["title", "periodo_inicial","periodo_final", "systemname", "color", "colorbackground", "fontSize", "text_tooltip", "text_tooltip_excel","view_select", "view_add", "view_excel","view_active"],
}
</script>

<style scoped>
/*---------------------------------------------- Cabeçalho geral ----------------------------------*/
.template_header {
    display: flex;
}

/*---------------------------------------- Logo da empresa no cabeçalho ---------------------------*/
.template_header .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 20px;
}

.template_header .logo img {
    width: 150px;
    height: 50px;
}

/*--------------------------------- Informações lado direito da logo da empresa -------------------*/
.name_report {
    display: flex;
    flex-direction: column;
}

.card .card-header .title-card-name {
    line-height: 22px;
    font-weight: 500;
    padding: 0px 10px 0px 0px;
}

.card .card-header .template_header .division_1 {
    margin: 5px 0px;
}

.card .card-header .title-card-date {
    font-size: 14px;
    line-height: 20px;
    padding: 0px 10px 0px 0px;
}

.card .card-header .title-card-system-name {
    font-size: 14px;
    line-height: 20px;
    padding: 0px 10px 0px 0px;
}

.card {
    /*margin-bottom: 25px;*/
    border-radius: 8px;
    border: none;
    box-shadow: 0 9px 23px rgba(0, 0, 0, .09), 0 5px 5px rgba(0, 0, 0, .06) !important;
    transition: box-shadow 0.8s cubic-bezier(.25, .8, .25, 1);
    max-height: 78vh;
}

.card .card-header {
    background-color: #fff;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

/*-------------------------------------------- header ---------------------------------------------*/
.card .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/*--------------------------------------------- Menu ----------------------------------------------*/
.card .header .menu i {
    color: #ffffff;
    background-color: #744CBC;
    font-size: 12px;
    border-radius: 10%;
    margin: 0;
    padding: 6px 5px 4px 5px;
    margin-left: 3px;
}

.btn-menu-export{
    background-color: green;
    color:#fff;
    text-decoration: none;
    border-radius: 14px;
    padding: 1px 10px 1px 10px !important;
    margin-right: 10px;
    font-size:14px;
    font-weight: 500;
}

.btn-menu-export:hover{
    color:#fff;
    background-color: green;
}

.card .card-body{
    overflow: auto;
}

/*=====================================================*/
.menu {
    display: flex;
    flex-direction: row;
}

.btn-link {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 10px;
}
</style>
