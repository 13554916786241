<template>
<div class="sizem">
    <div class="header">
        <Labels nome="Tamanho" cor="#72848c" size="25px" />
        <div class="search">
            <div class="input-group">
                <input type="text" class="form-control input-search" placeholder="pesquisa" v-model="search">
                <span class="input-group-btn">
                    <button type="button" class="btn btn-default btn-search" v-on:click="loadsearch"><i class="fas fa-search icon-search"></i></button>
                </span>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="cards">
            <Card title="Tamanhos Cadastrados" color="#72848c" colorbackground="#ffffff" fontSize="18px" text_tooltip="Adicionar tamanho" :view_add="size_add" :view_active="view_add_button">
                <template v-slot:content class="tabs">
                    <div class="row">
                        <div v-if="false" class="col-md-3">
                            <select class="form-select form-select-sm select_filter" id="status" v-model="company_selected" @change="loadsizems">
                                <option value="0" selected disabled>Selecione uma empresa</option>
                                <option v-for="(company_list, index) in companies_list" :value="company_list.id" :key="index">{{company_list.fantasy_name}}</option>
                            </select>
                        </div>
                        <div v-if="company_units" class="col-md-3">
                            <select class="form-select form-select-sm select_filter" id="status" v-model="company_unit_selected" @change="loadsizes_unit">
                                <option value="0" selected disabled>Selecione uma unidade</option>
                                <option v-for="(company_unit, index) in company_units" :value="company_unit.id" :key="index">{{company_unit.fantasy_name}}</option>
                            </select>
                        </div>
                    </div>
                    <Tab name_tab1="Ativo" name_tab2="Inativo">
                        <template v-slot:Ativo>
                            <div v-if="sizems_active == 'vazio'" class="dados_empty">
                                <p>Não há dados a serem exibidos!</p>
                            </div>
                            <div v-if="sizems_active != 'vazio' && sizems_active != 'nulo'" class="tables">
                                <table class="table table-hover">
                                    <thead>
                                        <tr>
                                            <th>Código</th>
                                            <th>Tamanho</th>
                                            <th>Status</th>
                                            <th v-if="size_info || size_edit || size_delete">Controle</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(iten, index) in sizems_active" :key="index">
                                            <td v-for="(i, index) in order(iten)" :key="index">{{i}}</td>
                                            <td v-if="size_info || size_edit || size_delete">
                                                <div class="controls">
                                                    <button v-if="size_info" @click="loadsizem(iten, 'info')" type="button" class="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#modal_delete">Detalhes</button>
                                                    <button v-if="size_edit" @click="loadsizem(iten, 'update')" type="button" class="btn btn-info btn-sm" data-bs-toggle="modal" data-bs-target="#modal_add">Editar</button>
                                                    <button v-if="size_delete" @click="loadsizem(iten, 'delete')" type="button" class="btn btn-danger btn-sm" data-bs-toggle="modal" data-bs-target="#modal_delete">Excluir</button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div v-if="paginate_view_a" class="paginate">
                                <v-pagination v-model="current_page_a" :pages="total_page_a" :range-size="1" active-color="#744CBC" @update:modelValue="updateHandler" />
                            </div>
                        </template>
                        <template v-slot:Inativo>
                            <div v-if="sizems_inactive == 'vazio'" class="dados_empty">
                                <p>Não há dados a serem exibidos!</p>
                            </div>
                            <div v-if="sizems_inactive != 'vazio' && sizems_inactive != 'nulo'" class="tables">
                                <table class="table table-hover">
                                    <thead>
                                        <tr>
                                            <th>Código</th>
                                            <th>Tamanho</th>
                                            <th>Status</th>
                                            <th v-if="size_info || size_edit || size_delete">Controle</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(iten, index) in sizems_inactive" :key="index">
                                            <td v-for="(i, index) in order(iten)" :key="index">{{i}}</td>
                                            <td v-if="size_info || size_edit || size_delete">
                                                <div class="controls">
                                                    <button v-if="size_info" @click="loadsizem(iten, 'info')" type="button" class="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#modal_delete">Detalhes</button>
                                                    <button v-if="size_edit" @click="loadsizem(iten, 'update')" type="button" class="btn btn-info btn-sm" data-bs-toggle="modal" data-bs-target="#modal_add">Editar</button>
                                                    <button v-if="size_delete" @click="loadsizem(iten, 'delete')" type="button" class="btn btn-danger btn-sm" data-bs-toggle="modal" data-bs-target="#modal_delete">Excluir</button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div v-if="paginate_view_i" class="paginate">
                                <v-pagination v-model="current_page_i" :pages="total_page_i" :range-size="1" active-color="#744CBC" @update:modelValue="updateHandler" />
                            </div>
                        </template>
                    </Tab>
                </template>
            </Card>
        </div>
        <div class="col-md-12">
            <div v-if="load" class="loading">
                <button class="btn btn-primary" type="button" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    <span class="visually-hidden">Loading...</span>
                </button>
            </div>
        </div>
    </div>
    <div class="modal fade" id="modal_add" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 v-if="mode === 'add'" class="modal-title" id="exampleModalLabel">Cadastrar Tamanho</h5>
                    <h5 v-if="mode === 'update'" class="modal-title" id="exampleModalLabel">Atualizar Tamanho</h5>
                </div>
                <div class="modal-body">
                    <form>
                        <div class="row">
                            <input id="sizem-id" type="hidden" v-model="sizem.id" />
                            <div class="col-md-4">
                                <label for="code" class="form-label">Código</label>
                                <input autocomplete="off" type="text" class="form-control" :class="{'is-invalid': v$.sizem.code.$error}" id="code" v-model="sizem.code" maxlength="31">
                                <div v-if="v$.sizem.code.$error" class="msg_error">{{codeErrors}}</div>
                            </div>
                            <div class="col-md-8">
                                <label for="sizem" class="form-label">Tamanho</label>
                                <input autocomplete="off" type="text" class="form-control" :class="{'is-invalid': v$.sizem.name.$error}" id="sizem" v-model="sizem.name" maxlength="31">
                                <div v-if="v$.sizem.name.$error" class="msg_error">{{nameErrors}}</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <label for="description" class="form-label">Descrição</label>
                                <input autocomplete="off" type="text" class="form-control" :class="{'is-invalid': v$.sizem.description.$error}" id="email" v-model="sizem.description" maxlength="101">
                                <div v-if="v$.sizem.description.$error" class="msg_error">{{descriptionErrors}}</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <label for="status" class="form-label">Status</label>
                                <select class="form-select" id="status" v-model="sizem.status">
                                    <option v-for="(statu, index) in status" :value="statu" :key="index">{{statu}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <label for="obs" class="form-label">Observação</label>
                                <textarea rows="5" autocomplete="off" type="text" class="form-control" :class="{'is-invalid': v$.sizem.obs.$error}" id="obs" v-model="sizem.obs" maxlength="501"></textarea>
                                <div v-if="v$.sizem.obs.$error" class="msg_error">{{obsErrors}}</div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button @click="reset" type="button" class="btn btn-danger" data-bs-dismiss="modal">Cancelar</button>
                    <button :disabled="v$.$invalid" @click="save" type="button" class="btn btn-primary" data-bs-dismiss="modal">Salvar</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="modal_delete" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modal_info" aria-hidden="true">
        <div class="modal-dialog" :class="larger">
            <div class="modal-content">
                <input id="sizem-id" type="hidden" v-model="sizem.id" />
                <div class="modal-header">
                    <h5 v-if="mode === 'delete'" class="modal-title" id="exampleModalLabel">Excluir Tamanho</h5>
                    <h5 v-if="mode === 'info'" class="modal-title" id="exampleModalLabel">Informações Do Tamanho</h5>
                </div>
                <div class="modal-body">
                    <div v-if="mode === 'delete'">
                        <div class="msg">
                            <h6>Você tem certeza que deseja excluir o registro?</h6>
                        </div>
                    </div>
                    <div v-if="mode === 'info'">
                        <div class="row">
                            <div class="col-md-12">
                                <label class="label_info"><b>Código: </b><span>{{sizem.code}}</span></label>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-md-12">
                                <label class="label_info"><b>Categoria: </b><span>{{sizem.name}}</span></label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <label class="label_info"><b>Descrição: </b><span>{{sizem.description}}</span></label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <label class="label_info"><b>Status: </b><span>{{sizem.status}}</span></label>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-md-12">
                                <label class="label_info"><b>Obs: </b><span>{{sizem.obs}}</span></label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button v-if="mode == 'info'" @click="reset" type="button" class="btn btn-danger btn_left" data-bs-dismiss="modal">Fechar</button>
                    <button v-if="mode != 'info'" @click="reset" type="button" class="btn btn-danger btn_left" data-bs-dismiss="modal">Cancelar</button>
                    <button v-if="mode != 'info'" @click="remove" type="button" class="btn btn-primary btn_right" data-bs-dismiss="modal">Confirmar</button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import {
    required,
    maxLength
} from '@vuelidate/validators'
import {
    baseApiUrl,
    userKey
} from '@/global'

import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

import axios from 'axios'
import Labels from "@/components/Labels/Label"
import Card from "@/components/Cards/Card"
import Tab from "@/components/Tabs/Tab"

export default {
    name: "sizem",
    data: function () {
        return {
            v$: useVuelidate(),
            mode: 'add',
            sizem: {},
            sizems: [],
            sizems_active: [],
            sizems_inactive: [],
            itens: '',
            larger: 'modal-lg',
            status: ['Ativo', 'Inativo'],
            search: '',
            dados_per_page_a: 0,
            dados_per_page_i: 0,
            current_page_a: 1,
            current_page_i: 1,
            total_page_a: 1,
            total_page_i:1,            
            page: 1,
            paginate_view_a:'',
            paginate_view_i:'',
            msg_error: '',
            load: false,
            size_add:true,                  
            size_info: true,
            size_edit: true,
            size_delete: true,
            companies_list:'',
            company_units:'',
            company_unit_selected: '0',
            company_selected:'0',
            view_add_button: false
        }
    },
    validations() {
        return {
            sizem: {
                code: {
                    maxLength: maxLength(30),
                    $autoDirty: true
                },
                name: {
                    required,
                    maxLength: maxLength(30),
                    $autoDirty: true
                },
                description: {
                    maxLength: maxLength(100),
                    $autoDirty: true
                },
                obs: {
                    maxLength: maxLength(500),
                    $autoDirty: true
                },
            }
        }
    },
    components: {
        Labels,
        Card,
        Tab,
        VPagination
    },
    mounted() {

        const access_credential = this.$cookie.getCookie(userKey)

        var permissoes = access_credential.permissions

        var permissoes_array = permissoes.split(',')

        this.size_add     = permissoes_array.includes('899983') == true ? true : false
        this.size_info    = permissoes_array.includes('899982') == true ? true : false
        this.size_edit    = permissoes_array.includes('899981') == true ? true : false
        this.size_delete  = permissoes_array.includes('899980') == true ? true : false

        this.sizem.status = this.status['0']

        this.loadcompanies();
    },
    computed: {
        codeErrors() {
            const code = this.v$.sizem.code
            if (!code.$dirty) {
                return ""
            }
            if (!code.maxLength.$response) {
                return "Limite máximo de 30 caracteres!"
            } else {
                return ""
            }
        },
        nameErrors() {
            const name = this.v$.sizem.name
            if (!name.$dirty) {
                return ""
            }
            if (!name.required.$response) {
                return "O campo é obrigatório!"
            } else if (!name.maxLength.$response) {
                return "Limite máximo de 30 caracteres!"
            } else {
                return ""
            }
        },
        descriptionErrors() {
            const description = this.v$.sizem.description
            if (!description.$dirty) {
                return ""
            }
            if (!description.maxLength.$response) {
                return "Limite máximo de 100 caracteres!"
            } else if (!description.$response) {
                return "Formato incorreto da informação!"
            } else {
                return ""
            }
        },
        obsErrors() {
            const obs = this.v$.sizem.obs
            if (!obs.$dirty) {
                return ""
            }
            if (!obs.maxLength.$response) {
                return "Limite máximo de 500 caracteres!"
            } else {
                return ""
            }
        }
    },
    methods: {
        loadsearch() {
            if (this.company_unit_selected > 0) {
                this.loadsizes_unit();
            } else if (this.company_selected > 0) {
                this.loadsizems()
            }
        },
        updateHandler(valor = 0) {

            this.page = valor

            if (this.company_unit_selected > 0) {
                this.loadsizes_unit();
            } else if (this.company_selected > 0) {
                this.loadsizems(1)
            }
        },
        loadcompanies() {

            this.load = true

            const access_credential = this.$cookie.getCookie(userKey)

             var params = {
                page: this.page,
                search: this.search,
                company_id: access_credential.company_id,
                code: access_credential.credential_code,
                menu: 'geral'
            }

            var method = 'post'
            var rotas = 'company-list-all-active'

            const access_token = this.$cookie.getCookie(userKey)

            const config = {
                headers: {
                    Authorization: `Bearer ${access_token.token}`
                }
            };

            axios[method](`${baseApiUrl}/${rotas}`, params, config, {
                timeout: 5000
            }).then(res => {

                this.company_selected = res.data.companies_list_active[0].id

                this.load = false

                this.loadsizems()

            }).catch((e) => {

                if (e.code === 'ECONNABORTED') {
                    this.load = false
                }
            });
        },
        loadsizems(valor = 0) {

            this.load = true

            const access_token = this.$cookie.getCookie(userKey)

            this.company_unit_selected = '0'

            if(valor != 1 || valor == 0){
                this.page = 1
            }

            var params = {
                page: this.page,
                search: this.search,
                company_id: access_token.company_id,
                credential_code: access_token.credential_code
            }

            var method = 'post'
            var rotas = 'size-dependency-company'

            const config = {
                headers: {
                    Authorization: `Bearer ${access_token.token}`
                }
            };

            axios[method](`${baseApiUrl}/${rotas}`, params, config,{
                timeout: 5000
            }).then(res => {

                this.company_units = res.data.company_units

                this.load = false
                
                if (res.data.sizes_list_active == 'vazio') {

                    this.sizems_active = 'vazio'
                    this.paginate_view_a = false
                    this.load = false
                } else {

                    this.total_page_a = res.data.sizes_list_active.last_page
                    this.current_page_a = res.data.sizes_list_active.current_page
                    this.dados_per_page_a = res.data.sizes_list_active.data.length

                    this.paginate_view_a = true

                    var sizes_active = res.data.sizes_list_active.data

                    var result_active = sizes_active.map(getList)

                    this.sizems_active = result_active

                    this.load = false

                }

                if (res.data.sizes_list_inactive == 'vazio') {
                    this.sizems_inactive = 'vazio'
                    this.paginate_view_i = false
                    this.load = false
                } else {

                    this.total_page_i = res.data.sizes_list_inactive.last_page
                    this.current_page_i = res.data.sizes_list_inactive.current_page
                    this.dados_per_page_i = res.data.sizes_list_inactive.data.length

                    this.paginate_view_i = true

                    var sizes_inactive = res.data.sizes_list_inactive.data

                    var result_inactive = sizes_inactive.map(getList)

                    this.sizems_inactive = result_inactive

                    this.load = false

                }
            }).catch((e) => {

                if (e.code === 'ECONNABORTED') {
                    this.load = false
                }
            });

            function getList(item) {
                return {
                    id: item.id,
                    code: item.code,
                    name: item.name,
                    status: item.status,
                    description: item.description,
                    obs: item.obs
                }
            }
        },
        loadsizes_unit() {

            this.load = true

            this.view_add_button = true

            var method = 'post'
            var rotas = 'size-dependency-unit'

            var params = {
                page: this.page,
                search: this.search,
                company_unit_id: this.company_unit_selected
            }

            const access_token = this.$cookie.getCookie(userKey)

            const config = {
                headers: {
                    Authorization: `Bearer ${access_token.token}`
                }
            };

            axios[method](`${baseApiUrl}/${rotas}`, params, config, {
                timeout: 5000
            }).then(res => {
                
                if (res.data.size_list_active == 'vazio') {

                    this.sizems_active = 'vazio'
                    this.paginate_view_a = false

                    this.load = false
                } else {

                    this.total_page_a = res.data.sizes_list_active.last_page
                    this.current_page_a = res.data.sizes_list_active.current_page
                    this.dados_per_page_a = res.data.sizes_list_active.data.length

                    this.paginate_view_a = true

                    var sizems_active = res.data.sizes_list_active.data

                    var result_active = sizems_active.map(getList)

                    this.sizems_active = result_active

                    this.load = false

                }

                if (res.data.sizes_list_inactive == 'vazio') {
                    this.sizems_inactive = 'vazio'
                    this.paginate_view_i = false

                    this.load = false
                } else {

                    this.total_page_i = res.data.sizes_list_inactive.last_page
                    this.current_page_i = res.data.sizes_list_inactive.current_page
                    this.dados_per_page_i = res.data.sizes_list_inactive.data.length

                    this.paginate_view_i = true

                    var sizems_inactive = res.data.sizes_list_inactive.data

                    var result_inactive = sizems_inactive.map(getList)

                    this.sizems_inactive = result_inactive

                    this.load = false

                }
            }).catch((e) => {

                this.load = false

                if (e.code === 'ECONNABORTED') {
                    this.load = false
                }
            });

            function getList(item) {
                return {
                    id: item.id,
                    code: item.code,
                    name: item.name,
                    status: item.status,
                    description: item.description,
                    obs: item.obs
                }
            }
        },
        order(iten) {

            var list = Object.values(iten)

            list = list.slice(1, 4)

            return list;
        },
        reset(mode = 'add') {
            this.mode = 'add'
            this.sizem = {}
            this.sizem.status = this.status['0']
            this.v$.$reset();

            if (mode != 'info' && mode != 'cancelar') {
                this.loadsizes_unit()
            }
        },
        save() {

            this.load = true

            if (this.v$.$invalid) {

                console.log("Formulário alterado!")

                this.load = false

            } else {
                const method = 'post'

                var rotas = this.sizem.id ? 'size-update' : 'size-create'

                const access_token = this.$cookie.getCookie(userKey)

                const config = {
                    headers: {
                        'Accept': "application/json",
                        Authorization: `Bearer ${access_token.token}`
                    }
                }

                this.sizem.company_id = this.company_selected
                this.sizem.company_unit_id = this.company_unit_selected

                axios[method](`${baseApiUrl}/${rotas}`, this.sizem, config,{
                    timeout: 5000
                }).then(res => {

                    this.load = false

                    if (res.data.code == '2000') {
                        this.$toast.success(res.data.message)
                    } else if (res.data.code == '3000') {
                        this.$toast.success(res.data.message)
                    } else {
                        this.$toast.success(res.data.message)
                    }

                    this.reset()
                }).catch((e) => {

                    if (e.code === 'ECONNABORTED') {
                        this.load = false
                    }
                });
            }
        },
        loadsizem(sizem, mode = 'add') {

            if (mode === 'delete') {
                this.larger = ''
            } else {
                this.larger = 'modal-lg'
            }

            this.mode = mode

            this.sizem = {
                ...sizem
            }
        },
        remove() {

            this.load = true

            if (this.dados_per_page_a == 1 || this.dados_per_page_i == 1) {
                this.page -= 1
            }

            var method = 'post'
            var rotas = 'size-delete'

            const access_token = this.$cookie.getCookie(userKey)

            const config = {
                headers: {
                    'Accept': "application/json",
                    Authorization: `Bearer ${access_token.token}`
                }
            }

            axios[method](`${baseApiUrl}/${rotas}`, this.sizem, config,{
                timeout: 5000
            }).then(res => {

                this.load = false

                if (res.data == 'Tamanho não encontrado!') {
                    this.$toast.error('Tamanho não encontrado!')
                } else {
                    this.$toast.success('Tamanho excluído com sucesso!')
                }

                this.reset()
            }).catch((e) => {

                if (e.code === 'ECONNABORTED') {
                    this.load = false
                }
            });
        }
    }
}
</script>

<style scoped>
.paginate {
    display: flex;
    justify-content: center;
}

/* ------------------------------------ Filtros de pesquisa ---------------------------------------*/
.cards .select_filter {
    margin-bottom: 15px;
}

/*-------------------------------------- Cabeçalho Companies --------------------------------------*/
.sizem {
    margin: 25px;
}

.sizem .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

/* -------------- Cabeçalho ------------------*/
.search .input-search {
    border-radius: 3px;
    border-color: #744cbc;
}

.search .input-search:focus,
.btn-search {
    box-shadow: 0 0 0 0 !important;
    outline: none !important;
}

.search .icon-search {
    color: #ffffff;
    transform: rotate(90deg);
}

.search .btn-search {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-color: #744cbc;
}

.search .btn-search:hover {
    background-color: #886aea !important;
}

/*--------------------------------------------- Table ---------------------------------------------*/
.sizem .cards .tables thead tr th {
    border-style: solid;
    border-bottom-width: 2px;
    color: rgb(114, 132, 140);
    border-bottom-color: rgb(222, 226, 230) !important;
}

.sizem .cards .tables thead th {
    font-size: 15px;
    font-weight: 600;
    padding-top: 10px;
    padding-bottom: 10px;
}

.sizem .cards .tables tbody td {
    font-size: 14px;
    padding-top: 4px;
    padding-bottom: 4px;
    line-height: 30px;
    color: #72848c;
}

.sizem .cards .tables tbody button {
    color: #ffffff;
    margin: 0 2px;
    padding: 2px 5px;
    box-shadow: 0 0 0 0 !important;
    outline: none !important;
}

.sizem .cards .pagination {
    display: flex;
    justify-content: center;
}

/*------------------------------------------- Paginação ------------------------------------------ */
.pagination-table {
    margin-top: 30px;
}

.pagination-table .page-item .page-link {
    color: #72848c;
    text-decoration: none;
    border: 1px solid #dee2e6;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    cursor: pointer;
}

.pagination-table .page-item.active .page-link {
    color: #ffffff;
    background-color: #744cbc;
    border-color: #744cbc;
}

/*--------------------------------------------- Modal ---------------------------------------------*/
.sizem .modal-content {
    border: none;
}

/*------------------------------------------- Modal Header ----------------------------------------*/
.sizem .modal-header {
    padding: 10px 20px;
    background-color: #744cbc;
}

.sizem .modal-header .modal-title {
    color: #ffffff;
    font-size: 18px;
}

/*-------------------------------------------- Modal Body -----------------------------------------*/
.sizem .modal-body .msg {
    text-align: center;
    color: #72848c;
}

.sizem .modal-body .msg h6 {
    padding: 20px 0px 12px 0px;
    font-size: 17px;
}

.sizem .modal-body label {
    font-size: 15px;
    color: #72848c;
    margin-top: 10px;
    margin-left: 1px;
    margin-bottom: 5px;
}

/*------------------------------------------- Modal Footer ----------------------------------------*/
.sizem .modal-footer {
    padding: 8px;
}

.sizem .modal-footer button {
    border: none;
}

/*----------------------------------------- Mensagem De Erro --------------------------------------*/
.msg_error {
    color: #ff0000;
    font-size: 14px;
}

input,
input:focus,
.is-invalid {
    box-shadow: 0 0 0 0;
    outline: 0;
}

.is-invalid {
    box-shadow: 0 0 0 0 !important;
    outline: 0 !important;
}

.dados_empty {
    color: #72848c;
    font-weight: 600;

}

.loading button {
    position: absolute;
    top: calc(50% - 35px);
    left: calc(50%);
    margin-left: 90px;
    width: 70px;
    height: 70px;
    background-color: #3b0253;
    border: none;
}

.loading span {
    width: 30px;
    height: 30px;
}
</style>
