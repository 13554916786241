import { createStore } from 'vuex'

export default createStore({
  state: {
    isMenuVisible:true,
    user:null
  },
  mutations: {
    toggleMenu(state, isVisible){

        if(isVisible == undefined){
          state.isMenuVisible = !state.isMenuVisible
        }else{
          state.isMenuVisible = isVisible
        }

        //console.log('toggleMenu = ' + state.isMenuVisible)
    },
    setUser(state, user){

      state.user = user
    }
  },
  getters:{
    userLogado(state){
      return state.user
    }
  }
})
