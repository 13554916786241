import { createRouter, createWebHistory } from 'vue-router'
/* Dashboard */
import System from '../views/template/dashboard/System.vue'
import Machine from '../views/template/dashboard/Machine.vue'
import Products from '../views/template/dashboard/Products.vue'

/* Autenticação */
import Login from '../views/template/login/Login.vue'
import RedefinirPassword from '../views/template/login/RedefinirPassword.vue'
import RedefinirPasswordSendEmail from '../views/template/login/RedefinirPasswordSendEmail.vue'
import RedefinirPasswordNew from '../views/template/login/RedefinirPasswordNew.vue'

/* Gestão de clientes */
import Company from '../views/template/client/Company.vue'
import Unit from '../views/template/client/Unit.vue'
import Zone from '../views/template/client/Zone.vue'
import Sector from '../views/template/client/Sector.vue'
import Characteristics from '../views/template/client/Characteristics.vue'

/* Gestão de produtos */
import Product from '../views/template/Product_manager/Product.vue'
import Category from '../views/template/Product_manager/Category.vue'
import Unit_measurement from '../views/template/Product_manager/Unit_measurement.vue'
import Size from '../views/template/Product_manager/Size.vue'

/* Central de identificação */
import People from '../views/template/central_identification/People.vue'
import Role from '../views/template/central_identification/Role.vue'
import Group from '../views/template/central_identification/Group.vue'
import Permission from '../views/template/central_identification/Permission.vue'
import Justification from '../views/template/central_identification/Justification.vue'
import Requisition from '../views/template/central_identification/Requisition.vue'

/* Relatórios */
import Epi_sheet from '../views/template/report/Epi_sheet.vue'
import Menu_report from '../views/template/report/MenuReport.vue'
import Delivery_regra from '../views/template/report/delivery_regra.vue'
import Delivery_requisicao from '../views/template/report/delivery_requisicao.vue'


/* Controle de acesso */
import User from '../views/template/user/User.vue'
import Credential from '../views/template/user/Credential.vue'
import Access_permission from '../views/template/user/Access-permission.vue'

/* Uploads */
import UploadZone from '../views/template/uploads/Upload-zone.vue'
import UploadCategory from '../views/template/uploads/Upload-category.vue'
import UploadFunction from '../views/template/uploads/Upload-function.vue'
import UploadGroup from '../views/template/uploads/Upload-group.vue'
import UploadJustification from '../views/template/uploads/Upload-justification.vue'
import UploadSector from '../views/template/uploads/Upload-sector.vue'
import UploadCollaborators from '../views/template/uploads/Upload-collaborators'
import UploadProducts from '../views/template/uploads/Upload-product'
import UploadGroupxProduct from '../views/template/uploads/Upload-group-product'

/* Config */
import Equipament from '../views/template/config/Equipament.vue'


import {userKey} from '@/global'

import { VueCookieNext } from 'vue-cookie-next'


const routes = [
  /* Dashboard */
  {
    path: '/system',
    name: 'System',
    component: System
  },
  {
    path: '/machine',
    name: 'Machine',
    component: Machine
  },
  {
    path: '/products',
    name: 'Products',
    component: Products
  },
  /* Autenticação */
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  /* Redefinição de senha */
  {
    path: '/RedefinirPassword',
    name: 'RedefinirPassword',
    component: RedefinirPassword
  },
  /* Redefinição de senha envio de email */
  {
    path: '/RedefinirPasswordSendEmail',
    name: 'RedefinirPasswordSendEmail',
    component: RedefinirPasswordSendEmail
  },
  /* Redefinição de senha - cadastro de nova senha */
  {
    path: '/RedefinirPasswordNew/:token',
    name: 'RedefinirPasswordNew',
    component: RedefinirPasswordNew
  },
  /* Gestão de clientes */
  {
    path: '/company',
    name: 'Company',
    component: Company,
    //meta:{requiresAuth: true}
  },
  {
    path: '/unit',
    name: 'Unit',
    component: Unit,
    //meta:{requiresAuth: true}
  },
  {
    path: '/zone',
    name: 'Zone',
    component: Zone,
    //meta:{requiresAuth: true}
  },
  {
    path: '/characteristics',
    name: 'Characteristics',
    component: Characteristics
  },
  /* Gestão de produtos */
  {
    path: '/product',
    name: 'Product',
    component: Product,
    //meta:{requiresAuth: true}
  },
  {
    path: '/sector',
    name: 'Sector',
    component: Sector,
    //meta:{requiresAuth: true}
  },
  {
    path: '/category',
    name: 'Category',
    component: Category,
    //meta:{requiresAuth: true}
  },
  {
    path: '/unit_measurement',
    name: 'Unit_measurement',
    component: Unit_measurement,
    //meta:{requiresAuth: true}
  },
  {
    path: '/size',
    name: 'Size',
    component: Size,
    meta:{requiresAuth: true}
  },
  /* Central de identificação */
  {
    path: '/people',
    name: 'People',
    component: People,
    //meta:{requiresAuth: true}
  },
  {
    path: '/role',
    name: 'Role',
    component: Role,
    //meta:{requiresAuth: true}
  },
  {
    path: '/group',
    name: 'Group',
    component: Group,
    //meta:{requiresAuth: true}
  },
  {
    path: '/permission',
    name: 'Permission',
    component: Permission,
    //meta:{requiresAuth: true}
  },
  {
    path: '/justification',
    name: 'Justification',
    component: Justification,
    //meta:{requiresAuth: true}
  },
  {
    path: '/requisition',
    name: 'Requisition',
    component: Requisition,
    //meta:{requiresAuth: true}
  },
  /* Report */
  {
    path:'/epi_sheet',
    name:'Epi_sheet',
    component: Epi_sheet,
    //meta:{requiresAuth: true}
  },
  {
    path:'/menu_report',
    name:'Menu_report',
    component: Menu_report,
    //meta:{requiresAuth: true}
  },
  {
    path:'/delivery_regra',
    name:'Delivery_regra',
    component: Delivery_regra,
    //meta:{requiresAuth: true}
  },
  {
    path:'/delivery_requisicao',
    name:'Delivery_requisicao',
    component: Delivery_requisicao,
    //meta:{requiresAuth: true}
  },

  /* Controle de acesso */
  {
    path: '/user',
    name: 'User',
    component: User,
    meta:{requiresAuth: true}
  },
  {
    path: '/credential',
    name: 'Credential',
    component: Credential,
    //meta:{requiresAuth: true}
  },
  {
    path: '/access_permission',
    name: 'Access_permission',
    component: Access_permission,
    //meta:{requiresAuth: true}
  },
  /*---------------------------------------------------------------------------------------------*/
  /*                                             Upload                                          */
  /*---------------------------------------------------------------------------------------------*/
  {
    path: '/uploadZone',
    name: 'UploadZone',
    component: UploadZone,
    meta:{requiresAuth: true}
  },
  {
    path: '/uploadCategory',
    name: 'UploadCategory',
    component: UploadCategory,
    meta:{requiresAuth: true}
  },
  {
    path: '/uploadFunction',
    name: 'UploadFunction',
    component: UploadFunction,
    meta:{requiresAuth: true}
  },
  {
    path: '/uploadGroup',
    name: 'UploadGroup',
    component: UploadGroup,
    meta:{requiresAuth: true}
  },
  {
    path: '/uploadJustification',
    name: 'UploadJustification',
    component: UploadJustification,
    meta:{requiresAuth: true}
  },
  {
    path: '/uploadSector',
    name: 'UploadSector',
    component: UploadSector,
    meta:{requiresAuth: true}
  },
  {
    path: '/uploadCollaborators',
    name: 'UploadCollaborators',
    component: UploadCollaborators,
    meta:{requiresAuth: true}
  },
  {
    path: '/uploadProducts',
    name: 'UploadProducts',
    component: UploadProducts,
    meta:{requiresAuth: true}
  },
  {
    path: '/uploadgroupxproduct',
    name: 'UploadGroupxProduct',
    component: UploadGroupxProduct,
    meta:{requiresAuth: true}
  },
  
  /*---------------------------------------------------------------------------------------------*/
  /*                                             Config                                          */
  /*---------------------------------------------------------------------------------------------*/
  {
    path: '/equipament',
    name: 'Equipament',
    component: Equipament,
    meta:{requiresAuth: true}
  },
  
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {

  const user = VueCookieNext.getCookie(userKey)

  if(to.matched.some(record => record.meta.requiresAuth)){

      user != null ? next() : next({path: '/login'})

  }else{
    next()
  }
})

export default router
