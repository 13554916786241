<template>
<div class="dashboard">
    <div class="row">
        <div v-if="false" class="row">
            <div class="col-md-3">
                <select class="form-select form-select-sm select_filter" id="status" v-model="company_selected" @change="loadDashboardSystem">
                    <option value="0" selected disabled>Selecione uma empresa</option>
                    <option v-for="(company_list, index) in companies_list" :value="company_list.id" :key="index">{{company_list.fantasy_name}}</option>
                </select>
            </div>
            <div class="col-md-3">
                <select class="form-select form-select-sm select_filter" id="status" v-model="company_unit_selected" @change="loadDashboard_unit">
                    <option value="0" selected disabled>Selecione uma unidade</option>
                    <option v-for="(company_unit, index) in company_units" :value="company_unit.id" :key="index">{{company_unit.fantasy_name}}</option>
                </select>
            </div>
            <div v-if="zones" class="col-md-3">
                <select class="form-select form-select-sm select_filter" id="status" v-model="zone_selected" @change="loadDashboard_zone">
                    <option value="0" selected disabled>Selecione uma área</option>
                    <option v-for="(zone, index) in zones" :value="zone.id" :key="index">{{zone.name}}</option>
                </select>
            </div>
            <div v-if="sectors" class="col-md-3">
                <select class="form-select form-select-sm select_filter" id="status" v-model="sector_selected" @change="loadDashboard_sector">
                    <option value="0" selected disabled>Selecione um setor</option>
                    <option v-for="(sector, index) in sectors" :value="sector.id" :key="index">{{sector.name}}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3">
            <CardInfoP title="Total usuários" :quantity="users_total" color="linear-gradient(45deg, #4facfe, #00f2fe)"></CardInfoP>
        </div>
        <div class="col-md-3">
            <CardInfoP title="Usuários ativos" :quantity="users_active" color="linear-gradient(45deg,#11998e,#38ef7d)"></CardInfoP>
        </div>
        <div class="col-md-3">
            <CardInfoP title="Usuários inativos" :quantity="users_inactive" color="linear-gradient(45deg,#ee0979,#ff6a00)"></CardInfoP>
        </div>
        <div class="col-md-3">
            <CardInfoP title="Usuários desligados" :quantity="users_off" color="linear-gradient(45deg,#f54ea2,#ff7676)"></CardInfoP>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col-md-6">
            <div class="card card-style">
                <div class="card-body card_body">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-12 product">
                                    <div class="title">Produto</div>
                                </div>
                                <div class="col-md-12 product">
                                    <div class="grafic">
                                        <div id="chart1"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="title">Permissões</div>
                                </div>
                                <div class="col-md-12">
                                    <div class="grafic">
                                        <div id="chart2"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="col-md-12">
                <div class="card card-style" :style="{ background: background }">
                    <div class="card-body card_body">
                        <div>
                            <p class="title-label">Entregas</p>
                        </div>
                        <div id="chart3">
                        </div>
                        <div class="type_delivery">
                            <div class="rule">
                                <p class="quantity">{{delivery_regra}}</p>
                                <p class="title">Por regra</p>
                            </div>
                            <div class="requisition">
                                <p class="quantity">{{delivery_request}}</p>
                                <p class="title">Por requisições</p>
                            </div>
                        </div>
                    </div>
                    <div class="circle"></div>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="col-md-12">
                <CardInfoTable title="Colaboradores" :quantity_total="peoples_total" :quantity_active="peoples_active" :quantity_inactive="peoples_inactive" :quantity_off="peoples_off" color="#717171" background="#fff"></CardInfoTable>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import ApexCharts from 'apexcharts';
import {
    baseApiUrl,
    showError,
    userKey
} from '@/global'
import axios from 'axios'
import CardInfoP from "@/components/Cards/Card-info-p";
import CardInfoTable from "@/components/Cards/Card-info-table";

export default {
    name: "system",
    data: function () {
        return {
            users_total: 0,
            users_active: 0,
            users_inactive: 0,
            users_off: 0,
            peoples_total: 0,
            peoples_active: 0,
            peoples_inactive: 0,
            peoples_off: 0,
            products_total: 10,
            products_active: 5,
            products_inactive: 5,
            companies_list: '',
            company_selected: '0',
            company_units: '',
            company_unit_selected: '0',
            zones: '',
            zone_selected: '0',
            sectors: '',
            sector_selected: '0',
            view_add_button: false,
            total_quantity: 0,
            delivery_regra: '0',
            delivery_request: '0',
            group_permission_total:'0',
            group_permission_active:'0',
            group_permission_inactive:'0'
        }
    },
    components: {
        CardInfoP,
        CardInfoTable
    },
    created() {

        //this.loadcompanies();

        this.loadDashboardSystem();

    },
    mounted() {

    },
    methods: {
        loadcompanies() {

            this.load = true

            var params = {
                page: this.page,
                search: this.search
            }

            var method = 'post'
            var rotas = 'company-list-all-active'

            const access_token = this.$cookie.getCookie(userKey)

            const config = {
                headers: {
                    Authorization: `Bearer ${access_token.token}`
                }
            };

            axios[method](`${baseApiUrl}/${rotas}`, params, config, {
                timeout: 5000
            }).then(res => {

                this.companies_list = res.data.companies_list_active.data

                this.load = false

            }).catch((e) => {

                if (e.code === 'ECONNABORTED') {
                    this.load = false
                }
            });
        },
        loadDashboardSystem() {

            this.load = true

            var method = 'post'
            var rotas = 'dashboard_first'

            const access_token = this.$cookie.getCookie(userKey)

            const config = {
                headers: {
                    Authorization: `Bearer ${access_token.token}`
                }
            };

            var params = {
                search: this.search,
                company_id: access_token.company_id
            }

            axios[method](`${baseApiUrl}/${rotas}`, params, config, {
                timeout: 5000
            }).then(res => {

                console.log(res.data)

                this.company_units = res.data.company_units

                this.users_total = res.data.users_full_count
                this.users_active = res.data.users_active_count
                this.users_inactive = res.data.users_inactive_count
                this.users_off = res.data.users_off

                this.peoples_total = res.data.collaborators_full_count
                this.peoples_active = res.data.collaborators_active_count
                this.peoples_inactive = res.data.collaborators_inactive_count
                this.peoples_off = res.data.collaborators_off

                this.products_total = res.data.products_full_count
                this.products_active = res.data.products_active_count
                this.products_inactive = res.data.products_inactive_count

                this.delivery_regra = res.data.delivery_regra

                this.delivery_request = res.data.delivery_request

                this.total_quantity = this.delivery_regra + this.delivery_request

                this.group_permission_total = res.data.GroupPermission_full_count
                this.group_permission_active = res.data.GroupPermission_active_count
                this.group_permission_inactive = res.data.GroupPermission_inactive_count

                this.grafic_1();

            }).catch((e) => {

                showError(e)

                if (e.code === 'ECONNABORTED') {
                    this.load = false
                }
            });
        },
        loadDashboard_unit() {

            this.load = true

            this.view_add_button = true

            var method = 'post'
            var rotas = 'dashboard_first_unit'

            var params = {
                page: this.page,
                search: this.search,
                company_unit_id: this.company_unit_selected
            }

            const access_token = this.$cookie.getCookie(userKey)

            const config = {
                headers: {
                    Authorization: `Bearer ${access_token.token}`
                }
            };

            axios[method](`${baseApiUrl}/${rotas}`, params, config, {
                timeout: 5000
            }).then(res => {

                console.log(res.data)

                this.users_total = res.data.users_full_count
                this.users_active = res.data.users_active_count
                this.users_inactive = res.data.users_inactive_count
                this.users_off = res.data.users_off

                this.peoples_total = res.data.collaborators_full_count
                this.peoples_active = res.data.collaborators_active_count
                this.peoples_inactive = res.data.collaborators_inactive_count
                this.peoples_off = res.data.collaborators_off

                this.zones = res.data.zones_list_active

            }).catch((e) => {

                this.load = false

                if (e.code === 'ECONNABORTED') {
                    this.load = false
                }
            });
        },
        loadDashboard_zone() {

            this.load = true

            var method = 'post'
            var rotas = 'dashboard_first_zone'

            var params = {
                page: this.page,
                search: this.search,
                company_id: this.company_selected,
                company_unit_id: this.company_unit_selected,
                zone_id: this.zone_selected
            }

            const access_token = this.$cookie.getCookie(userKey)

            const config = {
                headers: {
                    Authorization: `Bearer ${access_token.token}`
                }
            };

            axios[method](`${baseApiUrl}/${rotas}`, params, config, {
                timeout: 5000
            }).then(res => {

                console.log(res.data)

                this.users_total = res.data.users_full_count
                this.users_active = res.data.users_active_count
                this.users_inactive = res.data.users_inactive_count
                this.users_off = res.data.users_off

                this.peoples_total = res.data.collaborators_full_count
                this.peoples_active = res.data.collaborators_active_count
                this.peoples_inactive = res.data.collaborators_inactive_count
                this.peoples_off = res.data.collaborators_off

                this.sectors = res.data.sectors_active

            }).catch((e) => {

                this.load = false

                if (e.code === 'ECONNABORTED') {
                    this.load = false
                }
            });
        },
        loadDashboard_sector() {

            this.load = true

            var method = 'post'
            var rotas = 'dashboard_first_sector'

            var params = {
                page: this.page,
                search: this.search,
                company_id: this.company_selected,
                company_unit_id: this.company_unit_selected,
                zone_id: this.zone_selected,
                sector_id: this.sector_selected
            }

            const access_token = this.$cookie.getCookie(userKey)

            const config = {
                headers: {
                    Authorization: `Bearer ${access_token.token}`
                }
            };

            axios[method](`${baseApiUrl}/${rotas}`, params, config, {
                timeout: 5000
            }).then(res => {

                console.log(res.data)

                this.users_total = res.data.users_full_count
                this.users_active = res.data.users_active_count
                this.users_inactive = res.data.users_inactive_count
                this.users_off = res.data.users_off

                this.peoples_total = res.data.collaborators_full_count
                this.peoples_active = res.data.collaborators_active_count
                this.peoples_inactive = res.data.collaborators_inactive_count
                this.peoples_off = res.data.collaborators_off

                /*this.products_total = res.data.products_full_count
                this.products_active = res.data.products_active_count
                this.products_inactive = res.data.products_inactive_count*/

            }).catch((e) => {

                this.load = false

                if (e.code === 'ECONNABORTED') {
                    this.load = false
                }
            });
        },
        grafic_1() {
            var options1 = {
                series: [this.products_total, this.products_active, this.products_inactive],
                colors: ['#008ffb', '#00e396', '#F42B4E'],
                chart: {
                    width: 280,
                    type: 'donut',
                },
                labels: ['Total', 'Ativos', 'Inativos'],
                dataLabels: {
                    enabled: false
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            show: false
                        }
                    }
                }],
                legend: {
                    position: 'bottom',
                    offsetY: 0,
                    height: 0,
                }
            };

            var chart1 = new ApexCharts(document.querySelector("#chart1"), options1);
            chart1.render();

            var options2 = {
                series: [this.group_permission_total, this.group_permission_active, this.group_permission_inactive],
                chart: {
                    width: 280,
                    type: 'donut',
                },
                labels: ['Total', 'Ativas', 'Inativas'],
                dataLabels: {
                    enabled: false
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            show: false
                        }
                    }
                }],
                legend: {
                    position: 'bottom',
                    offsetY: 0,
                    height: 0,
                }
            };

            var chart2 = new ApexCharts(document.querySelector("#chart2"), options2);
            chart2.render();

            var total_itens = this.total_quantity

            var options = {
                chart: {
                    height: 200,
                    type: "radialBar",
                },
                series: [100],
                colors: ["#00f2fe"],
                plotOptions: {
                    radialBar: {
                        hollow: {
                            margin: 0,
                            size: "70%",
                            background: "#293450"
                        },
                        track: {
                            dropShadow: {
                                enabled: true,
                                top: 2,
                                left: 0,
                                blur: 4,
                                opacity: 0.15
                            }
                        },
                        dataLabels: {
                            name: {
                                offsetY: 25,
                                color: "#fff",
                                fontSize: "13px"
                            },
                            value: {
                                offsetY: -15,
                                color: "#fff",
                                fontSize: "30px",
                                show: true,
                                formatter: function (val) {
                                    return parseInt(val);
                                },
                            },
                            total: {
                                color: "#fff",
                                show: true,
                                label: 'Itens',
                                formatter: function () {
                                    // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                                    return total_itens
                                }
                            }
                        }
                    }
                },
                fill: {
                    type: "gradient",
                    gradient: {
                        shade: "dark",
                        type: "vertical",
                        gradientToColors: ["#87D4F9"],
                        stops: [0, 100]
                    }
                },
                stroke: {
                    lineCap: "round"
                },
                labels: ["Itens"]
            };

            var chart = new ApexCharts(document.querySelector("#chart3"), options);

            chart.render();
        }
    }
}
</script>

<style scoped>
/*-------------------------------------- Cabeçalho System --------------------------------------*/
.dashboard {
    margin: 25px;
}

/* ------------------------------------ Filtros de pesquisa ---------------------------------------*/
.dashboard .select_filter {
    margin-bottom: 15px;
}

/*======================================== Grafic two ==========================================*/
.card-style {
    border: none;
    box-shadow: 0 5px 15px rgb(57 98 254 / 2%), 0 3px 6px rgb(117 108 254 / 12%);
    border: none;
    background-color: #fff;
    margin-bottom: 10px;
}

.card_body {
    padding: 30px 10px 30px 10px;
}

.card_body .product {
    border-right: 1px solid #cdcdcd;
}

.card_body .title {
    text-align: center;
    margin-top: 15px;
    margin-bottom: 40px;
    font-weight: 500;
    font-size: 18px;
    color: #717171;
}

.card_body .grafic {
    display: flex;
    justify-content: center;
}

#chart1,
#chart2 {
    height: 212px;
}

#chart3 {
    height: 179px;
}

/*--------------------------------------- Style card Entregas --------------------------------------*/
.title-label {
    text-align: center;
    margin-top: 0;
    margin-top: 15px;
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 18px;
    color: #717171;
}

.result {
    color: #fff;
    font-size: 18px;
    line-height: 22px;
    font-weight: 500;
    text-align: left;
    margin-top: 0;
    margin-bottom: 10px;
}

.circle {
    width: 100px;
    height: 100px;
    position: absolute;
    margin-top: 10px;
    right: -20px;
    bottom: 0px;
    background-color: rgba(255, 255, 255, 15%);
    border-radius: 100%;
    border: none;
}

.type_delivery {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.rule {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-right: 1px solid rgba(121, 121, 121, 0.2);
}

.rule p {
    margin-bottom: 0;
    text-align: center;
    font-weight: 500;
}

.requisition {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-left: none;
}

.requisition p {
    margin-bottom: 0;
    text-align: center;
    font-weight: 500;
}

.rule .title,
.requisition .title {
    color: #717171;
    font-size: 14px;
    margin-bottom: 0px;
}

.rule .quantity,
.requisition .quantity {
    color: #000;
    font-size: 18px;
    margin-bottom: 0px;
}
</style>
